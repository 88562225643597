div.uploadHistoryExpTable>ul.collapse.show {
    display: table-row;
}

.gridView .colmDiVal.minheight150 {
    min-height: 120px;
}

.gridView .colmDiVal.minheight155 {
    min-height: 155px;
}

.piechartvalue {
    width: 100%;
    float: left;
    text-align: center;
    color: #fff;
    margin-top: -30px;
}

.piechartvalueText {
    font-size: 48px;
}

.piechartvalueText .drinksText {
    font-size: 12px;
    color: #818285;
}

.assignUnitDotted .orgznSiteTree div.treePrentDivActive>ul li.dottedUnits>div a {
    border: 2px dashed #0aaeff;
    background: transparent;
    color: #0aadfe;
}

.orgznSiteTree>ul li.nonDottedUnits>.orgznSiteTreeLiDiv a {
    background: #575f67;
    border: 2px solid #575f67;
}

.assignUnitDotted .orgznSiteTree>ul li>div>ul li>div>ul li>div>ul li>div>ul li>div.treePrentDivActive>ul li.nonDottedUnits>div a {
    background: #575f67;
    border: 2px solid #575f67;
    color: #fff;
}

.trendimg {
    background: url("../images/icons/arrow3DownT.svg") no-repeat top left;
    background-size: 20px auto;
    padding-top: 18px;
}

.totalsoldtext {
    font-size: 14px;
    width: 100%;
    float: left;
    text-align: center;
    color: #818285;
}

.topSoldDrinkschartouterdiv {
    width: 30%;
    height: 360px;
    float: left;
    position: relative;
}

.ReactTable {
    border: none;
}

.ReactTable .rt-thead .rt-th {
    border-right: none !important;
}

.ReactTable .rt-thead .rt-tr {
    text-align: left !important;
    color: #818285;
    font-size: 13px;
    border-bottom: 1px solid #4a4a4a;
    border-right: none !important;
}

.ReactTable .rt-tbody .rt-tr-group {
    border-bottom: none !important;
}

.ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td:first-child {
    border-bottom: none;
}

.ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td {
    border-bottom: 1px solid #1f2124;
    border-left: none;
    border-right: none;
}

.ReactTable .rt-tbody .rt-td {
    text-align: center;
    color: #fff;
    font-size: 14px;
}

.ReactTable .rt-tbody .rt-td img {
    height: 32px;
    width: 32px;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 -1px 0 0 #fff !important;
    color: #fff;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: inset 0 -1px 0 0 #fff !important;
    color: #fff;
}

.ReactTable .-pagination {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 0px !important;
    border-top: 2px solid #000 !important;
    box-shadow: none !important;
}

.ReactTable .-pagination .-pageInfo {
    color: #fff;
}

.ReactTable .-pagination .-btn {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 120px !important;
    height: 100%;
    border: 0;
    border-radius: 3px;
    padding: 0px !important;
    font-size: 1em;
    color: rgba(0, 0, 0, 0.6);
    background: none !important;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    cursor: pointer;
    outline: none;
}

.ReactTable .-pagination .-previous .paginationFooter {
    flex: 1 1;
    text-align: center;
    width: 120px;
    min-width: 150px;
    max-width: 250px;
}

.ReactTable .-pagination .-next .-btn {
    float: right;
}

.ReactTable .-pagination .paginationFooter {
    background: none;
}

.ReactTable .-pagination .-next .paginationFooter {
    flex: 1 1;
    text-align: center;
    width: 155px;
    min-width: 150px;
    max-width: 250px;
    float: right;
}

.ReactTable .-pagination .-next .paginationFooter>ul>li:nth-child(1) {
    text-align: center;
    width: 260px;
    border-right: 2px solid #000;
    color: #78797b;
}

.ReactTable .-pagination .-next .paginationFooter>ul>li:nth-child(2) {
    text-align: center;
    text-align: -webkit-center;
    color: #78797b;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
    background: transparent !important;
    color: #fff;
}

.ReactTable .-sort-desc {
    box-shadow: none !important;
}

.ReactTable .-sort-asc {
    box-shadow: none !important;
}

.ReactTable .-sort-asc:before,
.ReactTable .-sort-desc:before {
    content: "";
    height: 20px;
    width: 20px;
    float: right;
}

.ReactTable .-sort-asc:before {
    background: url("../images/icons/sort-up_white_A.svg");
}

.ReactTable .-sort-desc:before {
    background: url("../images/icons/sort-down_white_A.svg");
}

.service-react-table-grid .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td .error-table {
    width: 100%;
    max-height: 42px;
    height: 42px;
    padding: 0px;
    float: left;
    clear: both;
    background: #2d3034;
}

.service-react-table-grid .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td .error-table .warnLevel {
    background: #F93251 url(../images/icons/highLevel.svg) no-repeat center center;
    background-size: 24px auto;
    max-height: 42px;
    height: 42px;
    width: 48px;
}

.service-react-table-grid .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td .error-table .alertLevel {
    background: #ca9b0d url(../images/icons/mediumLevel.svg) no-repeat center center;
    background-size: 24px auto;
    max-height: 42px;
    height: 42px;
    width: 48px;
}

.service-react-table-grid .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td:nth-child(2) {
    padding-bottom: 0px;
    padding-top: 0px;
    padding-left: 0px;
}

.service-react-table-grid .ReactTable .rt-tbody .rt-td {
    text-align: left;
    color: #fff;
    font-size: 14px;
}

.service-react-table-grid .ReactTable .rt-tbody .rt-td:nth-child(1) {
    text-align: center;
    color: #fff;
    font-size: 14px;
}

.service-react-table-grid .ReactTable .helpIcon {
    width: 40px;
    display: inline-block;
    border: 0px;
    background: url(../images/icons/errorView.svg) no-repeat center center;
    background-size: 24px auto;
}

.location-react-table-grid .ReactTable .offline_img,
.location-react-table-grid .ReactTable .ffline_lable_content {
    opacity: 0.5;
}

.location-react-table-grid .ReactTable .rt-thead .rt-tr {
    text-align: left;
}

.ReactTable .rt-thead .rt-resizable-header-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.location-react-table-grid .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td:nth-child(8),
.location-react-table-grid .rt-thead .rt-tr div:nth-child(8),
.ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td:first-child,
.ReactTable .rt-thead .rt-tr .rt-th:first-child {
    text-align: center;
}
.ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td {
    text-align: left;
}



/* Map Styles */
#mapdiv {
    width: 100%;
    float: left;
    height: 600px;
    overflow: hidden;
    background: #2d3034;
    position: relative;
}

.map-marker,
.map-marker-location {
    /* adjusting for the marker dimensions so that it is centered on coordinates */
    position: relative;
    padding: 3px 5px;
    display: inline-block;
    background: #737373;
    color: #fff;
    font-size: 12px;
    border-radius: 4px;
}

.map-marker:hover,
.map-marker-location:hover { 
    z-index: 100;
}

.map-marker-location img {
    width: 34px;
    float: left;
    margin-right: 5px;
}

.map-marker-location .map-anchor {
    float: left;
}

.map-anchor,
.map-anchor:hover {
    cursor: pointer;
    color: #FFF;
}

.pulse {
    display: none;
    width: 10px;
    height: 10px;
    border: 5px solid #f7f14c;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-color: #716f42;
    z-index: 10;
    position: absolute;
}

.map-marker .dot,
.map-marker-location .dot {
    position: absolute;
    top: -11px;
    left: -8px;
    color: #000;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 11px;
    text-align: center;
    padding: 2px 0px;
}

.map-marker .dot.redColor,
.map-marker-location .dot.redColor {
    background-color: #ed1f24;
}

.map-marker .dot.yellowColor,
.map-marker-location .dot.yellowColor {
    background-color: #f7f14c;
}

.map-marker .dot.greyColor,
.map-marker-location .dot.greyColor {
    background-color: grey;
}

.map-marker .dot.disappear,
.map-marker-location .dot.disappear {
    display: none;
}

.ReactTable .errorStaus {
    background: #cd1d45;
}

.ReactTable .offlineStaus {
    background: #16191b;
    opacity: 0.5;
}

.ReactTable .warningStaus {
    background: #d39800;
}

.ReactTable a,
.ReactTable a:hover {
    color: #FFF;
}

.field-validation-error {
    color: red;
}

.field-validation-success {
    color: rgb(22, 202, 22);
}

.showUserValidation {
    display: block;
}

.hideUserValidation {
    display: none;
}

#peakHoursDBChartdivAllLocations {
    width: 100%;
    height: 80px;
    float: left;
    padding-top: 10px;
}

#revenueChartdivAllLocations {
    width: 100%;
    height: 80px;
    float: left;
}

/* Custom Switch css starts */
.switch {
    position: relative;
    border-radius: 13px;
    display: inline-block;
    width: 48px;
    height: 24px;
    outline: none;
    margin: 0;
}

.switchslider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f2f2f2;
    border: 2px solid #cfd7e1;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}

.switchslider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0px;
    bottom: 0px;
    background-color: #0d3968;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.switchslider {
    background-color: #c5cdd7;
    border: 1px solid #c5cdd7;
    box-shadow: none;
}

input:checked+.switchslider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* */
.navUserWrapper.showLogoutModal {
    display: block;
}

.addUserForm>ul>li .input-group select.singleoption {
    -webkit-appearance: none;
    appearance: none;
}

.addUserForm>ul>li select.singleoption::-ms-expand {
    display: none;
}

.addUserForm>ul>li select.singleoption {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    float: left;
    clear: both;
    padding: 5px 30px 10px 0px;
    height: auto;
    background: #f2f2f2 !important;
    color: #0D3968;
    font-size: 20px;
    border: 0px;
    border-bottom: 1px solid #6e88a4;
    border-radius: 0px;
}

.treeSecondNode>.assignedUnitGrpsTable>ul>li:nth-child(1) {
    padding-left: 24px;
}

.treeSecondNode .treeSecondNode>.assignedUnitGrpsTable>ul>li:nth-child(1) {
    padding-left: 48px;
}

.treeSecondNode .treeSecondNode .treeSecondNode>.assignedUnitGrpsTable>ul>li:nth-child(1) {
    padding-left: 72px;
}

.treeSecondNode .treeSecondNode .treeSecondNode .treeSecondNode>.assignedUnitGrpsTable>ul>li:nth-child(1) {
    padding-left: 96px;
}

.assignedUnitGrpsTable>ul.tBody li.assignedUnitGrpsTableUnitLi {
    padding-left: 120px;
}

#wrapper .toggleTransparent.showFade {
    display: block;
}

.errorMsg {
    color: #c00;
    font-size: 14px;
    display: block;
    width: 100%;
    float: left;
    text-align: left;
    margin-top: 5px;
}

.noResultMsg {
    color: #ffffff;
    width: 100%;
    float: left;
    padding: 15px 0px;
    text-align: center;
}

.sucessMsg {
    color: #6B8E23;
    margin-bottom: 10px;
}

.loadingProgress.showloader,
.showFirstLogin {
    display: block;
}

.orgznSiteTree>ul li>div.treePrentDivActive>a.treeNodeAvailableNoLast:before {
    background: #195d99;
}

.orgznSiteTreeLiDiv.removeLine a:before {
    width: 0;
}

.SWlistUnitsTable>ul>li:nth-child(9)>span.offlineStausTable {
    opacity: 0.5;
    background: url("../images/icons/offlineT.svg") no-repeat center center;
    background-size: 24px 24px;
    padding-left: 20px;
}

/*Orgaization Topology Add */
.select-group {
    position: absolute;
    bottom: 8px;
    width: 82%;
}

.gridView .colm8D.BgBorder0,
.gridView .colm9D.BgBorder0 {
    border: 0;
    background: none;
}

.DivRight {
    float: right;
}

.NotifnMgmtTable .ManagePassword,
.addUserForm .profileUpdateBtn {
    color: #0d3968;
    background: #bccddc;
    border: 0px;
    border-radius: 0px;
    padding: 10px 15px;
    font-family: 'open_sanssemibold';
    margin-top: 15px;
}

a:hover,
a:focus {
    text-decoration: none;
    cursor: pointer;
}

div.gridView .colmDiVal {
    padding: 0px 0px 0px;
}

.showHideDate {
    display: none;
    color: #fff;
    font-size: 12px;
    text-align: right;
    min-height: 18px;
    padding: 0px 10px 0;
    margin-top: 12px;
    min-width: 240px;
    max-width: 250px;
    float: right;
    text-transform: capitalize;
    position: absolute;
    top: 5px;
    right: 0px;
}

.showHideDate>div {
    display: inline-block;
}

.errorHistoricH4 .showHideDate {
    margin-top: 27px;
}

.showHideDate.showdp {
    display: block;
}

.showHideDate span {
    color: #afb1b2;
}

div.gridView .colmDi>h4 .dropdown>.dropdown-menu {
    width: auto;
    left: initial !important;
}

select.form-control:not([size]):not([multiple]) {
    height: auto;
}

.table-header .-sort-asc:before,
.table-header .-sort-desc:before {
    content: "";
    height: 20px;
    width: 20px;
    float: right;
}

.table-header .-sort-asc:before {
    background: url("../images/icons/sort-up_blue_A.svg");
}

.table-header .-sort-desc:before {
    background: url("../images/icons/sort-down_blue_A.svg");
}

.errorModalContentOKBtn {
    width: 100%;
    border: 0px;
    border-radius: 0px;
    padding: 17px 0px;
    font-size: 20px;
    background: #195d99;
    color: #ffffff;
}

.errorModalContent {
    width: 100%;
    float: left;
    padding: 0px 0px 20px 0px;
    font-size: 22px;
    color: #0D3968;
    font-family: 'open_sanssemibold';
}

.progress-bar.errorListTableBar {
    background: #3f4146;
    height: 100%;
    padding: 0px 2px;
    text-align: left;
}

.ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td:nth-child(7) {
    padding: 0px 5px;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* ------------------Date Picker------------ */
.dropdown.datepickerBtnGroup div.react-datepicker-popper[data-placement^="bottom"] {
    left: -178px !important;
}

div.react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 1px;
}

.dropdown.datepickerBtnGroup div.react-datepicker-popper[data-placement^="top"] {
    left: -178px !important;
    z-index: 9999;
}

div.react-datepicker-popper[data-placement^="top"] {
    margin-top: 1px;
    z-index: 9999;
}

div.react-datepicker__triangle {
    left: initial;
    right: 30px;
}

div.react-datepicker-wrapper {
    display: inline-block;
    min-width: 60px;
}

.gridView .colmDi div.react-datepicker-wrapper {
    max-width: 70px;
}

div.react-datepicker-wrapper input {
    width: 100%;
    padding: 0;
    background: #2d3034;
    border: 0;
    color: #b5b6b8;
    font-weight: bold;
    cursor: pointer;
    border-radius: 0;
    font-size: 10px;
}

.showHideDate div.react-datepicker-wrapper input {
    color: #b5b6b8;
    border-bottom: 1px solid #7b8686;
}

.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown,
.react-datepicker__triangle {
    display: none;
}

.react-datepicker__month-dropdown-container,
.react-datepicker__year-dropdown-container {
    border-bottom: 1px solid #afb5bd;
}

.react-datepicker__month-dropdown-container select,
.react-datepicker__year-dropdown-container select {
    background: #f0f0f0;
    border: 0;
    padding: 0;
    margin: 0;
}

div.react-datepicker__day--selected,
div.react-datepicker__day--selected:hover,
div.react-datepicker__day--keyboard-selected,
div.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range,
.react-datepicker__day--in-range:hover {
    background: #00b1ff;
    border-radius: 50%;
    color: #fff;
}

.disableUnwandedFilterIckon,
.disableNavLink .navbar-nav .nav-item:nth-of-type(2) .nav-link,
.disableNavLink .navbar-nav .nav-item:nth-of-type(3) .nav-link,
.disableNavLink .navbar-nav .nav-item:nth-of-type(4) .nav-link {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
} 
.navbarRight>li>a.navUser.disableUnwandedFilterIckon {
    background: #F2F2F2 url("../images/icons/usersA.svg") no-repeat center center;
    background-size: 24px auto;
    opacity: 1;
}

.disableNavLink .navbar-nav .nav-item .nav-link.navFilter {
	background: #F2F2F2 url("../images/icons/FilterA.svg") no-repeat center center;
    background-size: 24px auto;
    opacity: 1;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-start:hover {
    border: 1px solid #bfbfbf;
    border-radius: 50%;
    margin: 0;
}

.react-datepicker__navigation--next,
.gridView .colmDi>h4 .dropdown button.react-datepicker__navigation--next:hover {
    border: solid #8f959c;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-46deg);
    -webkit-transform: rotate(-46deg);
    top: 10px;
    right: 25px;
}

.react-datepicker__navigation--previous,
.gridView .colmDi>h4 .dropdown button.react-datepicker__navigation--previous:hover {
    border: solid #8f959c;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    top: 10px;
    left: 25px;
}

.gridView .colmDi>.titleH2Div>sup {
    color: #78797b;
}

.norecordsfound {
    width: 100%;
    color: #FFFFFF;
    text-align: center;
}

.norecordsfoundbluetext {
    width: 100%;
    text-align: center;
    color: #0D3968;
    font-weight: bold;
}

.norecordsfoundbluetextpaddingtop75 {
    width: 100%;
    text-align: center;
    color: #0D3968;
    font-weight: bold;
    padding-top: 75px;
}

.media-li-no-record {
    width: 100%;
    color: #6a6c6f;
    text-align: center;
    background-color: #f2f2f2;
}

.orgznSiteTree>ul li>div.treePrentDivActive>a.treeNodeAvailableNoLast:before {
    background: #195d99;
}

.loginIWrapper.hideForFirstLogin {
    display: none;
}

/*----- softwareAvailableUpdates---- */
/*Ghost drag removal*/
.availableUpdatesTable>ul>li a,
img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.navFilterWrapper.showLogoutModal {
    display: block;
    z-index: 99999999999;
}

div.styles_overlay__CLSq- {
    background: rgba(255, 255, 255, 0);
    z-index: 1051;
}

.selectedUnitHeaderName>ul {
    width: 100%;
    padding: 10px 10px;
    min-height: 52px;
}

.selectedUnitHeaderName>ul>li {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    padding: 5px 6px;
    font-size: 14px;
    background: #dae3ea;
    color: #044978;
}

.selectedUnitHeaderName>ul>li:last-child {
    float: right;
    background: #f2f2f2;
}

.selectedUnitHeaderName>ul>li .assignunitSite,
.selectedUnitHeaderName>ul>li .assignunitList {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-left: 10px;
}

.selectedUnitHeaderName>ul>li .assignunitSite {
    background: url("../images/icons/SidetreeS.svg") no-repeat center center;
    background-size: 24px 24px;
}

.selectedUnitHeaderName>ul>li .assignunitSite:hover,
.selectedUnitHeaderName>ul>li .assignunitSite.active {
    background: url("../images/icons/SidetreeDA.svg") no-repeat center center;
    background-size: 24px 24px;
}

.selectedUnitHeader>ul>li .assignunitList.active {
    background: url("../images/icons/ListviewDA.svg") no-repeat center center;
    background-size: 24px 24px;
}

.selectedUnitHeader>ul>li .assignunitSite:hover,
.selectedUnitHeader>ul>li .assignunitSite.active {
    background: url("../images/icons/SidetreeDA.svg") no-repeat center center !important;
    background-size: 24px 24px;
}

.selectedUnitHeaderName>ul>li .assignunitList {
    background: url("../images/icons/ListviewS.svg") no-repeat center center;
    background-size: 24px 24px;
}

.selectedUnitHeaderName>ul>li .assignunitList:hover,
.selectedUnitHeaderName>ul>li .assignunitList.active {
    background: url("../images/icons/ListviewDA.svg") no-repeat center center;
    background-size: 24px 24px;
} 
.selectedUnitHeaderName>ul>li .selectedVal {
    display: inline-block;
    background: #dae3ea;
    color: #044978;
    margin-right: 10px;
    padding: 5px 6px;
    font-size: 14px;
} 
.navbarRight>li.activeFilter>a.navFilter {
    background: #F2F2F2 url("../images/icons/FilterA.svg") no-repeat center center;
    background-size: 24px auto;
} 
.navbarRight>li.activeNavUser>a.navUser {
    background: #F2F2F2 url("../images/icons/usersA.svg") no-repeat center center;
    background-size: 24px auto;
} 
.orgznSiteTree>ul li>div>a .unitDeactive {
    position: absolute;
    top: 0;
    right: 0;
    background: url("../images/icons/unitDeactivate.png") no-repeat center center/contain;
    height: 20px;
    width: 20px;
    display: inline-block;
}

.orgznSiteTree>ul li>div>a .unitNotProvisioned {
    position: absolute;
    top: 0;
    right: 0;
    background: url("../images/icons/DeleteUnitA.svg") no-repeat center center/contain;
    height: 20px;
    width: 20px;
    display: inline-block;
}

/** Spinner class */
@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    /* top: 50%;
     left: 50%;
     */
    float: right;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    /* margin-left: -10px;
     */
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #0d3968;
    animation: spinner .6s linear infinite;
}

.workflow-spinner {
    margin-top: 15px !important;
    text-align: center;
}

.tablePagination,
.prevPage .nextPage,
.paginationFooter,
.prevPage .nextPage {
    cursor: pointer;
}

div.userManagementWrapper {
    border: 0;
}

.userManagementWrapper>.stickyTableHeader {
    position: fixed;
    width: 100%;
    z-index: 1;
}

.userManagementWrapper>.stickyTableHeader>.userMgmtHeader {
    background: #f2f2f2;
}

/* User mgmt & org mgmt static header css ends */
/* Menu Update Status static header start */
div.menuUpdateWrapper {
    border: 0;
}

.menuUpdateWrapper>.stickyTableHeader {
    position: fixed;
    width: 100%;
    z-index: 1;
}

.menuUpdateWrapper>.stickyTableHeader>.menuUpdateHeader {
    background: #f2f2f2;
}

.menuUpdateWrapper>.stickyTableHeader>.menuUpdateHeader>.selectedUnitHeader {
    background: #f2f2f2;
}

/* Menu Update Status static header end */
/* Software Available Update Screen static header start */
div.softwareUpdateWrapper {
    border: 0;
}

.softwareUpdateWrapper>.stickyTableHeader {
    position: fixed;
    width: 100%;
    z-index: 1;
}

.softwareUpdateWrapper>.stickyTableHeader>.softwareUpdateHeader,
.softwareUpdateWrapper>.stickyTableHeader>.softwareUpdateHeader,
.softwareUpdateWrapper>.stickyTableHeader>.softwareUpdateHeader>.selectedUnitHeader {
    background: #f2f2f2;
}

/* Unit Registration Static header start*/
div.unitRegistrationWrapper {
    border: 0;
}

.unitRegistrationWrapper>.stickyTableHeader {
    position: fixed;
    width: 100%;
    z-index: 9;
}

.unitRegistrationWrapper>.stickyTableHeader>.unitRegHeader,
.unitRegistrationWrapper>.stickyTableHeader .unitRegListTable {
    background: #f2f2f2;
}

.unitRegistrationWrapper .orgznManagementWrapper.assignUnitTopologyMarginSpace {
    margin-top: 118px;
}

.unitRegistrationWrapper .orgznManagementWrapper.assignUnitTopologyMarginSpace.withoutSelectedUnitHeader {
    margin-top: 62px;
}

.unitRegistrationWrapper .orgznManagementWrapper.assignUnitTopologyMarginSpace {
    margin-top: 116px;
}

/* Unit Registration Static header end*/
.styles_modal__gNwvD .modal.show,
.modal-open .modal.show {
    background: rgba(0, 0, 0, 0.5);
}

.styles_modal__gNwvD {
    padding: 0px;
    background: none;
}

#CountryFilter {
    display: block;
}

#PeakHoursBarChartdiv {
    width: 100%;
    height: 420px;
    min-height: 420px;
    margin-top: 15px;
}

.PeakHoursBarChartdiv1 {
    width: 100%;
    height: 420px;
    min-height: 420px;
    margin-top: 15px;
    float: left;
}

/* Tpm Value am charts style by kamal*/
.TPMvalueAllFrypotBarChartdiv {
    width: 100%;
    height: 240px;
    min-height: 240px;
    float: left;
}

.TPMGraphminheightdiv {
    min-height: 280px;
}

#convthmPeakHoursDBChartdiv,
#peakHoursDBChartdiv,
#revenueChartdiv,
#productsChartdiv {
    width: 100%;
    height: 80px;
    float: left;
}

#TotalBrewerCyclesChartdiv,
#totalWaterFlowChartdiv {
    width: 100%;
    height: 520px;
    float: left;
}

#utilizationPerWeekChartdiv,
#utilizationPerHourChartdiv {
    width: 100%;
    height: 320px;
    float: left;
}

#topSoldDrinkschartdiv {
    width: 100%;
    height: 360px;
    float: left;
}

#ovenUtilizationStatusPieChartDiv,
#frypotStatusChartDiv {
    width: 100%;
    height: 250px;
    float: left;
}

#topSoldDrinkschartdiv,
#convothermtop10Productschartdiv,
#top10Productschartdiv {
    width: 100%;
    height: 360px;
    float: left;
}

#unitErrorsStatuschartdiv,
#unitErrorsStatuschartdiv1,
#unitErrorsStatuschartdiv2,
#errorsStatuschartdiv,
#errorsStatuschartdivwe,
#connectivityStatuschartdiv,
#unitConnectivityStatuschartdiv,
#unitConnectivityStatuschartdiv1,
#unitConnectivityStatuschartdiv2,
#unitConnectivityStatuschartdiv3,
#unitConnectivityStatuschartdiv4 {
    width: 40%;
    height: 140px;
    float: left;
}

#CountryFilter,
#superUserDiv,
#equipmentManagementDiv,
#locationManagementDiv,
#serviceTechnicianDiv,
#developmentChefDiv,
#cookingDataTableDay,
#cookingDataTableWeek,
#cleaningDataDay,
#cleaningDataWeek {
    display: none;
}

#HoldingCycleTypesBarChartdiv,
#cleaningsBarChartdiv,
#totalProductsCookedBarChartdiv,
#mostFrequentErrBarChartdiv {
    width: 100%;
    height: 420px;
    min-height: 420px;
    float: left;
}

#totalFiletrsCompletedModalBarChartdiv {
    width: 100%;
    height: 380px;
    min-height: 380px;
    float: left;
}

/* #TPMvalueAllFrypotBarChartdiv{
     width: 100%;
     height: 160px;
     min-height: 180px;
     float: left;
}
 */
#ovenUtilizationBarChartdiv,
#trayUtilizationChartdiv {
    width: 100%;
    height: 380px;
    min-height: 360px;
    float: left;
}

#equipmentEfficiencyBarChartdiv {
    width: 100%;
    height: 290px;
    min-height: 290px;
    float: left;
}

#badCooksBarChartdiv {
    width: 100%;
    height: 240px;
    min-height: 240px;
    float: left;
}

#barChartdiv {
    width: 100%;
    height: 240px;
    min-height: 240px;
    float: left;
}

#panChartDiv,
#timelinechart {
    width: 100%;
    height: 500px;
    float: left;
    margin-top: 10px;
}

#timelinechart .amcharts-chart-div a {
    display: none !important;
}

.chartTitleDiv {
    width: 100%;
    float: left;
    padding: 0px;
}

.gaugeGraphSec {
    width: 100%;
    height: 150px;
    clear: both;
}

.gaugeName {
    font: 14px "open_sanslight";
    color: #b4b5b7;
    text-align: center;
}

#peakHourChart {
    width: 100%;
    height: 360px;
    float: left;
}

.peakhourData {
    font: 48px open_sanssemibold;
    color: #fff;
    text-align: center;
    float: left;
    width: 100%;
    padding-top: 15px;
}

.peakhourData span {
    font: 12px "open_sanslight";
    color: #b4b5b7;
    padding-left: 5px;
}

.pageTabHeader>ul>li>a.serviceMgtHeader {
    padding-left: 0px;
}

/*** ************** ************** **** *** Media Management *** **** ************** ************** ***/
.mediaAllTable>ul.tBody>li .mediaLibrarySMFC {
    display: none;
    width: 100%;
    float: left;
    padding-top: 5px;
}

/*********************************** ***********************************/
.cursor-not-allowed {
    cursor: not-allowed !important;
}

@media (min-width: 320px) and (max-width: 480px) {

    /*** ************** ************** **** *** Scroll Tip *** **** ************** ************** ***/
    .scroll-tip {
        position: fixed;
        bottom: 10px;
        width: 100%;
        height: 10px;
        display: none;
        left: 0px;
        z-index: 10000;
        background: url("../images/scrollbarEnable.png") no-repeat center;
        background-size: auto 10px;
    }

    .modal-scroll-tip {
        position: fixed;
        bottom: 10px;
        width: 100%;
        height: 10px;
        display: none;
        left: 0px;
        z-index: 10000;
        background: url("../images/scrollbarEnable.png") no-repeat center;
        background-size: auto 10px;
    }

    /*** ************** ************** **** *** Media Management *** **** ************** ************** ***/
    .mediaAllTable>ul>li:nth-child(3),
    .mediaAllTable>ul>li:nth-child(4) {
        display: none;
    }

    .mediaAllTable>ul.tBody>li .mediaLibrarySMFC {
        display: block;
        font-size: 14px;
        width: 100%;
    }
}

@media only screen and (min-width: 320px) and (max-width: 568px) {

    /*** ************** ************** **** *** Media Management *** **** ************** ************** ***/
    .mediaAllTable>ul>li:nth-child(3),
    .mediaAllTable>ul>li:nth-child(4) {
        display: none;
    }

    .mediaAllTable>ul.tBody>li .mediaLibrarySMFC {
        display: block;
        font-size: 14px;
        width: 100%;
    }

    .mediaUploadFTable .dragedDetailsTable .dragedDetailsRow .dragedImage {
        width: 20%;
    }

    .mediaUploadFTable .dragedDetailsTable .dragedDetailsRow .dragedText {
        padding: 0px;
        width: 80%;
        word-break: break-word;
        font-size: 12px;
    }

    .newReportForm>ul>li>label {
        width: 100px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .scroll-tip {
        position: fixed;
        bottom: 10px;
        width: 100%;
        height: 10px;
        display: none;
        left: 0px;
        z-index: 10000;
        background: url("../images/scrollbarEnable.png") no-repeat center;
        background-size: auto 10px;
    }

    .modal-scroll-tip {
        position: fixed;
        bottom: 10px;
        width: 100%;
        height: 10px;
        display: none;
        left: 0px;
        z-index: 10000;
        background: url("../images/scrollbarEnable.png") no-repeat center;
        background-size: auto 10px;
    }
}

@media(min-width:768px) and (max-width:812px) {
    .newReportForm>ul>li>label {
        width: 100px;
    }
}

.minheightSetTable {
    width: 100%;
    padding: 0px;
    float: left;
    clear: both;
    background: #2d3034;
    min-height: 300px;
}

div.react-datepicker-wrapper .datePickerSpan {
    width: 100%;
    padding: 0;
    background: #2d3034;
    border: 0;
    color: #b5b6b8;
    font-weight: 700;
    cursor: pointer;
    border-radius: 0;
    font-size: 10px;
    color: #b5b6b8;
    border-bottom: 1px solid #7b8686;
}

/* Frymaster Dispose Information Table min height */
.disposeInformationTableOuter {
    min-height: 300px;
}

/* Tooltip implemented for unit quality performance table header */
.react-tooltip-lite {
    border: 1px solid #888;
    background: #fff;
    color: black;
}

.react-tooltip-lite-arrow {
    border-color: #888;
    position: relative;
}

.target {
    cursor: pointer;
    display: inline;
}

.targetdisabled {
    cursor: default;
    display: block;
    width: 24px;
    height: 24px;
}

.target .react-tooltip-lite {
    cursor: default;
}
.tooltipContent {
    text-align: left;
    word-wrap: break-word;
    width: 200px;
    min-width: 200px;
    overflow-wrap: "break-word";
}

.tip-heading {
    margin: 0 0 1px;
    font-size: 16px;
    font-weight: bold;
}

.tip-list {
    margin: 0;
    padding: 0 0 0 1px;
}

.tip-list li {
    margin: 1px 0;
    padding: 0;
}

.pageTabHeader.greybg {
    background: #2d3034;
}

.subscribeButton {
    font-size: 24px;
    font-family: 'open_sanssemibold';
    width: 100%;
    text-align: center;
}

.StripeCheckout>span {
    font-family: 'open_sanssemibold';
    width: 100%;
    text-align: center;
    color: #000;
}

.oilDisposeFilter {
    color: #ffffff;
    float: right;
}

.gridView .colmDi>h4 .oilDisposeFilter .dropdown>.dropdown-toggle {
    float: right;
    font-size: 12px;
    text-transform: none;
    width: auto;
    padding: 0px 0px 0px 0px;
    height: auto;
    background: transparent;
    color: #ffffff;
    border: 0px;
    margin-right: 5px;
    font-family: 'open_sansregular';
}

.maintenanceText {
    width: 100%;
    float: left;
    text-align: center;
    margin: 5px 0px;
    padding: 10px;
    color: #FFF;
}

.rcSettingsTable>ul>li .form-control .time {
    opacity: 0.8;
}

.swagger-interface .main .base-url {
    display: none;
} 

.swagger-ui .info .base-url {
    display: none;
}

.swagger-ui .info hgroup.main a {
    display: none;
}

.swaggerHeaderInfo {
    width: 100%;
    float: left;
    text-align: left;
    vertical-align: left;
    font-size: 14px;
    color: #16406d;
    height: 60px;
    margin: 2px 2px 2px;
    padding-top: 25px;
}

.swagger-ui .dialog-ux .modal-ux-content h4 code {
    color: #3b4151;
}

.swagger-ui .dialog-ux .modal-ux-content p code {
    color: #3b4151;
}

.swagger-ui .dialog-ux .modal-ux-header h3 {
    visibility: hidden;
}

.swagger-ui .dialog-ux .modal-ux-header:after {
    content: 'Please enter api key value';
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    padding: 0 20px;
    visibility: visible;
    display: block;
    position: absolute;
    flex: 1 1;
    font-family: sans-serif;
    color: #3b4151;
}

.swagger-ui .parameters-container .parameters-col_description input[type=text] {
    max-width: 740px;
}

.swagger-ui .parameters-col_description .parameter__enum p {
    visibility: hidden;
}

.swagger-ui .auth-container .wrapper>code {
    color: #000;
}

.recipeCreationForm>ul.displaynone {
    display: none;
}

.cropmodal {
    font-family: sans-serif;
    text-align: center;
    overflow: auto;
}

.cropmodal>img {
    max-width: 100%;
}

.loadertext {
    color: #FFF;
    font-family: sans-serif;
    text-align: center;
}

.loadertextblue {
    color: #0d3968;
    font-family: sans-serif;
    text-align: center;
}

/** * Rangeslider */
.rangeslider {
    margin: 20px 0;
    position: relative;
    background: #e6e6e6;
    -ms-touch-action: none;
    touch-action: none;
}

.rangeslider,
.rangeslider .rangeslider__fill {
    display: block;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
}

.rangeslider .rangeslider__handle {
    background: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
}

.rangeslider .rangeslider__handle .rangeslider__active {
    opacity: 1;
}

.rangeslider .rangeslider__handle-tooltip {
    width: 40px;
    height: 40px;
    text-align: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    font-weight: normal;
    font-size: 14px;
    transition: all 100ms ease-in;
    border-radius: 4px;
    display: inline-block;
    color: white;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
}

.rangeslider .rangeslider__handle-tooltip span {
    margin-top: 12px;
    display: inline-block;
    line-height: 100%;
}

.rangeslider .rangeslider__handle-tooltip:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
}

/** * Rangeslider - Horizontal slider */
.rangeslider-horizontal {
    height: 12px;
    border-radius: 10px;
}

.rangeslider-horizontal .rangeslider__fill {
    height: 100%;
    background-color: #0095f9;
    border-radius: 10px;
    top: 0;
}

.rangeslider-horizontal .rangeslider__handle {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    background-color: #0095f9;
    border-color: #FFF;
    font-size: 12px;
}

.rangeslider-horizontal .rangeslider__handle-tooltip {
    top: -55px;
}

.rangeslider-horizontal .rangeslider__handle-tooltip:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid rgba(0, 0, 0, 0.8);
    left: 50%;
    bottom: -8px;
    transform: translate3d(-50%, 0, 0);
}

.rangeslider-horizontal .rangeslider__handle-label {
    position: absolute;
    top: 50%;
    left: 50%;
    font-weight: bold;
    transform: translate3d(-50%, -50%, 0);
    font-size: 12px;
    width: 32px;
    text-align: center;
}

/** * Rangeslider - Reverse */
.rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
    right: 0;
}

.rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
    top: 0;
    bottom: inherit;
}

/** * Rangeslider - Labels */
.rangeslider__labels {
    position: relative;
}

.rangeslider-vertical .rangeslider__labels {
    position: relative;
    list-style-type: none;
    margin: 0 0 0 24px;
    padding: 0;
    text-align: left;
    width: 250px;
    height: 100%;
    left: 10px;
}

.rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
    position: absolute;
    transform: translate3d(0, -50%, 0);
}

.rangeslider-vertical .rangeslider__labels .rangeslider__label-item::before {
    content: '';
    width: 10px;
    height: 2px;
    background: black;
    position: absolute;
    left: -14px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}

.rangeslider__labels .rangeslider__label-item {
    position: absolute;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    top: 10px;
    transform: translate3d(-50%, 0, 0);
}

.slider-horizontal {
    margin-bottom: 30px;
    margin-top: -12px;
}

.statusDetailsTab.offlineStaus .errorCode {
    display: none
}

.statusDetailsTab.offlineStaus>ul>li:nth-child(2) {
    padding: 12px 10px 11px !important
}

.recipeEditorGrid>ul>li .readyRecipeNav {
    background: url("../images/recipecreation/readyRecipA.png") no-repeat top center;
    background-size: 80px 80px;
}

.displaynone {
    display: none !important
}

.displayblock {
    display: block !important
}

.menuFilterHeader>ul>li:nth-child(1) a.displaynone {
    display: none;
}

.LoginBtnRegisterWrapper {
    width: 100%;
    bottom: 10px;
    left: 0px;
    margin: 0px;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    background-color: #e2e2e2 !important;
    border-color: #e2e2e2 !important;
}

#backdropElement {
    background: #504e4e !important
}

.backIcon {
    width: 64px;
    height: 64px;
    display: block;
    background: url("../images/icons/back.png") no-repeat center center;
    background-size: 36px 36px;
    border: 0;
    cursor: pointer
}

.infoIcon {
    float: right !important;
    margin: 12px 30px 0 0 !important
}  
.userProfile ul.userDetailsList>li .email {
    background: url(../images/icons/email_24x24.png) no-repeat left center;
    width: auto;
    float: left;
    clear: both;
    padding: 5px 0px 5px 40px;
    margin: 0px;
    color: #0D3968;
    background-size: 24px 24px;
    font-family: 'open_sanssemibold';
}

.borbt {
    border-bottom: 1px solid #000;
}

.customPara {
    color: #fff;
}

.customPara ul li {
    width: 49%;
    float: left;
}

.customPara ul li p {
    margin-bottom: 3px;
}

.customPara ul li:nth-child(2) {
    margin-left: 10px;
}

.customPara ul li p.greyTextContent {
    color: #afb1b2
}

.tester .reportTypeListCBox {
    padding-top: 0 !important
}

.mediaPreparationIcon .assignunitSite {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-left: 10px;
}

.mediaPreparationIcon .assignunitSite {
    background: url("../images/icons/SidetreeS.svg") no-repeat center center;
    background-size: 24px 24px;
}

.mediaPreparationIcon .assignunitSite:hover,
.mediaPreparationIcon .assignunitSite.active {
    background: url("../images/icons/SidetreeDA.svg") no-repeat center center;
    background-size: 24px 24px;
}

.mediaPreparationForm {
    padding-bottom: 30px !important;
}

/* ****** ****** ****** ****** ****** ** * ****** User Management List Form Custom Table ****** * ** ****** ****** ****** ****** ****** */
.userMgmtListTableCustom {
    width: 100%;
    display: table;
}

.userMgmtListTableCustom>ul {
    width: 100%;
    display: table-row;
}

.userMgmtListTableCustom>ul.tHead>li {
    color: #16406d;
    background: #E9EAEB;
    border-bottom: 1px solid #63778a;
    word-break: normal;
}

.userMgmtListTableCustom>ul.tHead>li:first-child {
    border-left: 0px;
    border-bottom: 1px solid #63778a;
}

.userMgmtListTableCustom>ul>li {
    display: table-cell;
    padding: 7px 10px;
    vertical-align: middle;
    border-bottom: 1px solid #aab4be;
    color: #16406d;
    font-size: 16px;
    text-align: left;
    word-break: break-word;
}

.userMgmtListTableCustom>ul>li>a {
    color: #16406d;
}

.userMgmtListTableCustom>ul>li h6 {
    font-size: 14px;
    font-weight: normal;
    padding: 0px 0px 0px;
}

.userMgmtListTableCustom>ul>li p {
    margin: 0px;
    font-size: 13px;
    color: #b5b6b8;
}

.userMgmtListTableCustom>ul>li:nth-child(1) {
    width: 40px;
    text-align: center;
    color: #16406d;
    border-bottom: 1px solid #F2F2F2;
}

.userMgmtListTableCustom>ul>li:nth-child(2) {
    width: 50px;
    padding-left: 0px;
    text-align: center;
}

.userMgmtListTableCustom>ul>li:nth-child(3) {
    width: 215px;
}

.userMgmtListTableCustom>ul>li:nth-child(4) {
    width: 215px;
}

.userMgmtListTableCustom>ul>li:nth-child(6) {
    text-align: center;
    width: 150px;
}

.userMgmtListTableCustom>ul.tBody>li:nth-child(7) {
    font-size: 14px;
    line-height: 18px;
}

.userMgmtListTableCustom>ul>li:nth-child(7) {
    text-align: center;
    width: 145px;
}

.userMgmtListTableCustom>ul>li:nth-child(8) {
    text-align: center;
    width: 110px;
}

.userMgmtListTableCustom>ul>li:nth-child(9) {
    width: 160px;
    text-align: center;
    padding-right: 15px;
}

.userMgmtListTableCustom>ul>li:nth-child(10) {
    width: 50px;
    text-align: center;
}

.userMgmtListTableCustom>ul>li .customCheckbox {
    margin: 0px;
    display: block;
}

.userMgmtListTableCustom>ul>li .userDP {
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 50%;
    background: #c9dff6;
}

.userMgmtListTableCustom>ul>li .userDP>img {
    width: 40px;
}

.userMgmtListTableCustom>ul>li .customCheckbox label {
    margin: 0px;
    padding-left: 24px;
    height: 24px;
    width: 24px;
}

.userMgmtListTableCustom>ul>li .btn-group-toggle .btn-secondary.focus,
.userMgmtListTableCustom>ul>li .btn-group-toggle .btn-secondary:focus {
    box-shadow: inherit;
}

.userMgmtListTableCustom>ul>li .btn-group-toggle .btn-secondary {
    width: 48px;
    height: 24px;
    border: 0px;
    background: url("../images/icons/switchOFF.svg") no-repeat center center;
    background-size: 48px 24px;
    background-color: transparent !important;
}

.userMgmtListTableCustom>ul>li .btn-group-toggle .btn-secondary.active {
    background: url("../images/icons/switchON.svg") no-repeat center center;
    background-size: 48px 24px;
}

.userMgmtListTableCustom>ul.tBody>li .userMListRL {
    display: none;
    width: 100%;
    float: left;
    font-size: 14px;
}

.globalFilterText {
    color: #afb1b2;
    margin-bottom: 0px;
    font-size: 12px;
    padding-right: 15px
}

.navbarRight .navSearchDrpdn {
    width: 600px !important
}

.rightArrow::after {
    float: right;
    margin-top: 10px;
}

.addUnitHeader.text-rightRemoved>ul>li:nth-child(3) {
    padding-right: 0 !important
}

.assignOrgHeader.text-rightRemoved>ul>li:nth-child(3) {
    padding-right: 15px !important
}

/* Service Management All brands except Frymaster Customcheckbox background image changes only when few options checked */
.errorListTable .customCheckbox.selectAllDiv input[type=checkbox]:checked+label,
.errorListTable .customCheckbox.selectAllDiv input[type=checkbox]:checked+i+label,
.errorListTable .customCheckbox.selectAllDiv input[type=checkbox]:checked+input+label {
    background: url("../images/icons/checkBallMulti.png") no-repeat left center;
    background-size: 24px 24px;
}

/* Frymaster - Customcheckbox background image changes only when few options checked */
.fErrorListTable .customCheckbox.selectAllDiv input[type=checkbox]:checked+label,
.fErrorListTable .customCheckbox.selectAllDiv input[type=checkbox]:checked+i+label,
.fErrorListTable .customCheckbox.selectAllDiv input[type=checkbox]:checked+input+label {
    background: url("../images/icons/checkBallMulti.png") no-repeat left center;
    background-size: 24px 24px;
}

/* ****** ****** ****** ****** ****** ** * ****** To show the Smart Group Icon in Filter - End ****** * ** ****** ****** ****** ****** ****** */
.menuBrandsTable>ul,
.mediaAllTable>ul,
.brandsLibraryTable>ul,
.prdtsSelcnTable>ul {
    cursor: pointer;
}

/* ****** ****** ****** ****** ****** ** * ****** User details in Org Tile start****** * ** ****** ****** ****** ****** ****** */
.userHeaderTable {
    clear: both;
    margin-bottom: 0px;
}

.form-controlleft,
.form-controlright {
    width: 48%;
    color: #0D3968;
    word-break: break-all;
    margin-bottom: 15px;
}

.form-controlleft {
    float: left;
}

.form-controlright {
    float: right;
    color: #a39d9d;
    padding: 0 0 0 5px;
}

.confirmationDeleteModal .popupHeader p {
    font-size: 16px;
    color: #0D3968
}

.borbottom {
    border-bottom: 1px solid #e2e2e2;
}

.confirmationSecond .modal-body {
    padding: 0;
}

.confirmationSecond .modal-body h5,
.modalFormWrapper {
    padding: 1rem;
}

.confirmationSecond .modal-body .modalForm ul {
    padding: 0;
}

/* ****** ****** ****** ****** ****** ** * ****** Org Header Rename Popup End****** * ** ****** ****** ****** ****** ****** */
.confirmationDeleteModal .popupHeader p {
    font-size: 16px;
    color: #0D3968
}

.borbottom {
    border-bottom: 1px solid #e2e2e2;
}

.confirmationSecond .modal-body {
    padding: 0;
}

.confirmationYesNo h5.text-center {
    color: #0D3968
}

.confirmationSecond .modal-body h5,
.modalFormWrapper {
    padding: 1rem;
}

.confirmationSecond .modal-body .modalForm ul {
    padding: 0;
}

.SignInSuccessModal .singINConfirmation .uploadCountText b {
    font-size: 1.25rem;
    color: #0D3968;
    margin-bottom: 15px;
    display: block;
}

.SignInSuccessModal .singINConfirmation .uploadCountText p {
    margin-bottom: 15px;
    font-size: 1rem;
    color: #0D3968;
}

.mediaLibraryTable .previewBtn a {
    color: #fff
}

.existingLoginForm .errorMsg {
    margin-top: 10px
} 
.nextText {
    font-family: 'open_sanssemibold';
    color: #18426f
}

.assignOrgHeader>ul>li>a.nextText:hover {
    color: #18426f
}

;

.fixedListItem {
    width: 100%;
}

.fixedListItem ul li {
    float: left !important;
    height: 35px !important;
    padding: 10px 10px 10px 0px !important;
}

.chartLinetoggle {
    margin-left: 35px;
}

.chartLinetoggle>input[type="checkbox"] {
    height: 15px;
    width: 15px;
}

.chartLinetoggle>span {
    font-family: 'open_sansregular';
    padding-left: 8px;
    font-size: 14px;
    color: #ffffff;
    position: relative;
    bottom: 2px;
}

.cursor-pointer,
.cursor-pointer:hover {
    cursor: pointer;
}

.orgUnitView>.customCheckbox {
    display: inline !important;
}

.targetdisabledCheckboxAlignment {
    cursor: default;
    display: flex;
}

pre.pretext {
    font-size: 100%;
}

.adminBgHeader>ul>li button.btn-default-text {
    color: #18426f;
}

.selectedUnitHeader ul li button.assignunitSite,
.menuAssignmentStatus ul li button.statusClearAll {
    border: 0
}

.menuFilterHeader>ul>li:nth-child(1) button {
    color: #96a8bb;
    padding: 18px 15px 19px;
    font-size: 18px;
    display: inline-block;
    font-family: 'open_sanssemibold';
}

.menuFilterHeader>ul>li:nth-child(1)>button:hover,
.menuFilterHeader>ul>li:nth-child(1)>button.active {
    color: #0d3968
}

.border0 {
    border: 0px
}

.mediaUploadFTable .drogAndDropG>ul>li.initialActive button.DragPreparationhere {
    background: url("../images/icons/dropA.svg") no-repeat left center;
    background-size: 24px auto;
}

.mediaUploadFTable .drogAndDropG>ul>li.active button {
    color: #fff;
}

.scheduleUpdateForm>ul>li .input-group {
    position: relative;
}

.scheduleUpdateWrapper .scheduleUpdateForm .input-group .dropdownTimeOptions {
    position: absolute;
    top: 40px
}

.navbar-dark .navbar-nav button.nav-link {
    color: rgba(255, 255, 255, .5);
    background: none;
}

.cbaddMenuRecipe li button {
    background: none;
    color: #fff;
}

.cbaddMenuRecipe>ul>li>button>.navIcon>img {
    max-height: 45px;
    max-width: 45px;
    display: inline-block;
}

.cbaddMenuRecipe>ul>li>button>.navIcon {
    width: 100%;
    float: left;
    clear: both;
    padding: 0;
    text-align: center;
    height: 80px;
    line-height: 80px;
}

.cbaddMenuRecipe>ul>li>button>.navTitle {
    width: 100%;
    float: left;
    clear: both;
    padding: 0;
    font-size: 16px;
    color: #fff;
    text-align: center;
}

.reWrapperTab>ul>li button.active {
    background: #000;
}

.reWrapperTab>ul>li button {
    color: #ffffff;
    padding: 10px 15px 10px;
    font-size: 16px;
    display: inline-block;
    background: #696969;
}

.scheduleUpdateWrapper .scheduleUpdateForm .input-group .dropdownTimeOptions>li>button {
    color: #0d3968;
    padding: 4px 0 2px 20px;
    display: block;
    border: 0;
    cursor: pointer;
}

.allUnitsGridLI {
    min-height: 245px !important;
}

.notificationModalTitle {
    font-weight: bolder;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.notificationResolutionStep {
    margin: 0px;
}

.backHide {
    height: 66px;
}

.modelDeviceRegistration {
    margin-top: 30px !important;
}

.msoLineHeightClass {
    mso-line-height-rule: exactly
}

.supportButtonWrapper {
    width: 134px;
    height: 50px;
    padding: 0px;
    margin: 10px 20px;
    position: fixed;
    bottom: 0px;
    overflow: visible;
    opacity: 1;
    border: 0px;
    z-index: 999;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-property: opacity, top, bottom;
    right: 0px;
}

.supportButtonWrapper>a {
    color: #fff;
    fill: #fff;
    padding: 0.92857rem 1.57143rem;
    border-radius: 999rem;
    bottom: 0;
    background-color: #195d99 !important;
    font-size: 1.07143rem;
    height: 45px;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}

.supportButtonWrapper>a:hover,
.supportButtonWrapper>a:active,
.supportButtonWrapper>a:focus {
    color: #fff !important;
}

.NotifnMgmtTable>ul.tHead>li:nth-child(2) {
    font-family: 'open_sanssemibold';
    color: #0d3968;
}

.NotifnMgmtTable>ul.tHead>li:nth-child(3) {
    font-family: 'open_sanssemibold';
    color: #0d3968;
}

.confirmationContentDiv .email {
    background: url(../images/icons/email_24x24.png) no-repeat left center;
    width: auto;
    float: left;
    clear: both;
    padding: 5px 0px 5px 40px;
    margin: 0px;
    color: #0D3968;
    background-size: 24px 24px;
    font-family: 'open_sanssemibold';
}

.billingInfo {
    margin-top: 20px;
    margin-bottom: 20px;
}

.newReportWrapperR .newReportForm .input-group>.react-datepicker-wrapper>.react-datepicker__input-container>input.dayDatepicker {
    background: #f2f2f2 url("../images/icons/dateS.svg") no-repeat right center !important;
    background-size: 24px 24px;
    padding-right: 30px;
}

.reportTypeList .invalid-feedback {
    margin-top: 3px;
    background: url("../images/icons/AttentionRed.svg") no-repeat left top;
    background-size: 20px 20px;
    display: block;
    padding-left: 22px;
    clear: both;
    float: left;
    color: #f93251;
    text-align: left;
}

.imageResizeModal .modal-body {
    max-height: inherit !important;
}

.imageResizeModal .ReactCrop {
    overflow-x: auto;
    overflow-y: hidden;
}

.billingInfoForm iframe,
.billingForm iframe {
    min-width: 100%;
    max-width: 100%;
    height: auto;
}

.orgViewModal button.finish {
    width: 90px;
    opacity: 1;
    margin: 0px;
    padding: 5px 10px;
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 16px;
    border: 0px;
    background: #195d99;
    color: #ffffff;
    border-radius: 12px;
}

.modalOrgView {
    width: 100%;
    float: left;
    overflow-y: auto;
    min-height: 220px;
}

#orgViewModal .modal-dialog {
    margin: 20px auto;
}

.reportLimitMessage {
    color: #0D3968;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 15px;
}

.clearBoth {
    clear: both;
    width: 100%;
    height: 10px;
}

.installedText {
    color: #0610ff !important;
}

.alignmentCenter {
    text-align: center;
}

.minHeight45 {
    min-height: 45px;
}
.react-datepicker__navigation--previous,
.gridView .colmDi>h4 .dropdown button.react-datepicker__navigation--previous:hover {
    border: solid #8f959c !important;
    border-width: 0 2px 2px 0 !important;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    top: 10px;
    left: 25px;
}

.react-datepicker__navigation--next,
.gridView .colmDi>h4 .dropdown button.react-datepicker__navigation--next:hover {
    border: solid #8f959c !important;
    border-width: 0 2px 2px 0 !important;
    display: inline-block;
    padding: 3px;
    transform: rotate(-46deg);
    -webkit-transform: rotate(-46deg);
    top: 10px;
    right: 25px;
}

.react-switch-handle{
    height: 23px !important;
    width: 22px !important;
}
.react-switch-bg{
    height: 25px !important;
    width: 50px !important;
}
.btn-group-toggle > span.toggleON,
.btn-group-toggle > span.toggleOFF{
    padding: 5px 5px;
    margin-top: -3px;
    float: left;
}
.btn-group-toggle > span.toggleOFF{
    float: right;
}