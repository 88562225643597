/* ****** ****** ****** ****** ****** **
 * ****** Imported CSS ****** *
** ****** ****** ****** ****** ****** */

/* @import url("uidesign.css"); */
@import url("developer.css");
@import url("orgmediascreen.css");
@import url("customscrollbar.css");

/* ****** ****** ****** ****** ****** **
 * ****** Common CSS ****** *
** ****** ****** ****** ****** ****** */

@font-face {
	font-family: 'open_sanssemibold';
	src: url('../fonts/opensans-semibold-webfont.eot');
	src: url('../fonts/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/opensans-semibold-webfont.woff2') format('woff2'), url('../fonts/opensans-semibold-webfont.woff') format('woff'), url('../fonts/opensans-semibold-webfont.ttf') format('truetype'), url('../fonts/opensans-semibold-webfont.svg#open_sanssemibold') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'open_sansregular';
	src: url('../fonts/opensans-regular-webfont.eot');
	src: url('../fonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/opensans-regular-webfont.woff2') format('woff2'), url('../fonts/opensans-regular-webfont.woff') format('woff'), url('../fonts/opensans-regular-webfont.ttf') format('truetype'), url('../fonts/opensans-regular-webfont.svg#open_sansregular') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'open_sanslight';
	src: url('../fonts/opensans-light-webfont.eot');
	src: url('../fonts/opensans-light-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/opensans-light-webfont.woff2') format('woff2'), url('../fonts/opensans-light-webfont.woff') format('woff'), url('../fonts/opensans-light-webfont.ttf') format('truetype'), url('../fonts/opensans-light-webfont.svg#open_sanslight') format('svg');
	font-weight: normal;
	font-style: normal;
}
body {
	background: #000000;
	color: #000000;
	font-family: 'open_sansregular';
	padding: 0px !important;
}
body.loginBg {
	background: #0d3968 url("../images/Keyvisual.png") no-repeat center center;
	background-size: 75% auto;
}
body.deviceRegtrnBg {
	background: #0d3968 url("../images/Keyvisual.png") no-repeat left center;
	background-size: 75% auto;
} 
body.connectivityBg,
body.helpBg,
body.subscriptionBg {
	background: #0d3968;
}
body.mainMenuBg {
	background: #042546;
}
body.adminBg {
	background: #f2f2f2;
}
body.siteTreeBg {
	background: #2D3034;
}
body.menuTreeBg {
	background: #000000;
}
*,
*:before,
*:after {
	box-sizing: border-box;
}
html {
	height: 100%;
	width: 100%;
}
body {
	width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-tap-highlight-color: transparent;
}
ul,
li,
ol {
	padding: 0px;
	margin: 0px;
	list-style: none;
	list-style-image: none;
	list-style-type: none;
	word-break: break-word;
}
a {
	color: #C8102E;
}
a:hover,
a:active,
a:focus {
	text-decoration: none;
	color: #C8102E;
	outline: none;
	outline: 0;
}
*:focus,
*:active,
a:active,
a:focus,
input:focus,
input:active {
	-moz-outline-style: none;
	outline: none;
	outline: 0;
	-webkit-box-shadow: none;
}
.btnIcon {
	position: relative;
	padding-left: 30px;
}
.btnIcon:before {
	position: absolute;
	top: -2px;
	left: 0px;
	content: " ";
	width: 24px;
	height: 24px;
}
.displaynone {
	display: none;
}
@mixin tab-focus() {
	outline: none;
}
a:focus,
input:focus,
textarea:focus,
button:focus,
select:focus {
	outline: 0 !important;
	outline: none;
	box-shadow: inherit !important;
}
a,
button,
.btn {
	outline: none;
	outline: 0;
	text-decoration: none;
}
.btn[disabled] {
	pointer-events: none;
	cursor: default;
	/*plain arrow*/
	opacity: 0.5;
}
.disabled {
	pointer-events: none;
	cursor: default;
	opacity: 0.5;
} 
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}
input[type=text]::-ms-clear,
input[type=tel]::-ms-clear,
input[type=number]::-ms-clear,
input[type=email]::-ms-clear,
input[type=password]::-ms-clear {
	display: none;
}
input[readonly="readonly"],
input[readonly] {
	pointer-events: none;
}
input[type=file]::-webkit-file-upload-button {
	display: none;
	cursor: pointer;
	visibility: hidden;
}
input::-ms-reveal {
	display: none;
}
.required {
	color: red;
}
.alert,
.clear {
	clear: both;
}
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
	outline: 0px;
	outline-offset: 0px;
}
.btn.btn-danger {
	background: #F93251;
	color: #ffffff;
	font-size: 14px;
	border: 0px;
}
.btn.btn-success {
	background: #00ad41;
	color: #ffffff;
	font-size: 14px;
	border: 0px;
}
.btn.btn-default {
	border-radius: 20px;
	background: #424548;
	border: 0px;
	color: #ffffff;
	font-size: 14px;
	padding: 6px 16px;
}
.btn-default-text {
	border-radius: 0px;
	background: transparent;
	border: 0px;
	color: #0d3968;
	font-size: 16px;
	padding: 0px 12px;
}
.btn-default-cancel {
	border-radius: 0px;
	background: #bccddc;
	border: 0px;
	color: #0d3968;
	font-size: 20px;
	padding: 6px 16px;
}
.btn-primary-submit {
	border-radius: 0px;
	background: #195d99;
	border: 0px;
	color: #ffffff;
	font-size: 20px;
	padding: 6px 16px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0px;
	margin-bottom: 0px;
	font-weight: normal;
}
object,
embed {
	outline: 0;
}
input::-moz-focus-inner {
	border: 0;
}
::-webkit-scrollbar {
	width: 12px;
	/* for vertical scrollbars */
	height: 12px;
	/* for horizontal scrollbars */
}
::-webkit-scrollbar-track {
	background: #c8d2dd;
}
::-webkit-scrollbar-thumb {
	background: #0d3968;
}
.frypotInfoModal.modal {
	text-align: center;
	padding: 0!important;
}
.modal-dialog.modal-lg.frypotInfoModal {
	width: 800px;
}
.unitAssignModal .modal-body,
.unitAssignModal .modal-footer,
.selectRecipeModal .modal-body,
.selectRecipeModal .modal-footer {
	padding: 0px;
} 
.unitAssignModal .unitRegForm > ul > li .form-control{
	background-color: #ffffff !important;
}
.unitAssignModal .smartTagsList{ 
	margin:20px 15px 20px 0px;
	padding:0px;
	position:relative;
	border: 1px solid #63778a;
} 
.unitRegUnitDetails {
	width: 100%;
	float: left; 
	padding-bottom:25px;
}
.unitRegUnitDetails .unitRegForm {
	width: 440px; 
	float:left;
}
.unitRegUnitDetails .smartTagsList {
	width: 510px; 
	float:left;
	margin-left:10px;
	min-height:360px;
} 
.selectRecipeModal .modal-dialog.modal-lg {
	max-width: 960px;
}
.unitAssignModal .modal-lg {
	max-width: max-content;
}
.selectRecipeModal .modal-dialog-centered.modal-md, 
.selectRecipeModal .modal-dialog.modal-md, 
.activateNowModal .modal-dialog.modal-md {
	max-width: 460px;
	width:460px;
	margin: 15px auto;
}
.selectRecipeModal.selectLiberaryModal .modal-dialog.modal-lg {
	max-width: 640px;
	background: #323232;
}
.orgViewModal .modal-header,
.selectRecipeModal .modal-header {
	font-size: 22px;
	color: #0d3968;
	font-family: 'open_sanssemibold';
}
.selectRecipeModal .modal-header.tAlginCenter {
	text-align:center;
	display:block;
}
.selectRecipeModal .modal-header.padding0px {
	padding: 10px 15px;
}
.frypotInfoModal.modal:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
	margin-right: -4px;
}
.frypotInfoModal .modal-dialog {
	display: inline-block;
	text-align: left;
	vertical-align: middle;
}
.subscriptionModal .modal-dialog,
.confirmationRMModal .modal-dialog,
.termsConditionsModal .modal-dialog,
.orgNodeModal .modal-dialog,
.reportDetailsModal .modal-dialog,
.transferDetailsModal .modal-dialog,
.contentViewModal .modal-dialog,
.infoModal .modal-dialog,
.swaggerInfoModal .modal-dialog,
.confirmationYesNoModal .modal-dialog,
.confirmationDeleteModal .modal-dialog,
.activateNowModal .modal-dialog,
.overwriteMediaModal .modal-dialog,
.confirmationOkModal .modal-dialog,
.SignOutModal .modal-dialog,
.SignInSuccessModal .modal-dialog {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) !important;
}
.mediaPreviewModal .imageandVideoPreview {
	width: 100%;
	float: left;
	clear: both;
	text-align: center;
}
.mediaPreviewModal .imageandVideoPreview > img,
.mediaPreviewModal .imageandVideoPreview > video {
	max-width: 100%;
}
.mediaPreviewModal #videodiv.imageandVideoPreview > div {
	width: 100% !important;
	height: auto !important;
}
.mediaPreviewModal #audiodiv.imageandVideoPreview > div {
	width: 100% !important;
	height: 60px !important;
}
.mediaPreviewModal button.close {
	width: 28px;
	height: 28px;
	background: url(../images/icons/cancelB.svg) no-repeat center center;
	background-size: 28px 28px;
	opacity: 1;
	margin: 0px;
	padding: 0px;
}
.modal-body {
	overflow-y: auto;
}
.modal-dialog.modal-md {
	width: 400px;
}
.modal-dialog.modal-md.sgModal {
	width: 660px;
	max-width:660px;
}
.modal-dialog.swaggerInfoModal.errorCodeModal {
	width: 90%;
	max-width: 90%;
}
.modal-dialog.swaggerInfoModal {
	width: 800px;
}
.modal-dialog.imageResizeModal {
	width: auto;
	max-width: fit-content;
	min-width: auto;
	max-height: fit-content;
}
.modal-dialog.modal-md .modal-content {
	border-radius: 0px;
	border: 0px;
}
.modal-dialog.modal-md .modal-footer {
	padding: 0px;
}
.modal-dialog.modal-md .singINBtn {
	border-radius: 0px;
	width: 100%;
	padding: 17px 10px;
} 
.mediaPreviewModal .modal-md {
	min-width: 520px;
	max-width: 520px;
}
.subscriptionModal .modal-md,
.confirmationRMModal .modal-md {
	min-width: 480px;
	max-width: 480px;
}
.overwriteMediaModal .modal-md {
	min-width: 720px;
	max-width: 720px;
}
.overwriteMediaModal .modal-body {
	padding: 15px 0px 0px 0px;
}
.confirmationOkModal .noInternetConnectionDiv {
	width: 100%;
	float: left;
	padding: 90px 20px 10px 20px;
	font-size: 18px;
	color: #0D3968;
	background: url("../images/deviceNWoffline.png") no-repeat top center;
	background-size: 90px 90px;
	text-align: center;
}
.confirmationOkModal .addtoHomeScreenDiv {
	width: 100%;
	float: left;
	padding: 10px 40px 10px 60px;
	font-size: 18px;
	color: #0D3968;
	background: url("../images/apple-touch-icon-57x57.png") no-repeat left center;
	background-size: 46px 46px;
	text-align: left;
}
.confirmationOkModal .addtoHomeScreenDiv button.close {
	position: absolute;
	right: 10px;
	top: 15px;
	width: 46px;
	height: 46px;
	background: url("../images/icons/cancelB.svg") no-repeat center center;
	background-size: 28px 28px;
	opacity: 1;
	margin: 0px;
}
.confirmationOkModal .confirmationDiv {
	width: 100%;
	float: left;
	padding: 10px 20px 10px 20px;
	font-size: 22px;
	color: #0D3968;
	font-family: 'open_sanssemibold';
	overflow: hidden;
	text-overflow: ellipsis;
}
.confirmationOkModal .confirmationContentDiv {
	width: 100%;
	float: left;
	padding: 15px;
}
.confirmationOkModal .confirmationContentDiv .reportsFileName {
	width: 100%;
	float: left;
	color: #0D3968;
	font-size: 14px;
	padding: 15px 0px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.confirmationOkModal .confirmationContentDiv > h5 {
	width: 100%;
	float: left;
	padding: 0px 0px 15px;
	margin: 0px;
	font-size: 22px;
	color: #0d3968;
	font-family: 'open_sanssemibold';
}
.confirmationOkModal .confirmationContentDiv > p {
	width: 100%;
	float: left;
	padding: 0px;
	margin: 0px;
	font-size: 20px;
	color: #0d3968;
}
.confirmationOkModal .confirmationContentDiv > h6 {
	width: 100%;
	float: left;
	padding: 15px 0px 15px;
	margin: 0px;
	font-size: 16px;
	color: #0d3968; 
	font-style:italic;
}
.confirmationOkModal .confirmationContentDiv > p.textAlignCenter {
	text-align: center;
} 
.confirmationOkModal .confirmationOKBtn {
	width: 100%;
	border: 0px;
	border-radius: 0px;
	padding: 17px 0px;
	font-size: 20px;
	background: #00d27a;
	margin: 0px;
} 
.reportDetailsModal .okBtn,
.confirmationOkModal .confirmationBasicBtn {
	width: 100%;
	border: 0px;
	border-radius: 0px;
	padding: 17px 0px;
	margin: 0px;
	font-size: 20px;
	background: #195d99;
} 
.rulesViewModal .submitBtn,
.contentViewModal .submitBtn,
.overwriteMediaModal .submitBtn {
	width: 100%;
	border: 0px;
	border-radius: 0px;
	padding: 17px 0px;
	font-size: 20px;
	background: #195d99;
}
.orgViewModal .modal-lg {
	min-width: 98%;
	max-width: 98%;
}  
.reportDetailsModal .modal-md,
.contentViewModal .modal-md {
	min-width: 620px;
	max-width: 620px;
	margin:0px auto;
} 
.orgNodeModal .modal-md{
	min-width: 760px;
	max-width: 760px;
	margin:0px auto;
} 
.transferDetailsModal .modal-md{
	min-width: 920px;
	max-width: 920px;
	margin:0px auto;
} 
.orgNodeModal .modal-sm{
	min-width: 370px;
	max-width: 370px;
	margin:0px auto;
} 
.orgNodeModal .modal-sm .modal-content{
	border:0px;
	border-radius:0px;
} 
.contentViewModal .modal-body {
	padding: 15px 0px;
} 
.contentViewModal .modal-header {
	font-size: 22px;
    color: #0d3968;
    font-family: 'open_sanssemibold';
}
.orgViewModal button.close,
.contentViewModal button.close {
	width: 28px;
	height: 28px;
	background: url(../images/icons/cancelB.svg) no-repeat center center;
	background-size: 28px 28px;
	opacity: 1;
	margin: 0px;
	padding: 0px;
} 
.contentViewModal button.close{
	position: absolute;
    right: 10px;
    top: 10px;
}
.transferDetailsModal .modal-body,
.orgNodeModal .modal-body,
.reportDetailsModal .modal-body{
	padding:0px 0px;
}
.selectRecipeModal .modal-header.bBg, 
.subscriptionModal .modal-header,
.confirmationRMModal .modal-header,
.unitAssignModal .modal-header,
.reportDetailsModal .modal-header {
	font-size: 18px;
    color: #ffffff; 
	background:#0d3968;
	border-radius:0px; 
	border:0px;
	padding:10px;
	word-break: break-all;
}
.transferDetailsModal .modal-header {
	font-size: 18px;
    color: #ffffff; 
	background:#0d3968;
	border-radius:0px; 
	border:0px;
	padding:10px;
	word-break: break-all;
}
.orgNodeModal .modal-header {
	font-size: 18px;
    color: #ffffff; 
	background:#0d3968;
	border-radius:0px; 
	border:0px;
	padding:5px 10px;
	display:block;
	min-height:48px;
	line-height:36px;
}
.orgNodeModal .modal-header > .largeText {  
	width: 100%;
	float: left; 
	font-size: 18px;
    color: #ffffff; 
	line-height:20px;
}
.orgNodeModal .modal-header > .smallText { 
    color: #ffffff; 
	width: 100%;
	float: left; 
	font-size: 12px; 
	line-height:16px;
}
.selectRecipeModal .modal-header.bBg button.close, 
.subscriptionModal button.close,
.confirmationRMModal button.close,
.reportDetailsModal button.close,
.orgNodeModal button.close {
	width: 28px;
	height: 28px;
	background: url(../images/icons/cancelA.svg) no-repeat center center;
	background-size: 28px 28px;
	opacity: 1;
	margin: 0px 0px 0px 25px;
	padding: 0px;
}
.transferDetailsModal button.close {
	width: 28px;
	height: 28px;
	background: url(../images/icons/cancelA.svg) no-repeat center center;
	background-size: 28px 28px;
	opacity: 1;
	margin: 0px 0px 0px 25px;
	padding: 0px;
}
.orgNodeModal button.save,  
.orgNodeModal button.cancel {
	width: 28px;
	height: 28px;  
	border:0px; 
	position:absolute;
	top:9px;
	z-index:1;
}
.orgNodeModal button.save { 
	background: url(../images/icons/Approve.svg) no-repeat center center;
	background-size: 28px 28px; 
	right:10px;
} 
.orgNodeModal button.cancel { 
	background: url(../images/icons/Cancel.svg) no-repeat center center;
	background-size: 28px 28px; 
	right:48px;
} 
.selectRecipeModal .confirmationOKBtn,
.subscriptionModal .confirmationOKBtn,
.confirmationRMModal .confirmationOKBtn {
	width: 100%;
	border: 0px;
	border-radius: 0px;
	padding: 12px 0px;
	font-size: 20px;
	background: #195d99;
	text-align:center;
	margin: 0px;
} 
.subscriptionModal .subscriptionDiv {
	width: 100%;
	float: left;
	padding:0px;  
} 
.subscriptionModal .subscriptionDiv p {
	width: 100%;
	float: left;
	padding: 0px;
	margin: 0px;
	font-size: 16px;
	color: #0d3968;
} 
.confirmationRMModal .reportStatusModal {
	width: 100%;
	float: left;
	padding:25px 15px; 
	text-align:center;
}
.confirmationRMModal .reportStatusModal > h6 {
	width: 100%;
	float: left;
	padding:35px 0px 0px;
	margin: 0px 0px;
	font-size: 20px;
	color: #0d3968;  
	text-align:center;
}  
.confirmationRMModal .reportStatusModal .smallText {
	width: 100%;
	float: left;
	padding: 0px;
	margin: 10px 0px 0px;
	font-size: 16px;
	color: #0d3968;
} 
.confirmationRMModal .rmStatusIcon{ 
	width: 60px;
    height: 60px;
    display: inline-block;  
    vertical-align: middle;
    text-align: center; 
    line-height: 60px;  
}   
.confirmationRMModal .rmStatusIcon img{  
	width:100%;
}  
.confirmationRMModal .deleteNoBtn,
.confirmationRMModal .deleteYesBtn {
	width: 50%;
	float: left;
	border: 0px;
	border-radius: 0px;
	padding: 17px 0px;
	font-size: 20px;
	margin: 0px;
}
.confirmationRMModal .deleteNoBtn {
	background: #c6d7e6;
	color: #0d3968;
}
.confirmationRMModal .deleteYesBtn {
	background: #195d99;
}
.reportDetailsModal .modalHeader {
	font-size: 18px;
    color: #ffffff; 
	background:#0d3968;
	border-radius:0px; 
	border:0px;
	padding:5px 10px;
	display:block;
	min-height:48px;
	line-height:36px;
}
.reportDetailsModal .modal-header > .largeText {  
	width: 100%;
	float: left; 
	font-size: 18px;
    color: #ffffff; 
	line-height:20px;
}
.reportDetailsModal .modal-header > .smallText { 
    color: #ffffff; 
	width: 100%;
	float: left; 
	font-size: 12px; 
	line-height:16px;
}
.reportDetailsModal .reportDetailsD {
	width: 100%;
	float: left;
	padding:15px 15px; 
	overflow-x:hidden;
	overflow-y:auto;
}
.reportDetailsModal .reportDetailsD h6 {
	width: 100%;
	float: left;
	padding:15px 0px 10px;
	margin: 0px 0px;
	font-size: 16px;
	color: #0d3968; 
	font-family: 'open_sanssemibold';
} 
.reportDetailsModal .reportDetailsD p {
    width: 100%;
    float: left;
    padding: 0px;
    margin: 0px 0px 20px;
    font-size: 16px;
    color: #0d3968;
}
.reportDetailsModal .reportDetailsD p.notUsed { 
    color: #19adff;
	font-family: 'open_sanssemibold';
    font-size: 16px;
}
.reportDetailsModal .reportDetailsD .descriptionEdit {
	background: url("../images/icons/penB.svg") no-repeat center center;
	background-size: 24px 24px;
	width:24px;
	height:24px;
	float:right;
}
.reportDetailsModal .reportDetailsS {
	width: 100%;
	float: left;
	padding:15px 15px;  
}
.reportDetailsModal .reportDetailsS h6 {
	width: 100%;
	float: left;
	padding:0px 0px 10px;
	margin: 0px 0px;
	font-size: 18px;
	color: #0d3968; 
	font-family: 'open_sanssemibold';
} 
.reportDetailsModal .reportDetailsS p {
    width: 100%;
    float: left;
    padding: 0px;
    margin: 0px 0px 20px;
    font-size: 16px;
    color: #0d3968;
}
.reportDetailsModal .reportDetailsD p.textareaP {
    white-space: pre-wrap;
	width: 448px;
} 
.reportDetailsModal .reportDetailsD p > .pFromDate{
	margin-right:10px;
} 
.reportDetailsModal .reportDetailsD .orgViewIcon{ 
	width: 36px;
    height: 36px;
    display: inline-block;
    background: #0d3968;
    padding: 2px;
    vertical-align: middle;
    text-align: center;
    border-radius: 2px;
    line-height: 30px;
	border:0px;
	margin-right:5px;
}   
.reportDetailsModal .reportDetailsD .orgViewIcon img{ 
	max-height:32px; 
	max-width:32px; 
}   
.termsConditionsModal .modal-lg{
	min-width: 750px;
	max-width: 750px;
	margin:0px auto;
}  
.termsConditionsModal .modal-body{
	padding:0px 0px;
	color: #ffffff; 
	background:#0d3968;
} 
.termsConditionsModal .modal-header {
	font-size: 18px;
    color: #ffffff; 
	background:#0d3968;
	border-radius:0px; 
	border:0px;
	border-bottom:1px solid #000;
	padding:10px;
} 
.termsConditionsModal button.close {
	width: 28px;
	height: 28px;
	background: url(../images/icons/cancelA.svg) no-repeat center center;
	background-size: 28px 28px;
	opacity: 1;
	margin: 0px;
	padding: 0px;
}
.termsConditionsModal .termsConditionsD {
	width: 100%;
	float: left;
	padding:15px 15px; 
	overflow-x:hidden;
	overflow-y:auto;
}
.termsConditionsModal .termsConditionsD > h4,
.termsConditionsModal .termsConditionsD > h5 {
	width: 100%;
	float: left;
	padding:0px 0px 10px;
	margin: 0px 0px; 
	color: #ffffff;  
	text-align:center;
} 
.termsConditionsModal .termsConditionsD > h4 { 
	font-size: 18px; 
	font-family: 'open_sanssemibold'; 
} 
.termsConditionsModal .termsConditionsD > h5 { 
	font-size: 14px; 
	font-weight:normal;
} 
.termsConditionsModal .termsConditionsD > h6 {
	width: 100%;
	float: left;
	padding:0px 0px 10px;
	margin: 0px 0px;
	font-size: 14px;
	color: #ffffff; 
	font-family: 'open_sanssemibold';
} 
.termsConditionsModal .termsConditionsD > p {
	width: 100%;
	float: left;
	padding: 0px;
	margin: 0px 0px 20px;
	font-size: 12px;
	color: #ffffff;
}
.termsConditionsModal .termsConditionsD > p a{
	text-decoration:underline;
	font-size: 12px;
	color: #ffffff;
}
.termsConditionsModal .termsConditionAgreeBtn{
	width:120px;
	float: left;
	padding:8px 0px;
	font-size: 16px;
	color: #ffffff;
	font-family: 'open_sanssemibold'; 
	font-style:italic;
	background: #195d99;
	border-radius:20px;
	text-align:center;
	border:0px;
	margin:15px 0px 0px;
}
.rulesViewModal .modal-md {
	min-width: 660px;
	max-width: 660px;
}
.activateNowModal .deleteConfirmation, 
.confirmationDeleteModal .deleteConfirmation {
	width: 100%;
	float: left;
	padding: 0px 0px 20px 0px;
	font-size: 22px;
	color: #0D3968;
	font-family: 'open_sanssemibold';
	overflow: hidden;
	text-overflow: ellipsis;
}
.confirmationDeleteModal .deleteNoBtn,
.confirmationDeleteModal .deleteYesBtn {
	width: 50%;
	float: left;
	border: 0px;
	border-radius: 0px;
	padding: 17px 0px;
	font-size: 20px;
	margin: 0px;
}
.confirmationDeleteModal .deleteNoBtn {
	background: #c6d7e6;
	color: #0d3968;
}
.confirmationDeleteModal .deleteYesBtn {
	background: #195d99;
}
.confirmationDeleteModal .contentDiv {
	width: 100%;
	float: left; 
	padding:15px;
	text-align:center;
} 
.confirmationDeleteModal .contentDiv > p {
    width: 100%;
    float: left;
    padding: 0px;
    margin: 0px 0px 20px;
    font-size: 16px;
    color: #0d3968;
}
.confirmationDeleteModal .contentDiv > p:last-child { 
    margin: 0px 0px 0px; 
}
.activateNowModal .activateNowBtn,
.activateNowModal .woActivationBtn { 
	float: left;
	border: 0px;
	border-radius: 0px;
	padding: 17px 0px;
	font-size: 20px;
	margin: 0px;
	max-height:64px;
	min-height:64px;
}
.activateNowModal .activateNowBtn {
	background: #c6d7e6;
	color: #0d3968;
	width: 40%;
}
.activateNowModal .woActivationBtn {
	background: #195d99;
	width: 60%;
	white-space: break-spaces;
}
.confirmationYesNoModal .confirmationYesNo {
	width: 100%;
	float: left;
	padding: 0px 0px 20px 0px;
	font-size: 22px;
	color: #0D3968;
}
.confirmationYesNoModal .confirmationYesNo > h5 {
	width: 100%;
	float: left;
	padding: 0px 0px 15px;
	margin: 0px;
	font-size: 22px;
	color: #0d3968;
	font-family: 'open_sanssemibold';
}
.confirmationYesNoModal .confirmationYesNo > p {
	width: 100%;
	float: left;
	padding: 0px;
	margin: 0px;
	font-size: 20px;
	color: #0d3968;
	word-wrap: normal;
} 
.reportDetailsModal .saveBtn,
.reportDetailsModal .cancelBtn,
.confirmationOkModal .finishBtn,
.confirmationOkModal .gotoDashboardBtn,
.selectRecipeModal .noBtn,
.selectRecipeModal .yesBtn,
.confirmationYesNoModal .noBtn,
.confirmationYesNoModal .yesBtn,
.unitAssignModal .noBtn,
.unitAssignModal .yesBtn {
	width: 50%;
	float: left;
	border: 0px;
	border-radius: 0px;
	padding: 17px 0px;
	font-size: 20px;
	margin: 0px;
}
.reportDetailsModal .cancelBtn,
.confirmationOkModal .finishBtn,
.selectRecipeModal .noBtn,
.confirmationYesNoModal .noBtn,
.unitAssignModal .noBtn {
	background: #c6d7e6;
	color: #0d3968;
}
.reportDetailsModal .saveBtn,
.selectRecipeModal .yesBtn,
.confirmationYesNoModal .yesBtn,
.unitAssignModal .yesBtn {
	background: #195d99;
}
.confirmationOkModal .gotoDashboardBtn {
	background: #195d99;
}
.selectRecipeModal .yesBtn.importDeActive {
	color: #a7b8c8;
	background: #dce3e9;
}
.confirmationYesNoModal .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 30px 5px 0px;
	height: auto;
	background: #fff !important;
	color: #0D3968;
	font-size: 20px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
}
.confirmationYesNoModal select.form-control,
.confirmationYesNoModal .input-group .react-datepicker-wrapper .react-datepicker__input-container input,
.confirmationYesNoModal .input-group #selectTime {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: #fff url("../images/icons/markdownA.svg") no-repeat right center !important;
}
.confirmationYesNoModal select.form-control option {
	font-size: 16px;
}
.confirmationYesNoModal select::-ms-expand {
	display: none;
}
.errorModalContent {
	width: 100%;
	float: left;
	padding: 0 0 20px;
	font-size: 22px;
	color: #0d3968;
	font-family: open_sanssemibold;
}
.errorModalContentOKBtn {
	width: 100%;
	border: 0;
	border-radius: 0;
	padding: 17px 0;
	margin:0px;
	font-size: 20px;
	background: #195d99;
	color: #fff;
}
.modal-dialog.infoModal .modal-content {
	border: 0px;
	border-radius: 0px;
}
.btn.btn-default.greenBtn,
.btn.btn-default.blueBtn,
.btn.btn-default.lgihtBlueBtn {
	border: 0px;
	border-radius: 0px;
	padding: 10px 15px;
	font-family: 'open_sanssemibold';
}
.btn.btn-default.lgihtBlueBtn {
	color: #0d3968;
	background: #bccddc;
}
.btn.btn-default.blueBtn {
	color: #ffffff;
	background: #195d99;
}
.btn.btn-default.greenBtn {
	color: #ffffff;
	background: #00d27a;
}
.btn.btn-secondary.frypotSelection {
	color: #b4b5b7;
	background: #424548;
	min-width: 80px;
	max-width: 180px;
	border-radius: 20px;
	border: 0px;
	font-size: 14px;
	padding: 6px 16px;
}
.btn.btn-secondary.frypotSelection option {
	color: #fff;
	background-color: #545b62;
}
.frypotBtnGroup {
	display: table-cell;
	text-align: left;
}
.btn.btn-default.installBtn {
	color: #ffffff;
	background: #195d99;
	min-width: 80px;
	border: 0px;
	font-size: 14px;
	padding: 6px 16px;
}
.imageResizeModal .modal-footer {
	padding: 10px 0px;
	display: block;
}
.imageResizeModal .imageResizeForm > div,
.imageResizeModal .imageResizeForm > img {
	max-width:inherit;
}
.imageResizeModal .cropConfirmButton {
	min-width: 120px;
	border: 0px;
	padding: 10px 15px;
	font-size: 16px;
	background: #195d99;
	margin: 0px auto;
}
.frypotInfoModal button.close,
.imageResizeModal button.close,
.swaggerInfoModal button.close,
.infoModal button.close {
	width: 28px;
	height: 28px;
	background: url("../images/icons/cancelB.svg") no-repeat center center;
	background-size: 28px 28px;
	opacity: 1;
	margin: 0px;
}
.frypotInfoModal .modal-header,
.swaggerInfoModal .modal-header,
.imageResizeModal .modal-header,
.infoModal .modal-header {
	width: 100%;
	float: left;
	padding: 15px 15px 0px;
	border: 0px;
	margin: 0px;
	font-size: 22px;
	color: #0d3968;
	font-family: 'open_sanssemibold';
}
.infoModal .infoContent {
	width: 100%;
	float: left;
	padding: 0px 25px 20px 0px;
	font-size: 16px;
	color: #0D3968;
}
.infoModal .infoContent > p {
	width: 100%;
	float: left;
	padding: 0px;
	margin: 0px;
	font-size: 18px;
	color: #0d3968;
}
.infoModal .infoContent > h6 {
	width: 100%;
	float: left;
	padding: 5px 10px;
	margin: 0px 0px;
	font-size: 18px;
	color: #0d3968;
	background: #e8eff5;
}
.infoModal .detailsInfoContent {
	width: 100%;
	float: left;
	padding: 0px 25px 20px 0px;
	font-size: 16px;
	color: #0D3968;
}
.infoModal .detailsInfoContent > p {
	width: 100%;
	float: left;
	padding: 0px;
	margin: 0px 0px 10px;
	font-size: 14px;
	color: #0d3968;
}
.infoModal .detailsInfoContent > h6 {
	width: 100%;
	float: left;
	padding: 5px 10px;
	margin: 0px 0px;
	font-size: 14px;
	color: #0d3968;
	background: #e8eff5;
	word-break: break-word;
}
.infoModal .detailsInfoContent > ul li {
	list-style-type: square;
	margin-left:25px;
}
.infoModal .sgModalContent {
	width: 100%;
	float: left;
	padding: 0px 0px 0px;
	font-size: 16px;
	color: #0D3968;
}
.infoModal .sgModalContent > p {
	width: 100%;
	float: left;
	padding: 0px;
	margin: 0px 0px 5px;
	font-size: 14px;
	color: #0d3968;
}
.infoModal .cancelBtn,
.infoModal .submitBtn {
	width: 50%;
	float: left;
	border: 0px;
	border-radius: 0px;
	padding: 17px 0px;
	font-size: 20px;
	margin: 0px;
}
.infoModal .cancelBtn {
	background: #c6d7e6;
	color: #0d3968;
}
.infoModal .submitBtn {
	background: #195d99;
}
.frypotInfoModal .modal-content {
	background: #f2f2f2;
}
.frypotInfoModal .modal-body {
	padding: 15px 0px;
}
.frypotInfoModal .modal-body.chartModalBody {
	padding: 15px 15px;
}
a.list-group-item,
button.list-group-item {
	color: #333F48;
}
.badge {
	background: #333F48;
}
@-ms-viewport {
	width: device-width;
}
@-ms-viewport {
	width: auto!important;
}
.progress {
	background: #57595d;
	border-radius: 0px;
}
.progress-bar.remainingDays {
	background: #F93251;
}
.progress-bar.goodStatus {
	background: #FFBE00;
}
.progress-bar.poorStatus {
	background: #f93251;
}
.progress-bar.greatStatus {
	background: #3cce82;
}
hr {
	width: 100%;
	float: left;
	clear: both;
	margin: 0px;
	padding: 0px;
	height: 20px;
	border: 0px;
}
#page-wrapper {
	width: 100%;
	padding:65px 0px 0;
}
#adminWrapper {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both;
}
.sideNavToggleTransparent {
	background: #000;
	position: fixed;
	top: 64px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	opacity: 0.6;
	z-index: 7;
	display: none;
} 
#adminWrapper .toggleTransparent,
#wrapper .toggleTransparent,
#wrapper .searchToggleTransparent,
#adminWrapper .searchToggleTransparent {
	background: #000;
	position: fixed;
	top: 64px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	opacity: 0.3;
	z-index: 9;
	display: none;
} 
#adminWrapper.navFilterToggled .toggleTransparent,
#wrapper.navFilterToggled .toggleTransparent {
	display: block;
}
.orgNodeTransparent {
	background: #000;
	position: fixed;
	top:0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	opacity: 0.3;
	z-index: 6666;
	display: none;
} 
#adminWrapper .userToggleTransparent,
#wrapper .userToggleTransparent {
	background: #000;
	position: fixed;
	top: 64px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	opacity: 0.3;
	z-index: 9;
	display: none;
}
#adminWrapper.navUserToggled .userToggleTransparent,
#wrapper.navUserToggled .userToggleTransparent {
	display: block;
}
#adminWrapper .treeInfoToggleTransparent,
#wrapper .treeInfoToggleTransparent {
	background: #000;
	position: fixed;
	top: 64px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	opacity: 0.3;
	z-index: 9;
	display: none;
}
#adminWrapper.treeinfoViewToggled .treeInfoToggleTransparent,
#wrapper.treeinfoViewToggled .treeInfoToggleTransparent {
	display: block;
}
/*** **************  ************** ****
		*** Under Construction ***
**** **************  ************** ***/

.underConstruction {
	width: 760px;
	margin: 0px auto;
	padding: 88px 0px 0px;
}
.underConstruction .underConstructionImg {
	width: 100%;
	float: left;
	margin: 0px 0px 30px;
	padding: 330px 0px 0px;
	background: url("../images/websiteBuilder.png") no-repeat top center;
	background-size: 320px 320px;
}
.underConstruction .cloudServerImg {
	width: 100%;
	float: left;
	margin: 0px 0px 30px;
	padding: 220px 0px 0px;
	background: url("../images/cloudServer.png") no-repeat top center;
	background-size: 200px 200px;
}
.underConstruction h4,
.underConstruction h3,
.underConstruction h2,
.underConstruction h1 {
	width: 100%;
	float: left;
	clear: both;
	text-align: center;
}
.underConstruction h1 {
	font-size: 142px;
	color: #afb0b1;
	font-family: 'open_sanssemibold';
}
.underConstruction h2 {
	font-size: 42px;
	color: #afb0b1;
	font-family: 'open_sanssemibold';
}
.underConstruction h3 {
	padding: 10px 0px 0px;
	font-size: 20px;
	color: #ffffff;
	font-family: 'open_sanslight';
}
.underConstruction h4 {
	padding: 10px 0px 0px;
	font-size: 16px;
	color: #ffffff;
	font-family: 'open_sanslight';
}
.underConstruction h4 .singINBtn {
	display: inline;
	padding: 6px 15px;
	margin-left: 15px;
	background: #195d99;
	font-size: 14px;
	color: #ffffff;
	font-family: 'open_sanslight';
}
/*** **************  ************** ****
		*** Header ***
**** **************  ************** ***/

.bg-Welbilt {
	background: #0d3968;
	padding: 0px 0px;
	color: #fff;
	min-height: 64px;
}
.bgAdmin {
	background: #0d3968;
	padding: 0px 0px;
	color: #fff;
	border-bottom: 2px solid #000;
}
.navbarLeft > li {
	padding: 10px 10px 9px;
	font-size: 14px;
	min-width: 230px;
}
.navbarLeft > li:first-child {
	border-right: 2px solid #000000; 
}
.navbarLeft > li > a {
	padding: 0px 0px;
}
.navbarLeft > li .navStoreIcon {
	float: left;
	margin: 0px 10px 0px 0px;
	width: 42px;
    line-height: 42px; 
}
.navbarLeft > li .navStoreIcon > img { 
	vertical-align: middle;
	max-width: 42px;
	max-height: 42px;
}
.navbarLeft > li .navStoreText {
	display: inline-block;
	color: #fff;
	font-size: 16px;
	max-width: 240px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap; 
}
.navbarLeft > li .navStoreText > b {
	font-weight: normal;
	display: block;
	font-size: 14px;
}
.titlebarLeft > li {
	padding: 10px 10px 9px;
	font-size: 18px;
	vertical-align: middle;
    display: table;
} 
.navbar-dark .navbar-nav.titlebarLeft .nav-link {
	color: #ffffff;
	display: table-cell;
    padding: 0px; 
}
.selectedNavbarLeft > li {
	padding: 10px 10px 9px;
	font-size: 14px;
}
.selectedNavbarLeft > li > a,
.selectedNavbarLeft > li > button {
	padding: 0px 0px;
}
.selectedNavbarLeft > li .notificaMgmtNav,
.selectedNavbarLeft > li .helpNav,
.selectedNavbarLeft > li .UnitRegistrationNav,
.selectedNavbarLeft > li .subscriptionNav,
.selectedNavbarLeft > li .UserManagementNav,
.selectedNavbarLeft > li .errorCenterNav,
.selectedNavbarLeft > li .swaggerNav,
.selectedNavbarLeft > li .UserProfileNav,
.selectedNavbarLeft > li .softwareNav,
.selectedNavbarLeft > li .transferNav,
.selectedNavbarLeft > li .menuNav,
.selectedNavbarLeft > li .recipeNav,
.selectedNavbarLeft > li .settingsNav,
.selectedNavbarLeft > li .mediaNav,
.selectedNavbarLeft > li .reportNav,
.selectedNavbarLeft > li .haccpNav,
.selectedNavbarLeft > li .orgznManagementNav,
.selectedNavbarLeft > li .smartTagNav {
	padding: 5px 15px 5px 50px;
	font-size: 18px;
	color: #ffffff
}
.selectedNavbarLeft > li .notificaMgmtNav {
	background: url("../images/mainmenu/notifications.svg") no-repeat left center;
	background-size: 40px 40px;
}
.selectedNavbarLeft > li .helpNav {
	background: url("../images/mainmenu/Help.svg") no-repeat left center;
	background-size: 40px 40px;
}
.selectedNavbarLeft > li .UnitRegistrationNav {
	background: url("../images/mainmenu/UnitRegistrationAssignment.svg") no-repeat left center;
	background-size: 40px 40px;
}
.selectedNavbarLeft > li .subscriptionNav {
	background: url("../images/mainmenu/warranty.svg") no-repeat left center;
	background-size: 40px 40px;
}
.selectedNavbarLeft > li .UserManagementNav {
	background: url("../images/mainmenu/users.svg") no-repeat left center;
	background-size: 40px 40px;
}
.selectedNavbarLeft > li .errorCenterNav {
	background: url("../images/mainmenu/errors.svg") no-repeat left center;
	background-size: 40px 40px;
}
.selectedNavbarLeft > li .swaggerNav {
	background: url("../images/mainmenu/swagger.png") no-repeat left center;
	background-size: 40px 40px;
}
.selectedNavbarLeft > li .UserProfileNav {
	background: url("../images/users/neutral.svg") no-repeat left center;
	background-size: 40px 40px;
}
.selectedNavbarLeft > li .softwareNav {
	background: url("../images/mainmenu/plugins.svg") no-repeat left center;
	background-size: 40px 40px;
}
.selectedNavbarLeft > li .transferNav {
	background: url("../images/mainmenu/transfer.png") no-repeat left center;
	background-size: 32px 32px;
}
.selectedNavbarLeft > li .menuNav {
	background: url("../images/mainmenu/menu.svg") no-repeat left center;
	background-size: 40px 40px;
}
.selectedNavbarLeft > li .recipeNav {
	background: url("../images/mainmenu/recipe.png") no-repeat left center;
	background-size: 40px 40px;
}
.selectedNavbarLeft > li .settingsNav {
	background: url("../images/mainmenu/settings.svg") no-repeat left center;
	background-size: 40px 40px;
}
.selectedNavbarLeft > li .mediaNav {
	background: url("../images/mainmenu/cloud.svg") no-repeat left center;
	background-size: 40px 40px;
}
.selectedNavbarLeft > li .reportNav {
	background: url("../images/mainmenu/report.svg") no-repeat left center;
	background-size: 40px 40px;
}
.selectedNavbarLeft > li .haccpNav {
	background: url("../images/mainmenu/haccp.svg") no-repeat left center;
	background-size: 40px 40px;
}
.selectedNavbarLeft > li .orgznManagementNav {
	background: url("../images/mainmenu/OrganizationMgmt.svg") no-repeat left center;
	background-size: 40px 40px;
}
.selectedNavbarLeft > li .smartTagNav {
	background: url("../images/mainmenu/smartTagNew.svg") no-repeat left center;
	background-size: 40px 40px;
}
.navbarLRight {
	display: block;
}
.navbarLRight > li .deviceSNText {
	color: #bac3d2;
	text-align: right;
	font-size: 12px;
	font-family: 'open_sanslight';
	display: block;
}
.navbarLRight > li .UTCText {
	color: #bac3d2;
	text-align: right;
	font-size: 12px;
	font-family: 'open_sanslight';
	display: block;
	max-width: 300px;
}
.navbarLRight > li .UTCText > b,
.navbarLRight > li .deviceSNText > b {
	font-weight: normal;
}
.navbarRight {
	float: right;
	margin: 0px !important;
	border-left: 2px solid #000000;
	flex-direction: row;
}
.navbarRight > li {
	padding: 0px;
	font-size: 14px;
}
.navbarRight > li > a {
	padding: 0px 0px;
}
.navbarRight > li > a {
	position: relative;
}
.navbarRight > li > a .navNotifiStatus {
	position: absolute;
	top: 20px;
	right: 18px;
	width: 10px;
	background: #0aaeff;
	display: inline-block;
	height: 10px;
	border-radius: 5px;
	border: 1px solid rgba(0, 0, 0, 0.1);
}
.navbarRight > li > a.navBack,
.navbarRight > li > a.userManualDownload,
.navbarRight > li > a.navFilter,
.navbarRight > li > a.navSearch,
.navbarRight > li > a.navAlarm,
.navbarRight > li > a.navUser {
	width: 60px;
	height: 64px;
	display: block;
}
.navbarRight > li > a.userManualDownload,
.navbarRight > li > a.userManualDownload {
	background: url("../images/icons/downloadT.png") no-repeat center center;
	background-size: 20px auto;
}
.navFilterToggled .navbarRight > li > a.userManualDownload,
.navbarRight > li > a.userManualDownload.active,
.navbarRight > li > a.userManualDownload:hover {
	background: #F2F2F2 url("../images/icons/download.png") no-repeat center center;
	background-size: 20px auto;
}
.navbarRight > li > a.navFilter,
.navbarRight > li > a.navFilter {
	background: url("../images/icons/filter.svg") no-repeat center center;
	background-size: 24px auto;
}
.navFilterToggled .navbarRight > li > a.navFilter,
.navbarRight > li > a.navFilter.active,
.navbarRight > li > a.navFilter:hover {
	background: #F2F2F2 url("../images/icons/FilterA.svg") no-repeat center center;
	background-size: 24px auto;
}
.navbarRight > li > a.navSearch {
	background: url("../images/icons/search.svg") no-repeat center center;
	background-size: 24px auto;
}
.navbarRight > li > a.navSearch.active,
.navbarRight > li > a.navSearch:hover {
	background: #F2F2F2 url("../images/icons/searchA.svg") no-repeat center center;
	background-size: 24px auto;
}
.navbarRight > li > a.navAlarm {
	background: url("../images/icons/alarm.svg") no-repeat center center;
	background-size: 24px auto;
}
.navbarRight > li > a.navAlarm.active,
.navbarRight > li > a.navAlarm:hover {
	background: #F2F2F2 url("../images/icons/BellA.svg") no-repeat center center;
	background-size: 24px auto;
}
.navbarRight > li > a.navUser,
.navbarRight > li > a.navUser {
	background: url("../images/icons/user.svg") no-repeat center center;
	background-size: 24px auto;
}
.navUserToggled .navbarRight > li > a.navUser,
.navbarRight > li > a.navUser.active,
.navbarRight > li > a.navUser:hover {
	background: #F2F2F2 url("../images/icons/usersA.svg") no-repeat center center;
	background-size: 24px auto;
}
.navbarRight > li > a.navBack {
	background: url("../images/icons/cancelA.svg") no-repeat center center;
	background-size: 36px auto;
}
.navbarRight > li > a.navBack.active,
.navbarRight > li > a.navBack:hover {
	background: #F2F2F2 url("../images/icons/cancelB.svg") no-repeat center center;
	background-size: 36px auto;
} 
.navbarRight a.navSearch{
	width: 60px;
	height: 64px;
	display: block;
	border:none;
	border-radius:0px;
}
.navSearchToggled .navbarRight .nav-item{  
	position: relative; 
}  
.navSearchDrpdn .advSearch {
    width: 100%;
    float: left;
    padding: 0px 0px 10px 0px; 
    color: #0D3968; 
}  
.navSearchDrpdn .hSearchText {
	border: 0px;
	border-radius: 0px;
	padding-left: 20px;
	border-bottom: 1px solid #7f95ad; 
	font-size: 20px;
	color: #0D3968;  
}
.navSearchDrpdn .advanced-pos-relative .hSearchSelect{
	border: 0px;
	border-radius: 0px;
	padding-left: 20px;
	border-bottom: 1px solid #7f95ad; 
	font-size: 16px;
	color: #0D3968;  
	padding: 7px 10px 6px;
	background:#ffffff;
}
.navSearchDrpdn .advanced-pos-relative select.form-control {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: #fff url("../images/icons/markdownA.svg") no-repeat right center !important;
	background-size:26px 16px !important;
}
.navSearchDrpdn .advanced-pos-relative  select.form-control option {
	font-size: 16px;
}
.navSearchDrpdn .advanced-pos-relative  select::-ms-expand {
	display: none;
} 
.navSearchDrpdn .dropdown-submenu {
  position: relative;
}

.navSearchDrpdn .dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 6px;
  top: .8em;
}

.navSearchDrpdn .dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: .1rem;
  margin-right: .1rem;
}
.navSearchDrpdn select.form-control { 
	background: #F2F2F2;
	border: 0px;
	border-radius: 0px;
	padding-left: 20px;
	border-bottom: 1px solid #7f95ad; 
	padding:6px 10px;
}
.navSearchDrpdn .dropdownSub { 
	background: #F2F2F2;
	border: 0px;
	border-radius: 0px;
	padding-left: 20px;
	border-bottom: 1px solid #7f95ad; 
	padding:6px 10px 7px; 
    color: #0D3968 !important;
}
.navSearchDrpdn .dropdown-menu {   
	border-radius: 0px; 
	margin:0px !important;
	width:100%;
}
.navSearchDrpdn .dropdown-menu li.option-disabled{   
	display: none;
}
.navSearchToggled #wrapper .searchToggleTransparent,
.navSearchToggled #adminWrapper .searchToggleTransparent{ 
    display: block;  
}
.navbarRight a.navSearch {
	background: url("../images/icons/search.svg") no-repeat center center;
	background-size: 24px auto;
}
.navbarRight a.navSearch.dropdown-toggle::after {
	display:none;
}
.navSearchToggled .navbarRight a.navSearch,
.navbarRight a.navSearch.active,
.navbarRight a.navSearch:hover {
	background: #F2F2F2 url("../images/icons/searchA.svg") no-repeat center center;
	background-size: 24px auto;
}
.navFilterWrapper {
	width: 100%;
	min-height: 320px;
	padding: 0px;
	background: #F2F2F2;
	position: absolute;
	top: 64px;
	left: 0px;
	z-index: 9999;
	display: none;
}
.filterListOuter {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px 64px 0px;
}
.filterListOuter .headerFilterSearch {
	border: 0px;
	border-radius: 0px;
	padding-left: 0px;
	border-bottom: 1px solid #7f95ad;
	width: 320px;
	font-size: 20px;
	color: #0D3968;
	margin: 0px auto 20px auto;
	background: #F2F2F2 url("../images/icons/searchD.svg") no-repeat right center;
	background-size: 24px auto;
}
#wrapper.navFilterToggled .navFilterWrapper {
	display: block;
}
.navFilterToggled .navbarRight > li:nth-child(2) > a,
.navFilterToggled .navbarRight > li:nth-child(3) > a,
.navFilterToggled .navbarRight > li:nth-child(4) > a {
	pointer-events: none;
	cursor: default;
	opacity: 0.5;
}
.navUserToggled .navbarRight > li:nth-child(1) > a,
.navUserToggled .navbarRight > li:nth-child(2) > a,
.navUserToggled .navbarRight > li:nth-child(3) > a {
	pointer-events: none;
	cursor: default;
	opacity: 0.5;
}
#wrapper.navUserToggled .navUserWrapper,
#adminWrapper.navUserToggled .navUserWrapper {
	display: block;
}
.countryCheckbox {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 20px;
	text-align: center;
}
.countryCheckbox > .customCheckbox {
	width: 24%;
	margin-right: 0px;
	margin: 10px 0px 5px;
	padding: 0px 10px 0px 0px;
}
.countryCheckbox.withMorethanThree > .customCheckbox {
	float: left;
}
.countryCheckbox > .customCheckbox input[type=checkbox] + label {
	font-size: 16px;
}
.countryCheckbox .tagTypeUnit,
.countryCheckbox .tagTypeLocation{
	margin-top: -3px;
}
.countryCheckbox .tagTypeUnit span,
.countryCheckbox .tagTypeLocation span{ 
	max-width: 160px; 
	padding: 4px 0px 4px 25px;
}
.countryCheckbox .tagTypeUnit:before,
.countryCheckbox .tagTypeLocation:before{
	content:"";
	display: block;
	height: 20px;
	width: 20px;
	background-color: inherit;
	border: inherit;
	position: absolute;
	left: -8px;
    top: calc(50% - 10px);
	clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
	transform: rotate(45deg);
	border-radius: 0.20em 0 0.20em 0.35em;
}
.navbar .sideNavBar {
	background: url(../images/icons/sideNavBar.png) no-repeat center center;
	background-size: auto 36px;
	width: 66px;
	height: 64px;
	margin: 0px 0px;
	border-right: 2px solid #000;
	display: none;
}
.navbar .welbiltRLogo {
	background: url(../images/icons/home.svg) no-repeat center center;
	background-size: auto 40px;
	width: 66px;
	height: 64px;
	margin: 0px 0px;
	border-right: 2px solid #000;
}
.navbar .welbiltLogo {
	background: url(../images/WBTKitchenConnectWhite.png) no-repeat left center;
	background-size: auto 40px;
	width: 270px;
	height: 64px;
	margin: 0px 15px 0px;
	border-radius: 4px;
}
.welcomText {
	width: 100%;
	float: left;
	text-align: center;
	padding: 25px 0px 0px;
	font-size: 30px;
	color: #fff;
	font-family: 'open_sanssemibold';
}
/* ****** ****** ****** ****** ****** **
 * ****** User Profile Details ****** *
** ****** ****** ****** ****** ****** */

.navUserWrapper {
	width: 400px;
	height: 100%;
	padding: 0px;
	background: #F2F2F2;
	position: fixed;
	top: 64px;
	right: 0px;
	z-index: 11;
	display: none;
	padding-bottom: 64px;
	overflow: hidden;
	z-index: 9999;
}
.userDetailsDiv {
	position: relative;
	height: 100%;
}
.userDetailsDiv .signOutBtn {
	width: 100%;
	border: 0px;
	border-radius: 0px;
	padding: 17px 10px;
	background: #195d99;
	position: absolute;
	bottom: 0px;
	left: 0px;
}
.userDetailsDiv .signOutBtn .signOutBtnIcon {
	width: 24px;
	height: 24px;
	display: inline-block;
	background: url("../images/icons/logoutA.svg") no-repeat center center;
	background-size: 24px 24px;
	margin-bottom: -6px;
	margin-right: 10px;
}
.userProfile {
	width: 100%;
	height: 100%;
	float: left;
	padding: 25px 25px 0px;
	overflow-y: auto;
	position: relative;
}
.userProfile > h4 {
	font-size: 30px;
	color: #0D3968;
	width: 100%;
	margin: 0px 0px 0px;
	padding: 0px 0px 25px;
}
.userProfile > h4 > .profileEdit {
	float: right;
	background: transparent;
	width: 24px;
	height: 24px;
	padding: 0px;
	border: 0px;
	margin-top: 2px;
}
.userProfile > h4 > .profileEdit > img {
	width: 24px;
	height: 24px;
}
.userProfile .userDetails {
	width: 100%;
	float: left;
}
.userProfile .userDetails .userProfileImg,
.userProfile .userDetails .userNameRole {
	float: left;
}
.userProfile .userDetails .userProfileImg {
	width: 64px;
	height: 64px;
	border-radius: 50%;
	background: #ff8a8a;
	margin-right: 10px;
}
.userProfile .userDetails .userProfileImg > img {
	max-width: 64px;
}
.userProfile .userDetails .userNameRole {
	width: 245px;
}
.userProfile .userDetails .userNameRole .userName,
.userProfile .userDetails .userNameRole .userRole {
	width: 100%;
	float: left;
	color: #0D3968;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.userProfile .userDetails .userNameRole .userName {
	font-size: 20px;
}
.userProfile .userDetails .userNameRole .userRole {
	font-size: 16px;
}
.userProfile ul.userDetailsList {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 0px 74px;
}
.userProfile ul.userDetailsList > li {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 0px 5px;
	margin: 0px;
	color: #0D3968;
	font-size: 20px;
}
.userProfile ul.userDetailsList > li.emptyLI {
	height: 90px;
	border: 0px;
}
.userProfile ul.userDetailsList > li .viewAccount {
	width: auto;
	float: left;
	clear: both;
	padding: 5px 0px 5px 40px;
	margin: 0px;
	color: #0D3968;
	background: url("../images/icons/usersA.svg") no-repeat left center;
	background-size: 24px 24px;
	font-family: 'open_sanssemibold';
}
.userProfile .themeChangeDL {
	width: 100%;
	float: left;
	clear: both;
	padding: 15px 0px;
	margin: 0px;
	color: #0D3968;  
}
.userProfile .btn-group-toggle .btn-secondary.focus,
.userProfile .btn-group-toggle .btn-secondary:focus {
	box-shadow: inherit;
}
.userProfile .btn-group-toggle .btn-secondary {
	width: 48px;
	height: 24px;
	border: 0px;
	background: url("../images/icons/switchDON.png") no-repeat center center;
	background-size: 48px 24px;
	background-color: transparent !important;
}
.userProfile .btn-group-toggle .btn-secondary.active {
	background: url("../images/icons/switchLON.png") no-repeat center center;
	background-size: 48px 24px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Login ****** *
** ****** ****** ****** ****** ****** */

.loginModalHeader {
	font-size: 22px;
	color: #0D3968;
	width: 100%;
	text-align: left;
	padding: 15px 30px;
	border: 0px;
}
.loginModalHeader button.close,
.loginModalHeader .firstLoginClose {
	width: 28px;
	height: 28px;
	background: url("../images/icons/cancelB.svg") no-repeat center center;
	background-size: 28px 28px;
	opacity: 1;
	margin: 0px;
}
.LoginBtnWrapper {
	width: 100%; 
	margin: 0px;
	float:left;
}
.loginForm.paddingLR {
	padding: 10px 15px 10px;
}
.loginForm {
	width: 100%;
	float: left;
	margin: 0px 0px;
	padding: 10px 0px 0px;
	background: #ffffff;
}
.loginForm > h3 {
	font-size: 22px;
	color: #0D3968;
	width: 100%;
	margin: 0px 0px 0px;
	padding: 5px 15px 0px;
}
.loginForm .has-error .checkbox,
.loginForm .has-error .checkbox-inline,
.loginForm .has-error .control-label,
.loginForm .has-error .help-block,
.loginForm .has-error .radio,
.loginForm .has-error .radio-inline,
.loginForm .has-error.checkbox label,
.loginForm .has-error.checkbox-inline label,
.loginForm .has-error.radio label,
.loginForm .has-error.radio-inline label {
	color: #ffffff;
}
.loginForm > ul {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 15px 0px;
}
.loginForm > ul > li {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 13px;
	margin: 0px;
}
.loginForm > ul > li:last-child {
	padding: 0px 0px 0px;
}
.loginForm > ul > li.marginBottom10px {
	margin-bottom: 10px;
}
.loginForm > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 30px 10px 0px;
	height: auto;
	background: #ffffff !important;
	color: #0D3968;
	font-size: 20px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
}
.loginForm > ul > li > p {
	color: #0D3968;
}
.loginForm > ul > li .customCheckbox {
	color: #0D3968;
	margin-top: 0px;
}
.loginForm > ul > li .customCheckbox label {
	font-size: 16px;
}
.loginForm > ul > li .form-control:focus {
	outline: none;
}
.loginForm > ul > li .btn.input-group-addon.reveal {
	border: 0px;
	width: 26px;
	height: 26px;
	padding: 0px;
	background: transparent;
}
.loginForm > ul > li .input-group {
	width: 100%;
	position: relative;
}
.loginForm > ul > li .input-group .glyphicon-eye-open,
.loginForm > ul > li .input-group .glyphicon-eye-close {
	width: 24px;
	height: 24px;
	display: block;
}
.loginForm > ul > li .input-group .glyphicon-eye-open {
	background: url("../images/icons/viewD.svg") no-repeat center center;
	background-size: 24px auto;
}
.loginForm > ul > li .input-group .glyphicon-eye-close {
	background: url("../images/icons/viewB.svg") no-repeat center center;
	background-size: 24px auto;
}
.loginForm > ul > li .input-group .input-group-btn {
	width: 26px;
	height: 26px;
	position: absolute;
	top: 5px;
	right: 0px;
	z-index: 3;
}
.loginForm > ul > li .btn.loginBtn {
	background: transparent;
	box-shadow: none;
	border: 0px;
}
.loginForm > ul > li.footerdDiv {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 0px;
	margin: 0px;
	text-align: center;
}
.loginForm > ul > li.footerdDiv > a {
	color: #ffffff;
}
.loginForm > ul > li.footerdDiv > a:hover {
	color: #0AAEFF;
}
.loginForm > ul > li.footerdDiv > a.forgotpassword {
	float: left;
	font-size: 12px;
	padding: 5px 0px;
}
.loginForm > ul > li.footerdDiv > a.register {
	float: right;
	font-size: 16px;
}
.loginForm > ul > li .forgotpassword {
	float: right;
	color: #0D3968;
	padding-top: 3px;
}
.loginButton {
	width: 100%;
	display: table;
}
.loginButton > ul {
	width: 100%;
	display: table-row;
}
.loginButton > ul > li {
	display: table-cell;
	padding: 5px 15px 5px;
	font-size: 16px;
	vertical-align: bottom;
	color: #9eafc2;
}
.loginButton > ul > li .loginIWrapper {
	width: 400px;
	margin: 0px auto;
}
.loginButton > ul > li a {
	color: #9eafc2;
	font-size: 14px;
}
.loginButton > ul > li .forChinese {
	float:right;
}
.loginButton > ul > li:nth-child(1) {
	width: 25%
}
.loginButton > ul > li:nth-child(2) {
	width: 50%
}
.loginButton > ul > li:nth-child(3) {
	width: 25%
}
.loginButton > ul > li .useExistingAccount {
	float: left;
}
.loginButton > ul > li .languageSel {
	background: url("../images/icons/languageA.svg") no-repeat center center;
	background-size: 24px auto;
	width: 24px;
	height: 24px;
	float: right;
	display: none;
}
.loginButton > ul > li .singINBtn {
	width: 100%;
	border: 0px;
	border-radius: 0px;
	padding: 17px 10px;
	background: #195d99;
	font-size: 20px;
}
.loginButton > ul > li .singINBtn.btn[disabled] {
	color: #b0c1d1;
	background: #e8eff5;
}
.firstLoginBtn .singINBtn {
	width: 100%;
	border: 0px;
	border-radius: 0px;
	padding: 12px 10px;
	background: #195d99;
	font-size: 20px;
}
.firstLoginBtn .singINBtn.btn[disabled] {
	color: #b0c1d1;
	background: #e8eff5;
	opacity: 1;
}
.modal.SignOutModal{
	z-index: 9999;
}
.SignOutModal .singOutConfirmation {
	width: 100%;
	padding: 0px 15px 10px;
	font-size: 20px;
	color: #0D3968;
}
.SignOutModal .signOutNoBtn,
.SignOutModal .signOutYesBtn {
	width: 50%;
	float: left;
	border: 0px;
	border-radius: 0px;
	padding: 17px 0px;
	font-size: 20px;
	margin: 0px;
}
.SignOutModal .signOutNoBtn {
	background: #c6d7e6;
	color: #0d3968;
}
.SignOutModal .signOutYesBtn {
	background: #195d99;
}
.SignInSuccessModal .singINConfirmation {
	width: 100%;
	padding: 0px 20px 10px 20px;
	font-size: 22px;
	color: #0D3968;
	font-family: 'open_sanssemibold';
}
.SignInSuccessModal .signINconfirmationOKBtn {
	width: 100%;
	border: 0px;
	border-radius: 0px;
	padding: 17px 0px;
	font-size: 20px;
	background: #00d27a;
	margin:0px;
}
.existingLoginForm {
	width: 100%;
	float: left;
	margin: 0px 0px;
	padding: 10px 0px 0px;
	background: #ffffff;
}
.existingLoginForm > ul {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 0px;
}
.existingLoginForm > ul > li {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 30px 13px;
	margin: 0px;
	color: #0D3968;
}
.existingLoginForm > ul > li > p {
	color: #0D3968;
}
.existingLoginForm > ul > li.marginBottom10px {
	margin-bottom: 10px;
}
.existingLoginForm > ul > li .customCheckbox {
	float: left;
	margin: 0px;
}
.existingLoginForm > ul > li .customCheckbox label {
	font-size: 16px;
}
.existingLoginForm > ul > li .forgotpassword {
	float: right;
	color: #0D3968;
	padding-top: 3px;
	font-size: 16px;
}
.existingLoginForm > ul > li:last-child {
	padding: 0px 0px 0px;
}
.existingLoginForm > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px 30px 10px 0px;
	height: auto;
	background: #ffffff !important;
	color: #0D3968;
	font-size: 20px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
}
.existingLoginForm > ul > li .form-control:focus {
	outline: none;
}
.existingLoginForm > ul > li .btn.input-group-addon.reveal {
	border: 0px;
	width: 26px;
	height: 26px;
	padding: 0px;
	background: transparent;
}
.existingLoginForm > ul > li .form-control.countryCode {
	width: 20%;
	margin-right: 5%;
}
.existingLoginForm > ul > li .form-control.phoneNumber {
	width: 75%;
}
.existingLoginForm > ul > li select.form-control {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: url("../images/icons/markdownA.svg") no-repeat right center !important;
}
.existingLoginForm > ul > li select::-ms-expand {
	display: none;
}
.existingLoginForm > ul > li .input-group {
	width: 100%;
	position: relative;
}
.existingLoginForm > ul > li .input-group .glyphicon-eye-open,
.existingLoginForm > ul > li .input-group .glyphicon-eye-close {
	width: 24px;
	height: 24px;
	display: block;
}
.existingLoginForm > ul > li .input-group .glyphicon-eye-open {
	background: url("../images/icons/viewD.svg") no-repeat center center;
	background-size: 24px auto;
}
.existingLoginForm > ul > li .input-group .glyphicon-eye-close {
	background: url("../images/icons/viewB.svg") no-repeat center center;
	background-size: 24px auto;
}
.existingLoginForm > ul > li .input-group .input-group-btn {
	width: 26px;
	height: 26px;
	position: absolute;
	top: 10px;
	right: 0px;
	z-index: 3;
}
.existingLoginForm > ul > li .btn.loginBtn {
	background: transparent;
	box-shadow: none;
	border: 0px;
}
.existingLoginForm > ul > li .existingSingINBtn {
	width: 100%;
	border: 0px;
	border-radius: 0px;
	padding: 17px 10px;
	background: #195d99;
	font-size: 20px;
}
.existingLoginForm > ul > li .resendCodeBtn {
	border: 0px;
	border-radius: 0px;
	padding: 8px 15px;
	background: #c6d7e6;
	color: #104274;
	font-size: 16px;
}
.existingLoginForm > ul > li .cancelBtn,
.existingLoginForm > ul > li .submitBtn {
	width: 50%;
	float: left;
	border: 0px;
	border-radius: 0px;
	padding: 17px 0px;
	font-size: 20px;
	margin: 0px;
}
.existingLoginForm > ul > li .registerBtn {
	width: 100%;
	float: left;
	border: 0px;
	border-radius: 0px;
	padding: 17px 0px;
	font-size: 20px;
	margin: 0px;
	background: #195d99;
}
.existingLoginForm > ul > li .cancelBtn {
	background: #c6d7e6;
	color: #0d3968;
}
.existingLoginForm > ul > li .submitBtn {
	background: #195d99;
}
.existingLoginForm > ul > li .input-group .requiredField {
	width: 26px;
	height: 26px;
	text-align: center;
	position: absolute;
	top: 5px;
	right: 0px;
	z-index: 3;
	color: #b8c3cf;
	font-size: 28px;
}
/*** **************  ************** ****
		*** Loading Progress ***
**** **************  ************** ***/

.loadingProgress {
	position: fixed;
	display: none;
	padding-left: 16px;
	z-index: 999999;
	top: 0px;
	left: 0px;
	outline: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	background: url("../images/bgTransparent.png") repeat;
}
.loadingProgressIn {
	position: absolute;
	width: 70px;
	height: 70px;
	left: 50%;
	top: 50%;
	margin: -40px 0px 0px -40px;
	z-index: 9999999;
	background: #333F48 url("../images/loading.gif") no-repeat center center;
	background-size: 45px 45px;
	border-radius: 12px;
}
.loadingProgressInText { 
	width: 100%; 
	text-align:center;
	position: absolute;  
	bottom: 50%;
	padding:0px 10px;
	margin: 0px 0px -65px 0px;
	z-index: 9999999;
	color:#ffffff;
	font-size:14px;
} 
/*** **************  ************** ****
		*** Side Bar ***
**** **************  ************** ***/

#wrapper {
	padding-left: 66px;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
#sidebar-wrapper {
	z-index: 1000;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 66px;
	padding-bottom: 76px;
	height: 100%;
	z-index: 1;
	overflow-x: hidden;
	overflow-y: auto;
	background: #000000;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
#page-content-wrapper {
	width: 100%;
	padding: 64px 0px 0px;
} 
.sidebarNav {
	margin: 0;
	padding: 0;
	list-style: none;
}
.sidebarNav.bottomNav {
	bottom: 0px;
	left: 0px;
	width: 100%;
	position: absolute;
}
.sidebarNav > li {
	width: 64px;
	height: 66px;
	border-bottom: 2px solid #000000;
}
.sidebarNav > li a.active,
.sidebarNav > li a:hover {
	background-color: #195d99;
	text-indent: -9999px;
}
.sidebarNav > li.brandName .homeNav,
.sidebarNav > li .dashboardNav,
.sidebarNav > li .reportNav,
.sidebarNav > li .alarmsNav,
.sidebarNav > li .qualityNav,
.sidebarNav > li .serviceNav,
.sidebarNav > li .unitNav,
.sidebarNav > li .recipeNav,
.sidebarNav > li .menuNav,
.sidebarNav > li .ListviewA,
.sidebarNav > li .MapViewA {
	width: 64px;
	height: 64px;
	display: block;
	text-indent: -9999px;
}
.sidebarNav > li.brandName .homeNav {
	background: #0d3968 url("../images/icons/home.svg") no-repeat center center;
	background-size: 36px auto;
}
.sidebarNav > li .dashboardNav {
	background: url("../images/icons/dashboard.svg") no-repeat center center;
	background-size: 24px auto;
}
.sidebarNav > li .reportNav {
	background: url("../images/icons/report.svg") no-repeat center center;
	background-size: 24px auto;
}
.sidebarNav > li .alarmsNav {
	background: url("../images/icons/home.svg") no-repeat center center;
	background-size: 24px auto;
}
.sidebarNav > li .qualityNav {
	background: url("../images/icons/quality.svg") no-repeat center center;
	background-size: 24px auto;
}
.sidebarNav > li .serviceNav {
	background: url("../images/icons/service.svg") no-repeat center center;
	background-size: 24px auto;
}
.sidebarNav > li .unitNav {
	background: url("../images/icons/unitDetails.svg") no-repeat center center;
	background-size: 24px auto;
}
.sidebarNav > li .recipeNav {
	background: url("../images/icons/recipeDetails.svg") no-repeat center center;
	background-size: 24px auto;
}
.sidebarNav > li .menuNav {
	background: url("../images/icons/menu.svg") no-repeat center center;
	background-size: 24px 24px;
}
.sidebarNav > li .ListviewA {
	background: url("../images/icons/ListviewA.svg") no-repeat center center;
	background-size: 24px auto;
}
.sidebarNav > li .MapViewA {
	background: url("../images/icons/MapViewA.svg") no-repeat center center;
	background-size: 24px auto;
}
/*** **************  ************** ****
		*** Page Header ***
**** **************  ************** ***/

.pageHeader {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both;
}
.pageHeader > ul {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both;
}
.pageHeader > ul > li {
	padding: 0px 0px;
	float: left;
}
.pageHeader > ul > li > a {
	padding: 18px 15px 19px;
	color: #78797b;
	display: block;
	font-size: 18px;
	font-family: 'open_sanssemibold';
}
.pageHeader > ul > li > a.active,
.pageHeader > ul > li > a:hover {
	color: #ffffff;
}
.pageHeader > ul > li.filterDropdown {
	float: right;
	padding: 12px 0px 13px; 
}

.pageHeader > ul > li .dropdown {
	float: right;
}
.pageHeader > ul > li .dropdown > .dropdown-toggle {
	float: right;
	font-size: 14px;
	text-transform: none;
	width: auto;
	padding: 0px 5px;
	height: auto;
	background: transparent;
	color: #afb1b2;
	border: 0px;
	margin-right: 10px;
	min-width: 65px;	
	max-width: 120px;
	text-overflow: ellipsis;
    overflow: hidden;
}
.pageHeader > ul > li .dropdown button:focus,
.pageHeader > ul > li .dropdown button:active,
.pageHeader > ul > li .dropdown button:hover,
.pageHeader > ul > li .dropdown .btn-secondary:not(:disabled):not(.disabled):active:focus,
.pageHeader > ul > li .dropdown .show>.btn-secondary.dropdown-toggle:focus {
	box-shadow: none;
	border: 0px;
	box-shadow: none;
}
.pageHeader > ul > li .dropdown > .dropdown-menu {
	width: 100%;
	padding: 0px 0px;
	background: #f2f2f2;
	border: 0px;
	border-radius: 0px;
	text-transform: none;
	font-size: 14px;
	z-index: 9999;
}
.pageHeader > ul > li .dropdown > .dropdown-menu .dropdown-item {
	padding: 8px 15px;
	color: #6c86a1;
	text-align: right;
}
.pageHeader > ul > li .dropdown > .dropdown-menu .dropdown-item:hover,
.pageHeader > ul > li .dropdown > .dropdown-menu .dropdown-item:focus,
.pageHeader > ul > li .dropdown > .dropdown-menu .dropdown-item:active,
.pageHeader > ul > li .dropdown > .dropdown-menu .dropdown-item.active {
	color: #18426f !important;
	background: transparent;
}
.pageHeader > ul > li .dropdown .dropdown-divider {
	border-top: 1px solid #68839f;
	margin: 1px 0;
}
.pageHeader > ul > li .dropdown .widthDateIcon {
	width: 100%;
	float: left;
	clear: both;
}
.pageHeader > ul > li .dropdown .widthDateIcon > img {
	float: left;
}
.pageHeader > ul > li .dropdown .widthDateIcon > span {
	float: right;
}
/*** **************  ************** ****
		*** Page Tab Header ***
**** **************  ************** ***/

.pageTabHeader {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both;
	background: #1F2124;
	border-bottom: 2px solid #000;
}
.pageTabHeader.minHeight01 {
	min-height: 52px;
}
.pageTabHeader.marginTop2px {
	border-top: 2px solid #000;
}
.pageTabHeader > ul {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both;
}
.pageTabHeader > ul > li {
	padding: 8px 10px;
	float: left;
}
.pageTabHeader > ul > li.filterDropdown {
	float: right;
	padding: 15px 0px 15px;
	min-height: 50px;
}
.pageTabHeader > ul > li.filterDropdown .showHideDate{
	margin-top:10px;
}
.pageTabHeader > ul > li .dropdown {
	float: right;
}
.pageTabHeader > ul > li .dropdown > .dropdown-toggle {
	float: right;
	font-size: 12px;
	text-transform: none;
	width: auto;
	padding: 0px 5px;
	height: auto;
	background: transparent;
	color: #ffffff;
	border: 0px;
	margin-right: 10px;
}
.pageTabHeader > ul > li .dropdown button:focus,
.pageTabHeader > ul > li .dropdown button:active,
.pageTabHeader > ul > li .dropdown button:hover,
.pageTabHeader > ul > li .dropdown .btn-secondary:not(:disabled):not(.disabled):active:focus,
.pageTabHeader > ul > li .dropdown .show>.btn-secondary.dropdown-toggle:focus {
	box-shadow: none;
	border: 0px;
	box-shadow: none;
}
.pageTabHeader > ul > li .dropdown > .dropdown-menu {
	width: 100%;
	padding: 0px 0px;
	background: #f2f2f2;
	border: 0px;
	border-radius: 0px;
	text-transform: none;
	font-size: 14px;
	z-index: 9999;
}
.pageTabHeader > ul > li .dropdown > .dropdown-menu .dropdown-item {
	padding: 8px 15px;
	color: #6c86a1;
	text-align: right;
}
.pageTabHeader > ul > li .dropdown > .dropdown-menu .dropdown-item:hover,
.pageTabHeader > ul > li .dropdown > .dropdown-menu .dropdown-item:focus,
.pageTabHeader > ul > li .dropdown > .dropdown-menu .dropdown-item:active,
.pageTabHeader > ul > li .dropdown > .dropdown-menu .dropdown-item.active {
	color: #18426f !important;
	background: transparent;
}
.pageTabHeader > ul > li .dropdown .dropdown-divider {
	border-top: 1px solid #68839f;
	margin: 1px 0;
}
.pageTabHeader > ul > li .dropdown .widthDateIcon {
	width: 100%;
	float: left;
	clear: both;
}
.pageTabHeader > ul > li .dropdown .widthDateIcon > img {
	float: left;
}
.pageTabHeader > ul > li .dropdown .widthDateIcon > span {
	float: right;
}
.pageTabHeader > ul > li.selectedBox {
	padding: 20px 0px;
	float: right;
}
.pageTabHeader > ul > li.selectedBox .selectedDuration {
	float: right;
	font-size: 12px;
	text-transform: none;
	width: auto;
	padding: 0px 5px;
	height: auto;
	background: transparent;
	color: #ffffff;
	border: 0px;
	margin-right: 10px;
}
.pageTabHeader > ul > li.selectedBox .selectedDuration > option {
	background: #141414;
	color: #ffffff;
	border: 0px;
	border-bottom: 1px solid #7e8693;
}
.pageTabHeader > ul > li .selectedValBox {
	float: left;
	margin-right: 10px;
}
.pageTabHeader > ul > li .selectedValBox > .warningBox,
.pageTabHeader > ul > li .selectedValBox > .errorBox {
	width: 32px;
	height: 12px;
	display: inline-block;
}
.pageTabHeader > ul > li .selectedValBox > .warningBox {
	background: #ffbe00;
}
.pageTabHeader > ul > li .selectedValBox > .errorBox {
	background: #F93251;
}
.pageTabHeader > ul > li > a {
	padding: 10px 15px 11px 30px;
	color: #78797b;
	display: block;
	font-size: 18px;
	font-family: 'open_sanssemibold';
}
.pageTabHeader > ul > li > a.active,
.pageTabHeader > ul > li > a:hover {
	color: #ffffff;
}
.pageTabHeader > ul > li a.listView {
	background: url("../images/icons/list.svg") no-repeat left center;
	background-size: 24px 24px;
}
.pageTabHeader > ul > li a.timelineView {
	background: url("../images/icons/timeline.svg") no-repeat left center;
	background-size: 24px 24px;
}
.pageTabHeader > ul > li a.listView.active,
.pageTabHeader > ul > li a.listView:hover {
	background: url("../images/icons/listActive.svg") no-repeat left center;
	background-size: 24px 24px;
}
.pageTabHeader > ul > li a.timelineView.active,
.pageTabHeader > ul > li a.timelineView:hover {
	background: url("../images/icons/timelineActive.svg") no-repeat left center;
	background-size: 24px 24px;
}
.pageTabHeader > ul > li a.locationsView {
	background: url("../images/icons/HotelD.svg") no-repeat left center;
	background-size: 24px 24px;
}
.pageTabHeader > ul > li a.unitsView {
	background: url("../images/icons/AssetD.svg") no-repeat left center;
	background-size: 24px 24px;
}
.pageTabHeader > ul > li a.locationsView.active,
.pageTabHeader > ul > li a.locationsView:hover {
	background: url("../images/icons/HotelA.svg") no-repeat left center;
	background-size: 24px 24px;
}
.pageTabHeader > ul > li a.sitetreeInfoShow {
	background: url("../images/icons/SitetreeInfoShowS.svg") no-repeat left center;
	background-size: 24px 24px;
}
.pageTabHeader > ul > li a.sitetreeInfoShow:hover {
	background: url("../images/icons/SitetreeInfoShowT.svg") no-repeat left center;
	background-size: 24px 24px;
}
.mapSideBartoggled .pageTabHeader > ul > li a.sitetreeInfoShow {
	background: url("../images/icons/SitetreeInfoHideS.svg") no-repeat left center;
	background-size: 24px 24px;
}
.mapSideBartoggled .pageTabHeader > ul > li a.sitetreeInfoShow:hover {
	background: url("../images/icons/SitetreeInfoHideT.svg") no-repeat left center;
	background-size: 24px 24px;
}
.pageTabHeader > ul > li a.unitsView.active,
.pageTabHeader > ul > li a.unitsView:hover {
	background: url("../images/icons/AssetA.svg") no-repeat left center;
	background-size: 24px 24px;
}
/* ****** ****** ****** ****** ****** **
 * ****** GridView ****** *
** ****** ****** ****** ****** ****** */

.gridViewTable.marginTop2px {
	margin-top: 2px;
}
.gridViewTable {
	width: 100%;
	display: table;
	padding: 0px;
}
.gridView {
	width: 100%;
	display: table-row;
	padding: 0px;
}
.gridView.selectedUnitBg .colm2D,
.gridView.selectedUnitBg .colm8D,
.gridView.selectedUnitBg .colm10D {
	background: #1f2124;
}
.gridView .colm2D,
.gridView .colm3D,
.gridView .colm4D,
.gridView .colm6D,
.gridView .colm7D,
.gridView .colm8D,
.gridView .colm9D,
.gridView .colm10D,
.gridView .colm12D {
	display: table-cell;
	background: #2d3034;
	border-left: 1px solid #000;
	border-right: 1px solid #000;
	border-bottom: 2px solid #000;
	vertical-align: top;
}
.gridView .colm12D.borderNone {
	border-bottom: 0px;
}
.gridView > div:first-child {
	border-left: 0px;
}
.gridView > div:last-child {
	border-right: 0px;
}
.gridView .colm2D {
	width: 16.666%;
}
.gridView .colm3D {
	width: 25%;
}
.gridView .colm4D {
	width: 33.333%;
}
.gridView .colm6D {
	width: 50%;
}
.gridView .colm7D {
	width: 58.333%;
}
.gridView .colm8D {
	width: 66.666%;
}
.gridView .colm9D {
	width: 75%;
}
.gridView .colm10D {
	width: 83.333%;
}
.gridView .colm12D {
	width: 100%;
}
.gridView .colmDi {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px;
	position: relative;
}
.gridView .colmDi.borderTop2px {
	border-top:2px solid #000;
}
.gridView .colmDi.padding0px {
	padding: 0px;
}
.gridView .colmDi > h4 {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 12px;
	font-size: 14px;
	font-weight: normal;
	color: #afb1b2;
	line-height: 18px;
	text-transform: uppercase;
	font-family: 'open_sanslight';
}
.gridView .colmDi > h4.paddingBottom {
	padding: 5px 0px 15px;
}
.gridView .colmDi > h4 .blueDot {
	width: 8px;
	height: 8px;
	background: #0aaeff;
	border-radius: 4px;
	/* cursor: pointer; */
	display: inline-block;
}
.gridView .colmDi > h4 .countWithStatus {
	float: right;
	color: #ffffff;
}
.gridView .colmDi > h4 .countWithStatus > img {
	width: 10px;
	margin-left: 3px;
}
.gridView .colmDi > h4 .dropdownFilterSM {
	float: right;
}
.gridView .colmDi > h4 .dropdown {
	float: right;
}
.gridView .colmDi > h4 .dropdown > .dropdown-toggle {
	float: right;
	font-size: 14px;
	text-transform: none;
	width: auto;
	padding: 0px 5px;
	height: auto;
	background: transparent;
	color: #afb1b2;
	border: 0px;
	margin-right: 10px;
	min-width: 65px;	
	max-width: 98px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.gridView .colmDi > h4 .dropdown.datepickerBtnGroup > .dropdown-toggle {
	margin-right: 0px;
}
.gridView .colmDi > h4 .dropdown button:focus,
.gridView .colmDi > h4 .dropdown button:active,
.gridView .colmDi > h4 .dropdown button:hover,
.gridView .colmDi > h4 .dropdown .btn-secondary:not(:disabled):not(.disabled):active:focus,
.gridView .colmDi > h4 .dropdown .show>.btn-secondary.dropdown-toggle:focus {
	box-shadow: none;
	border: 0px;
	box-shadow: none;
}
.gridView .colmDi > h4 .dropdown > .dropdown-menu {
	width: 100%;
	padding: 0px 0px;
	background: #f2f2f2;
	border: 0px;
	border-radius: 0px;
	text-transform: none;
	font-size: 14px;
	z-index: 9999;
}
.gridView .colmDi > h4 .dropdown > .dropdown-menu .dropdown-item {
	padding: 8px 15px;
	color: #6c86a1;
	text-align: right;
}
.gridView .colmDi > h4 .dropdown > .dropdown-menu .dropdown-item:hover,
.gridView .colmDi > h4 .dropdown > .dropdown-menu .dropdown-item:focus,
.gridView .colmDi > h4 .dropdown > .dropdown-menu .dropdown-item:active,
.gridView .colmDi > h4 .dropdown > .dropdown-menu .dropdown-item.active {
	color: #18426f !important;
	background: transparent;
}
.gridView .colmDi > h4 .dropdown .dropdown-divider {
	border-top: 1px solid #68839f;
	margin: 1px 0;
}
.gridView .colmDi > h4 .dropdown .widthDateIcon {
	width: 100%;
	float: left;
	clear: both;
}
.gridView .colmDi > h4 .dropdown .widthDateIcon > img {
	float: left;
}
.gridView .colmDi > h4 .dropdown .widthDateIcon > span {
	float: right;
}
.gridView .colmDi > h4 .saveBtn,
.gridView .colmDi > h4 .sendEmaiReport {
	float: right;
	font-family: 'open_sansregular';
}
.gridView .colmDi > h4 .sendEmaiReport > a {
	display: inline-block;
	width: 60px;
	padding: 7px 5px;
	text-align: center;
	border-radius: 20px;
	background: #424548 url("../images/icons/email.png") no-repeat center center;
	background-size: 23px auto;
}
.gridView .colmDi > h4 .sendEmaiReport > .clearErrorBtn {
	border-radius: 20px;
	background: #424548;
	font-size: 14px;
	border: 0px;
	margin-left: 10px;
}
.gridView .colmDiVal.minheight150 {
	min-height: 120px;
}
.gridView .colmDiVal.minheight155 {
	min-height: 155px;
}
.gridView .colm8D.BgBorder0,
.gridView .colm9D.BgBorder0 {
	border: 0;
	background: none;
}
div.gridView .colmDiVal {
	padding: 0px 0px 0px;
}
div.gridView .colmDi>h4 .dropdown>.dropdown-menu {
	width: auto;
	left: initial !important;
}
.gridView .colmDi > .titleH2Div > sup {
    color: #78797b;
}
.gridView .colmDi > h4 .oilDisposeFilter .dropdown > .dropdown-toggle {
	float: right;
    font-size: 12px;
    text-transform: none;
    width: auto;
    padding: 0px 0px 0px 0px;
    height: auto;
    background: transparent;
    color: #ffffff;
    border: 0px;
	margin-right: 5px;
	font-family: 'open_sansregular';
}
.piechartvalue {   
	width: 100%;
	float: left;
	text-align: center;
	color: #fff;
	margin-top: -30px;
}

.piechartvalueText {
	font-size: 48px;
}

.piechartvalueText .drinksText {
	font-size: 12px;
	color: #818285;
} 
.trendimg {
	background: url("../images/icons/arrow3DownT.svg") no-repeat top left;
	background-size: 20px auto;
	padding-top: 18px;
}
.sendEmaiReport .checkBoxListGrp { 
	margin: 0px 15px 0px 0px;
	float: left;
} 
.sendEmaiReport .checkBoxListGrp .customCheckbox {
    position: relative;
    display: inline-block;
    margin: 0px 10px 0px 0px;
}
.sendEmaiReport .checkBoxListGrp .customCheckbox label { 
    line-height:16px;
	color: #96a8bb;
	font-family: 'open_sansregular';
	height:32px;
	padding: 0px 0px 0px 30px;
	float:inherit;
}
.sendEmaiReport .checkBoxListGrp .customCheckbox label > span { 
    display: table;
	color: #ffffff;
	clear:both;
    font-family: 'open_sanssemibold';
}
.sendEmaiReport .crticalCBox.customCheckbox input[type=checkbox] + label {
	background: url("../images/icons/checkCOff.png") no-repeat left center;
	background-size: 24px 24px;
}
.sendEmaiReport .crticalCBox.customCheckbox input[type=checkbox] + label,
.sendEmaiReport .crticalCBox.customCheckbox input[type=checkbox] + i + label,
.sendEmaiReport .crticalCBox.customCheckbox input[type=checkbox] + input + label {
	background: url("../images/icons/checkCOff.png") no-repeat left center;
	background-size: 24px 24px;
}
.sendEmaiReport .crticalCBox.customCheckbox input[type=checkbox]:checked + label,
.sendEmaiReport .crticalCBox.customCheckbox input[type=checkbox]:checked + i + label,
.sendEmaiReport .crticalCBox.customCheckbox input[type=checkbox]:checked + input + label {
	background: url("../images/icons/checkCOn.png") no-repeat left center;
	background-size: 24px 24px;
}
.sendEmaiReport .nonCrticalCBox.customCheckbox input[type=checkbox] + label {
	background: url("../images/icons/checkNOff.png") no-repeat left center;
	background-size: 24px 24px;
}
.sendEmaiReport .nonCrticalCBox.customCheckbox input[type=checkbox] + label,
.sendEmaiReport .nonCrticalCBox.customCheckbox input[type=checkbox] + i + label,
.sendEmaiReport .nonCrticalCBox.customCheckbox input[type=checkbox] + input + label {
	background: url("../images/icons/checkNOff.png") no-repeat left center;
	background-size: 24px 24px;
}
.sendEmaiReport .nonCrticalCBox.customCheckbox input[type=checkbox]:checked + label,
.sendEmaiReport .nonCrticalCBox.customCheckbox input[type=checkbox]:checked + i + label,
.sendEmaiReport .nonCrticalCBox.customCheckbox input[type=checkbox]:checked + input + label {
	background: url("../images/icons/checkNOn.png") no-repeat left center;
	background-size: 24px 24px;
}
.sendEmaiReport .eventsCBox.customCheckbox input[type=checkbox] + label {
	background: url("../images/icons/checkAllOffA.svg") no-repeat left center;
	background-size: 24px 24px;
}
.sendEmaiReport .eventsCBox.customCheckbox input[type=checkbox] + label,
.sendEmaiReport .eventsCBox.customCheckbox input[type=checkbox] + i + label,
.sendEmaiReport .eventsCBox.customCheckbox input[type=checkbox] + input + label {
	background: url("../images/icons/checkAllOffA.svg") no-repeat left center;
	background-size: 24px 24px;
}
.sendEmaiReport .eventsCBox.customCheckbox input[type=checkbox]:checked + label,
.sendEmaiReport .eventsCBox.customCheckbox input[type=checkbox]:checked + i + label,
.sendEmaiReport .eventsCBox.customCheckbox input[type=checkbox]:checked + input + label {
	background: url("../images/icons/checkAllOnA.svg") no-repeat left center;
	background-size: 24px 24px;
}
.gridView .colmDi > h4 .selectedDuration {
	float: right;
	font-size: 12px;
	text-transform: none;
	width: auto;
	padding: 0px 5px;
	height: auto;
	background: transparent;
	color: #ffffff;
	border: 0px;
	margin-right: 10px;
}
.gridView .colmDi > h4 .selectedDuration > option {
	background: #141414;
	color: #ffffff;
	border: 0px;
	border-bottom: 1px solid #7e8693;
}
.gridView .colmDi > h4 .selectedDateList,
.gridView .colmDi > h4 .selectedMonthList,
.gridView .colmDi > h4 .selectedYearList {
	float: right;
	margin-right: 10px;
	font-size: 12px;
}
.gridView .colmDi > h4 .selectedDateList > span,
.gridView .colmDi > h4 .selectedMonthList > span,
.gridView .colmDi > h4 .selectedYearList > span {
	background: url("../images/icons/markoffA.svg") no-repeat right center;
	background-size: 12px 12px;
	padding-right: 15px;
	cursor: pointer;
	text-transform: initial;
}
.gridView .colmDi > h4 .selectedDateRange {
	float: right;
	margin-right: 10px;
	font-size: 12px;
}
.gridView .colmDi > h4 .selectedDateRange > span.fromDate,
.gridView .colmDi > h4 .selectedDateRange > span.toDate {
	background: url("../images/icons/markoffA.svg") no-repeat right center;
	background-size: 12px 12px;
	padding-right: 15px;
	cursor: pointer;
	text-transform: initial;
}
.gridView .colmDi > h4 .selectedDateRange .toText {
	margin-right: 10px;
	margin-left: 10px;
}
.gridView .colmDi > .titleH2Div {
	width: 100%;
	float: left;
	clear: both;
	padding: 8px 0px 0px;
	font-size: 30px;
	font-weight: normal;
	color: #ffffff;
	font-family: 'open_sanssemibold';
}
.gridView .colmDi > .titleH2Div > span {
	color: #78797b;
	/* text-transform: capitalize; */
}
.gridView .colmDi > .titleH4Div {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 20px;
	font-size: 14px;
	font-weight: normal;
	color: #78797b;
	text-transform: capitalize;
}
.gridView .colmDi > .titleH4DivCamelLeter {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 15px;
	font-size: 14px;
	font-weight: normal;
	color: #78797b;
}
.gridView .colmDi .statusH4 {
	width: 100%;
	float: left;
	clear: both;
	font-size: 12px;
	text-align: left;
}
.gridView .colmDi .statusH4.goodIcon {
	color: #FFBE00;
}
.gridView .colmDi .statusH4.poorIcon,
.gridView .colmDi .statusH5.poorIcon {
	color: #F93251;
}
.gridView .colmDi .statusH4.greatIcon {
	color: #3CCE82;
}
.gridView .colmDi .statusH4 > span {
	width: 60px;
	text-align: right;
	display: inline-block;
	padding: 0px 5px 0px 0px;
	font-size: 30px;
	font-family: 'open_sanssemibold';
}
.gridView .colmDi .statusH4.leftAlign > span {
	width: 42px;
	text-align: left; 
}
.gridView .colmDi .statusH5 {
	width: 100%;
	float: left;
	clear: both;
	font-size: 12px;
	color: #78797b;
	text-align: left;
	overflow: hidden;
    text-overflow: ellipsis;
    max-width: 280px;
    white-space: nowrap;
}
.gridView .colmDi .statusH5 > span {
	width: 60px;
	text-align: right;
	display: inline-block;
	padding: 0px 5px 0px 0px;
	font-size: 14px;
	font-weight: normal;
}
.gridView .colmDi .statusH6 {
	width: 100%;
	float: left;
	clear: both;
	text-align: left;
	overflow: hidden;
    text-overflow: ellipsis;
    max-width: 280px;
    white-space: nowrap;
}
.gridView .colmDi .statusH6.goodIcon {
	color: #FFBE00;
}
.gridView .colmDi .statusH6.poorIcon {
	color: #F93251;
}
.gridView .colmDi .statusH6.greatIcon {
	color: #3CCE82;
}
.gridView .colmDi .statusH6 > span {
	display: inline-block;
	padding: 0px 5px 0px 0px;
	font-family: 'open_sanssemibold';
}
.gridView .colmDi .statusH6 > p { 
	margin: 5px 0px 0px;
	font-size: 12px;
	--lh: 1.4rem;
	line-height: var(--lh);
	--max-lines: 1;
	max-height: calc(var(--lh) * var(--max-lines));
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 280px;
	white-space: nowrap;
}
.gridView .colmDi .statusH6 > p > span {
	display: inline-block;
	padding: 0px 5px 0px 0px;
	font-family: 'open_sanssemibold';
}
/* .gridView .colmDi .statusH6 > p::before {
	content: "...";
	position: absolute;
	bottom: 10px;
	right: 0;
} */
.gridView .colmDi > .divStatusWithIcon {
	width: 100%;
	float: left;
	clear: both;
	margin: 20px 0px 10px;
	padding: 33px 0px 10px;
	font-size: 14px;
	text-align: center;
	font-weight: normal;
	color: #afb0b1;
	font-family: 'open_sanssemibold';
}
.gridView .colmDi > .divStatusWithIcon.divStatusWithIconSM {
	margin: 0px auto;
	text-align: left;
	display: none;
	padding: 5px 0px 5px 25px;
}
.gridView .colmDi > .divStatusWithIcon.divStatusWithIconSM.online {
	background: url("../images/icons/smileyGood.svg") no-repeat left center;
	background-size: 22px 22px;
}
.gridView .colmDi > .divStatusWithIcon.divStatusWithIconSM.offline {
	background: url("../images/icons/smileyOffline.svg") no-repeat left center;
	background-size: 22px 22px;
}
.gridView .colmDi > .divStatusWithIcon.divStatusWithIconSM.warning {
	background: url("../images/icons/smileyBad.svg") no-repeat left center;
	background-size: 22px 22px;
}
.gridView .colmDi > .divStatusWithIcon.divStatusWithIconSM.neutral {
	background: url("../images/icons/smileyNeutral.svg") no-repeat left center;
	background-size: 22px 22px;
}
.gridView .colmDi > .divStatusWithIcon.online {
	background: url("../images/icons/smileyGood.svg") no-repeat top center;
	background-size: 32px 32px;
}
.gridView .colmDi > .divStatusWithIcon.offline {
	background: url("../images/icons/smileyOffline.svg") no-repeat top center;
	background-size: 32px 32px;
}
.gridView .colmDi > .divStatusWithIcon.warning {
	background: url("../images/icons/smileyBad.svg") no-repeat top center;
	background-size: 32px 32px;
}
.gridView .colmDi > .divStatusWithIcon.neutral {
	background: url("../images/icons/smileyNeutral.svg") no-repeat top center;
	background-size: 32px 32px;
}
.gridView .colmDi > .selectedDiviceImg {
	width: 100%;
	float: left;
	clear: both;
	margin: 0px;
	padding: 0px;
	text-align: center;
}
.gridView .colmDi > .selectedDiviceImg > img {
	max-width: 94px;
	max-height:94px;
}
.gridView .colmDi > .deviceSNUTCSM {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 0px;
	color: #ffffff;
	font-size: 12px;
	display: none;
}
.gridView .colmDi > .deviceSNUTCSM > .UTCText {
	padding: 5px 0px 0px;
	color: #78797b;
}
.gridView .colmDiVal {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px 0px 0px;
	text-align: center;
	color: #ffffff;
}
.gridView .colmDiVal.displaynone {
	display: none;
}
.gridView .colmDiVal.padding10 {
	width: 100%;
	padding: 0px 15%;
}
.gridView .colmDiVal > .valH1Div {
	padding: 5px 0px 0px;
	font-size: 48px;
	color: #ffffff;
	font-family: 'open_sanssemibold';
}
.gridView .colmDiVal > .valH1Div.timeDiv {
	font-size: 42px;
}
.gridView .colmDiVal > .valH1Div .TimeRangeSpan {
	padding: 16px 5px 0px 0px;
	font-size: 18px;
	color: #ffffff;
}
.gridView .colmDiVal > .valH1Div .valH1Span {
	padding: 16px 5px 0px 0px;
	font-size: 14px;
	color: #b5b6b8;
	min-width: 16px;
	display: inline-block;
}
.gridView .colmDiVal > .valH1Div .valH1Span.valHigh {
	background: url("../images/icons/arrow3UpT.svg") no-repeat top left;
	background-size: 20px auto;
}
.gridView .colmDiVal > .valH1Div .valH1Span.valLow {
	background: url("../images/icons/arrow3DownD.svg") no-repeat top left;
	background-size: 20px auto;
}
.gridView .colmDiVal > .lastPerformedDiv {
	padding: 10px 0px 0px;
	font-size: 18px;
	color: #b4b5b7;
	text-align: center;
}
.gridView .colmDiVal > .dateTimeDiv {
	padding: 0px 0px 10px;
	font-size: 24px;
	color: #ffffff;
	text-align: center;
}
.gridView .colmDiVal > .valH5Div {
	padding: 0px 0px;
	font-size: 12px;
	color: #b5b6b8;
}
.gridView .colmDiVal > .valH5StatusDiv {
	padding: 5px 0px 0px;
	font-size: 18px;
}
.gridView .colmDiVal > .valH5StatusDiv.levelGreat {
	color: #3CCE82;
}
.gridView .colmDiVal > .valH5StatusDiv.levelGood {
	color: #ca9b0d;
}
.gridView .colmDiVal > .valH5StatusDiv.levelPoor {
	color: #F93251;
}
.gridView .colmDiVal > .valH1WStatusDiv {
	padding: 20px 0px 20px 90px;
	font-size: 30px;
	color: #747373;
	width: 90%;
	margin: 0px auto;
	display: block;
	font-family: 'open_sanssemibold';
}
.gridView .colmDiVal > .valH1WStatusDiv .valH1WStatusSpan {
	padding: 0px 0px 12px;
	font-size: 12px;
	color: #b5b6b8;
	width: 100%;
	clear: both;
	float: left;
}
.gridView .colmDiVal > .valH1WStatusDiv.noErrorStatus {
	background: url("../images/icons/smileyGood.svg") no-repeat left center;
	background-size: 80px auto;
}
.gridView .colmDiVal > .valH1WStatusDiv.noErrorStatus.unitDashboardC {
	margin-top: 20px;
}
.gridView .screensaverImgView,
.gridView .preparationImgView {
	width: 100%;
	clear: both;
	float: left;
	min-height: 380px;
	max-height: 390px;
	text-align: center;
	background: #1F2124;
	position:relative;
}
.gridView .screensaverImgView .previewIcon,
.gridView .preparationImgView .previewIcon {
	width:42px;
	height:42px;
	border-radius:50%;
	position:absolute;
	left:25px;
	bottom:35px;	 
	background: url("../images/bgTransparent.png") repeat;
	text-align:center;
	vertical-align:middle;
	padding: 0px;
    border: 0px;
}
.gridView .screensaverImgView .previewIcon img,
.gridView .preparationImgView .previewIcon img {
	width:24px;
	height:24px;
	display:inline-block;
	line-height:42px;
}
.gridView .screensaverImgView .ImgIconView,
.gridView .preparationImgView .ImgIconView {
	width: 100%;
	clear: both;
	float: left;
	min-height: 380px;
	padding: 30px 0px;
	background: #62555c;
}
.gridView .screensaverImgView > img,
.gridView .preparationImgView > img {
	max-width: 100%;
	max-height: 363px;
	clear: both;
}
.gridView .screensaverStatus,
.gridView .preparationStatus {
	width: 100%;
	clear: both;
	float: left;
	height: 16px;
}
.gridView .screensaverStatus {
	background: #695f50;
}
.gridView .preparationStatus {
	background: #978d7e;
}
.gridView .screensaverSize,
.gridView .preparationFileSize,
.gridView .spaceAvailable {
	width: 100%;
	clear: both;
	float: left;
	padding: 10px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.gridView .screensaverSize .mediaTextH2,
.gridView .preparationFileSize .mediaTextH2,
.gridView .spaceAvailable .mediaTextH2 {
	font-size: 16px;
	color: #ffffff;
}
.gridView .screensaverSize .mediaTextH3,
.gridView .preparationFileSize .mediaTextH3,
.gridView .spaceAvailable .mediaTextH3 {
	font-size: 14px;
	color: #ffffff;
	opacity: 0.7;
}
.gridView .customProgressBar {
	display: table-cell;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.gridView .screensaverSize {
	background: #695f50;
	text-align: left;
}
.gridView .preparationFileSize {
	background: #978d7e;
	text-align: center;
}
.gridView .spaceAvailable {
	background: #1f2124;
	text-align: right;
}
.qualityMgmtBox > .valH2Div {
	padding: 5px 0px 0px;
	font-size: 48px;
	color: #ffffff;
}
.qualityMgmtBox > .valH3Div {
	padding: 5px 0px 0px;
	font-size: 20px;
	color: #b5b6b8;
}
.qualityMgmtBox > .valH4Div {
	padding: 5px 0px 30px;
	font-size: 16px;
	color: #606366;
}
.gridTableView {
	width: 100%;
	display: table;
}
.gridTableView > ul {
	width: 100%;
	display: table-row;
}
.gridTableView > ul > li {
	display: table-cell;
	padding: 10px 10px 0px;
	vertical-align: top;
	color: #ffffff;
}
.gridTableView > ul > li:first-child {
	width: 120px;
	padding: 10px 0px;
}
.gridTableView.gridTableViewSW > ul > li:first-child {
	width: 100%;
}
.gridTableView > ul > li:first-child > img {
	max-width: 100%;
	max-height:120px;
}
.gridTableView > ul > li > ul {
	width: 100%;
	display: table;
}
.gridTableView > ul > li > ul > li {
	width: 100%;
	display: table-row;
}
.gridTableView > ul > li > ul > li > label,
.gridTableView > ul > li > ul > li > .labelVal {
	display: table-cell;
	padding: 5px 5px;
	vertical-align: middle;
	font-size: 14px;
	text-align: left;
}
.gridTableView > ul > li > ul > li > label {
	color: #969799;
	min-width: 115px;
}
.gridTableView > ul > li > ul > li > .labelVal {
	color: #ffffff;
}
.gridTableView > ul > li > ul > li > .labelVal > .btn {
	min-width: 80%;
}
.gridTableView > ul > li > ul > li .labelVal.wifiSignal > img {
	height: 10px;
	margin: 0px 0px 3px 5px;
}
.gridTableView > ul > li .mouseOverMNSpan {
	position: relative;
	cursor: pointer;
}
.gridTableView > ul > li .mouseOverMNSpan .mouseOverMNDiv {
	width: 250px;
	position: absolute;
	top: 28px;
	left: -70px;
	background: #f2f2f2;
	padding: 0px 5px;
	display: none;
}
.gridTableView > ul > li .mouseOverMNSpan .mouseOverMNDiv h5 {
	padding: 0px;
	color: #0d3968;
	font-size: 32px;
	padding: 5px 0px 5px;
	margin: 0px 0px 0px;
	text-align: center;
	border-bottom: 1px solid #68839f;
}
.mouseOverMNTable {
	width: 100%;
	display: table;
}
.mouseOverMNTable > ul {
	width: 100%;
	display: table-row;
}
.mouseOverMNTable > ul > li {
	display: table-cell;
	padding: 5px 5px 5px;
	vertical-align: middle;
	color: #000;
	font-size: 12px;
	border-bottom: 1px solid #dfdfdf;
}
.gridTableView > ul > li .mouseOverMNSpan .mouseOverMNDiv h5 .titlClr1,
.mouseOverMNTable > ul.tablRClr1 > li:nth-child(1),
.mouseOverMNTable > ul.tablRClr1 > li:nth-child(2) {
	color: #e71d35;
}
.gridTableView > ul > li .mouseOverMNSpan .mouseOverMNDiv h5 .titlClr2,
.mouseOverMNTable > ul.tablRClr2 > li:nth-child(1),
.mouseOverMNTable > ul.tablRClr2 > li:nth-child(2) {
	color: #1b998b;
}
.gridTableView > ul > li .mouseOverMNSpan .mouseOverMNDiv h5 .titlClr3,
.mouseOverMNTable > ul.tablRClr3 > li:nth-child(1),
.mouseOverMNTable > ul.tablRClr3 > li:nth-child(2) {
	color: #2f294f;
}
.gridTableView > ul > li .mouseOverMNSpan .mouseOverMNDiv h5 .titlClr4,
.mouseOverMNTable > ul.tablRClr4 > li:nth-child(1),
.mouseOverMNTable > ul.tablRClr4 > li:nth-child(2) {
	color: #ed4312;
}
.gridTableView > ul > li .mouseOverMNSpan .mouseOverMNDiv h5 .titlClr5,
.mouseOverMNTable > ul.tablRClr5 > li:nth-child(1),
.mouseOverMNTable > ul.tablRClr5 > li:nth-child(2) {
	color: #ca00cb;
}
.gridTableView > ul > li .mouseOverMNSpan .mouseOverMNDiv h5 .titlClr6,
.mouseOverMNTable > ul.tablRClr6 > li:nth-child(1),
.mouseOverMNTable > ul.tablRClr6 > li:nth-child(2) {
	color: #00bc04;
}
.mouseOverMNTable > ul > li:nth-child(1) {
	width: 100px;
}
.mouseOverMNTable > ul > li:nth-child(2) {
	width: 50px;
	font-size: 16px;
	padding: 0px;
	text-align: center;
}
.gridTableView > ul > li .mouseOverMNSpan:hover .mouseOverMNDiv {
	display: block;
}
.remainingFooter {
	width: 100%;
	float: left;
	clear: both;
	padding: 20px 0px 7px;
	font-size: 20px;
	font-weight: normal;
	color: #ffffff;
	text-align: left;
}
.remainingFooterL,
.remainingFooterR {
	width: 50%;
	float: left;
}
.remainingFooterL {
	text-align: left;
	font-size: 14px;
	color: #ffffff;
	padding: 5px 0px 0px;
}
.remainingFooterL > span {
	color: #969799;
}
.remainingFooterL > span.remainingDaysEnd {  
    color: #ffffff; 
}
.remainingFooterR {
	text-align: right;
}
.progressBarBText {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 5px;
	font-size: 20px;
	font-weight: normal;
	color: #ffffff;
	text-align: left;
}
.progressBarBText.alignRight {
	text-align: right;
}
.warantyTable {
	width: 100%;
	display: table;
}
.warantyTable > ul {
	width: 100%;
	display: table-row;
}
.warantyTable > ul > li {
	display: table-cell;
	padding: 10px 0px 0px;
	vertical-align: top;
	color: #ffffff;
}
.warantyTable > ul > li > ul {
	width: 100%;
	display: table;
}
.warantyTable > ul > li > ul > li {
	width: 100%;
	display: table-row;
}
.warantyTable > ul > li > ul > li > label,
.warantyTable > ul > li > ul > li > .labelVal {
	display: table-cell;
	padding: 5px 5px;
	vertical-align: middle;
	font-size: 14px;
	text-align: left;
}
.warantyTable > ul > li > ul > li > label .drpedCntnBtnGroup {
	display: inline-block;
	margin-left: 10px;
}
.warantyTable > ul > li > ul > li > label {
	color: #969799;
}
.warantyTable > ul > li > ul > li > .labelVal {
	color: #ffffff;
	min-width:45px;
}
.warantyTable > ul > li > ul > li .labelVal.wifiSignal > img {
	height: 10px;
	margin: 0px 0px 3px 5px;
}
.warantyTable > ul > li.extendWarrantyBtn {
	padding: 90px 0px 0px 0px;
	text-align: right;
}
/*** **************  ************** ****
		*** Installed Packages ***
**** **************  ************** ***/
.gridTableIPsView {
	width: 100%;
	display: table;
}
.gridTableIPsView > ul {
	width: 100%;
	display: table-row;
}
.gridTableIPsView > ul > li {
	display: table-cell;
	padding: 5px 10px 5px;
	vertical-align: top;
	color: #ffffff;
} 
.gridTableIPsView > ul > li:nth-child(1) {
	width:40%;
} 
.gridTableIPsView > ul > li:nth-child(2) {
	width:30%;
} 
.gridTableIPsView > ul > li:nth-child(3) {
	width:30%;
} 
.gridTableIPsView > ul.tHead > li { 
	padding: 5px 10px 5px; 
	/* border-top:1px solid #000; */
}  
.gridTableIPsView:first-child > ul.tHead > li {
	border-top:0px; 
}
.gridTableIPsView > ul > li > label,
.gridTableIPsView > ul > li > .labelVal {
	display: table-cell;
	padding: 5px 5px;
	vertical-align: middle;
	font-size: 14px;
	text-align: left;
}
.gridTableIPsView > ul.tBody > li,
.gridTableIPsView > ul.tBody > li { 
	padding:0px 10px 5px; 
}
.gridTableIPsView > ul.tBody > li > label,
.gridTableIPsView > ul.tBody > li > .labelVal { 
	padding:0px 5px 0px; 
}
.gridTableIPsView > ul.tHead > li > label,
.gridTableIPsView > ul.tHead > li > .labelVal { 
	padding:0px 5px 0px; 
}
.gridTableIPsView > ul > li > label {
	color: #969799;
	min-width: 115px;
}
.gridTableIPsView > ul.tBody > li > label,
.gridTableIPsView > ul > li > .labelVal {
	color: #ffffff;
}  
.gridTableIPsView > ul > li .lastUpdateRef {
	width:24px;
	height:24px;   
	display:inline-block;
	background: url("../images/icons/updateT.svg") no-repeat center center;
	background-size: 24px auto;
}
.gridTableSmartGrpV {
	width: 100%;
	display: table;
}
.gridTableSmartGrpV > ul {
	width: 100%;
	display: table-row;
}
.gridTableSmartGrpV > ul.tHead > li {
	color: #9fa8b5; 
	border-bottom: 1px solid #4a4a4a;
}  
.gridTableSmartGrpV > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #1f2124;
	color: #ffffff;
	font-size: 14px; 
	text-align: left;
}  
.gridTableSmartGrpV > ul:last-child > li { 
	border-bottom:0px; 
}  
.gridTableSmartGrpV > ul > li .badge { 
	padding:5px;
	font-weight:normal;
} 
.gridTableSmartGrpV > ul > li:nth-child(2) {
	width:160px; 
}
.gridTableSmartGrpV > ul > li:nth-child(3) {
	width:80px;
	padding-left:0px;
	padding-right:0px;	
	text-align: center;
}
.gridTableSmartGrpV > ul > li:nth-child(3) .btn{
	color: #0aaeff;
	background:none;
	padding: 0px;
}  
.gridTableSmartGrpV > ul > li .upArrow,
.gridTableSmartGrpV > ul > li .downArrow {
	width: 20px;
	height: 20px;
	display: inline-block;
}
.gridTableSmartGrpV > ul > li .upArrow {
	background: url("../images/icons/arrow3UpT.svg") no-repeat center center;
	background-size: 20px 20px;
} 
.btn.addSamrtGroupBtn{
	color: #0aaeff;
	background:none;
	padding: 0px;
	float:left;
	font-size:14px;
	margin:10px 10px 0px;
}  
/*** **************  ************** ****
		*** Location Table ***
**** **************  ************** ***/

.locationTableView {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px 0px 12px;
}
.locationTableView > ul {
	width: 100%;
	display: table;
}
.locationTableView > ul > li {
	display: table-row;
	padding: 10px 10px 0px;
	vertical-align: top;
	color: #ffffff;
}
.locationTableView > ul > li > label,
.locationTableView > ul > li > .labelVal {
	display: table-cell;
	padding: 15px 5px;
	vertical-align: middle;
	font-size: 14px;
	text-align: left;
	width: 50%;
}
.locationTableView > ul li > label {
	color: #969799;
}
.locationTableView > ul > li > .labelVal {
	color: #ffffff;
}
.locationTableView > ul > li > .labelVal > .btn {
	min-width: 80%;
}
.locationGridTable {
	width: 100%;
	float: left;
	clear: both;
}
.locationGridTable > ul {
	width: 100%;
	display: table;
}
.locationGridTable > ul > li {
	width: 100%;
	display: table-row;
	padding: 10px 10px 0px;
	vertical-align: top;
	color: #ffffff;
}
.locationGridTable > ul > li:first-child {
	padding: 0px 10px 0px;
}
.locationGridTable > ul > li > label,
.locationGridTable > ul > li > .labelVal {
	display: table-cell;
	padding: 5px 5px;
	vertical-align: middle;
	font-size: 14px;
	text-align: left;
}
.locationGridTable > ul > li > label {
	padding: 5px 5px 5px 0px;
}
.locationGridTable > ul > li:last-child > label {
	padding: 5px 5px 0px 0px;
}
.locationGridTable > ul > li:last-child > .labelVal {
	padding: 5px 5px 0px 5px;
}
.locationGridTable > ul > li > label {
	color: #969799;
	min-width: 110px;
}
.locationGridTable > ul > li > .labelVal {
	color: #ffffff;
}
/*** **************  ************** ****
		*** Last Error Solved Table ***
**** **************  ************** ***/

.lastErrorTableView {
	width: 100%;
	display: table;
}
.lastErrorTableView > ul {
	width: 100%;
	display: table;
}
.lastErrorTableView > ul > li {
	display: table-cell;
	padding: 10px 10px 5px;
	vertical-align: top;
	color: #ffffff;
	width: 50%;
	text-align: left;
}
.lastErrorTableView > ul:last-child > li {
	text-align: right;
}
.lastErrorTableView > ul > li > h5,
.lastErrorTableView > ul > li > h6 {
	padding: 0px 0px 5px;
	font-size: 13px;
	text-align: left;
}
.lastErrorTableView > ul li > p {
	color: #969799;
	text-align: left;
	font-size: 13px;
	margin: 10px 0px 0px;
}
/*** **************  ************** ****
		*** Actions Buttons List ***
**** **************  ************** ***/
.btnListView {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both;
}
.btnListView > ul {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both;
}
.btnListView > ul > li {
	width: 100%;
	padding: 15px 0px 6px;
	float: left;
	clear: both;
}
.btnListView > ul > li .actionsStatus {
	width: 100%;
	padding: 3px 10% 0px;
	float: left;
	clear: both;
	font-size: 14px;
	color: #ffffff;
	text-align: left;
}
.btnListView > ul > li.withDownloadBtn .btn:first-child {
	width: 60%;
}
.btnListView > ul > li.withDownloadBtn .downloadBtn {
	width: 19%;
	margin-left: 1%;
	text-align: center;
}
.btnListView > ul > li > .btn {
	width: 80%;
	border-radius: 0px;
	text-align: left;
	padding: 10px 12px;
	background: #616366;
	max-width: 280px;
	position:relative;
} 
.btnListView > ul > li > .btn.retrieveBtn { 
	padding: 7px 12px; 
} 
.btnListView > ul > li > .btn .btnText{  
	overflow: hidden;
	display: inline-block;
	line-height: 16px;
	text-overflow: ellipsis;
	max-width: 85%;
}
.btnListView > ul > li > .btn .btnText b { 
	font-size:12px;
	font-weight:normal;
	display:block;
} 
.btnListView > ul > li > .btn .btnProgress{  
	height:5px; 
	display:block;
	position:absolute;
	bottom:0px;
	left:0px;
}
.btnListView > ul > li > .btn .btnProgress.retrieved {
	background: #3cce82;
}
.btnListView > ul > li > .btn .btnProgress.retrieving {
	background: #0aaeff;
}
.btnListView > ul > li > .btn .btnProgress.retrievFailed {
	background: #f93251;
} 
.btnListView > ul > li > .btn.btn-info {
	background: #195d99;
	 border: 0px;
	 font-size: 14px;
}
.btnListView > ul > li > .btn.btn-danger {
	background: #F93251;
}
.btnListView > ul > li > .btn.btn-success {
	background: #00ad41;
}
.btnListView > ul > li > .btn .btnIcon.ChangeGroup:before {
	background: url("../images/icons/SidetreeA.svg") no-repeat center center;
	background-size: 24px auto;
}
.btnListView > ul > li > .btn .btnIcon.DeactivateUnit:before {
	background: url("../images/icons/deactivateUnit.svg") no-repeat center center;
	background-size: 24px auto;
}
.btnListView > ul > li > .btn .btnIcon.DeleteUnit:before {
	background: url("../images/icons/deleteUnit.svg") no-repeat center center;
	background-size: 24px auto;
}
.btnListView > ul > li > .btn .btnIcon.VideoTutorials:before {
	background: url("../images/icons/playVideo.svg") no-repeat center center;
	background-size: 24px auto;
}
.btnListView > ul > li > .btn .btnIcon.Manuals:before {
	background: url("../images/icons/manual.svg") no-repeat center center;
	background-size: 24px auto;
}
.btnListView > ul > li > .btn .btnIcon.OpenLogbook {
	position: relative;  
    width: 30px;
    height: 24px;
}
.btnListView > ul > li > .btn .btnIcon.OpenLogbook:before {
	background: url("../images/icons/CreateReportA.svg") no-repeat center center;
	background-size: 24px auto;
}
.btnListView > ul > li > .btn .btnIcon.DownloadRetriveLog {
	position: relative; 
    display: inline-block;
    width: 30px;
    height: 24px;
}
.btnListView > ul > li > .btn .btnIcon.DownloadRetriveLog:before {
	background: url("../images/icons/logDownload.png") no-repeat center center;
	background-size: 24px auto;
}
.btnListView > ul > li > .btn .btnIcon.downloadLogbook:before {
	background: url("../images/icons/downloadC.svg") no-repeat center center;
	background-size: 24px auto;
}
.btnListView > ul > li > .btn .btnIcon.downloadBackup:before {
	background: url("../images/icons/shareA.svg") no-repeat center center;
	background-size: 24px auto;
}
.btnListView > ul > li > .btn .btnIcon.retrivalLogBtn {
	position: relative; 
    display: inline-block;
    width: 30px;
    height: 24px;
}
.btnListView > ul > li > .btn .btnIcon.retrivalLogBtn:before {
	background: url("../images/icons/ReloadW.svg") no-repeat center center;
	background-size: 24px auto;
}
/* ****** ****** ****** ****** ****** **
 * ****** Error List ****** *
** ****** ****** ****** ****** ****** */

.errorListTab {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both;
	background: #2d3034;
}
.errorListTable {
	width: 100%;
	display: table;
}
.errorListTable > ul {
	width: 100%;
	display: table-row;
}
.errorListTable > ul.tHead > li {
	color: #9fa8b5;
	font-size: 14px;
	border-bottom: 1px solid #4a4a4a;
}
.errorListTable > ul.tHead > li:first-child {
	border-left: 0px;
}
.errorListTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #1f2124;
	color: #ffffff;
	text-align: left;
	font-size: 14px;
}
.errorListTable > ul.tBody > li:first-child {
	border-bottom: 1px solid #2d3034;
}
.errorListTable > ul.tBody > li {
	padding: 4px 10px;
}
.errorListTable > ul.tBody > li.progress {
	background: #2d3034;
	padding: 0px 0px 0px 10px;
}
.errorListTable > ul.tBody.descExp > li {
	background: #1b1c1f;
	vertical-align: top;
} 
.errorListTable > ul.tBody > li.progress .progress-bar {
	background: #3f4146;
	height: 100%;
	max-height: 48px;
	padding: 0px 2px;
	text-align: left;
	overflow: visible;
}
.errorListTable > ul.tBody.descExp > li.progress .progress-bar {
	background: #282a2c;
}
.errorListTable > ul > li h6 {
	font-size: 14px;
	font-weight: normal;
	padding: 0px 0px 0px;
}
.errorListTable > ul > li p {
	margin: 0px;
	font-size: 13px;
	color: #b5b6b8;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 320px;
}
.errorListTable > ul.descExp > li p {
	overflow: inherit;
	text-overflow: inherit;
	white-space: normal;
	max-width: inherit;
}
.errorListTable > ul > li:nth-child(1) {
	width: 40px;
	text-align: center;
	padding-left: 0px;
	padding-right: 0px;
}
.errorListTable > ul > li:nth-child(2) {
	width: 60px;
	text-align: center;
	padding: 10px 5px;
}
.errorListTable > ul > li:nth-child(3) {
	width: 48px;
	padding-left: 0px;
	padding-right: 0px;
	text-align: center;
}
.errorListTable > ul.tBody > li:nth-child(3) {
	padding: 0px;
}
.errorListTable > ul > li:nth-child(4) {
	width: 100px;
	padding: 10px 10px;
}
.errorListTable > ul > li:nth-child(6) {
	width: 220px;
	padding: 10px 10px;
}
.errorListTable > ul > li:nth-child(7) {
	width: 180px;
}
.errorListTable > ul > li:nth-child(8) {
	width: 50px;
	text-align: center;
	padding-left: 0px;
	padding-right: 0px;
}
.errorListTable > ul > li:nth-child(9) {
	width: 40px;
	text-align: center;
	padding-left: 0px;
	padding-right: 0px;
}
.errorListTable.historicErrorsTable > ul > li:nth-child(6) {
	width: 60px;
	padding: 10px 0px;
	text-align: left;
}
.errorListTable.historicErrorsTable > ul > li:nth-child(7),
.errorListTable.historicErrorsTable > ul > li:nth-child(8) {
	width: 180px;
	padding: 10px 0px;
	text-align: left;
}
.errorListTable.historicErrorsTable > ul > li:nth-child(9) {
	width: 180px;
	text-align: left;
}
.errorListTable.historicErrorsTable > ul.tHead > li:nth-child(9) {
	padding-left:10px;
}
.errorListTable.historicErrorsTable > ul > li:nth-child(10) {
	width: 50px;
	text-align: center;
	padding-left: 0px;
	padding-right: 0px;
}
.errorListTable.historicErrorsTable > ul > li:nth-child(11) {
	width: 40px;
	text-align: center;
	padding-left: 0px;
	padding-right: 0px;
}
.errorListTable > ul > li.errorStausLi {
	padding: 0px;
}
.errorListTable > ul > li span.errorStaus {
	width: 48px;
	height: 48px;
	display: inline-block;
	border: 0px;
}
.errorListTable > ul > li .helpIcon,
.errorListTable > ul > li .errorView {
	width: 40px;
	height: 40px;
	display: inline-block;
	border: 0px;
}
.errorListTable > ul > li .helpIcon {
	background: url("../images/icons/errorView.svg") no-repeat center center;
	background-size: 24px auto;
}
.errorListTable > ul > li .errorView {
	background: url("../images/icons/navExpandIcon.png") no-repeat center center;
	background-size: 32px auto;
}
.errorListTable > ul > li .warnLevel {
	background: #F93251 url("../images/icons/highLevel.svg") no-repeat center center;
	background-size: 24px auto;
}
.errorListTable > ul > li .alertLevel {
	background: #ca9b0d url("../images/icons/mediumLevel.svg") no-repeat center center;
	background-size: 24px auto;
}
.errorListTable > ul > li .checkLevel {
	background: #3CCE82 url("../images/icons/mediumLevel.svg") no-repeat center center;
	background-size: 24px auto;
}
.errorListTable > ul > li .infoLevel {
	background: #09aefe url("../images/icons/mediumLevel.svg") no-repeat center center;
	background-size: 24px auto;
}
.errorListTable > ul > li .descCollapse {
	background: url("../images/icons/arrow2r_A.svg") no-repeat center center;
	background-size: 24px 24px;
}
.errorListTable > ul.descExp > li .descCollapse {
	background: url("../images/icons/markoffA.svg") no-repeat center center;
	background-size: 24px 24px;
}
.errorListTable > ul > li .errorEvent {
	background:#7c8691 url("../images/icons/errorEventW.png") no-repeat center center;
	background-size: 24px auto;
}
.errorListTable > ul > li .customCheckbox {
	margin: 0px;
	display: inline-block;
}
.errorListTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}
.errorListTable > ul > li .customCheckbox input[type=checkbox] + label {
	background: url("../images/icons/checkOffA.svg") no-repeat left center;
	background-size: 24px 24px;
}
.errorListTable > ul > li .customCheckbox input[type=checkbox] + label,
.errorListTable > ul > li .customCheckbox input[type=checkbox] + i + label,
.errorListTable > ul > li .customCheckbox input[type=checkbox] + input + label {
	background: url("../images/icons/checkOffA.svg") no-repeat left center;
	background-size: 24px 24px;
}
.errorListTable > ul > li .customCheckbox input[type=checkbox]:checked + label,
.errorListTable > ul > li .customCheckbox input[type=checkbox]:checked + i + label,
.errorListTable > ul > li .customCheckbox input[type=checkbox]:checked + input + label {
	background: url("../images/icons/checkOnA.svg") no-repeat left center;
	background-size: 24px 24px;
}
.errorListTable > ul.tBody > li .errorListSM {
	display: none;
	width: 100%;
	float: left;
}
.errorListTable > ul.tBody > li .errorTypeSM {
	display: none;
}
/* ****** ****** ****** ****** ****** **
 * ****** Frymaster Error List ****** *
** ****** ****** ****** ****** ****** */
 
.fErrorListTable {
	width: 100%;
	display: table;
}
.fErrorListTable > ul {
	width: 100%;
	display: table-row;
}
.fErrorListTable > ul.tHead > li {
	color: #9fa8b5;
	font-size: 14px;
	border-bottom: 1px solid #4a4a4a;
}
.fErrorListTable > ul.tHead > li:first-child {
	border-left: 0px;
}
.fErrorListTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #1f2124;
	color: #ffffff;
	text-align: left;
	font-size: 14px;
}
.fErrorListTable > ul.tBody > li:first-child {
	border-bottom: 1px solid #2d3034;
}
.fErrorListTable > ul.tBody > li {
	padding: 4px 10px;
}
.fErrorListTable > ul.tBody > li.progress {
	background: #2d3034;
	padding: 0px 0px 0px 10px;
}
.fErrorListTable > ul.tBody.descExp > li {
	background: #1b1c1f;
	vertical-align: top;
} 
.fErrorListTable > ul.tBody > li.progress .progress-bar {
	background: #3f4146;
	height: 100%;
	max-height: 48px;
	padding: 0px 2px;
	text-align: left;
}
.fErrorListTable > ul.tBody.descExp > li.progress .progress-bar {
	background: #282a2c;
}
.fErrorListTable > ul > li h6 {
	font-size: 14px;
	font-weight: normal;
	padding: 0px 0px 0px;
}
.fErrorListTable > ul > li p {
	margin: 0px;
	font-size: 13px;
	color: #b5b6b8;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap; 
}
.fErrorListTable > ul.descExp > li p {
	overflow: inherit;
	text-overflow: inherit;
	white-space: normal;
	max-width: inherit;
}
.fErrorListTable > ul > li:nth-child(1) {
	width: 40px;
	text-align: center;
	padding-left: 0px;
	padding-right: 0px;
}
.fErrorListTable > ul > li:nth-child(2) {
	width: 60px;
	text-align: center;
	padding: 10px 5px;
}
.fErrorListTable > ul > li:nth-child(3) {
	width: 48px;
	padding-left: 0px;
	padding-right: 0px;
	text-align: center;
}
.fErrorListTable > ul.tBody > li:nth-child(3) {
	padding: 0px;
}
.fErrorListTable > ul > li:nth-child(4) {
	width: 100px;
	padding: 10px 10px;
} 
.fErrorListTable > ul > li:nth-child(5) {
	width: 70px;
	padding: 10px 0px;
}
.fErrorListTable > ul > li:nth-child(6) p { 
	max-width:320px;
}
.fErrorListTable > ul > li:nth-child(7) {
	width: 220px;
	padding: 10px 10px;
}
.fErrorListTable > ul > li:nth-child(8) {
	width: 180px;
}
.fErrorListTable > ul > li:nth-child(9) {
	width: 50px;
	text-align: center;
	padding-left: 0px;
	padding-right: 0px;
}
.fErrorListTable > ul > li:nth-child(10) {
	width: 40px;
	text-align: center;
	padding-left: 0px;
	padding-right: 0px;
}
.fErrorListTable.historicErrorsTable > ul > li:nth-child(6) p { 
	max-width:240px;
}
.fErrorListTable.historicErrorsTable > ul > li:nth-child(7) {
	width: 60px;
	padding: 10px 0px; 
}
.fErrorListTable.historicErrorsTable > ul > li:nth-child(8),
.fErrorListTable.historicErrorsTable > ul > li:nth-child(9) {
	width: 180px;  
	text-align: left;
	padding-left: 0px;
	padding-right: 0px;
}
.fErrorListTable.historicErrorsTable > ul.tBody > li:nth-child(8),
.fErrorListTable.historicErrorsTable > ul.tBody > li:nth-child(9) { 
	padding-top:5px;
	padding-bottom:5px;
	line-height:18px; 
}
.fErrorListTable.historicErrorsTable > ul > li:nth-child(10) {
	width: 180px;
	text-align: left;
}
.fErrorListTable.historicErrorsTable > ul.tHead > li:nth-child(10) {
	width: 180px;
	text-align: left;
	padding-left:10px;
}
.fErrorListTable.historicErrorsTable > ul > li:nth-child(11) {
	width: 50px;
	text-align: center;
	padding-left: 0px;
	padding-right: 0px;
}
.fErrorListTable.historicErrorsTable > ul > li:nth-child(12) {
	width: 40px;
	text-align: center;
	padding-left: 0px;
	padding-right: 0px;
}
.fErrorListTable > ul > li.errorStausLi {
	padding: 0px;
}
.fErrorListTable > ul > li span.errorStaus {
	width: 48px;
	height: 48px;
	display: inline-block;
	border: 0px;
}
.fErrorListTable > ul > li .helpIcon,
.fErrorListTable > ul > li .errorView {
	width: 40px;
	height: 40px;
	display: inline-block;
	border: 0px;
}
.fErrorListTable > ul > li .helpIcon {
	background: url("../images/icons/errorView.svg") no-repeat center center;
	background-size: 24px auto;
}
.fErrorListTable > ul > li .errorView {
	background: url("../images/icons/navExpandIcon.png") no-repeat center center;
	background-size: 32px auto;
}
.fErrorListTable > ul > li .warnLevel {
	background: #F93251 url("../images/icons/highLevel.svg") no-repeat center center;
	background-size: 24px auto;
}
.fErrorListTable > ul > li .alertLevel {
	background: #ca9b0d url("../images/icons/mediumLevel.svg") no-repeat center center;
	background-size: 24px auto;
}
.fErrorListTable > ul > li .checkLevel {
	background: #3CCE82 url("../images/icons/mediumLevel.svg") no-repeat center center;
	background-size: 24px auto;
}
.fErrorListTable > ul > li .infoLevel {
	background: #09aefe url("../images/icons/mediumLevel.svg") no-repeat center center;
	background-size: 24px auto;
}
.fErrorListTable > ul > li .errorEvent {
	background:#7c8691 url("../images/icons/errorEventW.png") no-repeat center center;
	background-size: 24px auto;
}
.fErrorListTable > ul > li .descCollapse {
	background: url("../images/icons/arrow2r_A.svg") no-repeat center center;
	background-size: 24px 24px;
}
.fErrorListTable > ul.descExp > li .descCollapse {
	background: url("../images/icons/markoffA.svg") no-repeat center center;
	background-size: 24px 24px;
}
.fErrorListTable > ul > li .customCheckbox {
	margin: 0px;
	display: inline-block;
}
.fErrorListTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}
.fErrorListTable > ul > li .customCheckbox input[type=checkbox] + label {
	background: url("../images/icons/checkOffA.svg") no-repeat left center;
	background-size: 24px 24px;
}
.fErrorListTable > ul > li .customCheckbox input[type=checkbox] + label,
.fErrorListTable > ul > li .customCheckbox input[type=checkbox] + i + label,
.fErrorListTable > ul > li .customCheckbox input[type=checkbox] + input + label {
	background: url("../images/icons/checkOffA.svg") no-repeat left center;
	background-size: 24px 24px;
}
.fErrorListTable > ul > li .customCheckbox input[type=checkbox]:checked + label,
.fErrorListTable > ul > li .customCheckbox input[type=checkbox]:checked + i + label,
.fErrorListTable > ul > li .customCheckbox input[type=checkbox]:checked + input + label {
	background: url("../images/icons/checkOnA.svg") no-repeat left center;
	background-size: 24px 24px;
}
.fErrorListTable > ul.tBody > li .errorListSM {
	display: none;
	width: 100%;
	float: left;
}
.fErrorListTable > ul.tBody > li .errorTypeSM {
	display: none;
}
/* ****** ****** ****** ****** ****** **
 * ****** Number of Products Sold Table ****** *
** ****** ****** ****** ****** ****** */

.drinksSold {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both;
	background: #2d3034;
	min-height: 300px;
}
.drinksSoldTable {
	width: 100%;
	display: table;
}
.drinksSoldTable > ul {
	width: 100%;
	display: table-row;
}
.drinksSoldTable > ul.tHead > li {
	color: #9fa8b5;
	font-size: 14px;
	border-bottom: 1px solid #4a4a4a;
}
.drinksSoldTable > ul.tHead > li:first-child {
	border-left: 0px;
}
.drinksSoldTable > ul.tHead > li:last-child {
	text-align: left;
}
.drinksSoldTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #1f2124;
	color: #ffffff;
	font-size: 14px;
	width: 95px;
	text-align: center;
}
.drinksSoldTable > ul.tBody > li:first-child {
	border-bottom: 1px solid #2d3034;
}
.drinksSoldTable > ul > li:nth-child(1) {
	width: 40px;
	text-align: center;
}
.drinksSoldTable > ul > li:nth-child(2) {
	width: auto;
	text-align: left;
}
.drinksSoldTable > ul.tBody > li:nth-child(odd) {
	background: #2a3d48;
}
.drinksSoldTable > ul.tBody > li:nth-child(even) {
	background: #26495c;
}
.drinksSoldTable > ul.tBody > li:nth-child(1) {
	background: transparent;
}
.drinksSoldTable > ul.tBody > li:nth-child(2) {
	background: transparent;
}
.drinksSoldTable > ul.tBody > li:last-child {
	width: 140px;
	text-align: left;
	background: transparent;
}
.drinksSoldTable > ul > li .upArrow,
.drinksSoldTable > ul > li .downArrow {
	width: 20px;
	height: 20px;
	display: inline-block;
}
.drinksSoldTable > ul > li .upArrow {
	background: url("../images/icons/arrow3UpT.svg") no-repeat center center;
	background-size: 20px 20px;
}
.drinksSoldTable > ul > li .downArrow {
	background: url("../images/icons/arrow3DownD.svg") no-repeat center center;
	background-size: 20px 20px;
}
/*** **************  ************** ****
		*** Error Wrapper ***
**** **************  ************** ***/

.errorWrapper {
	width: 100%;
	height: 100%;
	padding: 0px;
	background: #F2F2F2;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 99999;
	overflow: hidden;
}
/*** **************  ************** ****
		*** Error Top Header ***
**** **************  ************** ***/

.errorTopHeader {
	width: 100%;
	padding: 0px;
	display: table;
	background: #0d3968;
	border-bottom: 1px solid #7e8690;
}
.errorTopHeader > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.errorTopHeader > ul > li {
	padding: 0px 0px;
	display: table-cell;
	color: #0D3968;
	vertical-align: middle;
}
.errorTopHeader > ul > li a {
	color: #0D3968;
}
.errorTopHeader > ul > li:nth-child(1) {
	text-align: left;
	width: 64px;
	border-right: 2px solid #000000;
}
.errorTopHeader > ul > li:nth-child(2) {
	text-align: left;
	font-size: 18px;
	color: #FFFFFF;
	padding-left: 15px;
}
.errorTopHeader > ul > li:nth-child(3) {
	text-align: right;
	width: 64px;
	border-left: 2px solid #000000;
}
.errorTopHeader > ul > li > a:hover {
	color: #000000;
}
.errorTopHeader > ul > li .backIcon,
.errorTopHeader > ul > li .cancelIcon {
	width: 64px;
	height: 64px;
	display: block;
}
.errorTopHeader > ul > li .backIcon {
	background: url("../images/icons/BackA.svg") no-repeat center center;
	background-size: 36px 36px;
}
.errorTopHeader > ul > li .cancelIcon {
	background: url("../images/icons/cancelA.svg") no-repeat center center;
	background-size: 36px 36px;
}
/*** **************  ************** ****
		*** Error Header ***
**** **************  ************** ***/

.errorHeader {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both;
	border-bottom: 1px solid #7e8690;
}
.errorHeader a {
	color: #96a8bb;
	padding: 18px 24px;
	font-size: 18px;
	display: inline-block;
	font-family: 'open_sanssemibold';
}
.errorHeader a:hover,
.errorHeader a.active {
	color: #0d3968;
}
.serviceInfoListOuter {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px 64px 128px;
	height: 100%;
	overflow: auto;
}
.serviceInfoListOuter.paddingLR0px {
	padding: 5px 0px 128px;
}
.serviceInfoListOuter.paddingT35px {
	padding: 35px 64px 128px;
}
.serviceInfoListOuter .serviceInfoListF {
	width: 100%;
	float: left;
	padding: 0px 0px 0px 0px;
}
.serviceInfoListOuter .serviceInfoListC {
	width: 50%;
	float: inherit;
	margin: 0px auto;
}
.serviceInfoListOuter .serviceInfoListL {
	width: 50%;
	float: left;
	padding: 0px 10px 0px 0px;
}
.serviceInfoListOuter .serviceInfoListL.ServiceReport {
	padding-right: 40px;
}
.serviceInfoListOuter .serviceInfoListR {
	width: 50%;
	float: right;
	text-align: center;
}
.serviceInfoListOuter .serviceInfoListR.ServiceReport {
	padding-left: 40px;
	border-left: 1px solid #979797;
}
.serviceInfoListOuter .serviceInfoListF h5,
.serviceInfoListOuter .serviceInfoListL h5,
.serviceInfoListOuter .serviceInfoListR h5 {
	width: 100%;
	float: left;
	padding: 15px 0px 15px;
	color: #0D3968;
	font-size: 14px;
	text-align: left;
	font-family: 'open_sanssemibold';
}
.serviceInfoListOuter .boxDiv {
	width: 100%;
	float: left;
	padding: 15px;
	background: #195d99;
}
.serviceInfoListOuter .boxDiv.whiteBg {
	background: #ffffff;
}
.serviceInfoListOuter .newPart {
	width: 100%;
	float: left;
	text-align: left;
	padding: 0px 0px 5px 40px;
	color: #0d3968;
	background: url("../images/icons/informationB.svg") no-repeat left top;
	background-size: 24px 24px;
}
.serviceInfoListOuter .playVideo {
	width: 100%;
	float: left;
	text-align: left;
	padding: 5px 0px 5px 45px;
	color: #ffffff;
	background: url("../images/icons/MovieA.svg") no-repeat left center;
	background-size: 36px auto;
}
/*** **************  ************** ****
		*** Error Frequency ***
**** **************  ************** ***/

.errorFrequencyTable {
	width: auto;
	max-width: 100%;
	display: table;
	margin: 10px 0px 0px;
}
.errorFrequencyTable > ul {
	width: 100%;
	display: table-row;
}
.errorFrequencyTable > ul.tHead > li {
	color: #68829e;
	border-bottom: 1px solid #63778a;
}
.errorFrequencyTable > ul.tHead > li:nth-child(1) {
	border-bottom: 1px solid #63778a;
}
.errorFrequencyTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #acbac8;
	color: #0d3968;
	font-size: 16px;
	text-align: left;
}
.errorFrequencyTable > ul > li:nth-child(1) {
	width: 30px;
	text-align: center;
	border-bottom: 1px solid #F2F2F2;
}
.errorFrequencyTable > ul > li:nth-child(2) {
	width: 180px;
}
.errorFrequencyTable > ul > li:nth-child(3) {
	width: 60px;
}
.errorFrequencyTable > ul > li .errorStatusLevel {
	width: 18px;
	height: 18px;
	display: inline-block;
	overflow: hidden;
}
.errorFrequencyTable > ul > li .warnLevelIcon {
	background: url("../images/icons/warnLevelIcon.png") no-repeat left center;
	background-size: 18px 18px;
}
.errorFrequencyTable > ul > li .alertLevelIcon {
	background: url("../images/icons/alertLevelIcon.png") no-repeat left center;
	background-size: 18px 18px;
}
.errorFrequencyTable > ul > li .checkLevelIcon {
	background: url("../images/icons/checkLevelIcon.png") no-repeat left center;
	background-size: 18px 18px;
}
.errorFrequencyTable > ul > li .infoLevelIcon {
	background: url("../images/icons/infoLevelIcon.png") no-repeat left center;
	background-size: 18px 18px;
}
/*** **************  ************** ****
		*** Manager Action ***
**** **************  ************** ***/

.managerActionTable {
	width: 100%;
	display: table;
	margin: 10px 0px 0px;
}
.managerActionTable > ul {
	width: 100%;
	display: table-row;
}
.managerActionTable > ul.tHead > li {
	color: #68829e;
	border-bottom: 1px solid #63778a;
}
.managerActionTable > ul.tHead > li:nth-child(1) {
	border-bottom: 1px solid #63778a;
}
.managerActionTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #acbac8;
	color: #0d3968;
	font-size: 16px;
	text-align: left;
}
.managerActionTable > ul > li h6 {
	text-align: left;
	margin-bottom: 10px;
}
.managerActionTable > ul > li p {
	text-align: left;
	font-size: 14px;
	margin-bottom: 0px;
}
.managerActionTable > ul > li:nth-child(1) {
	width: 40px;
	text-align: center;
	border-bottom: 1px solid #F2F2F2;
	vertical-align: top;
	color: #6c85a1;
}
.managerActionTable > ul > li:nth-child(3) {
	width: 40px;
	text-align: center;
	vertical-align: top;
}
.managerActionTable > ul > li .editIcon {
	width: 24px;
	height: 24px;
	display: inline-block;
	border: 0px;
	background: url("../images/icons/penD.svg") no-repeat center center;
	background-size: 24px auto;
}
/*** **************  ************** ****
		*** Create Report Form ***
**** **************  ************** ***/

.createReportForm {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both;
}
.createReportForm > ul {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both;
}
.createReportForm > ul > li {
	width: 100%;
	padding: 15px 0px 14px;
	float: left;
	clear: both;
}
.createReportForm > ul > li.paddingT0px {
	padding: 0px 0px 14px;
}
.createReportForm > ul > li .form-control {
	border: 0px;
	background: #f2f2f2;
	border-radius: 0px;
	padding-left: 0px;
	font-size: 20px;
	border-bottom: 1px solid #7f95ad;
}
.createReportForm > ul > li textarea::placeholder {
	color: #63778a;
}
.createReportForm > ul > li textarea.form-control {
	border: 0px;
	background: #ffffff;
	padding: 10px;
	border-radius: 0px;
	border-bottom: 0px;
}
.createReportForm > ul > li textarea::-moz-input-placeholder {
	font-weight: normal;
	text-decoration: underline;
	text-decoration-color: rgba(251, 175, 93, 1);
}
.createReportFormBtns {
	width: 100%;
	padding: 0px 0px 0px;
	float: left;
	clear: both;
	text-align: left;
}
.createReportFormBtns > .btn {
	min-width: 140px;
}
.createReportForm > ul > li .devicegrpCheckbox {
	width: 100%;
	padding: 0px 0px;
	float: left;
	clear: both;
}
.createReportForm > ul > li .devicegrpCheckbox .customCheckbox {
	width: 100%;
	padding: 0px 0px 10px;
	margin: 0px 0px 10px;
	float: left;
	clear: both;
}
.createReportForm > ul > li .devicegrpCheckbox .customCheckbox label {
	font-size: 20px;
	color: #0D3968;
	padding-left: 35px;
}
/*** **************  ************** ****
		*** Custom Checkbox ***
**** **************  ************** ***/

.customCheckbox {
	position: relative;
	display: inline-block;
	margin: 10px 10px 0px 0px;
}
.customCheckbox input[type=checkbox] + label {
	display: inline-block;
	height: 30px;
	line-height: 24px;
	float: left;
	padding-left: 30px;
	margin-bottom: 0px;
	background: url("../images/icons/checkAllOff.svg") no-repeat left center;
	background-size: 24px 24px;
	font-size: 14px;
	font-weight: normal;
}
.customCheckbox input[type=checkbox] + label,
.customCheckbox input[type=checkbox] + i + label,
.customCheckbox input[type=checkbox] + input + label {
	display: inline-block;
	height: 30px;
	line-height: 24px;
	float: left;
	padding-left: 30px;
	margin-bottom: 0px;
	background: url("../images/icons/checkAllOff.svg") no-repeat left center;
	background-size: 24px 24px;
	font-size: 14px;
	font-weight: normal;
}
.customCheckbox label {
	display: inline-block !important;
	cursor: pointer;
	position: relative;
	margin: 0px 15px 0px 0px;
	height: 30px;
	line-height: 30px;
	padding: 3px 0px 0px 30px;
	font-size: 14px;
	font-weight: normal;
	text-align: left;
}
.customCheckbox input[type="checkbox"] {
	display: none;
}
.customCheckbox.selectAllDiv input[type=checkbox]:checked + label,
.customCheckbox.selectAllDiv input[type=checkbox]:checked + i + label,
.customCheckbox.selectAllDiv input[type=checkbox]:checked + input + label {
	background: url("../images/icons/checkBallMulti.png") no-repeat left center;
	background-size: 24px 24px;
}
.customCheckbox.selectAllDivD input[type=checkbox]:checked + label,
.customCheckbox.selectAllDivD input[type=checkbox]:checked + i + label,
.customCheckbox.selectAllDivD input[type=checkbox]:checked + input + label {
	background: url("../images/icons/checkAllOnD.svg") no-repeat left center;
	background-size: 24px 24px;
}
.customCheckbox.singleSlectAllDiv input[type=checkbox] + label,
.customCheckbox.singleSlectAllDiv input[type=checkbox] + i + label,
.customCheckbox.singleSlectAllDiv input[type=checkbox] + input + label {
	background: url("../images/icons/checkBallMulti.png") no-repeat left center;
	background-size: 24px 24px;
}
.customCheckbox.multiSlectAllDiv input[type=checkbox]:checked + label,
.customCheckbox.multiSlectAllDiv input[type=checkbox]:checked + i + label,
.customCheckbox.multiSlectAllDiv input[type=checkbox]:checked + input + label {
	background: url("../images/icons/checkAllOn.svg") no-repeat left center;
	background-size: 24px 24px;
}
.customCheckbox input[type=checkbox]:checked + label,
.customCheckbox input[type=checkbox]:checked + i + label,
.customCheckbox input[type=checkbox]:checked + input + label {
	background: url("../images/icons/checkAllOn.svg") no-repeat left center;
	background-size: 24px 24px;
}
.customCheckbox.customCheckboxWOlabel {
	margin: 0px 0px 0px 0px;
}
.customCheckbox.customCheckboxWOlabel input[type=checkbox] + label {
	padding-left: 24px;
}
.customCheckbox.customCheckboxWOlabel label {
	margin: 0px 0px 0px 0px;
	padding: 0px 0px 0px 24px;
}
.filterListOuter .customCheckbox label { 
	line-height: 20px !important;
	word-break: break-word;
}
/*** **************  ************** ****
		*** Custom RadioButton ***
**** **************  ************** ***/

.customRadioBtn {
	position: relative;
	display: inline-block;
	margin: 10px 10px 0px 0px;
}
.customRadioBtn input[type=radio] + label {
	display: inline-block;
	height: 30px;
	line-height: 24px;
	float: left;
	padding-left: 30px;
	margin-bottom: 0px;
	background: url("../images/icons/radioButtonUnchecked.svg") no-repeat left center;
	background-size: 24px 24px;
	font-size: 14px;
	font-weight: normal;
}
.customRadioBtn input[type=radio] + label,
.customRadioBtn input[type=radio] + i + label,
.customRadioBtn input[type=radio] + input + label {
	display: inline-block;
	height: 30px;
	line-height: 24px;
	float: left;
	padding-left: 30px;
	margin-bottom: 0px;
	background: url("../images/icons/radioButtonUnchecked.svg") no-repeat left center;
	background-size: 24px 24px;
	font-size: 14px;
	font-weight: normal;
}
.customRadioBtn label {
	display: inline-block !important;
	cursor: pointer;
	position: relative;
	margin: 0px 15px 0px 0px;
	height: 30px;
	line-height: 30px;
	padding: 3px 0px 0px 30px;
	font-size: 14px;
	font-weight: normal;
}
.customRadioBtn input[type="radio"] {
	display: none;
}
.customRadioBtn input[type=radio]:checked + label,
.customRadioBtn input[type=radio]:checked + i + label,
.customRadioBtn input[type=radio]:checked + input + label {
	background: url("../images/icons/radioButtonChecked.svg") no-repeat left center;
	background-size: 24px 24px;
}
.customRadioBtn.customCheckboxWOlabel {
	margin: 0px 0px 0px 0px;
}
.customRadioBtn.customCheckboxWOlabel input[type=radio] + label {
	padding-left: 24px;
}
.customRadioBtn.customCheckboxWOlabel label {
	margin: 0px 0px 0px 0px;
	padding: 0px 0px 0px 24px;
}
/*** **************  ************** ****
		*** Media Library Table ***
**** **************  ************** ***/

.mediaLibraryTable {
	width: 100%;
	display: table;
}
.mediaLibraryTable > ul {
	width: 100%;
	display: table-row;
}
.mediaLibraryTable > ul > li {
	display: table-cell;
	padding: 10px 0px 0px;
	vertical-align: top;
	color: #ffffff;
}
.mediaLibraryTable > ul > li:first-child {
	width: 120px;
}
.mediaLibraryTable > ul > li > ul {
	width: 100%;
	display: table;
}
.mediaLibraryTable > ul > li > ul > li {
	width: 100%;
	display: table-row;
}
.mediaLibraryTable > ul > li > ul > li > label,
.mediaLibraryTable > ul > li > ul > li > .labelVal {
	display: table-cell;
	padding: 5px 5px;
	vertical-align: middle;
	font-size: 14px;
	text-align: left; 
}
.mediaLibraryTable > ul > li > ul > li > label {
	color: #969799;
	width: 90px;
}
.mediaLibraryTable > ul > li > ul > li > .labelVal {
	color: #ffffff;
}
.mediaLibraryTable .previewBtn {
	min-width: 150px;
	position: absolute;
	bottom: 15px;
	right: 15px;
}
/*** **************  ************** ****
		*** Active Recipe ***
**** **************  ************** ***/

.recipesLibraryGrid {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both;
}
.recipesLibraryGrid > ul {
	padding: 0px;
	margin: 0px -1px;
	clear: both;
	width: 100%;
}
.recipesLibraryGrid > ul > li {
	width: 25%;
	padding: 0px 1px 2px;
	float: left;
}
.recipesLibraryGrid .recipesLibraryGridLI {
	width: 100%;
	padding: 10px;
	float: left;
	clear: both;
	background: #43464a;
}
.recipesLibraryGridLI h5 {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 5px;
	font-size: 12px;
	font-weight: normal;
	color: #ffffff;
}
.recipesLibraryGridLI .recipeGridImg {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 5px;
	font-size: 14px;
	font-weight: normal;
	color: #ffffff;
}
.recipesLibraryGridLI .recipeGridImg > img {
	width: 100%;
	float: left;
	clear: both;
}
.recipesLibraryGridLI .recipePriceGrid {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 5px;
	font-size: 14px;
	font-weight: normal;
	color: #ffffff;
}
/*** **************  ************** ****
		*** Top 10 Widget  ***
**** **************  ************** ***/
#topSoldDrinkschartdiv {
  width: 100%;
  height:360px;
  float: left;
}  
.topSoldDrinkschartouterdiv {
	width: 30%;
	height: 360px;
	float: left;
	position: relative;
}
.topSoldDrinks {
	width: 70%;
	min-height: 450px;
	height: auto !important;
	padding: 15px 0px 0px;
	float: left;
	background: #2d3034;
}
.topSoldDrinksTable {
	width: 100%;
	display: table;
}
.topSoldDrinksTable > ul,
.topSoldDrinksTable > div {
	width: 100%;
	display: table-row;
}
.topSoldDrinksTable > ul.tHead > li {
	color: #9fa8b5;
	font-size: 14px;
	border-bottom: 1px solid #4a4a4a;
}
.topSoldDrinksTable > ul.tHead > li:first-child {
	border-left: 0px;
}
.topSoldDrinksTable > ul > li,
.topSoldDrinksTable > div > div {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #1f2124;
	color: #ffffff;
	text-align: left;
	font-size: 14px;
}
.topSoldDrinksTable > div > div:first-child {
	border-bottom: 1px solid #2d3034;
}
.topSoldDrinksTable > ul > li:nth-child(1),
.topSoldDrinksTable > div > div:nth-child(1) {
	width: 40px;
	text-align: center;
}
.topSoldDrinksTable > ul > li:nth-child(2),
.topSoldDrinksTable > div > div:nth-child(2) {
	width: 14px;
}
.topSoldDrinksTable > ul > li:nth-child(5),
.topSoldDrinksTable > div > div:nth-child(5) {
	width: 120px;
	text-align: center;
}
.topSoldDrinksTable > ul > li:nth-child(4),
.topSoldDrinksTable > div > div:nth-child(4) {
	width: 78px;
	text-align: center;
}
.topSoldDrinksTable .marker {
	width: 14px;
	height: 14px;
	display: inline-block;
}
.topSoldDrinksTable .change {
	color: #0AAEFF;
}
.topSoldDrinksTable .change:before {
	content: "\25b2";
	color: #0AAEFF;
}
.topSoldDrinksTable .change.negative {
	color: #6b6e71;
}
.topSoldDrinksTable .change.negative:before {
	content: "\25bc";
	color: #6b6e71;
}
/*** **************  ************** ****
		*** Connectivity Status Widget  ***
**** **************  ************** ***/

.connectivityStatus {
	width: 60%;
	padding: 25px 0px;
	float: left;
	background: #2d3034;
}
.connectivityStatusTable {
	width: 100%;
	display: table;
}
.connectivityStatusTable > div {
	width: 100%;
	display: table-row;
}
.connectivityStatusTable > div:nth-child(1) > div {
	color: #ffffff;
}
.connectivityStatusTable > div:nth-child(2) > div {
	color: #78797b;
}
.connectivityStatusTable > div > div {
	display: table-cell;
	padding: 2px 10px;
	vertical-align: middle;
	color: #ffffff;
	text-align: left;
	font-size: 14px;
}
.connectivityStatusTable > div > div:nth-child(1) {
	width: 40px;
	text-align: right;
	font-size: 30px;
	font-family: 'open_sanssemibold';
}
.connectivityStatusTable > div:nth-child(2) > div:nth-child(1) {
	width: 40px;
	text-align: right;
	font-size: 16px;
}
.connectivityStatusTable > div > div:nth-child(2) {
	font-size: 14px;
}
/*** **************  ************** ****
		*** Error Status Widget  ***
**** **************  ************** ***/

.errorsStatus {
	width: 60%;
	padding: 0px;
	float: left;
	background: #2d3034;
}
.errorsStatusTable {
	width: 100%;
	display: table;
}
.errorsStatusTable > div {
	width: 100%;
	display: table-row;
}
.errorsStatusTable > div:nth-child(1) > div {
	color: #f93251;
}
.errorsStatusTable > div:nth-child(2) > div {
	color: #ffbe00;
}
.errorsStatusTable > div:nth-child(3) > div {
	color: #78797b;
}
.errorsStatusTable > div > div {
	display: table-cell;
	padding: 2px 10px 10px 0px;
	vertical-align: middle;
	color: #ffffff;
	text-align: left;
	font-size: 14px;
}
.errorsStatusTable > div > div:nth-child(1) {
	width: 40px;
	text-align: right;
	font-size: 30px;
	line-height: 32px;
	font-family: 'open_sanssemibold';
}
.errorsStatusTable > div:nth-child(3) > div:nth-child(1) {
	width: 40px;
	text-align: right;
	font-size: 16px;
}
.errorsStatusTable > div > div:nth-child(2) {
	font-size: 14px;
	padding-right: 0px;
}
/*** **************  ************** ****
		*** All Locations  ***
**** **************  ************** ***/

.allLocationsGrid {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both;
}
.allLocationsGrid > ul {
	padding: 0px;
	margin: 0px -1px;
	clear: both;
}
.allLocationsGrid > ul > li {
	width: 20%;
	padding: 0px 1px 2px;
	float: left;
	position: relative;
}
.allLocationsGrid > ul > li:hover {
	cursor: pointer;
}
.allLocationsGridLI {
	width: 100%;
	padding: 10px;
	float: left;
	clear: both;
	background: #2d3034;
}
.allLocationsGridLI .noOfDevice {
	float: left;
	text-align: left;
	clear: both;
	color: #ffffff;
	opacity: 0.5;
	min-height: 24px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.allLocationsGridLI.offlineStaus {
	background: #16191b;
	opacity: 0.8;
}
.allLocationsGridLI.offlineStaus:before,
.allLocationsGridLI.errorStaus:before,
.allLocationsGridLI.warningStaus:before {
	position: absolute;
	bottom: 10px;
	right: 10px;
	content: " ";
	width: 24px;
	height: 24px;
}
.allLocationsGridLI.offlineStaus:before {
	background: url("../images/icons/offlineA.svg") no-repeat center center;
	background-size: 24px 24px;
	opacity: 0.5;
}
.allLocationsGridLI.errorStaus {
	background: #cd1d45;
}
.allLocationsGridLI.errorStaus:before {
	background: url("../images/icons/errorA.svg") no-repeat center center;
	background-size: 24px 24px;
}
.allLocationsGridLI.warningStaus {
	background: #d39800;
}
.allLocationsGridLI.warningStaus:before {
	background: url("../images/icons/attentionA.svg") no-repeat center center;
	background-size: 24px 24px;
}
.allLocationsGridLI h5 {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 5px;
	font-size: 14px;
	font-weight: normal;
	color: #ffffff;
	box-shadow: 0 0 black;
	text-transform: uppercase;
	min-height: 46px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.allLocationsGridLI h5 > span {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 0px 0px;
	font-family: 'open_sanssemibold';
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.allLocationsGridLI .locationsGridImg {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px 0px 5px;
	font-size: 14px;
	font-weight: normal;
	color: #ffffff;
	text-align: center;
}
.allLocationsGridLI .locationsGridImg > img {
	max-width: 120px;
	min-width: 120px;
	max-height: 120px;
	clear: both;
}
/*** **************  ************** ****
		*** All Units  ***
**** **************  ************** ***/

.allUnitsGrid {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both;
}
.allUnitsGrid > ul {
	padding: 0px;
	margin: 0px -1px;
	clear: both;
}
.allUnitsGrid > ul > li {
	width: 20%;
	padding: 0px 1px 2px;
	float: left;
	position: relative;
} 
.allUnitsGridLI {
	width: 100%;
	padding: 10px 10px 90px;
	float: left;
	clear: both;
	background: #2d3034;
	position: relative;
	min-height: 240px;
}  
.unitsDetailsTab {
	width: 100%;
	display: table;
	cursor: pointer;
}
.unitsDetailsTab > ul {
	width: 100%;
	display: table-row; 
}
.unitsDetailsTab > ul > li {
	color: #9fa8b5;
	font-size: 14px; 
	display:table-cell;
	vertical-align:middle;
	padding:5px; 
}
.unitsDetailsTab > ul > li:nth-child(2) {
	width:70px; 
}
.unitsDetailsTab > ul > li:nth-child(2) > img{
	max-width:100%;
	max-height:80px;
}
.unitsDetailsTab > ul > li h4 {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px;
	font-size: 14px;
	font-weight: normal;
	color: #ffffff; 
	text-transform: uppercase;  
}
.unitsDetailsTab > ul > li h4 > span{
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 5px;
}
.unitsDetailsTab > ul > li h5,
.unitsDetailsTab > ul > li h6 { 
	padding: 0px 0px 5px; 
	font-weight: normal;
	color: #ffffff;
	opacity: 0.5;	
	max-width: 150px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.unitsDetailsTab > ul > li h5 {
	font-size:14px;	
}
.unitsDetailsTab > ul > li h6 {
	font-size:12px;	
}

.statusDetailsTab {
	width: 100%;
    display: table;
    position: absolute;
    bottom: 0px;
    left: 0px; 
	cursor:pointer;
	min-height:44px;
}
.statusDetailsTab > ul {
	width: 100%;
	display: table-row;
} 
.statusDetailsTab > ul > li {
	display: table-cell;
	padding:10px;
	vertical-align: top; 
	color: #ffffff;
	text-align: left;
	font-size: 14px;
}   
.statusDetailsTab > ul > li h6 {
	font-size: 14px;
	font-weight: normal;
	padding: 0px 0px 0px;
}
.statusDetailsTab > ul > li p {
	margin: 0px;
	font-size: 13px;
	color: #ffffff;
	opacity: 0.8;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 100px;
}
.statusDetailsTab > ul.descExp > li p {
	overflow: inherit;
	text-overflow: inherit;
	white-space: normal;
	max-width: inherit;
	overflow-x:hidden;
	max-height:80px;
	overflow-y: auto;
	margin-top: 10px;
}
.statusDetailsTab > ul > li:nth-child(1) {
	width: 40px;
	text-align: center; 
	padding-left:0px;
	padding-right:0px;
}
.statusDetailsTab > ul > li:nth-child(2) { 
	padding-left:0px;
}  
.statusDetailsTab > ul > li:nth-child(3) {
	padding-left:0px;
	padding-right:0px;
}  
.statusDetailsTab > ul.descExp > li:nth-child(2),
.statusDetailsTab > ul > li h6 {
	display:none;
}  
.statusDetailsTab.offlineStaus > ul > li:nth-child(1) {
	width:100%;
	text-align:left;
	padding:12px 10px 11px;
} 
.statusDetailsTab > ul.descExp > li h6 {
	display:block;
}
.statusDetailsTab > ul > li .descCollapse {
	width:24px;
	height:24px;
	border:0px;
}
.statusDetailsTab > ul > li .descCollapse {
	background: url("../images/icons/arrow2r_A.svg") no-repeat center center;
	background-size: 24px 24px;
}
.statusDetailsTab > ul.descExp > li .descCollapse {
	background: url("../images/icons/markoffA.svg") no-repeat center center;
	background-size: 24px 24px;
} 
.statusDetailsTab.offlineStaus, .offlineStaus {
	background: #16191b;
	opacity: 0.8;
}
.statusDetailsTab.offlineStaus:before,
.statusDetailsTab.errorStaus:before,
.statusDetailsTab.warningStaus:before {
	position: absolute;
	top: 10px;
	right: 10px;
	content: " ";
	width: 24px;
	height: 24px;
}
.statusDetailsTab.offlineStaus:before {
	background: url("../images/icons/offlineA.svg") no-repeat center center;
	background-size: 24px 24px;
	opacity: 0.5;
}
.statusDetailsTab.errorStaus {
	background: #cd1d45;
}
.statusDetailsTab.errorStaus:before {
	background: url("../images/icons/errorA.svg") no-repeat center center;
	background-size: 24px 24px;
}
.statusDetailsTab.warningStaus {
	background: #d39800;
}
.statusDetailsTab.warningStaus:before {
	background: url("../images/icons/attentionA.svg") no-repeat center center;
	background-size: 24px 24px;
} 
/* ****** ****** ****** ****** ****** **
 * ****** List Locations Table ****** *
** ****** ****** ****** ****** ****** */

.listLocations {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both;
	background: #2d3034;
}
.listLocationsTable {
	width: 100%;
	display: table;
}
.listLocationsTable > ul {
	width: 100%;
	display: table-row;
	cursor: pointer;
}
.listLocationsTable > ul.tHead > li {
	color: #9fa8b5;
	font-size: 14px;
	border-bottom: 1px solid #4a4a4a;
}
.listLocationsTable > ul.tHead > li:first-child {
	border-left: 0px;
}
.listLocationsTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #1f2124;
	color: #ffffff;
	text-align: left;
	font-size: 14px; 
}
.listLocationsTable > ul.errorStaus > li {
	background: #cd1d45;
}
.listLocationsTable > ul.warningStaus > li {
	background: #d39800;
}
.listLocationsTable > ul.tBody > li:first-child {
	border-bottom: 1px solid #2d3034;
}
.listLocationsTable > ul.tBody > li {
	padding: 7px 10px;
}
.listLocationsTable > ul.tBody.offlineStaus {
	opacity: 0.5;
}
.listLocationsTable > ul > li:nth-child(1),
.listLocationsTable > ul > li:nth-child(2) {
	width: 5%;
	text-align: center;
	padding-left:5px;
	padding-right:5px;
}
.listLocationsTable > ul.tBody > li:nth-child(2) > img {
	width: 32px;
	height: 32px;
}
.listLocationsTable > ul > li:nth-child(3),
.listLocationsTable > ul > li:nth-child(4),
.listLocationsTable > ul > li:nth-child(5),
.listLocationsTable > ul > li:nth-child(7) {
	width: 15%;
}
.listLocationsTable > ul > li:nth-child(7) {
	text-align: center;
} 
.listLocationsTable > ul > li:nth-child(8) {
	width: 7%;
	text-align: center;
	padding-left:5px;
	padding-right:5px;
}
.listLocationsTable > ul.tBody > li:nth-child(8) {
	width: 5%; 
}
.listLocationsTable > ul.tBody > li:nth-child(8) > img {
	width: 24px; 
}
.listLocationsTable > ul.tBody > li .locationsListSM {
	display: none;
	width: 100%;
	float: left;
}
/* ****** ****** ****** ****** ****** **
 * ****** List Units Table ****** *
** ****** ****** ****** ****** ****** */

.listUnits {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both;
	background: #2d3034;
}
.listUnitsTable {
	width: 100%;
	display: table;
}
.listUnitsTable > ul {
	width: 100%;
	display: table-row;
	cursor: pointer;
}
.listUnitsTable > ul.tHead > li {
	color: #9fa8b5;
	font-size: 14px;
	border-bottom: 1px solid #4a4a4a;
}
.listUnitsTable > ul.tHead > li:first-child {
	border-left: 0px;
}
.listUnitsTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #1f2124;
	color: #ffffff;
	text-align: left;
	font-size: 14px; 
}
.listUnitsTable > ul.errorStaus > li {
	background: #cd1d45;
}
.listUnitsTable > ul.warningStaus > li {
	background: #d39800;
}
.listUnitsTable > ul.tBody > li:first-child {
	border-bottom: 1px solid #2d3034;
}
.listUnitsTable > ul.tBody.offlineStaus {
	opacity: 0.5;
}
.listUnitsTable > ul.tBody > li {
	padding: 7px 10px;
}
.listUnitsTable > ul > li:nth-child(1),
.listUnitsTable > ul > li:nth-child(2) {
	width: 5%;
	text-align: center;
	padding-left:5px;
	padding-right:5px;
} 
.listUnitsTable > ul > li:nth-child(2) {
	padding-left:5px;
	padding-right:5px;
} 
.listUnitsTable > ul.tBody > li:nth-child(2) > img {
	width: 32px;
	height: 32px;
}
.listUnitsTable > ul > li:nth-child(3),
.listUnitsTable > ul > li:nth-child(4),
.listUnitsTable > ul > li:nth-child(5),
.listUnitsTable > ul > li:nth-child(6),
.listUnitsTable > ul > li:nth-child(7),
.listUnitsTable > ul > li:nth-child(8) {
	width: 12%;
}   
.listUnitsTable > ul > li:nth-child(10) {
	width: 7%;
	text-align: center;
	padding-left:5px;
	padding-right:5px;
}   
.listUnitsTable > ul.tBody > li:nth-child(10) > img {
	width: 24px; 
} 
.listUnitsTable > ul.tBody > li:nth-child(10) {
	width: 5%; 
}
.listUnitsTable > ul.tBody > li .unitsListSM,
.listUnitsTable > ul.tBody > li .unitsListSML {
	display: none;
	width: 100%;
	float: left;
}
.listUnitsTable > ul.tBody > li .unitsListAN {
	display: none;
	width: 100%;
	float: left;
	background: url("../images/ANTransparent.png") repeat;
	padding: 5px;
	font-size: 12px;
}
.listUnitsTable > ul.tBody > li .unitsListAN > b {
	width: 100%;
	float: left;
	font-weight: normal;
}
/* ****** ****** ****** ****** ****** **
 * ****** Store Map ****** *
** ****** ****** ****** ****** ****** */

.storeMapWrapper {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both;
	background: #2d3034;
}
#storeMap {
	width: 100%;
	min-height: 620px;
	max-height: 100%;
}
#unitMap {
	width: 100%;
	min-height: 620px;
	max-height: 100%;
}
/*** **************  ************** ****
		*** Filter Header ***
**** **************  ************** ***/

.filterHeader {
	width: 100%;
	padding: 0px;
	display: table;
	border-bottom: 1px solid #7e8690;
}
.filterHeader > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.filterHeader > ul > li {
	padding: 0px 0px;
	display: table-cell;
	color: #0D3968;
	vertical-align: middle;
}
.filterHeader > ul > li a {
	color: #0D3968;
	font-family: 'open_sanssemibold';
	padding: 20px 15px;
	display: block;
}
.filterHeader > ul > li:nth-child(1) {
	text-align: left;
	width: 64px;
}
.filterHeader > ul > li:nth-child(2) {
	text-align: center;
	font-size: 20px;
	color: #0d3968;
	font-family: 'open_sanssemibold';
}
.filterHeader > ul > li:nth-child(3) {
	text-align: right;
	width: 90px;
} 
.filterHeader > ul > li > a:hover {
	color: #000000;
}
.filterHeader > ul > li .backIcon,
.filterHeader > ul > li .forwardIcon,
.filterHeader > ul > li .cancelIcon {
	width: 64px;
	height: 64px;
	display: block;
}
.filterHeader > ul > li .backIcon {
	background: url("../images/icons/back.png") no-repeat center center;
	background-size: 36px 36px;
}
.filterHeader > ul > li .forwardIcon {
	background: url("../images/icons/forward.png") no-repeat center center;
	background-size: 36px 36px;
}
.filterHeader > ul > li .cancelIcon {
	background: url("../images/icons/cancel.png") no-repeat center center;
	background-size: 36px 36px;
}
/*** **************  ************** ****
		*** Error Frequency ***
**** **************  ************** ***/

.filterCategoryTable {
	width: 100%;
	display: table;
	margin: 10px 0px 0px;
}
.filterCategoryTable > ul {
	width: 100%;
	display: table-row;
}
.filterCategoryTable > ul > li {
	display: table-cell;
	padding: 10px 15px;
	vertical-align: middle;
	border-bottom: 1px solid #dadfe3;
	color: #0D3968;
	font-size: 20px;
	text-align: left;
}
.filterCategoryTable > ul > li:nth-child(1) {
	width: 245px;
}
.filterCategoryTable > ul > li:nth-child(2) {
	text-align: right;
	padding-left: 0px;
}
.filterCategoryTable > ul > li:nth-child(3) {
	width: 50px;
}
.filterCategoryTable > ul > li .nextIcon {
	background: url("../images/icons/arrow1B.svg") no-repeat center center;
	background-size: 24px 24px;
	display: block;
}
.filterCategoryTable > ul > li .selectedCountry {
	color: #0D3968;
	display: inline-block;
	font-size: 16px;
	padding: 0px 5px;
	margin: 0px;
}
.filterCategoryTable > ul > li .selectedCountry.alert-dismissible .close {
	position: relative;
	padding: 0px 5px;
	color: #0D3968;
}
.clearBtnDiv {
	width: 100%;
	padding: 10px 0px 15px;
	float: left;
	clear: both;
	text-align: right;
}
.clearBtnDiv .btn {
	background: inherit;
	color: #0D3968;
	font-size: 14px;
	border-radius: 0px;
}
/*** **************  ************** ****
		*** Map Sidebar ***
**** **************  ************** ***/

#wrapper .storeMapWrapper {
	padding-left: 0;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	position: relative;
}
#wrapper.mapSideBartoggled .storeMapWrapper {
	padding-left: 250px;
}
#mapSidebarWrapper {
	z-index: 1000;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 0;
	height: 100%;
	overflow-y: auto;
	background: #2d3034;
	border-right: 2px solid #000;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
#wrapper.mapSideBartoggled #mapSidebarWrapper {
	width: 250px;
}
.mapContentWrapper {
	position: relative;
	margin-right: 0;
}
#wrapper.mapSideBartoggled .mapContentWrapper {
	position: relative;
	margin-right: 0;
}
/* Sidebar Styles */

.mapSidebarList {
	position: absolute;
	top: 0;
	width: 250px;
	margin: 0;
	padding: 0;
	list-style: none;
}
.mapSidebarList > li,
.mapSidebarList > li > ul > li {
	width: 100%;
	padding: 0px 0px;
	float: left;
	clear: both;
	background: #43464a;
	border-bottom: 1px solid #2d3034;
}
.mapSidebarList > li > ul > li {
	background: #2d3034;
}
.mapSidebarList > li .dropdown-toggle::after {
	border: 0px;
}
.mapSidebarList > li > ul > li:last-child {
	border-bottom: 0px;
}
.mapSidebarList > li > a,
.mapSidebarList > li > ul > li > a {
	width: 100%;
	padding: 0px 0px;
	float: left;
	clear: both;
	text-decoration: none;
	color: #999999;
}
.mapSidebarList > li > a > span,
.mapSidebarList > li > ul > li > a > span {
	padding: 5px 5px;
	font-size: 14px;
}
.mapSidebarList > li > a > span.countryFlag {
	text-align: center;
	width: 34px;
	float: left;
	margin-right: 5px;
}
.mapSidebarList > li > ul > li > a > span.unitImg {
	text-align: center;
	width: 34px;
	float: left;
	margin-right: 5px;
}
.mapSidebarList > li > a > span.countryFlag > img,
.mapSidebarList > li > ul > li > a > span.unitImg img {
	width: 24px;
	height: 24px;
}
.mapSidebarList > li > a > span.countryName,
.mapSidebarList > li > ul > li > a > span.countryName {
	text-align: left;
	float: left;
	padding: 7px 5px;
}
.mapSidebarList > li > a > span.numberOfCounts,
.mapSidebarList > li > ul > li > a > span.numberOfCounts {
	text-align: right;
	width: 30px;
	font-size: 14px;
	color: #8e9092;
	padding-right: 10px;
	float: right;
	padding: 7px 5px;
}
.mapSidebarList > li.mapSidebarListBrand > a > span.numberOfCounts,
.mapSidebarList > li.mapSidebarListBrand > ul > li > a > span.numberOfCounts {
	padding: 12px 5px;
}
.mapSidebarList > li > a:hover {
	text-decoration: none;
	color: #fff;
	background: rgba(255, 255, 255, 0.2);
}
.mapSidebarList>.mapSidebarListBrand span.countryName {
	font-size: 16px;
	padding: 10px 5px;
}
.mapSidebarList>.mapSidebarListBrand > a {
	color: #999999;
}
.mapSidebarList>.mapSidebarListBrand > a:hover {
	color: #fff;
	background: none;
}
/*** **************  ************** ****
		*** Pagination Footer ***
**** **************  ************** ***/

.paginationFooter {
	width: 100%;
	padding: 0px;
	display: table;
	background: #1f2124;
}
.paginationFooter.borderTop {
	border-top: 2px solid #000;
}
.paginationFooter > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.paginationFooter > ul > li {
	padding: 10px 10px;
	display: table-cell;
	color: #ffffff;
	vertical-align: middle;
	text-align: center;
}
.paginationFooter > ul > li a {
	color: #0D3968;
}
.paginationFooter > ul > li.prevPageLi {
	text-align: left;
	width: 42px;
	border-right: 2px solid #000;
}
.paginationFooter > ul > li.prevPageCountLi {
	text-align: left;
	color: #78797b;
	width: 90px;
}
.paginationFooter > ul > li.nextPageCountLi {
	text-align: right;
	color: #78797b;
	width: 90px;
}
.paginationFooter > ul > li.nextPageLi {
	text-align: right;
	width: 42px;
	border-left: 2px solid #000;
}
.paginationFooter > ul > li > a:hover {
	color: #000000;
}
.paginationFooter > ul > li .prevPage,
.paginationFooter > ul > li .nextPage {
	display: block;
	width: 24px;
}
.paginationFooter > ul > li .prevPage {
	background: url("../images/icons/arrowLA.svg") no-repeat center center;
	background-size: 24px 24px;
}
.paginationFooter > ul > li .nextPage {
	background: url("../images/icons/arrowRA.svg") no-repeat center center;
	background-size: 24px 24px;
}
/*** **************  ************** ****
		*** Filter Pagination ***
**** **************  ************** ***/

.filterPagination {
	width: 100%;
	padding: 0px;
	display: table;
	border-top: 1px solid #7e8690;
}
.filterPagination > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.filterPagination > ul > li {
	padding: 10px 10px;
	display: table-cell;
	color: #0d3968;
	vertical-align: middle;
}
.filterPagination > ul > li a {
	color: #0d3968;
}
.filterPagination > ul > li:nth-child(1) {
	text-align: left;
	width: 42px;
}
.filterPagination > ul > li:nth-child(2) {
	text-align: left;
	color: #0d3968;
	width: 90px;
}
.filterPagination > ul > li:nth-child(3) {
	text-align: center;
	color: #0d3968;
	font-family: 'open_sanssemibold';
}
.filterPagination > ul > li:nth-child(4) {
	text-align: right;
	color: #0d3968;
	width: 90px;
}
.filterPagination > ul > li:nth-child(5) {
	text-align: right;
	width: 42px;
}
.filterPagination > ul > li > a:hover {
	color: #000000;
}
.filterPagination > ul > li .prevPage,
.filterPagination > ul > li .nextPage {
	display: block;
	width: 24px;
}
.filterPagination > ul > li .prevPage {
	background: url("../images/icons/back.png") no-repeat center center;
	background-size: 24px 24px;
}
.filterPagination > ul > li .nextPage {
	background: url("../images/icons/forward.png") no-repeat center center;
	background-size: 24px 24px;
}
/*** **************  ************** ****
		*** Table Pagination ***
**** **************  ************** ***/

.tablePagination {
	width: 100%;
	padding: 0px;
	display: table;
	border-top: 1px solid #7e8690;  
}
.tablePagination > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.tablePagination > ul > li {
	padding: 10px 10px;
	display: table-cell;
	color: #0d3968;
	vertical-align: middle;
}
.tablePagination > ul > li a {
	color: #0d3968;
}
.tablePagination > ul > li:nth-child(1) {
	text-align: left;
	width: 42px;
}
.tablePagination > ul > li:nth-child(2) {
	text-align: left;
	color: #0d3968;
	width: 90px;
}
.tablePagination > ul > li:nth-child(3) {
	text-align: center;
	color: #0d3968;
	font-family: 'open_sanssemibold';
}
.tablePagination > ul > li:nth-child(4) {
	text-align: right;
	color: #0d3968;
	width: 90px;
}
.tablePagination > ul > li:nth-child(5) {
	text-align: right;
	width: 42px;
}
.tablePagination > ul > li > a:hover {
	color: #000000;
}
.tablePagination > ul > li .prevPage,
.tablePagination > ul > li .nextPage {
	display: block;
	width: 24px;
}
.tablePagination > ul > li .prevPage {
	background: url("../images/icons/back.png") no-repeat center center;
	background-size: 24px 24px;
}
.tablePagination > ul > li .nextPage {
	background: url("../images/icons/forward.png") no-repeat center center;
	background-size: 24px 24px;
}
/*** **************  ************** ****
		*** Main Menu Grids  ***
**** **************  ************** ***/

.mainMenuGrid {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both; 
}
.mainMenuGrid > ul {
	padding: 0px;
	margin: 0px -1px;
	clear: both;
	width: 100%;
}
.mainMenuGrid > ul > li {
	width: 16.666%;
	padding: 0px 1px 2px;
	float: left;
	position: relative;
	cursor: pointer;
}
.mainMenuGrid > ul > li.userNotificationInfo {
	opacity: 0.5;
	cursor: pointer;
}
.mainMenuGrid > ul > li h5 {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 5px;
	font-size: 14px;
	font-weight: normal;
	color: #7e8f9f;
	box-shadow: 0 0 black;
	text-align: center;
	min-height: 48px;
}
.mainMenuGrid > ul > li > span {
	width: 100%;
	float: left;
	clear: both;
	padding: 15px 0px 0px;
	text-align: center;
}
.mainMenuGrid > ul > li > span img {
	width: 80px;
	max-height: 100px;
	clear: both;
}
/*** **************  ************** ****
		*** Unit Registration Wrapper  ***
**** **************  ************** ***/

.unitRegistrationWrapper {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both; 
}
/*** **************  ************** ****
		*** Unit Registration Header ***
**** **************  ************** ***/

.unitRegHeader {
	width: 100%;
	padding: 0px;
	display: table;
	border-bottom: 1px solid #7e8690;
}
.unitRegHeader > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.unitRegHeader > ul > li {
	padding: 10px 15px;
	display: table-cell;
	color: #0D3968;
	vertical-align: middle;
}
.unitRegHeader > ul > li a {
	color: #0D3968;
}
.unitRegHeader > ul > li:nth-child(1) {
	text-align: left;
	font-size: 18px;
	color: #0D3968;
	font-family: 'open_sanssemibold';
}
.unitRegHeader > ul > li:nth-child(2) {
	text-align: right;
	font-size: 16px;
}
.unitRegHeader > ul > li > a:hover {
	color: #000000;
}
.unitRegHeader > ul > li .btn {
	margin-left: 10px;
	padding:0px 0px;
}
.unitRegHeader > ul > li .btn.btn-default { 
	background:#195d99;
}
.unitRegHeader > ul > li .btn.disabled {
	color: #bcc3cf;
}
.unitRegHeader > ul > li .bulkUnitOnboarding,
.unitRegHeader > ul > li .unitEdit,
.unitRegHeader > ul > li .unitAdd,
.unitRegHeader > ul > li .unitDelete {
	width: 24px;
	height: 24px;
}
.unitRegHeader > ul > li .bulkUnitOnboarding {
	background: url("../images/icons/upload.png") no-repeat center center;
	background-size: 20px 20px;
}
.unitRegHeader > ul > li .bulkUnitOnboarding.disabled {
	background: url("../images/icons/upload.png") no-repeat center center;
	background-size: 20px 20px;
}
.unitRegHeader > ul > li .unitEdit {
	background: url("../images/icons/penB.svg") no-repeat center center;
	background-size: 24px 24px;
}
.unitRegHeader > ul > li .unitEdit.disabled {
	background: url("../images/icons/penD.svg") no-repeat center center;
	background-size: 24px 24px;
}
.unitRegHeader > ul > li .unitAdd {
	background: url("../images/icons/plusA.svg") no-repeat center center;
	background-size: 24px 24px;
}
.unitRegHeader > ul > li .unitAdd.disabled {
	background: url("../images/icons/plusD.svg") no-repeat center center;
	background-size: 24px 24px;
}
.unitRegHeader > ul > li .unitDelete {
	background: url("../images/icons/trashB.svg") no-repeat center center;
	background-size: 24px 24px;
}
.unitRegHeader > ul > li .unitDelete.disabled {
	background: url("../images/icons/trashC.svg") no-repeat center center;
	background-size: 24px 24px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Unit List Table ****** *
** ****** ****** ****** ****** ****** */

.unitRegListTable {
	width: 100%;
	display: table;
}
.unitRegListTable > ul {
	width: 100%;
	display: table-row;
}
.unitRegListTable > ul.tHead > li {
	color: #16406d;
	border-bottom: 1px solid #63778a;
	background: #e9eaeb;
} 
.unitRegListTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
} 
.unitRegListTable > ul.tHead > li:nth-child(1) {
    border-left: 0px;
    border-bottom: 1px solid #63778a;
}
.unitRegListTable > ul > li:nth-child(1) {
	width: 54px;
	text-align: center; 
	color: #16406d;
	border-bottom: 1px solid #F2F2F2;
	padding-left: 5px;
	padding-right: 5px;
}
.unitRegListTable > ul > li:nth-child(2) {
	padding-left: 0px;
	text-align: left;
}
.unitRegListTable > ul > li:nth-child(3),
.unitRegListTable > ul > li:nth-child(4) {
	width: 160px;
}
.unitRegListTable > ul > li:nth-child(5),
.unitRegListTable > ul > li:nth-child(6) {
	width: 180px; 
} 
.unitRegListTable > ul > li:nth-child(7) {
	width: 220px;
}
.unitRegListTable > ul.tBody > li:nth-child(7) {
	font-size: 14px;
}
.unitRegListTable > ul > li:nth-child(8),
.unitRegListTable > ul > li:nth-child(9) {
	width: 90px; 
    padding-left: 5px;
    padding-right: 5px;
	text-align:center;
}  
.unitRegListTable > ul > li:nth-child(10) {
	width: 58px;
	text-align: center;
}
.unitRegListTable > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.unitRegListTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}
.unitRegListTable > ul > li .confirmationTick {
	width: 24px;
	height: 24px;
	display: inline-block;
	border: 0px;
	background: url("../images/icons/confirmGreen.svg") no-repeat center center;
	background-size: 24px auto; 
}
.unitRegListTable > ul > li .assignReload {
	width: 24px;
	height: 24px;
	display: inline-block;
	border: 0px;
	background: url("../images/icons/ReloadS.svg") no-repeat center center;
	background-size: 24px auto;
}
.unitRegListTable > ul.tBody > li .unitsRSM,
.unitRegListTable > ul.tBody > li .unitsASM,
.unitRegListTable > ul.tBody > li .unitsListSM {
	display: none;
	width: 100%;
	float: left;
}
.unitRegListTable > ul.tBody > li .unitsListAN {
	display: none;
	width: 100%;
	float: left;
	background: #fff;
	padding: 5px;
	font-size: 12px;
}
.unitRegListTable > ul.tBody > li .unitsListAN > b {
	width: 100%;
	float: left;
	font-weight: normal;
}
/* ****** ****** ****** ****** ****** **
 * ****** Unit List Table ****** *
** ****** ****** ****** ****** ****** */
.unitRegCList {
	width: 100%;
	display: table;
}
.unitRegCList > ul {
	width: 100%;
	display: table-row;
}
.unitRegCList > ul.tHead > li {
	color: #16406d;
	border-top: 1px solid #63778a;
	border-bottom: 1px solid #63778a;
	background: #e9eaeb;
} 
.unitRegCList > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
} 
.unitRegCList > ul.tHead > li:nth-child(1) {
    border-left: 0px;
    border-bottom: 1px solid #63778a;
}
.unitRegCList > ul > li:nth-child(1) {
	width: 54px;
	text-align: center; 
	color: #16406d;
	border-bottom: 1px solid #F2F2F2;
	padding-left: 5px;
	padding-right: 5px;
}
.unitRegCList > ul > li:nth-child(2) {
	padding-left: 0px;
	text-align: left;
}
.unitRegCList > ul > li:nth-child(3),
.unitRegCList > ul > li:nth-child(4) {
	width: 160px;
}
.unitRegCList > ul > li:nth-child(5),
.unitRegCList > ul > li:nth-child(6) {
	width: 180px; 
} 
.unitRegCList > ul > li:nth-child(7) {
	width: 200px;
	text-align:center;
}  
.unitRegCList > ul > li:nth-child(8) {
	width: 240px;
	text-align: left;
} 
.unitRegCList > ul.tBody > li .unitsRSM {
	display: none;
	width: 100%;
	float: left;
}
.unitRegCList > ul.tBody > li .unitsListSM {
	display: none;
	width: 100%;
	float: left;
}
.unitRegCList > ul.tBody > li .unitsListAN {
	display: none;
	width: 100%;
	float: left;
	background: #fff;
	padding: 5px;
	font-size: 12px;
}
.unitRegCList > ul.tBody > li .unitsListAN > b {
	width: 100%;
	float: left;
	font-weight: normal;
}
.unitRegCList > ul.tBody > li .registeredDevices {
	color:#70bf69;
}
.unitRegCList > ul.tBody > li .existingDevices {
	color:#52b0f9;
}
.unitRegCList > ul.tBody > li .duplicateEntry {
	color:#fd9331;
}
.unitRegCList > ul.tBody > li .wrongEntry {
	color:#f93251;
} 
/*** **************  ************** ****
		*** Add Unit Header ***
**** **************  ************** ***/

.addUnitHeader {
	width: 100%;
	padding: 0px;
	display: table;
	background: #f2f2f2;
	border-bottom: 1px solid #7e8690;
}
.addUnitHeader > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.addUnitHeader > ul > li {
	padding: 0px 0px;
	display: table-cell;
	color: #6e87a2;
	vertical-align: middle;
}
.addUnitHeader > ul > li a {
	color: #6e87a2;
	padding: 21px 0px;
	display: block;
}
.addUnitHeader > ul > li a:hover,
.addUnitHeader > ul > li a:active,
.addUnitHeader > ul > li a.activeState {
    color: #0d3968 !important;
}
.addUnitHeader > ul > li:nth-child(1) {
	text-align: left;
	width: 64px;
}
.addUnitHeader > ul > li:nth-child(2) {
	text-align: center;
	font-size: 20px;
	color: #0d3968;
	font-family: 'open_sanssemibold';
}
.addUnitHeader > ul > li:nth-child(3) {
	text-align: right;
	width: 80px;
	font-size: 16px;
	padding-right: 15px;
}
.addUnitHeader > ul > li:nth-child(3) a {
	color: #18426f;
}
.addUnitHeader > ul > li .backIcon {
	width: 64px;
	height: 64px;
	display: block;
	background: url("../images/icons/back.png") no-repeat center center;
	background-size: 36px 36px;
}

/*** **************  ************** ****
		*** Selected Unit Header ***
**** **************  ************** ***/

.selectedUnitHeader {
	width: 100%;
	padding: 0px;
	display: table;
	background: #f2f2f2;
	border-bottom: 1px solid #7e8690;
}
.selectedUnitHeader > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.selectedUnitHeader > ul > li {
	padding: 12px 15px;
	display: table-cell;
	color: #6e87a2;
	vertical-align: middle;
}
.selectedUnitHeader > ul > li a {
	color: #6e87a2;
	display: block;
}
.selectedUnitHeader > ul > li:nth-child(1) a {
	color: #0d3968;
	display: inline-block;
	margin-right: 25px;
}
.selectedUnitHeader > ul > li:nth-child(1) {
	text-align: left;
	font-size: 18px;
	color: #0d3968;
}
.selectedUnitHeader > ul > li:nth-child(2) {
	text-align: right;
	width: 400px;
	font-size: 16px;
	padding-right: 15px;
}
.selectedUnitHeader > ul > li .dateRangeForm{
	min-width:330px;
}
.selectedUnitHeader.withSchedule > ul > li:nth-child(1) {
	width: 320px;
}
.selectedUnitHeader.withSchedule > ul > li:nth-child(2) {
	text-align: center;
	width: auto;
	padding: 0px;
}
.selectedUnitHeader.withSchedule > ul > li:nth-child(3) {
	width: 110px;
	text-align: right;
}
.selectedUnitHeader.withSchedule > ul > li .scheduleUpdateForm {
	width: auto;
	float: none;
	padding: 0px;
	clear: inherit;
	display: inline-block;
} 
.selectedUnitHeader > ul > li .useTagsforunit {
	background:#0d3968; 
	color:#ffffff;
	padding: 6px 16px 6px 35px;
	position:relative;
}
.selectedUnitHeader > ul > li .useTagsforunit:before {
	position: absolute;
	top:5px;
	left:7px;
	background:url("../images/icons/smartTagW.svg") no-repeat center center;
	background-size: auto 20px; 
	width:24px;
	height:24px;
	content: " ";  
} 
.selectedUnitHeader.menuAssignmentStatus > ul > li .statusClearAll {
	background: url("../images/icons/ConfirmA.png") no-repeat right center;
	background-size: 24px 24px;
	padding: 0px 30px 0px 0px;
	width: 100%;
	float: left;
	line-height: 18px; 
	text-align: right;
}
.selectedUnitHeader > ul > li .refreshIcon {
	width: 24px;
	height: 24px;
	margin-left: 10px;
	float: right;
	background: url("../images/icons/refresh.svg") no-repeat center center;
	background-size: 24px 24px;
}
.selectedUnitHeader > ul > li > a:hover {
	color: #000000;
}
.userMgmtHeader > ul > li .assignunitSite, 
.selectedUnitHeader > ul > li .assignunitSite,
.selectedUnitHeader > ul > li .assignunitList {
	width: 24px;
	height: 24px;
	display: inline-block;
	margin-left: 10px;
} 
.selectedUnitHeader > ul > li .assignunitSite {
	background: url("../images/icons/SidetreeS.svg") no-repeat center center;
	background-size: 24px 24px;
}
.userMgmtHeader > ul > li .assignunitSite {
	background: url("../images/icons/SidetreeDA.svg") no-repeat center center;
	background-size: 24px 24px;
}
.userMgmtHeader > ul > li .assignunitSite.disabled {
	background: url("../images/icons/SidetreeS.svg") no-repeat center center;
	background-size: 24px 24px;
}
.selectedUnitHeader > ul > li .assignunitSite:hover,
.selectedUnitHeader > ul > li .assignunitSite.active {
	background: url("../images/icons/SidetreeDA.svg") no-repeat center center;
	background-size: 24px 24px;
}
.selectedUnitHeader > ul > li .assignunitList {
	background: url("../images/icons/ListviewS.svg") no-repeat center center;
	background-size: 24px 24px;
}
.selectedUnitHeader > ul > li .assignunitList:hover,
.selectedUnitHeader > ul > li .assignunitList.active {
	background: url("../images/icons/ListviewDA.svg") no-repeat center center;
	background-size: 24px 24px;
} 
.selectedUnitHeader > ul > li .checkBoxListGrp {
	display:inline-block;
	margin-right:15px;
} 
.selectedUnitHeader > ul > li .checkBoxListGrp .customCheckbox {
    position: relative;
    display: inline-block;
    margin: 0px 10px 0px 0px;
}
.selectedUnitHeader > ul > li .checkBoxListGrp .customCheckbox label { 
    line-height:16px;
	color: #0D3968;
	font-family: 'open_sansregular';
	height:32px;
	padding: 0px 0px 0px 30px;
	float:inherit;
}
.selectedUnitHeader > ul > li .checkBoxListGrp .customCheckbox label > span { 
    display: table;
	color: #0D3968;
	clear:both;
    font-family: 'open_sanssemibold';
}
.userMgmtHeader > ul > li .assignunitSite {
	background: url("../images/icons/SidetreeDA.svg") no-repeat center center;
	background-size: 24px 24px;
}
.userMgmtHeader > ul > li .assignunitSite.disabled{opacity:0.2}
/*** **************  ************** ****
		*** Unit Register Form ***
**** **************  ************** ***/

.unitRegFormWrapper {
	width: 100%;
	float: left;
	margin: 0px 0px;
}
.unitRegFormWrapper.alignCenter {
	width: 1000px;
	float: none;
	margin: 0px auto;
}
.unitRegForm {
	width: 400px;
	margin: 0px auto;
	clear: both;
}
.unitRegForm.floatLeft {
	float:left;
	clear: none;
}
.unitRegForm.bulkUploadForm {
	width: 470px; 
}
.unitRegForm > ul {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px 15px 0px;
}
.unitRegForm > ul > li {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px 0px 5px;
	margin: 0px;
}
.unitRegForm > ul > li.marginBottom10px {
	margin-bottom: 20px;
}
.unitRegForm > ul > li.textAlignCenter {
	text-align:center;
}
.unitRegForm > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 30px 10px 0px;
	height: auto;
	background: #f2f2f2 !important;
	color: #0D3968;
	font-size: 20px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
}
.unitAssignModal .unitRegForm > ul > li .form-control { 
	font-size:18px; 
}
.unitRegForm > ul > li select.form-control {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: #f2f2f2 url("../images/icons/markdownA.svg") no-repeat right center !important;
}
.unitRegForm > ul > li select::-ms-expand {
	display: none;
} 
.unitRegForm > ul > li > p,
.unitRegForm > ul > li > label {
	color: #0D3968;
}
.unitRegForm > ul > li > label.fWeightB { 
	font-family: 'open_sanssemibold';
}
.unitRegForm > ul > li .input-group {
	width: 100%;
	position: relative;
}
.unitRegForm > ul > li .labelL {
	width:135px; 
	float:left;
	padding-top: 7px;
	color: #0D3968;
	font-size:16px; 
}
.unitRegForm > ul > li .labelL.fWeightB { 
	font-family: 'open_sanssemibold';
}
.unitRegForm > ul > li .valueR {
	width:65%; 
	float:right;
}
.unitRegForm > ul > li .input-group .requiredField {
	width: 26px;
	height: 26px;
	text-align: center;
	position: absolute;
	top: 5px;
	right: 0px;
	z-index: 3;
	color: #b8c3cf;
	font-size: 28px;
}
.unitRegForm > ul > li  input.form-control:placeholder-shown, 
.unitRegForm > ul > li  input.form-control1:placeholder-shown {
    opacity: 1;
}
.unitRegForm > ul > li .modelNameInpt::placeholder {
	color: #0d3968 !important;
	opacity: 1; /* Firefox */
} 
.unitRegForm > ul > li .modelNameInpt:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #0d3968;
	opacity: 1;
} 
.unitRegForm > ul > li .modelNameInpt::-ms-input-placeholder { /* Microsoft Edge */
 color: #0d3968;
 opacity: 1;
}
.unitRegForm > ul > li .unitOnboardingTemplate {  
	width:auto;  
	display:inline-block;
	padding:10px 0px 10px 30px;
	color: #0d3968; 
	background: url("../images/icons/download.png") no-repeat left center;
	background-size: 20px 20px;
	margin:5px 0px 25px;
}
.unitRegForm > ul > li .unitOnboardingTemplate:hover {  
	color: #000;  
}
.requiredNoteDiv {
	width: 100%;
	float: left;
	clear: both;
	padding: 25px 0px 5px 15px;
	color: #68829e;
	font-size: 14px;
}
.myInformationTable .requiredNoteDiv {
	padding: 25px 0px 25px 0px;
}
/*** **************  ************** ****
		*** User Management Wrapper  ***
**** **************  ************** ***/

.userManagementWrapper {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both; 
}
.userManagementWrapper .exitingUserRolesSelection {
	width: 100%;
	padding: 15px 55px;
	float: left;
	clear: both;
}
.userManagementWrapper .exitingUserRolesSelection .exitingRolesSelect {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: #f2f2f2 url("../images/icons/markdownA.svg") no-repeat right center !important;
	width: 420px;
	float: left;
	clear: both;
	padding: 5px 30px 10px 0px;
	height: auto;
	color: #0D3968;
	font-size: 20px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
}
.userManagementWrapper .exitingUserRolesSelection .exitingRolesSelect::-ms-expand {
	display: none;
}
/*** **************  ************** ****
		*** User Management Header ***
**** **************  ************** ***/

.userMgmtHeader {
	width: 100%;
	padding: 0px;
	display: table;
	border-bottom: 1px solid #7e8690;
}
.userMgmtHeader > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.userMgmtHeader > ul > li {
	padding:0px;
	display: table-cell;
	color: #0D3968;
	vertical-align: middle;
	font-size: 18px;
	font-family: 'open_sanssemibold';
}
.userMgmtHeader > ul > li a {
	color: #96a8bb;
}
.userMgmtHeader > ul > li > a.active,
.userMgmtHeader > ul > li > a:hover {
	color: #0D3968;
}
.userMgmtHeader > ul > li:nth-child(1) {
	text-align: left; 
}
.userMgmtHeader > ul > li:nth-child(1) a {
	color: #96a8bb;
	padding: 18px 15px 19px;
	font-size: 18px;
	display: inline-block;
	font-family: 'open_sanssemibold';
}
.userMgmtHeader > ul > li:nth-child(2) {
	text-align: right;
	padding-right: 15px;
} 
.userMgmtHeader > ul > li:nth-child(1) > a:hover,
.userMgmtHeader > ul > li:nth-child(1) > a.active {
	color: #0d3968;
}
.userMgmtHeader > ul > li .btn {
	margin-left: 10px;
}
.userMgmtHeader > ul > li .btn.disabled {
	color: #bcc3cf;
}
.userMgmtHeader > ul > li .userDuplicate,
.userMgmtHeader > ul > li .userEdit,
.userMgmtHeader > ul > li .userRoleEdit,
.userMgmtHeader > ul > li .userDelete,
.userMgmtHeader > ul > li .userAdd {
	width: 24px;
	height: 24px;
}
.userMgmtHeader > ul > li .userEdit,
.userMgmtHeader > ul > li .userRoleEdit {
	background: url("../images/icons/penB.svg") no-repeat center center;
	background-size: 24px 24px;
}
.userMgmtHeader > ul > li .userEdit.disabled,
.userMgmtHeader > ul > li .userRoleEdit.disabled {
	background: url("../images/icons/penD.svg") no-repeat center center;
	background-size: 24px 24px;
}
.userMgmtHeader > ul > li .userDelete {
	background: url("../images/icons/trashB.svg") no-repeat center center;
	background-size: 24px 24px;
}
.userMgmtHeader > ul > li .userDelete.disabled {
	background: url("../images/icons/trashC.svg") no-repeat center center;
	background-size: 24px 24px;
}
.userMgmtHeader > ul > li .userAdd {
	background: url("../images/icons/plusA.svg") no-repeat center center;
	background-size: 24px 24px;
}
.userMgmtHeader > ul > li .userAdd.disabled {
	background: url("../images/icons/plusD.svg") no-repeat center center;
	background-size: 24px 24px;
}
/* ****** ****** ****** ****** ****** **
 * ****** User Management List Table ****** *
** ****** ****** ****** ****** ****** */


.userMgmtListTable {
	width: 100%;
	display: table;
}
.userMgmtListTable > ul {
	width: 100%;
	display: table-row;
}
.userMgmtListTable > ul.tHead > li {
	color: #16406d;
	border-bottom: 1px solid #63778a;
	background: #e9eaeb;
}
.userMgmtListTable > ul.tHead > li:first-child {
	border-left: 0px;
	border-bottom: 1px solid #63778a;
}
.userMgmtListTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left; 
}
.userMgmtListTable > ul > li > a {
	color: #16406d;
}
.userMgmtListTable > ul > li h6 {
	font-size: 14px;
	font-weight: normal;
	padding: 0px 0px 0px;
}
.userMgmtListTable > ul > li p {
	margin: 0px;
	font-size: 13px;
	color: #b5b6b8;
}
.userMgmtListTable > ul > li:nth-child(1) {
	width: 54px;
	text-align: center;
	color: #16406d;
	border-bottom: 1px solid #F2F2F2;
	padding-left: 5px;
        padding-right: 5px;
}
.userMgmtListTable > ul > li:nth-child(2) {
	width: 50px;
	padding-left: 0px;
	text-align: center;
}
.userMgmtListTable > ul > li:nth-child(3) {
	width: 200px;
}
.userMgmtListTable > ul > li:nth-child(4) {
	width: 150px;
	padding-left:0px;
	padding-right:0px;
}
.userMgmtListTable > ul > li:nth-child(6) { 
	width: 220px;
}
.userMgmtListTable > ul.tBody > li:nth-child(6) { 
	font-size: 14px;
}
.userMgmtListTable > ul > li:nth-child(7) {
	text-align: center;
	width: 130px;
}
.userMgmtListTable > ul > li:nth-child(8) {
	text-align: center;
	width: 100px;
	padding-left:0px;
	padding-right:0px;
}
.userMgmtListTable > ul > li:nth-child(9) {
	text-align: center;
	width: 180px;
	padding-left:0px;
	padding-right:0px;
} 
.userMgmtListTable > ul > li:nth-child(10) {
	width: 58px;
	text-align: center; 
}
.userMgmtListTable > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.userMgmtListTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}
.userMgmtListTable > ul > li .userDP {
	width: 40px;
	height: 40px;
	display: inline-block;
	border-radius: 50%;
	background: #ff8a8a;
}
.userMgmtListTable > ul > li .userDP > img {
	width: 40px;
	height: 40px;
}
.userMgmtListTable > ul > li .btn-group-toggle .btn-secondary.focus,
.userMgmtListTable > ul > li .btn-group-toggle .btn-secondary:focus {
	box-shadow: inherit;
}
.userMgmtListTable > ul > li .btn-group-toggle .btn-secondary {
	width: 48px;
	height: 24px;
	border: 0px;
	background: url("../images/icons/switchOFF.svg") no-repeat center center;
	background-size: 48px 24px;
	background-color: transparent !important;
}
.userMgmtListTable > ul > li .btn-group-toggle .btn-secondary.active {
	background: url("../images/icons/switchON.svg") no-repeat center center;
	background-size: 48px 24px;
}
.userMgmtListTable > ul.tBody > li .userMListRL {
	display: none;
	width: 100%;
	float: left;
	font-size: 12px;
	background:#ffffff;
	padding:3px;
}
/* ****** ****** ****** ****** ****** **
 * ****** User Email Report Configuration List Table ****** *
** ****** ****** ****** ****** ****** */

.userEmailRCListTable {
	width: 100%;
	display: table;
}
.userEmailRCListTable > ul {
	width: 100%;
	display: table-row;
}
.userEmailRCListTable > ul.tHead > li {
	color: #16406d;
	border-bottom: 1px solid #63778a;
	background: #e9eaeb;
}
.userEmailRCListTable > ul.tHead > li:first-child {
	border-left: 0px;
	border-bottom: 1px solid #63778a;
}
.userEmailRCListTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
}
.userEmailRCListTable > ul > li > a {
	color: #16406d;
}
.userEmailRCListTable > ul > li:nth-child(1) {
	width: 40px;
	text-align: center;
	color: #16406d;
	border-bottom: 1px solid #F2F2F2;
}
.userEmailRCListTable > ul > li:nth-child(2) {
	width: 50px;
	padding-left: 0px;
	text-align: center;
}
.userEmailRCListTable > ul > li:nth-child(4),
.userEmailRCListTable > ul > li:nth-child(5) {
	width: 320px;
}
.userEmailRCListTable > ul > li:nth-child(6) {
	width: 40px;
	text-align: center;
	padding-right: 15px;
}
.userEmailRCListTable > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.userEmailRCListTable > ul > li .userDP {
	width: 40px;
	height: 40px;
	display: inline-block;
	border-radius: 50%;
	background: #ff8a8a;
}
.userEmailRCListTable > ul > li .userDP > img {
	width: 40px;
	height: 40px;
}
.userEmailRCListTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}
.userEmailRCListTable > ul.tBody > li .userMListRL {
	display: none;
	width: 100%;
	float: left;
	font-size: 14px;
}
.userEmailRCListTable > ul.tBody > li .ErrorRassigned {
	color: #0d3968;
	background: #bccddc;
	padding: 5px 10px;
	font-family: 'open_sanssemibold';
	font-size: 12px;
	display: inline-block;
	margin: 3px;
}
/*** **************  ************** ****
		*** User Profile Header ***
**** **************  ************** ***/

.userProfileHeader {
	width: 100%;
	padding: 0px;
	display: table;
	border-bottom: 1px solid #7e8690;
}
.userProfileHeader > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.userProfileHeader > ul > li {
	padding: 0px 0px;
	display: table-cell;
	color: #96a8bb;
	vertical-align: middle;
}
.userProfileHeader > ul > li a {
	color: #96a8bb;
	margin-right: 20px;
	padding: 18px 15px 19px;
	font-size: 18px;
	display: inline-block;
	font-family: 'open_sanssemibold';
}
.userProfileHeader > ul > li > a:hover,
.userProfileHeader > ul > li > a.active {
	color: #0d3968;
}
/*** **************  ************** ****
		*** Add User Header ***
**** **************  ************** ***/

.addUserHeader {
	width: 100%;
	padding: 0px;
	display: table;
	background: #f2f2f2;
	border-bottom: 1px solid #7e8690;
}
.addUserHeader > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.addUserHeader > ul > li {
	padding: 0px 0px;
	display: table-cell;
	color: #6e87a2;
	vertical-align: middle;
}
.addUserHeader > ul > li .btn-default-text,
.adminBgHeader > ul > li a,
.adminBgHeader > ul > li .btn-default-text {
	color: #0d3968;
	padding: 21px 0px;
	display: inline-block;
}
.addUserHeader > ul > li .btn-default-text.activeState,
.adminBgHeader > ul > li a.activeState,
.adminBgHeader > ul > li button.activeState,
.addUnitHeader > ul > li a.activeState,
.addUnitHeader > ul > li button.activeState  {
	font-family: 'open_sanssemibold';
	color: #0d3968;
}
.addUserHeader > ul > li a {
	color: #16406d;
	padding: 21px 0px;
	display: inline-block;
}
.addUserHeader > ul > li:nth-child(1) {
	text-align: left;
	width: 64px;
}
.addUserHeader > ul > li:nth-child(2) {
	text-align: center;
	font-size: 18px;
	color: #0d3968;
	font-family: 'open_sanssemibold';
}
.addUserHeader > ul > li:nth-child(3) {
	text-align: right;
	width: 80px;
	font-size: 16px;
	padding-right: 15px;
}
.addUserHeader > ul > li .backIcon {
	width: 64px;
	height: 64px;
	display: block;
	background: url("../images/icons/back.png") no-repeat center center;
	background-size: 36px 36px;
}
/*** **************  ************** ****
		*** Admin Bg Header ***
**** **************  ************** ***/

.adminBgHeader {
	width: 100%;
	padding: 0px;
	display: table;
	background: #f2f2f2;
	border-bottom: 1px solid #7e8690;
}
.adminBgHeader > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.adminBgHeader > ul > li {
	padding: 0px 0px;
	display: table-cell;
	color: #6e87a2;
	vertical-align: middle;
}
.adminBgHeader > ul > li .btn-default-text {
	color: #6e87a2;
	padding: 21px 0px;
	display: inline-block;
}
.adminBgHeader > ul > li a {
	color: #6e87a2;
	padding: 21px 0px;
	display: inline-block;
}
.adminBgHeader > ul > li:nth-child(1) {
	text-align: left;
	width: 64px;
}
.adminBgHeader > ul > li:nth-child(2) {
	text-align: center;
	font-size: 18px;
	color: #0d3968;
	font-family: 'open_sanssemibold';
}
.adminBgHeader > ul > li:nth-child(3) {
	text-align: right;
	width: 80px;
	font-size: 16px;
	padding-right: 15px;
}
.adminBgHeader > ul > li > a:hover,
.adminBgHeader > ul > li > a.active {
	color: #0d3968;
}
.adminBgHeader > ul > li .backIcon {
	width: 64px;
	height: 64px;
	display: block;
	background: url("../images/icons/back.png") no-repeat center center;
	background-size: 36px 36px;
}
/*** **************  ************** ****
		*** Add User Form ***
**** **************  ************** ***/

.addUserFormWrapper {
	width: 100%;
	float: left;
	margin: 0px 0px;
}
.addUserForm {
	width: 450px;
	margin: 50px auto;
	clear: both;
}
.addUserForm > ul {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px 15px 0px;
}
.addUserForm > ul > li {
	width: 100%;
	float: left;
	padding: 10px 0px 5px;
	margin: 0px;
}
.addUserForm .forgotpassword {
    float: right;
    color: #0D3968; 
    font-size: 16px;
	margin-right:15px;
}
.addUserForm > ul > li.streetName {
	width: 66%;
	margin-right: 2%;
}
.addUserForm > ul > li.streetNumber {
	width: 32%;
}
.addUserForm > ul > li.cityName {
	width: 67%;
}
.addUserForm > ul > li.zipcode {
	width: 31%;
	margin-right: 2%;
}
.addUserForm > ul > li .form-control.countryCode {
	width: 30%;
	margin-right: 5%;
}
.addUserForm > ul > li .form-control.phoneNumber {
	width: 60%;
}
.addUserForm > ul > li.infoLi {
	padding: 15px;
	margin: 10px 0px 0px;
	background: #ffffff;
}
.addUserForm > ul > li.marginBottom10px {
	margin-bottom: 20px;
}
.addUserForm > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 30px 10px 0px;
	height: auto;
	background: #f2f2f2 !important;
	color: #0D3968;
	font-size: 20px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
}
.addUserForm > ul > li select.form-control {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: #f2f2f2 url("../images/icons/markdownA.svg") no-repeat right center !important;
}
.addUserForm > ul > li select::-ms-expand {
	display: none;
}
.addUserForm > ul > li .form-control.countryCode {
	width: 20%;
	margin-right: 2%;
}
.addUserForm > ul > li .form-control.phoneNumber {
	width: 75%;
}
.addUserForm > ul > li > p {
	color: #0D3968;
}
.addUserForm > ul > li > p.formInfoIcon {
	color: #0D3968;
	padding: 0px 0px 0px 35px;
	background: url("../images/icons/informationB.svg") no-repeat top left;
	background-size: 24px 24px;
	font-size: 14px;
	margin: 0px;
}
.addUserForm > ul > li .input-group {
	width: 100%;
	position: relative;
}
.addUserForm > ul > li .input-group .requiredField {
	width: 26px;
	height: 26px;
	text-align: center;
	position: absolute;
	top: 5px;
	right: 0px;
	z-index: 3;
	color: #b8c3cf;
	font-size: 28px;
}
.addUserForm > ul > li .ManagePassword {
	color: #0d3968;
	background: #bccddc;
	border: 0px;
	border-radius: 0px;
	padding: 10px 15px;
	font-family: 'open_sanssemibold';
}
/* ****** ****** ****** ****** ****** **
 * ****** User Management Roles Table ****** *
** ****** ****** ****** ****** ****** */

.userMgmtRolesTable {
	width: 100%;
	display: table;
}
.userMgmtRolesTable > ul {
	width: 100%;
	display: table-row;
}
.userMgmtRolesTable > ul.tHead > li {
	color: #16406d;
	border-bottom: 1px solid #63778a;
	background: #e9eaeb;
}
.userMgmtRolesTable > ul.tHead > li:first-child {
	border-left: 0px;
	border-bottom: 1px solid #63778a;
}
.userMgmtRolesTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
}
.userMgmtRolesTable > ul > li > a {
	color: #16406d;
}
.userMgmtRolesTable > ul > li h6 {
	font-size: 14px;
	font-weight: normal;
	padding: 0px 0px 0px;
}
.userMgmtRolesTable > ul > li p {
	margin: 0px;
	font-size: 13px;
	color: #b5b6b8;
}
.userMgmtRolesTable > ul > li:nth-child(1) {
	width: 54px;
	text-align: center;
	color: #16406d;
	border-bottom: 1px solid #F2F2F2;
	padding-left:5px;
	padding-right:5px;
}
.userMgmtRolesTable > ul > li:nth-child(2) {
	width: 50px;
	padding-left: 0px;
}
.userMgmtRolesTable > ul > li:nth-child(3) {
	width: 220px;
} 
.userMgmtRolesTable > ul > li:nth-child(5) {
	text-align: center;
	width: 58px;
}
.userMgmtRolesTable > ul > li .userDP {
	width: 40px;
	height: 40px;
	display: block;
	border-radius: 50%;
	background: #ff8a8a;
}
.userMgmtRolesTable > ul > li .userDP > img {
	width: 40px;
	height: 40px;
}
.userMgmtRolesTable > ul > li .btn-group-toggle .btn-secondary.focus,
.userMgmtRolesTable > ul > li .btn-group-toggle .btn-secondary:focus {
	box-shadow: inherit;
}
.userMgmtRolesTable > ul > li .btn-group-toggle .btn-secondary {
	padding-right: 35px;
	border: 0px;
	background: url("../images/icons/lockedD.svg") no-repeat right center;
	background-size: 24px 24px;
	background-color: transparent !important;
	color: #aebbc9;
	font-size: 12px;
}
.userMgmtRolesTable > ul > li .btn-group-toggle .btn-secondary.active {
	background: url("../images/icons/lockedB.svg") no-repeat right center;
	background-size: 24px 24px;
	color: #0D3968;
}
.userMgmtRolesTable > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.userMgmtRolesTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}
/*** **************  ************** ****
		*** Choose User Form ***
**** **************  ************** ***/

.chooseUserFormWrapper {
	width: 100%;
	float: left;
	margin: 0px 0px;
}
.chooseUserForm {
	width: 400px;
	margin: 0px auto;
	clear: both;
}
.chooseUserForm > ul {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px 15px 0px;
}
.chooseUserForm > ul > li {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px 0px 0px;
	margin: 0px;
}
.chooseUserForm > ul > li .userNameDP {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 0px;
	color: #0D3968;
	font-size: 24px;  
}
.chooseUserForm > ul > li .userNameDP.viewUser {
	text-align: center;
}
.chooseUserForm > ul > li .userNameDP.viewUser > .userDPImg {
	float: none;
}
.chooseUserForm > ul > li .userNameDP > .userDPImg,
.chooseUserForm > ul > li .userNameDP > .userDText {
	display:table-cell; 
	vertical-align:top;
}
.chooseUserForm > ul > li .userNameDP > .userDPImg {
	width: 48px;  
	padding: 0px 10px 0px 0px;
} 
.chooseUserForm > ul > li .userNameDP > .userDPImg.withCircle {
	border-radius: 50%;
	background: #ff8a8a;
}
.chooseUserForm > ul > li .userNameDP > .userDPImg > img {
	width: 48px;
	height: 48px;
}
.chooseUserForm > ul > li .userNameDP .form-control {
	width: 290px;
	clear: none;
	float: left;
}
.chooseUserForm > ul > li .smartGroup .form-control {
	width: 100%;  
	margin-bottom:15px;
}
.chooseUserForm > ul > li .userNameDP > .userDPImg .ChangePicture {
	font-size: 10px;
	color: #b0bcca;
	display: block;
	margin-top: 5px;
}
.chooseUserForm > ul > li.marginBottom10px {
	margin-bottom: 20px;
}
.chooseUserForm > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 30px 10px 0px;
	height: auto;
	background: #f2f2f2 !important;
	color: #0D3968;
	font-size: 20px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
}
.chooseUserForm > ul > li select.form-control {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: #f2f2f2 url("../images/icons/markdownA.svg") no-repeat right center !important;
}
.chooseUserForm > ul > li select::-ms-expand {
	display: none;
}
.chooseUserForm > ul > li > p {
	color: #0D3968;
}
.chooseUserForm > ul > li .input-group {
	width: 100%;
	position: relative;
}
.chooseUserForm > ul > li .input-group .requiredField {
	width: 26px;
	height: 26px;
	text-align: center;
	position: absolute;
	top: 5px;
	right: 0px;
	z-index: 3;
	color: #b8c3cf;
	font-size: 28px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Assign to Brands Table ****** *
** ****** ****** ****** ****** ****** */

.assigntoBrands {
	width: 100%;
	display: table;
	margin-top: 15px;
}
.assigntoBrands > ul {
	width: 100%;
	display: table-row;
}
.assigntoBrands > ul.tHead > li {
	color: #66809c;
	border-bottom: 1px solid #65809d;
	background: #e9eaeb;
}
.assigntoBrands > ul.tHead > li .customCheckbox {
	margin: 0px 0px;
	display: inline-block;
	width: 24px;
	height: 24px;
}
.assigntoBrands > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	color: #16406d;
	font-size: 16px;
	text-align: left;
}
.assigntoBrands > ul > li:nth-child(1) {
	text-align: left;
	color: #6e87a2;
	padding-left: 64px;
}
.assigntoBrands > ul > li:nth-child(2) {
	width: 55px;
	text-align: center;
	padding-right: 20px;
}
.assigntoBrands > ul > li .customCheckbox {
	margin: 10px 0px;
	display: inline-block;
	width: 30%;
}
.assigntoBrands > ul > li .customCheckbox label {
	color: #0D3968;
	font-size: 16px;
	text-transform: uppercase;
}
.assigntoBrands > ul > li .customCheckbox.customCheckboxWOlabel input[type=checkbox] + label {
	padding-left: 34px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Email Report Configuration ****** *
** ****** ****** ****** ****** ****** */

.assigntoEmailReport {
	width: 100%;
	display: table;
}
.assigntoEmailReport > ul {
	width: 100%;
	display: table-row;
}
.assigntoEmailReport > ul.tHead > li {
	color: #66809c;
	border-bottom: 1px solid #65809d;
	background: #e9eaeb;
}
.assigntoEmailReport > ul.tHead > li .customCheckbox {
	margin: 0px 0px;
	display: inline-block;
	width: 24px;
	height: 24px;
}
.assigntoEmailReport > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	color: #16406d;
	font-size: 16px;
	text-align: left;
}
.assigntoEmailReport > ul > li:nth-child(1) {
	text-align: left;
	color: #6e87a2;
	padding-left: 64px;
}
.assigntoEmailReport > ul > li .customCheckbox {
	margin: 10px 0px;
	display: inline-block;
	width: 25%;
}
.assigntoEmailReport > ul > li .customCheckbox label {
	color: #0D3968;
	font-size: 16px;
}
.assigntoEmailReport > ul > li .customCheckbox.customCheckboxWOlabel input[type=checkbox] + label {
	padding-left: 34px;
}
/* ****** ****** ****** ****** ****** **
 * ****** User Role Assign Table ****** *
** ****** ****** ****** ****** ****** */

.userRoleVETable {
	width: 100%;
	display: table;
	margin: 35px 0px 25px;
}
.userRoleVETable > ul {
	width: 100%;
	display: table-row;
}
.userRoleVETable > ul.tHead > li {
	color: #66809c;
	border-bottom: 1px solid #65809d;
	background: #e9eaeb;
}
.userRoleVETable > ul.tHead > li:nth-child(2) {
	font-family: 'open_sansregular';
}
.userRoleVETable > ul.tHead > li .customCheckbox {
	margin: 0px 0px;
	display: inline-block;
	width: 24px;
	height: 24px;
}
.userRoleVETable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 16px;
	text-align: left;
}
.userRoleVETable > ul.tBody > li:nth-child(1) {
	border-bottom: 1px solid #f2f2f2;
}
.userRoleVETable > ul > li:nth-child(1) {
	text-align: center;
	color: #16406d;
	width: 54px;
}
.userRoleVETable > ul > li:nth-child(2) {
	font-family: 'open_sanssemibold';
}
.userRoleVETable > ul > li:nth-child(4),
.userRoleVETable > ul > li:nth-child(6) {
	width: 60px;
	text-align: center;
}
.userRoleVETable > ul > li:nth-child(3),
.userRoleVETable > ul > li:nth-child(5) {
	width: 35px;
	text-align: right;
	padding-left: 0px;
	padding-right: 0px;
}
.userRoleVETable > ul > li .customCheckbox {
	margin: 0px 0px;
	display: inline-block;
	width: 24px;
	height: 24px;
}
.userRoleVETable > ul > li .customCheckbox label {
	padding-left: 24px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Assigned Unit Groups Table ****** *
** ****** ****** ****** ****** ****** */

.assignedUnitGroupsTableDiv {
	width: 100%;
	float: left;
	padding: 40px 64px;
}
.assignedUnitGrpsTableOuter {
	float: left;
	width: 560px;
	padding: 0px;
}
.assignedUnitGrpsTable {
	width: 100%;
	display: table;
}
.assignedUnitGrpsTable > ul {
	width: 100%;
	display: table-row;
}
.assignedUnitGrpsTable > ul.tBody li {
	padding: 5px 10px;
}
.assignedUnitGrpsTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #0d3968;
	color: #0D3968;
	font-size: 16px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.assignedUnitGrpsTable > ul > li .spanIndent01 {
	width: 24px;
	float: left;
}
.assignedUnitGrpsTable > ul > li a:hover {
	color: #C8102E;
	text-decoration: none;
}
.assignedUnitGrpsTable > ul > li a {
	color: #333F48;
}
.assignedUnitGrpsTable > ul > li:nth-child(1) {
	text-align: left;
}
.assignedUnitGrpsTable > ul > li:nth-child(2) {
	width: 60px;
	text-align: right;
}
.panel-body.assignedUnitGrpsTable {
	padding: 0px;
	margin: 0px;
	border: 0px;
}
.panel-body.assignedUnitGrpsTable > ul.tBody li {
	border-bottom: 1px solid #acbac8;
}
.collapseExpand.collapsed,
.collapseExpand.collapsed:hover,
.collapseExpand.collapsed:focus,
.collapseExpand.collapsed:active {
	background: url("../images/icons/arrow2rA.svg") no-repeat center center;
	background-size: 24px 24px;
	width: 24px;
	height: 24px;
	border: 0px;
	float: left;
}
.collapseExpand,
.collapseExpand:hover,
.collapseExpand:focus,
.collapseExpand:active {
	background: url("../images/icons/markdownA.svg") no-repeat center center;
	background-size: 24px 24px;
	width: 24px;
	height: 24px;
	border: 0px;
	float: left;
}
/* ****** ****** ****** ****** ****** **
 * ****** Notification Settings Table ****** *
** ****** ****** ****** ****** ****** */

.notificationTypeForm {
	width: 100%;
	float: left;
	padding: 0px 64px;
}
.NotifnMgmtTable {
	width: 100%;
	display: table;
	margin: 15px 0px 15px;
}
.NotifnMgmtTable > ul {
	width: 100%;
	display: table-row;
}
.NotifnMgmtTable > ul.tHead > li {
	color: #68839f;
	border-bottom: 1px solid #0d3968;
}
.NotifnMgmtTable > ul.tHead > li:nth-child(1) {
	font-family: 'open_sanssemibold';
	padding-left: 0px;
	color: #0d3968;
}
.NotifnMgmtTable > ul.tHead > li .customCheckbox {
	margin: 0px 0px;
	display: inline-block;
	width: 24px;
	height: 24px;
}
.NotifnMgmtTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
}
.NotifnMgmtTable > ul > li:nth-child(1) {
	padding-left: 0px;
}
.NotifnMgmtTable > ul > li:nth-child(2) {
	width: 250px;
	text-align: left;
}
.NotifnMgmtTable > ul > li:nth-child(3) {
	width: 120px;
	text-align: right;
	padding-right: 0px;
}
.NotifnMgmtTable > ul > li:nth-child(4) {
	width: 35px;
	text-align: center;
	padding-right: 0px;
}
.NotifnMgmtTable > ul > li:nth-child(5) {
	width: 120px;
	text-align: right;
	padding-right: 0px;
}
.NotifnMgmtTable > ul > li:nth-child(6) {
	width: 35px;
	text-align: center;
	padding-right: 0px;
}
.NotifnMgmtTable > ul > li .customCheckbox {
	margin: 0px 0px;
	display: inline-block;
	width: 24px;
	height: 24px;
}
.NotifnMgmtTable > ul > li .customCheckbox label {
	padding-left: 24px;
}
.NotifnMgmtTable > ul > li .infoModalView {
	width: 24px;
	height: 24px;
	display: inline-block;
	background: url("../images/icons/Information2S.png") no-repeat left top;
	background-size: 24px 24px;
	margin-left: 5px;
}
.NotifnMgmtTable > ul > li .infoModalView:hover {
	background: url("../images/icons/Information2A.png") no-repeat left top;
	background-size: 24px 24px;
}
.NotifnMgmtTable > ul > li .input-group {
	width: 160px;
	display: inline-block;
}
.NotifnMgmtTable > ul > li .inputGroupLabel {
	float: right;
	padding: 10px 0px 0px;
}
.NotifnMgmtTable > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 30px 10px 0px;
	height: auto;
	background: #f2f2f2 !important;
	color: #0D3968;
	font-size: 16px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
}
.NotifnMgmtTable > ul > li select.form-control {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: #f2f2f2 url("../images/icons/markdownA.svg") no-repeat right center !important;
}
.notificationTypeForm .seveBtn {
	color: #0d3968;
	background: #bccddc;
	border: 0;
	border-radius: 0;
	padding: 10px 15px;
	font-family: open_sanssemibold;
	margin-top: 15px;
}
/* ****** ****** ****** ****** ****** **
 * ****** My Information Table ****** *
** ****** ****** ****** ****** ****** */

.myInformationForm {
	width: 100%;
	float: left;
	padding: 0px 64px;
}
.myInformationTable {
	width: 100%;
	display: table;
	margin: 15px 0px 15px;
}
.myInformationTable > ul {
	width: 100%;
	display: table-row;
}
.myInformationTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: top;
	color: #0d3968;
	font-size: 16px;
	text-align: left;
}
.myInformationTable > ul > li .addUserForm {
	width: 100%;
	float: left;
	clear: both;
	margin: 0px;
}
.myInformationTable > ul > li .addUserForm > ul {
	padding: 0px 15px 0px 0px;
}
.myInformationTable > ul:first-child > li {
	vertical-align: middle;
}
.myInformationTable > ul > li h3 {
	padding: 0px;
	color: #0d3968;
	font-size: 20px;
	padding: 0px;
	margin: 0px 0px 10px;
	font-family: 'open_sanssemibold';
}
.myInformationTable > ul > li h4 {
	padding: 0px;
	color: #0d3968;
	font-size: 16px;
	padding: 0px;
	margin: 0px 0px 10px;
}
.myInformationTable > ul > li h5 {
	padding: 0px;
	color: #0d3968;
	font-size: 16px;
	padding: 0px;
	margin: 0px 0px 10px;
}
.myInformationTable > ul > li:nth-child(1) {
	padding-left: 0px;
	width: 84px;
}
.myInformationTable > ul > li:nth-child(2) {
	width: 25%;
}
.myInformationTable.editInfoTable > ul > li:nth-child(2) {
	width: 45%;
}
.myInformationTable.editInfoTable > ul > li:nth-child(3) {
	width: 400px;
}
.myInformationTable > ul > li:nth-child(1) > h4 {
	margin-bottom: 0px;
}
.myInformationTable > ul > li .userProfileImg {
	width: 64px;
	height: 64px;
	display: block;
	border-radius: 50%;
	background: #ff8a8a;
	text-align: center;
}
.myInformationTable > ul > li .userProfileImg > img {
	max-width: 60px;
	width: 100%;
}
.myInformationTable > ul > li .ChangePicture {
	font-size: 10px;
	color: #b0bcca;
} 
/*** **************  ************** ****
		*** Software Management Wrapper  ***
**** **************  ************** ***/

.softwareUpdateWrapper {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both; 
}
/*** **************  ************** ****
		*** Software Management Header ***
**** **************  ************** ***/

.softwareUpdateHeader {
	width: 100%;
	padding: 0px;
	display: table;
	border-bottom: 1px solid #7e8690;
}
.softwareUpdateHeader > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.softwareUpdateHeader > ul > li {
	padding: 0px 0px;
	display: table-cell;
	color: #96a8bb;
	vertical-align: middle;
}
.softwareUpdateHeader > ul > li a {
	color: #96a8bb;
	margin-right: 20px;
	padding: 18px 15px 19px;
	font-size: 18px;
	display: inline-block;
	font-family: 'open_sanssemibold';
}
.softwareUpdateHeader > ul > li > a:hover,
.softwareUpdateHeader > ul > li > a.active {
	color: #0d3968;
}
.softwareUpdateHeader > ul > li:nth-child(2) {
	text-align:right; 
}
.softwareUpdateHeader > ul > li .add,
.softwareUpdateHeader > ul > li .transfertoSG, 
.softwareUpdateHeader > ul > li .edit,
.softwareUpdateHeader > ul > li .delete,
.softwareUpdateHeader > ul > li .listV {
	width: 24px;
	height: 24px;
	margin-right:10px;
}  
.softwareUpdateHeader > ul > li .add {
	background: url("../images/icons/plusA.svg") no-repeat center center;
	background-size: 24px 24px;
}  
.softwareUpdateHeader > ul > li .add.disabled {
	background: url("../images/icons/plusD.svg") no-repeat center center;
	background-size: 24px 24px;
} 
.softwareUpdateHeader > ul > li .transfertoSG {
	background: url("../images/icons/assignDeviceT.svg") no-repeat center center;
	background-size: 22px 22px;
}
.softwareUpdateHeader > ul > li .transfertoSG.disabled {
	background: url("../images/icons/assignDeviceD.svg") no-repeat center center;
	background-size: 22px 22px;
} 
.softwareUpdateHeader > ul > li .edit {
	background: url("../images/icons/penB.svg") no-repeat center center;
	background-size: 24px 24px;
} 
.softwareUpdateHeader > ul > li .edit.disabled {
    background: url("../images/icons/penD.svg") no-repeat center center;
    background-size: 24px 24px;
} 
.softwareUpdateHeader > ul > li .delete {
	background: url("../images/icons/trashB.svg") no-repeat center center;
	background-size: 24px 24px;
} 
.softwareUpdateHeader > ul > li .delete.disabled {
    background: url("../images/icons/trashC.svg") no-repeat center center;
    background-size: 24px 24px;
}
.softwareUpdateHeader > ul > li .listV {
	background: url("../images/icons/ListviewDA.svg") no-repeat center center;
	background-size: 24px 24px; 
}  
.softwareUpdateHeader > ul > li .selectedBrand { 
	padding-right:15px;
} 
.softwareUpdateHeader > ul > li .selectedBrand > img { 
	height:22px;
}  
/*** **************  ************** ****
		*** C2C API Header ***
**** **************  ************** ***/

.swaggerHeader {
	width: 100%;
	padding: 0px;
	display: table;
	border-bottom: 1px solid #7e8690;
}
.swaggerHeader > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.swaggerHeader > ul > li {
	padding: 0px 0px;
	display: table-cell;
	color: #96a8bb;
	vertical-align: middle;
}
.swaggerHeader > ul > li:nth-child(2) {
	text-align: right;
	padding-right: 15px;
}
.swaggerHeader > ul > li a {
	color: #96a8bb;
	margin-right: 20px;
	padding: 18px 15px 19px;
	font-size: 18px;
	display: inline-block;
	font-family: 'open_sanssemibold';
}
.swaggerHeader > ul > li > a:hover,
.swaggerHeader > ul > li > a.active {
	color: #0d3968;
}
.swaggerHeader > ul > li h5,
.swaggerHeader > ul > li h6 {
	font-size: 14px;
	color: #878787;
	display: inline-block;
	margin-left: 10px;
	font-family: 'open_sansregular';
	font-weight: normal;
}
.swaggerHeader > ul > li h5 > span,
.swaggerHeader > ul > li h6 span {
	color: #fff;
	background-color: #343a40;
	padding: 3px 10px;
	border-radius: 4px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Available Updates Table ****** *
** ****** ****** ****** ****** ****** */
.availableUpdatesTableOuter {
	width: 100%;
	float: left;
	padding: 0px;
}
.availableUpdatesTableOuter .panel-collapse {
	background: #ffffff;
}
.availableUpdatesTable {
	width: 100%;
	display: table;
}
.availableUpdatesTable > ul {
	width: 100%;
	display: table-row;
}
.availableUpdatesTable > ul.tHead li {
	color: #657f9c;
	background: #E9EAEB;
	border-bottom: 1px solid #607488;
}
.availableUpdatesTable > ul.tHead .active {
	color: #153f6d;
} 
.availableUpdatesTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
}
.availableUpdatesTable > ul > li a {
	color: #2dbaff;
}
.availableUpdatesTable > ul.tBody > li:nth-child(1) {
	border-bottom: 1px solid #f2f2f2;
}
.availableUpdatesTable > ul > li:nth-child(1) {
	width: 44px;
	text-align: center;
}
.availableUpdatesTable > ul > li:nth-child(2) { 
	padding-left: 0px;
	text-align: left; 
}  
.availableUpdatesTable > ul > li:nth-child(3) { 
	width: 160px; 
	text-align: center;
} 
.availableUpdatesTable > ul > li:nth-child(4),
.availableUpdatesTable > ul > li:nth-child(5) {
	width: 25%;
} 
.availableUpdatesTable > ul > li:nth-child(6) {
	width: 45px;
	text-align: center;
	padding-right: 0px;
	padding-left:0px;
}
.availableUpdatesTable > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.availableUpdatesTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}
.availableUpdatesTable > ul > li .badge.badge-secondary {
    background:none;
	color: #0D3968;
    padding:3px 3px;
    font-size: 12px;
    display: inline-block; 
}
.availableUpdatesTable > ul.tBody > li .SWUpdateSM,
.availableUpdatesTable > ul.tBody > li .SWUpdateSMVD {
	display: none;
	width: 100%;
	float: left;
}
.availableUpdatesTable > ul.tBody > li .SWUpdateSMVD > b {
	font-weight: normal;
}
.panel-body.availableUpdatesTable {
	padding: 0px;
	margin: 0px;
	border: 0px;
}
.panel-body.availableUpdatesTable > ul.tBody li {
	border-bottom: 1px solid #acbac8;
}
.panel-body.subGrpTable.availableUpdatesTable > ul.tBody li {
	padding-left: 34px;
} 
.avlUpdatesExpTable {
	width: 100%;
	display: table;
}
.avlUpdatesExpTable > ul {
	width: 100%;
	display: table-row;
} 
.avlUpdatesExpTable > ul.tHead .active {
	color: #153f6d;
}
.avlUpdatesExpTable > ul.tBody li {
	padding: 15px 10px;
}
.avlUpdatesExpTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: top;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
}
.avlUpdatesExpTable > ul > li a {
	color: #2dbaff;
}
.avlUpdatesExpTable > ul.tBody > li:nth-child(1) {
	border-bottom: 1px solid #f2f2f2;
}
.avlUpdatesExpTable > ul > li:nth-child(1) {
	width: 44px;
	text-align: center;
}
.avlUpdatesExpTable > ul > li:nth-child(2) {
	width: 220px;
	padding-left: 0px;
	text-align: left;
}
.avlUpdatesExpTable > ul > li:nth-child(2) > img {
	height: 24px;
	max-width: 100%;
}
.avlUpdatesExpTable > ul > li:nth-child(2) > b,
.avlUpdatesExpTable > ul > li:nth-child(3) > b,
.avlUpdatesExpTable > ul > li:nth-child(4) > b {
	clear: both;
	font-weight: normal;
	display: block;
	font-family: 'open_sanssemibold';
} 
.avlUpdatesExpTable > ul > li:nth-child(4) {
	width: 240px;
} 
.avlUpdatesExpTable > ul.tBody > li .SWUpdateSM {
	display: none;
	width: 100%;
	float: left;
}
/* ****** ****** ****** ****** ****** **
 * ****** Update Status Table ****** *
** ****** ****** ****** ****** ****** */
 
.updateStatusTableOuter {
	width: 100%;
	float: left;
	padding: 0px;
}
.updateStatusTableOuter .panel-collapse {
	background: #ffffff;
}
.updateStatusTable {
	width: 100%;
	display: table;
}
.updateStatusTable > ul {
	width: 100%;
	display: table-row;
}
.updateStatusTable > ul.tHead li {
	background: #E9EAEB;
	border-bottom: 1px solid #607488;
}
.updateStatusTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
}
.updateStatusTable > ul > li a:hover {
	color: #C8102E;
	text-decoration: none;
}
.updateStatusTable > ul > li a {
	color: #333F48;
}
.updateStatusTable > ul.tBody > li:nth-child(1) {
	border-bottom: 1px solid #f2f2f2;
}
.updateStatusTable > ul > li:nth-child(1) {
	width: 54px;
	text-align: center;
	padding-left:5px;
	padding-right:5px;
}
.updateStatusTable > ul > li:nth-child(2) {
	padding-left: 0px;
	text-align: left;
}
.updateStatusTable > ul > li:nth-child(3),
.updateStatusTable > ul > li:nth-child(4),
.updateStatusTable > ul > li:nth-child(5),
.updateStatusTable > ul > li:nth-child(6) {
	width: 180px;
}
.updateStatusTable > ul > li:nth-child(7) {
	width: 100px;  
}
.updateStatusTable > ul > li:nth-child(8) {
	width: 30px;
	text-align: center;
	padding-left:0px;
	padding-right:0px;
}
.updateStatusTable > ul > li:nth-child(8) > img {
	width: 24px;
	height: 24px;
}
.updateStatusTable > ul > li:nth-child(9) {
	width: 120px;
} 
.updateStatusTable > ul > li:nth-child(10) {
	width: 58px;
	text-align: center;
}
.updateStatusTable > ul.tBody > li .updateStatusSM,
.updateStatusTable > ul.tBody > li .updateStatusSB {
	display: none;
	width: 100%;
	float: left;
}
.updateStatusTable > ul.tBody > li .updateStatusSB {
	margin-top: 5px;
}
.updateStatusTable > ul > li .progress {
	height: 8px;
	border-radius: 4px;
	background: #d8d8d8;
}
.updateStatusTable > ul > li .progress .updatingSW {
	background: #0aaeff;
}
.updateStatusTable > ul > li .progress .pendingSW{
	background: #d8d8d8;
}
.updateStatusTable > ul > li .progress .warningStatus {
	background: #f93251;
}
.updateStatusTable > ul > li .progress .cancelStatus{ 
	background:#6e809f;
} 
.updateStatusTable > ul > li .progress .completedStatus {
	background: #3cce82;
}
/* ****** ****** ****** ****** ****** **
 * ****** Media Status Table ****** *
** ****** ****** ****** ****** ****** */
 
.mediaStatusOuter {
	width: 100%;
	float: left;
	padding: 0px;
}
.mediaStatusOuter .panel-collapse {
	background: #ffffff;
}
.mediaStatus {
	width: 100%;
	display: table;
}
.mediaStatus > ul {
	width: 100%;
	display: table-row;
}
.mediaStatus > ul.tHead li {
	background: #E9EAEB;
	border-bottom: 1px solid #607488;
}
.mediaStatus > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
}
.mediaStatus > ul > li a:hover {
	color: #C8102E;
	text-decoration: none;
}
.mediaStatus > ul > li a {
	color: #333F48;
}
.mediaStatus > ul.tBody > li:nth-child(1) {
	border-bottom: 1px solid #f2f2f2;
}
.mediaStatus > ul > li:nth-child(1) {
	width: 54px;
	text-align: center;
	padding-left:5px;
	padding-right:5px;
}
.mediaStatus > ul > li:nth-child(2) {
	padding-left: 0px;
	text-align: left;
}
.mediaStatus > ul > li:nth-child(3),
.mediaStatus > ul > li:nth-child(4),
.mediaStatus > ul > li:nth-child(5) {
	width: 220px;
} 
.mediaStatus > ul > li:nth-child(6) {
	width: 30px;
	text-align: center;
	padding-left:0px;
	padding-right:0px;
}
.mediaStatus > ul > li:nth-child(6) > img {
	width: 24px;
	height: 24px;
}
.mediaStatus > ul > li:nth-child(7) {
	width: 180px;
} 
.mediaStatus > ul > li:nth-child(8) {
	width: 58px;
	text-align: center;
}
.mediaStatus > ul.tBody > li .updateStatusSM,
.mediaStatus > ul.tBody > li .updateStatusSB {
	display: none;
	width: 100%;
	float: left;
}
.mediaStatus > ul.tBody > li .updateStatusSB {
	margin-top: 5px;
}
.mediaStatus > ul > li .progress {
	height: 8px;
	border-radius: 4px;
	background: #d8d8d8;
}
.mediaStatus > ul > li .progress .updatingSW {
	background: #0aaeff;
}
.mediaStatus > ul > li .progress .pendingSW{
	background: #d8d8d8;
}
.mediaStatus > ul > li .progress .warningStatus {
	background: #f93251;
}
.mediaStatus > ul > li .progress .cancelStatus{ 
	background:#6e809f;
} 
.mediaStatus > ul > li .progress .completedStatus {
	background: #3cce82;
}
/* ****** ****** ****** ****** ****** **
 * ****** Update Status Expanded Table ****** *
** ****** ****** ****** ****** ****** */

.updateStatusExpTable {
	width: 100%;
	display: table;
}
.updateStatusExpTable > ul {
	width: 100%;
	display: table-row;
}
.updateStatusExpTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
}
.updateStatusExpTable > ul > li a:hover {
	color: #C8102E;
	text-decoration: none;
}
.updateStatusExpTable > ul > li a {
	color: #333F48;
}
.updateStatusExpTable > ul.tBody > li:nth-child(1) {
	border-bottom: 1px solid #f2f2f2;
}
.updateStatusExpTable > ul > li:nth-child(1) {
	width: 54px;
	text-align: center;
	padding-left:5px;
	padding-right:5px;
}
.updateStatusExpTable > ul > li:nth-child(2) { 
	padding-left:0px; 
}
.updateStatusExpTable > ul > li h4 {
	color: #0D3968;
	font-size: 16px;
	font-weight: normal;
	margin: 0px 0px 5px;
	font-family: 'open_sanssemibold';
}
.updateStatusExpTable > ul > li h4 > span {
	color: #748da8;
	margin-left: 10px;
}
.updateStatusExpTable > ul > li h5 {
	color: #0D3968;
	font-size: 14px;
	font-weight: normal;
}
.updateStatusExpTable > ul > li h5 > span {
	color: #748da8;
	margin-left: 10px;
}
/* ****** ****** ****** ****** ****** **
 * ****** HACCP Update Status Table ****** *
** ****** ****** ****** ****** ****** */

.haccpUpdateStatusTableOuter {
	width: 100%;
	float: left;
	padding: 0px;
}
.haccpUpdateStatusTable {
	width: 100%;
	display: table;
}
.haccpUpdateStatusTable > ul {
	width: 100%;
	display: table-row;
}
.haccpUpdateStatusTable > ul.tHead li {
	background: #E9EAEB;
	border-bottom: 1px solid #607488;
}
.haccpUpdateStatusTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
}
.haccpUpdateStatusTable > ul > li a:hover {
	color: #C8102E;
	text-decoration: none;
}
.haccpUpdateStatusTable > ul > li a {
	color: #333F48;
}
.haccpUpdateStatusTable > ul.tBody > li:nth-child(1) {
	border-bottom: 1px solid #f2f2f2;
}
.haccpUpdateStatusTable > ul > li:nth-child(1) {
	width: 54px;
	text-align: center;
	padding-left:5px;
	padding-right:5px;
}
.haccpUpdateStatusTable > ul > li:nth-child(2) {
	padding-left: 0px;
	text-align: left;
}
.haccpUpdateStatusTable > ul > li:nth-child(3) {
	width: 180px;
}
.haccpUpdateStatusTable > ul > li:nth-child(4) {
	width: 220px;
}
.haccpUpdateStatusTable > ul > li:nth-child(5) {
	width: 220px; 
}
.haccpUpdateStatusTable > ul > li:nth-child(6) {
	width: 180px;
}
.haccpUpdateStatusTable > ul > li:nth-child(7) {
	width: 120px;
}
.haccpUpdateStatusTable > ul.tBody > li .updateStatusSM,
.haccpUpdateStatusTable > ul.tBody > li .updateStatusSB {
	display: none;
	width: 100%;
	float: left;
}
.haccpUpdateStatusTable > ul.tBody > li .updateStatusSB {
	margin-top: 5px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Menu Update Status Expanded Table ****** *
** ****** ****** ****** ****** ****** */

.menuUpdateStatusExpTable {
	width: 100%;
	display: table;
}
.menuUpdateStatusExpTable > ul {
	width: 100%;
	display: table-row;
}
.menuUpdateStatusExpTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
} 
.menuUpdateStatusExpTable > ul > li a {
	color: #333F48;
}
.menuUpdateStatusExpTable > ul.tBody > li:nth-child(1) {
	border-bottom: 1px solid #f2f2f2;
}
.menuUpdateStatusExpTable > ul > li:nth-child(1) {
	width: 54px;
	text-align: center;
	padding-left:5px;
	padding-right:5px;
}
.menuUpdateStatusExpTable > ul > li:nth-child(2) {
	padding-left: 0px;
}
.menuUpdateStatusExpTable > ul > li:nth-child(3) {
	width: 250px;
	text-align: center;
}
.menuUpdateStatusExpTable > ul > li .btn {
	background: #195d99;
	color: #ffffff;
	min-width: 80px;
}
.menuUpdateStatusExpTable > ul > li .btn-skip {
	background: #e8ecf1;
	color: #195d99;
	min-width: 80px;
	border-radius: 20px;
	border: 0px;
	font-size: 14px;
	padding: 6px 16px;
}
.menuUpdateStatusExpTable .menuUpdateExpTable {
	width: 100%;
	display: table;
}
.menuUpdateStatusExpTable .menuUpdateExpTable > ul {
	width: 100%;
	display: table-row;
}
.menuUpdateStatusExpTable .menuUpdateExpTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
}
.menuUpdateStatusExpTable .menuUpdateExpTable > ul > li:nth-child(1) {
	width: 54px;
	padding-left: 0px;
	text-align: center;
}
.menuUpdateStatusExpTable .menuUpdateExpTable > ul > li h4,
.menuUpdateStatusExpTable .menuUpdateExpTable > ul > li h5 {
	color: #0D3968;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 14px;
}
.menuUpdateStatusExpTable .menuUpdateExpTable > ul > li h4 {
	margin: 0px 0px 5px;
	font-family: 'open_sanssemibold';
}
.menuUpdateStatusExpTable .menuUpdateExpTable > ul > li h5 > span {
	color: #748da8;
	margin-left: 10px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Upload History Expanded Table ****** *
** ****** ****** ****** ****** ****** */

.uploadHistoryExpTable {
	width: 100%;
	display: table;
}
.uploadHistoryExpTable > ul {
	width: 100%;
	display: table-row;
}
.uploadHistoryExpTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
}
.uploadHistoryExpTable > ul > li .mediaFileSpan {
	width: 80px;
	height: 80px;
	display: block;
	background: #f2f2f2;
	border: 1px solid #7e8690;
}
.uploadHistoryExpTable > ul > li .mediaFileSpan > img {
	width: 100%;
}
.uploadHistoryExpTable > ul > li h4,
.uploadHistoryExpTable > ul > li h5,
.uploadHistoryExpTable > ul > li h6 {
	font-size: 14px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.uploadHistoryExpTable > ul > li h4 {
	color: #0D3968;
	font-family: 'open_sanssemibold';
}
.uploadHistoryExpTable > ul > li h5 {
	color: #0D3968;
}
.uploadHistoryExpTable > ul > li h6 {
	color: #748da8;
}
.uploadHistoryExpTable > ul > li .btn {
	background: #195d99;
	color: #ffffff;
	min-width: 80px;
	margin-top: 10px;
}
.uploadHistoryExpTable > ul > li .btn-skip {
	background: #e8ecf1;
	color: #195d99;
	min-width: 80px;
	margin-top: 10px;
	border-radius: 20px;
	border: 0px;
	font-size: 14px;
	padding: 6px 16px;
}
.uploadHistoryExpTable > ul > li a:hover {
	text-decoration: none;
}
.uploadHistoryExpTable > ul > li a {
	color: #333F48;
}
.uploadHistoryExpTable > ul.tBody > li:nth-child(1) {
	border-bottom: 1px solid #f2f2f2;
}
.uploadHistoryExpTable > ul > li:nth-child(1) {
	width: 54px;
	text-align: center;
	padding-left:5px;
	padding-right:5px;
}
.uploadHistoryExpTable > ul > li:nth-child(2),
.uploadHistoryExpTable > ul > li:nth-child(4) {
	padding: 0px;
	width: 80px;
}
.uploadHistoryExpTable > ul > li:nth-child(3) {
	width: 260px;
}
.uploadHistoryExpTable > ul > li:nth-child(6) {
	width: 260px;
}
div.uploadHistoryExpTable > ul.collapse.show {
	display: table-row;
}
/*** **************  ************** ****
		*** Media Wrapper  ***
**** **************  ************** ***/

.mediaWrapper {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both; 
}
.mediaWrapper.assignOrgTreeWrapper {
	padding-top: 0px;
}
/*** **************  ************** ****
		*** Assign Media Header ***
**** **************  ************** ***/

.assignMediaHeader {
	width: 100%;
	padding: 0px;
	display: table;
	background: #f2f2f2;
	border-bottom: 1px solid #7e8690;
}
.assignMediaHeader > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.assignMediaHeader > ul > li {
	padding: 0px 0px;
	display: table-cell;
	color: #6e87a2;
	vertical-align: middle; 
}
.assignMediaHeader > ul > li a {
	color: #6e87a2;
	padding: 21px 0px;
	display: block;
}
.assignMediaHeader > ul > li:nth-child(1) {
	text-align: left;
	width: 64px;
}
.assignMediaHeader > ul > li:nth-child(2) {
	text-align: center;
	font-size: 20px;
	color: #0d3968;
	font-family: 'open_sanssemibold';
	padding-left: 120px;
}
.assignMediaHeader > ul > li:nth-child(3) {
	text-align: right;
	width: 120px;
	font-size: 16px;
	padding-right: 15px;
}
.assignMediaHeader > ul > li:nth-child(3) a {
	color: #18426f;
}
.assignMediaHeader > ul > li > a:hover {
	color: #000000;
}
.assignMediaHeader > ul > li .backIcon {
	width: 64px;
	height: 64px;
	display: block;
	background: url("../images/icons/back.png") no-repeat center center;
	background-size: 36px 36px;
}
/*** **************  ************** ****
		*** Menu &amp; Unit Settings Header ***
**** **************  ************** ***/

.mediaHeader {
	width: 100%;
	padding: 0px;
	display: table;
	border-bottom: 1px solid #7e8690; 
	background: #f2f2f2; 
}
.mediaHeader > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.mediaHeader > ul > li {
	padding: 18px 15px 19px;
	display: table-cell;
	color: #0D3968;
	vertical-align: middle;
	font-size: 18px;
	font-family: 'open_sanssemibold';
}
.mediaHeader > ul > li a {
	color: #96a8bb;
}
.mediaHeader > ul > li > a.active,
.mediaHeader > ul > li > a:hover {
	color: #0D3968;
}
.mediaHeader > ul > li:nth-child(1) {
	text-align: left;
	width: 150px;
}
.mediaHeader > ul > li:nth-child(2) {
	text-align: left;
	width: 150px;
}
.mediaHeader > ul > li:nth-child(3) {
	text-align: left;
	width: 150px;
}
.mediaHeader > ul > li:nth-child(4) {
	text-align: left;
	width: 150px;
}
.mediaHeader.mediamclibraryH > ul > li:nth-child(3) {
	text-align: right;
	width: auto;
}
.mediaHeader > ul > li:nth-child(5) {
	text-align: right;
} 
.mediaHeader > ul > li .btn {
	margin-left: 10px;
}
.mediaHeader > ul > li .btn.disabled {
	color: #bcc3cf;
} 
.mediaHeader > ul > li .referesh,
.mediaHeader > ul > li .download,
.mediaHeader > ul > li .assignMedia,
.mediaHeader > ul > li .rowDuplicate,
.mediaHeader > ul > li .rowEdit,
.mediaHeader > ul > li .rowDelete,
.mediaHeader > ul > li .rowAdd {
	width: 24px;
	height: 24px;
}  
.mediaHeader > ul > li .download {
	background: url("../images/icons/downloadA.png") no-repeat center center;
	background-size: 24px 24px;
}
.mediaHeader > ul > li .download.disabled {
	background: url("../images/icons/downloadD.png") no-repeat center center;
	background-size: 24px 24px;
}
.mediaHeader > ul > li .referesh {
	background: url("../images/icons/refresh.svg") no-repeat center center;
	background-size: 22px 22px;
}
.mediaHeader > ul > li .referesh.disabled {
	background: url("../images/icons/refreshD.svg") no-repeat center center;
	background-size: 22px 22px;
}
.mediaHeader > ul > li .assignMedia {
	background: url("../images/icons/assignDeviceT.svg") no-repeat center center;
	background-size: 22px 22px;
}
.mediaHeader > ul > li .assignMedia.disabled {
	background: url("../images/icons/assignDeviceD.svg") no-repeat center center;
	background-size: 22px 22px;
}
.mediaHeader > ul > li .rowDuplicate {
	background: url("../images/icons/copyE.svg") no-repeat center center;
	background-size: 24px 24px;
}
.mediaHeader > ul > li .rowDuplicate.disabled {
	background: url("../images/icons/copyD.svg") no-repeat center center;
	background-size: 24px 24px;
}
.mediaHeader > ul > li .rowEdit {
	background: url("../images/icons/penB.svg") no-repeat center center;
	background-size: 24px 24px;
}
.mediaHeader > ul > li .rowEdit.disabled {
	background: url("../images/icons/penD.svg") no-repeat center center;
	background-size: 24px 24px;
}
.mediaHeader > ul > li .rowDelete {
	background: url("../images/icons/trashB.svg") no-repeat center center;
	background-size: 24px 24px;
}
.mediaHeader > ul > li .rowDelete.disabled {
	background: url("../images/icons/trashC.svg") no-repeat center center;
	background-size: 24px 24px;
}
.mediaHeader > ul > li .rowAdd {
	background: url("../images/icons/plusA.svg") no-repeat center center;
	background-size: 24px 24px;
}
.mediaHeader > ul > li .rowAdd.disabled {
	background: url("../images/icons/plusD.svg") no-repeat center center;
	background-size: 24px 24px;
}
.mediaUploadImgMp4 {
	display: none;
}
/*** **************  ************** ****
		*** Media upload Header ***
**** **************  ************** ***/

.mediaULHeader {
	width: 100%;
	padding: 0px;
	display: table; 
	background: #f2f2f2;
}
.mediaULHeader > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.mediaULHeader > ul > li {
	display: table-cell;
	color: #0D3968;
	vertical-align: middle;
	font-size: 18px;
	font-family: 'open_sanssemibold';
	border-bottom: 1px solid #7e8690;
}
.mediaULHeader > ul > li.padding3 {
	padding:1rem;
}
.mediaULHeader > ul > li a {
	color: #96a8bb;
	display: inline-block;
	padding: 18px 15px 19px;
	margin-right: 15px;
}
.mediaULHeader > ul > li > a.active,
.mediaULHeader > ul > li > a:hover {
	color: #0D3968;
}
/* ****** ****** ****** ****** ****** **
 * ****** Media All Table ****** *
** ****** ****** ****** ****** ****** */

.mediaAllTable {
	width: 100%;
	display: table;
}
.mediaAllTable > ul {
	width: 100%;
	display: table-row;
}
.mediaAllTable > ul.tHead > li {
	color: #16406d;
	background: #e9eaeb;
	border-bottom: 1px solid #63778a;
}
.mediaAllTable > ul.tHead > li:first-child {
	border-left: 0px;
	border-bottom: 1px solid #63778a;
}
.mediaAllTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
}
.mediaAllTable > ul > li:nth-child(1) {
	width: 54px;
	text-align: center;
	color: #16406d;
	border-bottom: 1px solid #F2F2F2;
	padding-left:5px;
	padding-right:5px;
}
.mediaAllTable > ul > li:nth-child(3) {
	width: 280px;
	padding-left: 0px;
}
.mediaAllTable > ul > li:nth-child(3) > img {
	height: 24px;
	max-width: 260px;
}
.mediaAllTable > ul > li:nth-child(4),
.mediaAllTable > ul > li:nth-child(5),
.mediaAllTable > ul > li:nth-child(6),
.mediaAllTable > ul > li:nth-child(7),
.mediaAllTable > ul > li:nth-child(8) {
	text-align: center;
}
.mediaAllTable > ul.tBody > li:nth-child(4),
.mediaAllTable > ul.tBody > li:nth-child(5),
.mediaAllTable > ul.tBody > li:nth-child(6),
.mediaAllTable > ul.tBody > li:nth-child(7) {
	color: #0D3968;
	font-size: 20px;
}
.mediaAllTable > ul > li:nth-child(8) {
	width: 60px;
}
.mediaAllTable > ul > li .forwardNext {
	background: url("../images/icons/forwardA.svg") no-repeat center center;
	background-size: 40px 40px;
	width: 40px;
	height: 40px;
	display: inline-block;
}
/* ****** ****** ****** ****** ****** **
 * ****** Reports All Table ****** *
** ****** ****** ****** ****** ****** */

.brandsLibraryTable {
	width: 100%;
	display: table;
}
.brandsLibraryTable > ul {
	width: 100%;
	display: table-row;
}
.brandsLibraryTable > ul.tHead > li {
	color: #16406d;
	background: #e9eaeb;
	border-bottom: 1px solid #63778a;
}
.brandsLibraryTable > ul.tHead > li:first-child {
	border-left: 0px;
	border-bottom: 1px solid #63778a;
}
.brandsLibraryTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left; 
}
.brandsLibraryTable > ul > li:nth-child(1) {
	width: 54px;
	text-align: center;
	color: #16406d;
	border-bottom: 1px solid #F2F2F2; 
}
.brandsLibraryTable > ul > li:nth-child(2) { 
	padding-left: 0px;
	width:190px; 
}
.brandsLibraryTable > ul.tBody > li:nth-child(2) {
	text-transform: uppercase;
} 
.brandsLibraryTable > ul > li:nth-child(3) > img {
	height: 24px;
}
.brandsLibraryTable > ul > li.logoH > img {
	height: 34px;
}
.brandsLibraryTable > ul > li:nth-child(4) {
	width: 60px;
}
.brandsLibraryTable > ul > li .forwardNext {
	background: url("../images/icons/forwardA.svg") no-repeat center center;
	background-size: 40px 40px;
	width: 40px;
	height: 40px;
	display: inline-block;
}
/* ****** ****** ****** ****** ****** **
 * ****** Media Preparation Table ****** *
** ****** ****** ****** ****** ****** */

.fileInputField {
	display: none;
} 
.mediamclibraryForm {
	width: 100%;
	float: left;
	clear: both;
}
.mediaListForm {
	width: 100%;
	float: left;
	clear: both;
	padding-bottom: 115px; 
}
.mediaPreparationTable,
.mediaScreensaverTable,
.mediaCompilationTable {
	width: 100%;
	display: table;
}
.mediaPreparationTable > ul,
.mediaScreensaverTable > ul,
.mediaCompilationTable > ul {
	width: 100%;
	display: table-row;
}
.mediaPreparationTable > ul.tHead > li,
.mediaScreensaverTable > ul.tHead > li,
.mediaCompilationTable > ul.tHead > li {
	color: #16406d;
	background: #e9eaeb;
	border-bottom: 1px solid #63778a;
}
.mediaPreparationTable > ul.tHead > li:first-child,
.mediaScreensaverTable > ul.tHead > li:first-child,
.mediaCompilationTable > ul.tHead > li:first-child {
	border-left: 0px;
	border-bottom: 1px solid #63778a;
}
.mediaPreparationTable > ul > li,
.mediaScreensaverTable > ul > li,
.mediaCompilationTable > ul > li {
	display: table-cell;
	padding: 7px 5px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left; 
} 
.mediaPreparationTable > ul > li > a,
.mediaScreensaverTable > ul > li > a,
.mediaCompilationTable > ul > li > a {
	color: #16406d;
}
.mediaPreparationTable > ul > li:nth-child(1),
.mediaScreensaverTable > ul > li:nth-child(1),
.mediaCompilationTable > ul > li:nth-child(1) {
	width: 40px;
	text-align: center;
	color: #16406d;
	border-bottom: 1px solid #F2F2F2;
}
.mediaPreparationTable > ul > li:nth-child(2),
.mediaScreensaverTable > ul > li:nth-child(2),
.mediaCompilationTable > ul > li:nth-child(2) {
	width: 80px;
	padding: 0px;
}
.mediaPreparationTable > ul.tBody > li:nth-child(2) {
	background: #515151;
}
.mediaPreparationTable > ul > li:nth-child(3),
.mediaScreensaverTable > ul > li:nth-child(3),
.mediaCompilationTable > ul > li:nth-child(3) {
	width: 240px;
}
.mediaPreparationTable > ul > li:nth-child(4),
.mediaScreensaverTable > ul > li:nth-child(4),
.mediaCompilationTable > ul > li:nth-child(4) {
	width: 240px;
}
.mediaPreparationTable > ul > li:nth-child(5),
.mediaScreensaverTable > ul > li:nth-child(5),
.mediaCompilationTable > ul > li:nth-child(5) {
	width: 125px;
	padding-left:5px;
	padding-right: 5px;
	text-align: center;
}
.mediaPreparationTable > ul > li:nth-child(6),
.mediaScreensaverTable > ul > li:nth-child(6),
.mediaCompilationTable > ul > li:nth-child(6) {
	width: 110px; 
	padding-left:5px;
	padding-right: 5px;
	text-align: center;
}
.mediaPreparationTable > ul > li:nth-child(7),
.mediaScreensaverTable > ul > li:nth-child(7),
.mediaCompilationTable > ul > li:nth-child(7) {
	width: 70px;
	text-align: center;
}  
.mediaPreparationTable > ul > li:nth-child(9),
.mediaScreensaverTable > ul > li:nth-child(9),
.mediaCompilationTable > ul > li:nth-child(9) {
	width: 130px;   
	padding-left:0px;
	padding-right:0px; 
} 
.mediaPreparationTable > ul > li:nth-child(10),
.mediaScreensaverTable > ul > li:nth-child(10),
.mediaCompilationTable > ul > li:nth-child(10) {
	width: 58px;
	text-align: center; 
}  
.mediaPreparationTable > ul > li .assignedTo,
.mediaScreensaverTable > ul > li .assignedTo,
.mediaCompilationTable > ul > li .assignedTo {
	background: #e9eaeb;
	padding: 3px 25px 3px 3px;
	font-size: 11px;
	display: inline-block;
	margin: 2px;
}
.mediaPreparationTable > ul > li .assignedTo .close,
.mediaScreensaverTable > ul > li .assignedTo .close,
.mediaCompilationTable > ul > li .assignedTo .close {
	width: 20px;
	height: 20px;
	display: inline-block;
	padding: 0px;
}
.mediaPreparationTable > ul > li .mediaPreview,
.mediaScreensaverTable > ul > li .mediaPreview,
.mediaCompilationTable > ul > li .mediaPreview {
	background: url("../images/icons/viewS.svg") no-repeat center center;
	background-size: 24px auto;
	display: inline-block;
	width: 24px;
	height: 24px;
}
.mediaPreparationTable > ul > li .mediaPreview:hover,
.mediaPreparationTable > ul > li .mediaPreview.active,
.mediaScreensaverTable > ul > li .mediaPreview:hover,
.mediaScreensaverTable > ul > li .mediaPreview.active,
.mediaCompilationTable > ul > li .mediaPreview:hover,
.mediaCompilationTable > ul > li .mediaPreview.active {
	background: url("../images/icons/viewB.svg") no-repeat center center;
	background-size: 24px auto;
}
.mediaPreparationTable .assignunitSite,
.mediaScreensaverTable .assignunitSite,
.mediaCompilationTable .assignunitSite {
	width: 24px;
	height: 24px;
	display: inline-block;
	margin-left: 10px; 
	background: url("../images/icons/SidetreeS.svg") no-repeat center center;
	background-size: 24px 24px;
}
.mediaPreparationTable .assignunitSite:hover,
.mediaPreparationTable .assignunitSite.active,
.mediaScreensaverTable .assignunitSite:hover,
.mediaScreensaverTable .assignunitSite.active,
.mediaCompilationTable .assignunitSite:hover,
.mediaCompilationTable .assignunitSite.active {
	background: url("../images/icons/SidetreeDA.svg") no-repeat center center;
	background-size: 24px 24px;
}
.mediaPreparationTable > ul > li .mediaFileSpan,
.mediaScreensaverTable > ul > li .mediaFileSpan,
.mediaCompilationTable > ul > li .mediaFileSpan {
	width: 80px;
	min-height: 80px;
	display: inline-block;
}
.mediaPreparationTable > ul > li .mediaFileSpan > img,
.mediaScreensaverTable > ul > li .mediaFileSpan > video,
.mediaScreensaverTable > ul > li .mediaFileSpan > img,
.mediaScreensaverTable > ul > li .mediaFileSpan > video,
.mediaCompilationTable > ul > li .mediaFileSpan > img,
.mediaCompilationTable > ul > li .mediaFileSpan > video {
	width: 100%;
}
.mediaPreparationTable > ul > li .customCheckbox,
.mediaScreensaverTable > ul > li .customCheckbox,
.mediaCompilationTable > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.mediaPreparationTable > ul > li .customCheckbox label,
.mediaScreensaverTable > ul > li .customCheckbox label,
.mediaCompilationTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}
.mediaPreparationTable > ul.tBody > li .mediaSListSM,
.mediaPreparationTable > ul.tBody > li .mediaSListRS,
.mediaPreparationTable > ul.tBody > li .mediaSListAS,
.mediaScreensaverTable > ul.tBody > li .mediaSListSM,
.mediaScreensaverTable > ul.tBody > li .mediaSListRS,
.mediaScreensaverTable > ul.tBody > li .mediaSListAS,
.mediaCompilationTable > ul.tBody > li .mediaSListSM,
.mediaCompilationTable > ul.tBody > li .mediaSListRS,
.mediaCompilationTable > ul.tBody > li .mediaSListAS {
	display: none;
	width: 100%;
	float: left;
	font-size: 12px;
}
.mediaPreparationTable > ul > li .progress,
.mediaScreensaverTable > ul > li .progress,
.mediaCompilationTable > ul > li .progress {
	height: 8px;
	border-radius: 4px;
	background: #d8d8d8;
}
.mediaPreparationTable > ul > li .progress .updatingSW,
.mediaScreensaverTable > ul > li .progress .updatingSW,
.mediaCompilationTable > ul > li .progress .updatingSW {
	background: #0aaeff;
}
.mediaPreparationTable > ul > li .progress .warningStatus,
.mediaScreensaverTable > ul > li .progress .warningStatus,
.mediaCompilationTable > ul > li .progress .warningStatus {
	background: #f93251;
} 
.mediaPreparationTable > ul > li .progress .completedStatus,
.mediaScreensaverTable > ul > li .progress .completedStatus,
.mediaCompilationTable > ul > li .progress .completedStatus {
	background: #3cce82;
}
.mediaFileSizeTooLarge {
	width: 100%;
	padding: 10px;
	text-align: center;
	background: #ffffff;
	color: #ff004b;
	position: fixed;
	bottom: 90px;
	left: 0px;
	font-family: 'open_sanssemibold';
}
.mediaUploadFTable {
	width: 100%;
	display: table;
	position: fixed;
	bottom: 0px;
	left: 0px;
}
.mediaUploadFTable > ul {
	width: 100%;
	display: table-row;
}
.mediaUploadFTable > ul > li {
	display: table-cell;
	padding: 0px;
	vertical-align: middle;
	color: #16406d;
	font-size: 14px;
	text-align: left; 
}
.mediaUploadFTable > ul > li:nth-child(1) {
	width: 80%;
	text-align: center;
	color: #627d9a;
	background: #e3e5e8;
	border-right: 1px solid #ffffff;
}
.mediaUploadFTable > ul > li:nth-child(2) {
	width: 20%;
	position: relative;
	text-align: center;
	color: #a3b2c1;
	background: #dce3e9;
	border-left: 1px solid #ffffff;
}
.mediaUploadFTable > ul > li:nth-child(2) > button {
	color: #a7b8c8;
	background: #dce3e9;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0px;
	top: 0px;
	padding: 30px 5px;
	text-align: center;
	font-size: 20px;
	border: 0px;
	cursor: pointer;
	font-family: 'open_sanssemibold';
}
.mediaUploadFTable > ul > li:nth-child(2) > button.active {
	color: #ffffff;
	background: #195d99;
}
.mediaUploadFTable .drogAndDropG {
	width: 100%;
	display: table;
	min-height: 65px;
}
.mediaUploadFTable .drogAndDropG > ul {
	width: 100%;
	display: table-row;
}
.mediaUploadFTable .drogAndDropG > ul > li {
	display: table-cell;
	padding: 0px;
	vertical-align: middle;
	color: #16406d;
	text-align: center;
}
.mediaUploadFTable .drogAndDropG > ul > li:nth-child(1) a {
	color: #627d9a;
	text-align: left;
}
.mediaUploadFTable .drogAndDropG > ul > li:nth-child(2) a {
	color: #a5b3c2;
	text-align: left;
}
.mediaUploadFTable .drogAndDropG > ul > li:nth-child(1) {
	width: 50%;
	background: #e3e6e8;
	font-size: 14px;
	border-right: 1px solid #ffffff;
}
.mediaUploadFTable .drogAndDropG > ul > li:nth-child(2) {
	width: 50%;
	background: #e3e6e8;
	font-size: 14px;
	border-left: 1px solid #ffffff;
}
.mediaUploadFTable > ul > li .maxFileSize {
	width: 100%;
	padding: 5px;
	float: left;
	clear: both;
	text-align: right;
	background: #1f2124;
	color: #ffffff;
	font-size: 11px;
	position: relative;
}
.mediaUploadFTable > ul > li .maxFileSize .progress-bar {
	clear: both;
	padding: 5px;
	font-size: 11px;
	font-weight: normal;
	color: #ffffff;
	text-align: right;
	position: absolute;
	left: 0px;
	top: 0px;
	background: #0aaeff;
}
.mediaUploadFTable > ul > li .maxFileSize .progress-bar.errorUpload {
	background: #f93251;
}
.mediaUploadFTable .drogAndDropG .DragScreensaverhere,
.mediaUploadFTable .drogAndDropG .DragPreparationhere {
	display: inline-block;
	padding: 11px 10px 11px 40px;
}
.mediaUploadFTable .drogAndDropG > ul > li.active {
	background: #0d3968;
}
.mediaUploadFTable .drogAndDropG > ul > li.initialActive a {
	color: #0d3968;
}
.mediaUploadFTable .drogAndDropG > ul > li.active a {
	color: #FFFFFF;
}
.mediaUploadFTable .drogAndDropG .DragPreparationhere {
	background: url("../images/icons/dropS.svg") no-repeat left center;
	background-size: 24px auto;
}
.mediaUploadFTable .drogAndDropG > ul > li.active .DragPreparationhere {
	background: url("../images/icons/dropO.svg") no-repeat left center;
	background-size: 24px auto;
}
.mediaUploadFTable .drogAndDropG .DragScreensaverhere {
	background: url("../images/icons/dropS.svg") no-repeat left center;
	background-size: 24px auto;
}
.mediaUploadFTable .drogAndDropG > ul > li.active .DragScreensaverhere {
	background: url("../images/icons/dropO.svg") no-repeat left center;
	background-size: 24px auto;
}
.mediaUploadFTable .drogAndDropG > ul > li.initialActive .DragPreparationhere {
	background: url("../images/icons/dropA.svg") no-repeat left center;
	background-size: 24px auto;
}
.mediaUploadFTable .drogAndDropG > ul > li.initialActive .DragScreensaverhere {
	background: url("../images/icons/dropA.svg") no-repeat left center;
	background-size: 24px auto;
}
.mediaUploadFTable .dragedDetailsTable {
	width: 100%;
	display: table;
	background: #575f67;
}
.mediaUploadFTable .dragedDetailsTable.compilationBg {
	background: #7e8690;
}
.mediaUploadFTable .dragedDetailsTable .dragedDetailsRow {
	width: 100%;
	display: table-row;
}
.mediaUploadFTable .dragedDetailsTable .dragedDetailsRow .dragedImage,
.mediaUploadFTable .dragedDetailsTable .dragedDetailsRow .dragedText {
	display: table-cell;
	padding: 0px;
	vertical-align: middle;
	color: #16406d;
}
.mediaUploadFTable .dragedDetailsTable .dragedDetailsRow .dragedImage {
	width: 64px;
	height: 64px;
	background: #515151;
}
.mediaUploadFTable .dragedDetailsTable .dragedDetailsRow .dragedImage > img {
	width: 100%;
}
.mediaUploadFTable .dragedDetailsTable .dragedDetailsRow .dragedImage > video {
	width: 100%;
	max-height: 58px;
}
.mediaUploadFTable .dragedDetailsTable .dragedDetailsRow .dragedText {
	padding: 10px;
	position: relative;
}
.mediaUploadFTable .dragedDetailsTable .dragedDetailsRow .dragedText h5 {
	color: #ffffff;
	text-align: left;
	font-size: 16px;
	margin-bottom: 5px;
	max-width: 280px;
	font-family: 'open_sanslight';
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.mediaUploadFTable .dragedDetailsTable .dragedDetailsRow .dragedText h6 {
	color: #b4b8bb;
	text-align: left;
	font-size: 14px;
}
.mediaUploadFTable .dragedDetailsTable .dragedDetailsRow .dragedText .removeImageVideo {
	background: url("../images/icons/cancelA.svg") no-repeat center center;
	background-size: 24px auto;
	width: 24px;
	height: 24px;
	position: absolute;
	top: 50%;
	right: 10px;
	margin-top: -12px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Date Picker reset ****** *
** ****** ****** ****** ****** ****** */

.datepicker {
	padding: 15px;
	border-radius: 0px;
	z-index: 99999 !important;
}
.datepicker-dropdown:before,
.datepicker-dropdown:after {
	border: 0px;
}
.datepicker th.prev,
.datepicker th.prev:hover {
	background: url("../images/icons/arrowr1A.svg") no-repeat center center;
	background-size: 20px 20px;
	text-indent: -99999px;
}
.datepicker th.next,
.datepicker th.next:hover {
	background: url("../images/icons/arrow1A.svg") no-repeat center center;
	background-size: 20px 20px;
	text-indent: -99999px;
}
.datepicker td {
	text-align: center;
	width: 32px;
	height: 32px;
	border-radius: 0px;
	border: none;
}
.datepicker th {
	font-weight: normal;
	font-family: 'open_sanssemibold';
	color: #49688b;
	font-size: 14px;
}
.datepicker table tr td.active.active,
.datepicker table tr td.month.active.active {
	background: transparent;
	border: 0px;
	color: #0d3968;
	font-family: 'open_sanssemibold';
	position: relative;
}
.datepicker table tr td.active.active:before {
	border: 1px solid #0d3968;
	border-radius: 50%;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	content: " ";
}
.datepicker table tr td.active,
.datepicker table tr td.month.active {
	background: transparent;
	border: 0px;
	color: #0d3968;
	font-family: 'open_sanssemibold';
	position: relative;
}
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover.active {
	background: transparent;
	border: 0px;
	color: #0d3968;
	font-family: 'open_sanssemibold';
}
.datepicker table tr td.month.active:hover,
.datepicker table tr td.month.active:hover.active {
	background: transparent;
	border: 1px solid #0d3968;
	color: #0d3968;
	font-family: 'open_sanssemibold';
}
.dateRangeSelectDiv {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px;
}
.dateRangeSelectDiv > span.selectFromDate,
.dateRangeSelectDiv > span.selectToDate {
	background: url("../images/icons/dateS.svg") no-repeat left center;
	background-size: 24px 24px;
	padding: 5px 5px 5px 30px;
	cursor: pointer;
	text-transform: initial;
	display: inline-block;
}
/* ****** ****** ****** ****** ****** **
 * ****** Assign Unit List Table ****** *
** ****** ****** ****** ****** ****** */ 
.SWlistUnits {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both; 
}
.SWlistUnitsTable {
	width: 100%;
	display: table;
}
.SWlistUnitsTable > ul {
	width: 100%;
	display: table-row;
}
.SWlistUnitsTable > ul.disabled {
	pointer-events: inherit;
}
.SWlistUnitsTable > ul.tHead > li {
	color: #16406d;
    border-bottom: 1px solid #63778a;
    background: #e9eaeb;
}
.SWlistUnitsTable > ul.tHead > li:first-child {
	border-left: 0px;
}
.SWlistUnitsTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
    color: #16406d;
	text-align: left;
	font-size: 14px; 
}
.SWlistUnitsTable > ul.offlineUnits > li {
	opacity: 0.5;
}
.SWlistUnitsTable > ul.selectedStaus > li {
	background: #1d5484;
}
.SWlistUnitsTable > ul.tBody > li:first-child {
	border-bottom: 1px solid #F2F2F2;
}
.SWlistUnitsTable > ul.tBody > li {
	padding: 7px 10px;
} 
.SWlistUnitsTable > ul > li:nth-child(1) {
	width: 54px;
	padding-left:5px;
	padding-right:5px;
	text-align: center;
}
.SWlistUnitsTable > ul > li:nth-child(2) {
	width: 52px;
	text-align: center;
}   
.SWlistUnitsTable > ul.tBody > li:nth-child(2) > img {
	width: 32px;
	height: 32px;
} 
.SWlistUnitsTable > ul > li:nth-child(4) {
	width:10%
}
.SWlistUnitsTable > ul > li:nth-child(5) {
	width:10%
}
.SWlistUnitsTable > ul > li:nth-child(6) ,
.SWlistUnitsTable > ul > li:nth-child(7) {
	width:140px;
}
.SWlistUnitsTable > ul > li:nth-child(8),
.SWlistUnitsTable > ul > li:nth-child(9) {
	width:140px;
}
.SWlistUnitsTable > ul > li:nth-child(10),
.SWlistUnitsTable > ul > li:nth-child(11) {
	text-align: center;
	width: 85px;
	padding-left:5px;
	padding-right:5px;
}
.SWlistUnitsTable > ul > li:nth-child(11) > img{ 
	max-width: 26px; 
}
.SWlistUnitsTable > ul > li:nth-child(12) {
	text-align: center;
	width: 68px;
} 
.SWlistUnitsTable > ul > li > span.offlineStausTable {
	opacity: 0.5;
	background: url("../images/icons/offlineA.svg") no-repeat center center;
	background-size: 24px 24px;
	padding-left: 20px;
}   
.SWlistUnitsTable > ul > li > .tagedUnits,
.SWlistUnitsTable > ul > li > .orgTagedUnits {  
	display:inline-block;
	width:24px;
}  
.SWlistUnitsTable > ul > li > .tagedUnits { 
	background: url("../images/icons/smartTagUnit.svg") no-repeat center center;
	background-size: 22px 22px; 
}   
.SWlistUnitsTable > ul > li > .orgTagedUnits { 
	background: url("../images/icons/smartTagLocation.svg") no-repeat center center;
	background-size: 22px 22px; 
} 
.SWlistUnitsTable > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.SWlistUnitsTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
} 
.SWlistUnitsTable > ul > li .customRadioBtn {
	margin: 0px;
	display: inline-block;
} 
.SWlistUnitsTable > ul > li .customRadioBtn label {
	margin: 0px;
	padding-left: 30px;  
	padding-left: 24px;
	height: 24px;
	width: 24px;
}
.SWlistUnitsTable > ul.tBody > li .unitsListSM,
.SWlistUnitsTable > ul.tBody > li .unitsListSML {
	display: none;
	width: 100%;
	float: left;
}
.SWlistUnitsTable > ul.tBody > li .unitsListAN {
	display: none;
	width: 100%;
	float: left;
	background: url("../images/ANTransparent.png") repeat;
	padding: 5px;
	font-size: 12px;
}
.SWlistUnitsTable > ul.tBody > li .unitsListAN > b {
	width: 100%;
	float: left;
	font-weight: normal;
}
/* ****** ****** ****** ****** ****** **
 * ****** Menu Assign Status Expanded Table ****** *
** ****** ****** ****** ****** ****** */
 
.SGlistUnitsExpTable {
	width: 100%;
	padding: 0px 45px;
	float: left;
	clear: both;
	background:#ffffff;	
}
.SGExpTableOuter { 
	margin: 5px;
	float: left;
}
.SGExpTable {
	width: 100%;
	display: table; 
}
.SGExpTable > ul {
	width: 100%;
	display: table-row;
}
.SGExpTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
}  
.SGExpTable > ul > li:nth-child(1) {
	width: 40px;  
	padding-left: 0px;
}
.SGExpTable > ul > li:nth-child(2) {
	padding-left: 0px;
} 
/* ****** ****** ****** ****** ****** **
 * ****** Media Overwrite Table ****** *
** ****** ****** ****** ****** ****** */

.overwriteMediaHeader {
	width: 100%;
	display: table;
	padding: 0px 0px 10px;
}
.overwriteMediaHeader > ul {
	width: 100%;
	display: table-row;
}
.overwriteMediaHeader > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: top;
	color: #1b4471;
	text-align: left;
	font-size: 14px; 
}
.overwriteMediaHeader > ul > li > h5 {
	color: #0d3968;
	font-size: 22px;
	padding: 0px 0px 25px;
	font-family: 'open_sanssemibold';
}
.overwriteMediaHeader > ul > li > p {
	color: #0f3b6a;
	font-size: 16px;
	margin: 0px;
}
.overwriteMediaHeader > ul > li:nth-child(1) {
	width: 90px;
	text-align: center;
}
.overwriteMediaHeader > ul > li:nth-child(1) > img {
	width: 56px;
}
.mediaOverwriteTable {
	width: 100%;
	display: table;
	margin-top: 30px;
}
.mediaOverwriteTable > ul {
	width: 100%;
	display: table-row;
}
.mediaOverwriteTable > ul.tHead > li {
	color: #66809c;
	font-size: 14px;
	background: #e9eaeb;
	border-bottom: 1px solid #6c7e90;
}
.mediaOverwriteTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	color: #1b4471;
	text-align: left;
	font-size: 14px; 
}
.mediaOverwriteTable > ul.tBody > li {
	padding: 7px 10px;
	background: #ffffff;
	border-bottom: 1px solid #b4bec8;
}
.mediaOverwriteTable > ul > li:nth-child(1) {
	width: 40px;
	text-align: center;
}
.mediaOverwriteTable > ul:last-child > li {
	border-bottom: 0px;
}
.mediaOverwriteTable > ul.tBody > li:nth-child(1) {
	color: #768fa9;
}
.mediaOverwriteTable > ul.tBody > li:nth-child(2) > .unitImg {
	float: left;
	padding: 5px 0px;
	margin-right: 5px;
}
.mediaOverwriteTable > ul.tBody > li:nth-child(2) > .unitImg > img {
	width: 42px;
	height: 42px;
	display: inline-block;
}
.mediaOverwriteTable > ul.tBody > li:nth-child(2) > .unitName {
	float: left;
}
.mediaOverwriteTable > ul.tBody > li:nth-child(2) > .unitName > b {
	width: 100%;
	display: inline-block;
	clear: both;
	font-weight: normal;
}
.mediaOverwriteTable > ul > li:nth-child(4) {
	text-align: center;
	width: 110px;
}
.mediaOverwriteTable > ul > li:nth-child(5) {
	text-align: center;
	width: 80px;
}
.mediaOverwriteTable > ul > li .customRadioBtn {
	margin: 0px;
	display: block;
}
.mediaOverwriteTable > ul.tHead .customRadioBtn label {
	margin: 0px;
	padding-left: 30px;
}
.mediaOverwriteTable > ul.tBody .customRadioBtn label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}
/*** **************  ************** ****
		*** Schedule Update ***
**** **************  ************** ***/

.scheduleUpdateWrapper {
	width: 100%;
	float: left;
	padding: 40px 20px;
}
.scheduleUpdateWrapperL {
	width: 35%;
	padding-right: 20px;
	float: left;
	min-height: 460px;
}
.scheduleUpdateWrapperR {
	width: 65%;
	float: left;
	min-height: 460px;
	padding: 20px 25px;
	border-left: 1px solid #7e8690;
}
.selectedSWscheduleUpdate {
	width: 100%;
	float: left;
	padding: 20px;
	background: #e9eaeb;
	border: 1px solid #dee0e1;
}
.selectedSWscheduleUpdate .selectedBrandLogo {
	width: 100%;
	float: left;
	padding: 10px 0px 5px;
	text-align: center;
}
.selectedSWscheduleUpdate .selectedBrandLogo > img {
	display: inline-block;
	height: 28px;
	max-width:100%;
}
.selectedSWscheduleUpdate .selectedBrandModel {
	width: 100%;
	float: left;
	padding: 10px 0px 5px;
	text-align: center;
	font-size: 18px;
	color: #0d3968;
}
.selectedSWscheduleUpdate .selectedBrandModel > span {
	width: 100%;
	float: left;
	padding: 0px 0px 5px;
	font-size: 14px;
	color: #627d9a;
}
.selectedSWscheduleUpdate .selectedSWpakageDetails {
	width: 100%;
	float: left;
	padding: 15px;
	text-align: center;
	font-size: 18px;
	color: #0d3968;
	background: #f2f2f2;
}
.selectedSWscheduleUpdate .selectedSWpakageDetails h5,
.selectedSWscheduleUpdate .selectedSWpakageDetails h6 {
	width: 100%;
	float: left;
	padding: 5px 0px 5px;
	text-align: center;
	font-size: 14px;
	color: #0d3968;
	word-break: break-word;
}
.selectedSWscheduleUpdate .selectedSWpakageDetails h6 {
	color: #627d9a;
}
.scheduleUpdateOption {
	width: 100%;
	float: left;
}
.scheduleUpdateOption.borderTop {
	border-top: 1px solid #dee0e1;
	padding-top:15px;
	margin-top:20px;
}
.scheduleUpdateOption > .customCheckbox input[type=checkbox] + label,
.scheduleUpdateOption > .customCheckbox input[type=checkbox] + i + label,
.scheduleUpdateOption > .customCheckbox input[type=checkbox] + input + label {
	font-size: 18px;
	color: #0d3968;
}
.scheduleUpdateOption > .customRadioBtn {
	width: 100%;
	float: left;
	margin: 0px 0px 30px;
}
.scheduleUpdateOption > .customRadioBtn input[type=radio] + label,
.scheduleUpdateOption > .customRadioBtn input[type=radio] + i + label,
.scheduleUpdateOption > .customRadioBtn input[type=radio] + input + label {
	font-size: 18px;
	color: #0d3968;
}
.scheduleUpdateOption .newReportForm {
	width: 100%;
	float: left;
	padding: 0px 0px 25px 30px;
	clear: both;
	margin-top:-20px;
} 
.scheduleUpdateOption .newReportForm > ul {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 0px;
}
.scheduleUpdateOption .newReportForm > ul > li {
	float: left;
	padding: 0px 10px 0px 0px; 
}
.scheduleUpdateOption .newReportForm > ul > li .customRadioBtn label{
	font-size: 18px;
    color: #0d3968;
}
.scheduleUpdateOption > ul {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 0px;
}
.scheduleUpdateOption > ul > li {
	float: left;
	padding: 0px 20px 0px 0px; 
}
.scheduleUpdateOption .reportTypeInfo {
	background: url("../images/icons/informationC.png") no-repeat center center;
	background-size: 24px 24px;
	width:24px;
	height:24px;
	float:left;
	margin: 10px 10px 0px 0px;
} 
.scheduleUpdateOption .customRadioBtn { 
	float:left; 
} 

/*** **************  ************** ****
		*** Install Options ***
**** **************  ************** ***/
.installOption {
	width: 100%;
	float: left;
	margin-bottom:35px;
	margin-left:30px; 
} 
.installOption.marginTopNeg {
	margin-top:-25px;
} 
.installOption > .customCheckbox input[type=checkbox] + label,
.installOption > .customCheckbox input[type=checkbox] + i + label,
.installOption > .customCheckbox input[type=checkbox] + input + label {
	font-size: 18px;
	color: #0d3968;
}
.installOption > ul > li > .customRadioBtn { 
	float: left; 
}
.installOption > ul > li > .customRadioBtn input[type=radio] + label,
.installOption > ul > li > .customRadioBtn input[type=radio] + i + label,
.installOption > ul > li > .customRadioBtn input[type=radio] + input + label {
	font-size: 18px;
	color: #0d3968;
} 
.installOption > ul {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 0px;
}
.installOption > ul > li {
	float: left;
	padding: 0px 20px 0px 0px; 
}
.installOption .reportTypeInfo {
	background: url("../images/icons/informationC.png") no-repeat center center;
	background-size: 24px 24px;
	width:24px;
	height:24px;
	float:left;
	margin: 12px 10px 0px 0px;
}  

.scheduleUpdateForm {
	width: 100%;
	float: left;
	padding: 0px 0px 5px 35px;
	clear: both;
}
.scheduleUpdateForm > ul {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 0px;
}
.scheduleUpdateForm > ul > li {
	float: left;
	padding: 0px 10px 0px 0px;
}
.scheduleUpdateForm > ul > li:first-child {
	padding-right: 30px;
}
.scheduleUpdateForm > ul > li > label {
	float: left;
	padding: 20px 15px 0px 0px;
	color: #62768a;
	font-size: 14px;
	font-weight: normal;
}
.scheduleUpdateForm > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 30px 5px 0px;
	height: auto;
	background: #f2f2f2 !important;
	color: #0D3968;
	font-size: 20px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
}
.scheduleUpdateForm > ul > li select.form-control,
.scheduleUpdateForm > ul > li .input-group .react-datepicker-wrapper .react-datepicker__input-container input,
.scheduleUpdateForm > ul > li .input-group #selectTime {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: #f2f2f2 url("../images/icons/markdownA.svg") no-repeat right center !important;
	padding-left: 18px;
}
.scheduleUpdateForm > ul > li .input-group #ScheduleDate:focus {
	color: transparent;
	text-shadow: 0px 0px 0px #666;
}
.scheduleUpdateForm > ul > li select.form-control option {
	font-size: 16px;
}
.scheduleUpdateForm > ul > li select::-ms-expand {
	display: none;
}
.scheduleUpdateForm > ul > li .input-group {
	float: left;
	width: 180px;
}
.menuScheduleUpdate {
	width: 100%;
	float: left;
	padding: 50px 20px;
	background: #e9eaeb;
	border: 1px solid #dee0e1;
}
.menuScheduleUpdate .menuDrogAndDrop {
	width: 100%;
	float: left;
	padding: 100px 0px 5px;
	text-align: center;
	background: url("../images/icons/drogAndDrop.png") no-repeat top center;
	font-size: 18px;
	color: #627d9a;
	word-break: break-all;
}
.menuScheduleUpdate .menuDrogAndDropedText {
	width: 100%;
	float: left;
	padding: 10px 0px 15px;
	text-align: center;
	font-size: 18px;
	color: #0d3968;
}
.menuScheduleUpdate .menuDrogDroporBrowse {
	width: 100%;
	float: left;
	padding: 0px 0px 0px;
	text-align: center;
	font-size: 18px;
	color: #1b4470;
}
.menuScheduleUpdate .menuBrowse {
	width: 100%;
	float: left;
	padding: 5px 0px 5px;
	text-align: center;
	font-size: 18px;
	color: #fff;
	background: #0d3968;
}
.menuScheduleUpdate .menuUpdateFile {
	display: none;
}
/*** **************  ************** ****
		*** Menu Update Wrapper  ***
**** **************  ************** ***/

.menuUpdateWrapper {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both; 
}
/*** **************  ************** ****
		*** Menu Update Header ***
**** **************  ************** ***/

.menuUpdateHeader {
	width: 100%;
	padding: 0px;
	display: table;
	border-bottom: 1px solid #7e8690;
}
.menuUpdateHeader > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.menuUpdateHeader > ul > li {
	padding: 0px 0px;
	display: table-cell;
	color: #96a8bb;
	vertical-align: middle;
}
.menuUpdateHeader > ul > li a {
	color: #96a8bb;
	margin-right: 20px;
	padding: 18px 15px 19px;
	font-size: 18px;
	display: inline-block;
	font-family: 'open_sanssemibold';
}
.menuUpdateHeader > ul > li > a:hover,
.menuUpdateHeader > ul > li > a.active {
	color: #0d3968;
}
/*** **************  ************** ****
		*** Menu Filter Header ***
**** **************  ************** ***/

.menuFilterHeader {
	width: 100%;
	padding: 0px;
	display: table;
	border-bottom: 1px solid #7e8690;
	background: #f2f2f2;
}
.menuFilterHeader.fixedHeader {
	position: fixed;
	top: 60px;
	left: 0px;
	z-index: 9;
}
.menuFilterHeader > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.menuFilterHeader > ul > li {
	padding: 0px 0px;
	display: table-cell;
	color: #96a8bb;
	vertical-align: middle;
}
.menuFilterHeader > ul > li:nth-child(1) a {
	color: #96a8bb;
	padding: 18px 15px 19px;
	font-size: 18px;
	display: inline-block;
	font-family: 'open_sanssemibold';
}
.menuFilterHeader > ul > li:nth-child(1) > a:hover,
.menuFilterHeader > ul > li:nth-child(1) > a.active {
	color: #0d3968;
}
.menuFilterHeader > ul > li:nth-child(2) {
	text-align: right;
	padding-right: 15px;
}
.menuFilterHeader > ul > li.selectedModel {
	color: #0d3968;
}
.menuFilterHeader > ul > li .btn {
	margin-left: 15px;
}
.menuFilterHeader > ul > li .cbfileretrieval,
.menuFilterHeader > ul > li .createCopy,
.menuFilterHeader > ul > li .forwardA,
.menuFilterHeader > ul > li .sgAdd,
.menuFilterHeader > ul > li .transfertoSG,
.menuFilterHeader > ul > li .retrieveFromDevice,
.menuFilterHeader > ul > li .menuUpload,
.menuFilterHeader > ul > li .recipeEditor,
.menuFilterHeader > ul > li .menuDownload,
.menuFilterHeader > ul > li .menuEdit,
.menuFilterHeader > ul > li .menuDelete,
.menuFilterHeader > ul > li .menuAdd {
	width: 24px;
	height: 24px;
}
.menuFilterHeader > ul > li .referesh {
	background: url("../images/icons/refresh.svg") no-repeat center center;
	background-size: 22px 22px;
}
.menuFilterHeader > ul > li .referesh.disabled {
	background: url("../images/icons/refreshD.svg") no-repeat center center;
	background-size: 22px 22px;
}
.menuFilterHeader > ul > li .sgAdd {
	background: url("../images/icons/SmartGroupStatusA.svg") no-repeat center center;
	background-size: 24px 24px;
}
.menuFilterHeader > ul > li .sgAdd.disabled {
	background: url("../images/icons/SmartGroupStatusD.svg") no-repeat center center;
	background-size: 24px 24px;
}
.menuFilterHeader > ul > li .transfertoSG {
	background: url("../images/icons/assignDeviceT.svg") no-repeat center center;
	background-size: 22px 22px;
}
.menuFilterHeader > ul > li .transfertoSG.disabled {
	background: url("../images/icons/assignDeviceD.svg") no-repeat center center;
	background-size: 22px 22px;
}
.menuFilterHeader > ul > li .retrieveFromDevice {
	background: url("../images/icons/assignDeviceLT.svg") no-repeat center center;
	background-size: 22px 22px;
}
.menuFilterHeader > ul > li .retrieveFromDevice.disabled {
	background: url("../images/icons/assignDeviceLD.png") no-repeat center center;
	background-size: 22px 22px;
}
.menuFilterHeader > ul > li .forwardA {
	background: url("../images/icons/forwardD.svg") no-repeat center center;
	background-size: 22px 22px;
} 
.menuFilterHeader > ul > li .createCopy {
	background: url("../images/icons/copyE.svg") no-repeat center center;
	background-size: 24px 24px;
} 
.menuFilterHeader > ul > li .createCopy.disabled {
	background: url("../images/icons/copyD.svg") no-repeat center center;
	background-size: 24px 24px;
} 
.menuFilterHeader > ul > li .cbfileretrieval {
	background: url("../images/icons/retrievalBlue.svg") no-repeat center center;
	background-size: 26px 26px;
}  
.menuFilterHeader > ul > li .cbfileretrieval.disabled { 
	opacity:0.3;
}  
.menuFilterHeader > ul > li .menuEdit {
	background: url("../images/icons/penB.svg") no-repeat center center;
	background-size: 24px 24px;
}
.menuFilterHeader > ul > li .menuEdit.disabled {
	background: url("../images/icons/penD.svg") no-repeat center center;
	background-size: 24px 24px;
}
.menuFilterHeader > ul > li .menuDelete {
	background: url("../images/icons/trashB.svg") no-repeat center center;
	background-size: 24px 24px;
}
.menuFilterHeader > ul > li .menuDelete.disabled {
	background: url("../images/icons/trashC.svg") no-repeat center center;
	background-size: 24px 24px;
}
.menuFilterHeader > ul > li .menuAdd {
	background: url("../images/icons/plusA.svg") no-repeat center center;
	background-size: 24px 24px;
}
.menuFilterHeader > ul > li .menuAdd.disabled {
	background: url("../images/icons/plusD.svg") no-repeat center center;
	background-size: 24px 24px;
}
.menuFilterHeader > ul > li .menuDownload {
	background: url("../images/icons/downloadA.png") no-repeat center center;
	background-size: 24px 24px;
}
.menuFilterHeader > ul > li .menuDownload.disabled {
	background: url("../images/icons/downloadD.png") no-repeat center center;
	background-size: 24px 24px;
}
.menuFilterHeader > ul > li .menuUpload {
	background: url("../images/icons/uploadA.png") no-repeat center center;
	background-size: 24px 24px;
}
.menuFilterHeader > ul > li .menuUpload.disabled {
	background: url("../images/icons/uploadD.png") no-repeat center center;
	background-size: 24px 24px;
}
.menuFilterHeader > ul > li .recipeEditor {
	background: url("../images/icons/recipeEditor.png") no-repeat center center;
	background-size: 24px 24px;
} 
.menuFilterHeader > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 30px 0px 0px;
	height: auto;
	background: #f2f2f2 !important;
	color: #0D3968;
	font-size: 20px;
	border: 0px;
	border-radius: 0px;
}
.menuFilterHeader > ul > li .input-group {
	width: auto;
	display: inline-flex;
	padding-right: 15px;
	border-right: 1px solid #7e8690;
}
.menuFilterHeader > ul > li select.form-control,
.menuFilterHeader > ul > li .input-group .react-datepicker-wrapper .react-datepicker__input-container input,
.menuFilterHeader > ul > li .input-group #selectTime {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: #f2f2f2 url("../images/icons/markdownA.svg") no-repeat right center !important;
	padding-left: 18px;
}
.menuFilterHeader > ul > li .input-group #ScheduleDate:focus {
	color: transparent;
	text-shadow: 0px 0px 0px #666;
}
.menuFilterHeader > ul > li select::-ms-expand {
	display: none;
} 
/* ****** ****** ****** ****** ****** **
 * ****** Menu Brands Table ****** *
** ****** ****** ****** ****** ****** */

.menuBrandsTableDiv {
	width: 100%;
	float: left;
	padding: 0px 0px;
}
.menuBrandsTable {
	width: 100%;
	display: table;
}
.menuBrandsTable > ul {
	width: 100%;
	display: table-row;
}
.menuBrandsTable > ul.tHead > li {
	color: #16406d;
	background: #e9eaeb;
	border-bottom: 1px solid #63778a;
}
.menuBrandsTable > ul.tHead > li:nth-child(1) {
	border-left: 0px;
	border-bottom: 1px solid #63778a;
}
.menuBrandsTable > ul > li {
	display: table-cell;
	padding: 5px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left; 
}
.menuBrandsTable > ul > li:nth-child(1) {
	width: 54px;
	padding-left:5px;
	padding-right:5px;
	text-align: center;
	color: #16406d;
	border-bottom: 1px solid #F2F2F2;
}
.menuBrandsTable > ul > li:nth-child(2) {
	padding-left: 0px;
	width:190px;
}
.menuBrandsTable > ul.tBody > li:nth-child(2) {
	text-transform: uppercase;
} 
.menuBrandsTable > ul > li:nth-child(3) > img {
	height: 24px;
}
.menuBrandsTable > ul > li.logoH > img {
	height: 34px;
}
.menuBrandsTable > ul > li:nth-child(4) {
	width: 40px;
	text-align: center;
}
.menuBrandsTable > ul > li > a {
	color: #16406d;
}
.menuBrandsTable > ul > li .forwardNext {
	background: url("../images/icons/forwardA.svg") no-repeat center center;
	background-size: 40px 40px;
	width: 40px;
	height: 40px;
	display: inline-block;
}
/* ****** ****** ****** ****** ****** **
 * ****** Recipe Table ****** *
** ****** ****** ****** ****** ****** */

.recipeCreationTableDiv {
	width: 100%;
	float: left;
	padding: 0px 0px;
}
.recipeCreationTable {
	width: 100%;
	display: table;
}
.recipeCreationTable > ul {
	width: 100%;
	display: table-row;
}
.recipeCreationTable > ul.tHead > li {
	color: #16406d;
	background: #e9eaeb;
	border-bottom: 1px solid #63778a;
}
.recipeCreationTable > ul.tHead > li:first-child {
	border-left: 0px;
	border-bottom: 1px solid #63778a;
}
.recipeCreationTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left; 
}
.recipeCreationTable > ul > li:nth-child(1) {
	width: 50px;
	text-align: center;
	color: #16406d;
	border-bottom: 1px solid #F2F2F2;
}
.recipeCreationTable > ul > li:nth-child(2) {
	padding-left: 0px; 
	width: 190px;
}
.recipeCreationTable > ul > li:nth-child(3) > img {
	height: 24px;  
} 
.recipeCreationTable > ul > li:nth-child(4) {
	text-align: center;
}
.recipeCreationTable > ul > li:nth-child(4) img {
	width: 16px;
}
.recipeCreationTable > ul > li:nth-child(5) {
	width: 40px;
	text-align: center;
}
.recipeCreationTable > ul > li > a {
	color: #16406d;
}
.recipeCreationTable > ul > li .forwardNext {
	background: url("../images/icons/forwardA.svg") no-repeat center center;
	background-size: 40px 40px;
	width: 40px;
	height: 40px;
	display: inline-block;
}
/* ****** ****** ****** ****** ****** **
 * ****** Products Selection Table ****** *
** ****** ****** ****** ****** ****** */

.prdtsSelcnTableDiv {
	width: 100%;
	float: left;
	padding: 0px 0px;
}
.prdtsSelcnTable {
	width: 100%;
	display: table;
}
.prdtsSelcnTable > ul {
	width: 100%;
	display: table-row;
}
.prdtsSelcnTable > ul.tHead > li {
	color: #16406d;
	background: #e9eaeb;
	border-bottom: 1px solid #63778a;
}
.prdtsSelcnTable > ul.tHead > li:first-child {
	border-left: 0px;
	border-bottom: 1px solid #63778a;
}
.prdtsSelcnTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
}
.prdtsSelcnTable > ul > li:nth-child(1) {
	width: 50px;
	text-align: center;
	color: #16406d;
	border-bottom: 1px solid #F2F2F2;
}
.prdtsSelcnTable > ul > li:nth-child(2) {
	padding-left: 0px;
	width:190px;
}
.prdtsSelcnTable > ul > li:nth-child(3) > img {
	height: 24px; 
}
.prdtsSelcnTable > ul > li:nth-child(4),
.prdtsSelcnTable > ul > li:nth-child(5) {
	width: 220px;
	text-align: center;
}
.prdtsSelcnTable > ul > li:nth-child(6) {
	width: 40px;
	text-align: center;
}
.prdtsSelcnTable > ul > li > a {
	color: #16406d;
}
.prdtsSelcnTable > ul > li .forwardNext {
	background: url("../images/icons/forwardA.svg") no-repeat center center;
	background-size: 40px 40px;
	width: 40px;
	height: 40px;
	display: inline-block;
}
/*** **************  ************** ****
		*** Report Wrapper  ***
**** **************  ************** ***/

.reportWrapper {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both; 
}
/*** **************  ************** ****
		*** User Management Header ***
**** **************  ************** ***/

.reportHeader {
	width: 100%;
	padding: 0px;
	display: table;
	border-bottom: 1px solid #7e8690;
}
.reportHeader > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.reportHeader > ul > li {
	padding: 0px 0px;
	display: table-cell;
	color: #0D3968;
	vertical-align: middle;
	font-size: 18px;
	font-family: 'open_sanssemibold';
}
.reportHeader > ul > li > a {
	color: #96a8bb;
	margin-right: 10px;
	padding: 10px 15px 10px;
	font-size: 16px;
	display: inline-block;
	font-family: 'open_sanssemibold';
}
.reportHeader > ul > li > .OrgChartSTNav,
.reportHeader > ul > li > .UnitSTNav { 
	padding: 10px 5px 10px 30px; 
	margin-left:15px;
	position:relative;
}
.reportHeader > ul > li > .OrgChartSTNav:before,
.reportHeader > ul > li > .UnitSTNav:before { 
	position: absolute;
	top:10px;
	left:0px; 
	width:24px;
	height:24px;
	content: " "; 
	opacity:0.6;
}
.reportHeader > ul > li > .OrgChartSTNav.active:before,
.reportHeader > ul > li > .UnitSTNav.active:before,
.reportHeader > ul > li > .OrgChartSTNav:hover::before,
.reportHeader > ul > li > .UnitSTNav:hover::before {  
	opacity:1;
}
.reportHeader > ul > li .OrgChartSTNav:before {
	background: url("../images/icons/smartTagLocation.svg") no-repeat left center;
	background-size: 22px 22px;
}
.reportHeader > ul > li .UnitSTNav:before {
	background: url("../images/icons/smartTagUnit.svg") no-repeat left center;
	background-size: 22px 22px;
}
.reportHeader > ul > li > a.active,
.reportHeader > ul > li > a:hover {
	color: #0D3968;
}
.reportHeader > ul > li:nth-child(1) {
	text-align: left;
}
.reportHeader > ul > li:nth-child(2) {
	text-align: right;
	padding-right: 10px;
}
.reportHeader > ul > li > a:hover {
	color: #000000;
}
.reportHeader > ul > li .btn {
	margin-left: 10px;
}
.reportHeader > ul > li a.btn {
	margin-left: 10px;
	margin-right: 0px;
}
.reportHeader > ul > li .logViewBtn {
	color: #0d3968;
	background: #bccddc;
	padding: 10px 15px;
	font-family: 'open_sanssemibold';
	font-size: 14px;
}
.reportHeader > ul > li .btn.disabled {
	color: #bcc3cf;
}
.reportHeader > ul > li .emailReport,
.reportHeader > ul > li .download,
.reportHeader > ul > li .edit,
.reportHeader > ul > li .delete,
.reportHeader > ul > li .add {
	width: 24px;
	height: 24px;
}
.reportHeader > ul > li > .haccpViewerTxt {
	font-size: 14px;
	border-bottom: 1px solid #0D3968;
	padding: 0;
}
.reportHeader > ul > li > .haccpViewerTxt:hover {
	border-bottom: 1px solid transparent;
	text-decoration: none;
}
.reportHeader > ul > li .download {
	background: url("../images/icons/downloadA.png") no-repeat center center;
	background-size: 24px 24px;
}
.reportHeader > ul > li .download.disabled {
	background: url("../images/icons/downloadD.png") no-repeat center center;
	background-size: 24px 24px;
}
.reportHeader > ul > li .edit {
	background: url("../images/icons/penB.svg") no-repeat center center;
	background-size: 24px 24px;
}
.reportHeader > ul > li .edit.disabled {
	background: url("../images/icons/penD.svg") no-repeat center center;
	background-size: 24px 24px;
}
.reportHeader > ul > li .delete {
	background: url("../images/icons/trashB.svg") no-repeat center center;
	background-size: 24px 24px;
}
.reportHeader > ul > li .delete.disabled {
	background: url("../images/icons/trashC.svg") no-repeat center center;
	background-size: 24px 24px;
}
.reportHeader > ul > li .add {
	background: url("../images/icons/plusA.svg") no-repeat center center;
	background-size: 24px 24px;
}
.reportHeader > ul > li .add.disabled {
	background: url("../images/icons/plusD.svg") no-repeat center center;
	background-size: 24px 24px;
}
.reportHeader > ul > li .emailReport {
	background: url("../images/icons/emailA.png") no-repeat center center;
	background-size: 24px 24px;
}
.reportHeader > ul > li .emailReport.disabled {
	background: url("../images/icons/emailD.png") no-repeat center center;
	background-size: 24px 24px;
}
.reportHeader > ul > li .clearErrorBtn {
	border-radius: 20px;
	background: #0D3968;
	font-size: 14px;
	border: 0px;
	color:#ffffff;
	margin-right: 10px;
}
.reportHeader > ul > li .clearErrorBtn {
	border-radius: 20px;
	background: #0D3968;
	font-size: 14px;
	border: 0px;
	color:#ffffff;
	margin-right: 10px;
} 
.reportHeader > ul > li .checkBoxListGrp {
	display:inline-block;
	margin-right:15px;
} 
.reportHeader > ul > li .checkBoxListGrp .customCheckbox {
    position: relative;
    display: inline-block;
    margin: 0px 10px 0px 0px;
}
.reportHeader > ul > li .checkBoxListGrp .customCheckbox label { 
    line-height:16px;
	color: #96a8bb;
	font-family: 'open_sansregular';
	height:32px;
	padding: 0px 0px 0px 30px;
	float:inherit;
}
.reportHeader > ul > li .checkBoxListGrp .customCheckbox label > span { 
    display: table;
	color: #0D3968;
	clear:both;
    font-family: 'open_sanssemibold';
}
.reportHeader > ul > li .crticalCBox.customCheckbox input[type=checkbox] + label {
	background: url("../images/icons/checkCOff.png") no-repeat left center;
	background-size: 24px 24px;
}
.reportHeader > ul > li .crticalCBox.customCheckbox input[type=checkbox] + label,
.reportHeader > ul > li .crticalCBox.customCheckbox input[type=checkbox] + i + label,
.reportHeader > ul > li .crticalCBox.customCheckbox input[type=checkbox] + input + label {
	background: url("../images/icons/checkCOff.png") no-repeat left center;
	background-size: 24px 24px;
}
.reportHeader > ul > li .crticalCBox.customCheckbox input[type=checkbox]:checked + label,
.reportHeader > ul > li .crticalCBox.customCheckbox input[type=checkbox]:checked + i + label,
.reportHeader > ul > li .crticalCBox.customCheckbox input[type=checkbox]:checked + input + label {
	background: url("../images/icons/checkCOn.png") no-repeat left center;
	background-size: 24px 24px;
}
.reportHeader > ul > li .nonCrticalCBox.customCheckbox input[type=checkbox] + label {
	background: url("../images/icons/checkNOff.png") no-repeat left center;
	background-size: 24px 24px;
}
.reportHeader > ul > li .nonCrticalCBox.customCheckbox input[type=checkbox] + label,
.reportHeader > ul > li .nonCrticalCBox.customCheckbox input[type=checkbox] + i + label,
.reportHeader > ul > li .nonCrticalCBox.customCheckbox input[type=checkbox] + input + label {
	background: url("../images/icons/checkNOff.png") no-repeat left center;
	background-size: 24px 24px;
}
.reportHeader > ul > li .nonCrticalCBox.customCheckbox input[type=checkbox]:checked + label,
.reportHeader > ul > li .nonCrticalCBox.customCheckbox input[type=checkbox]:checked + i + label,
.reportHeader > ul > li .nonCrticalCBox.customCheckbox input[type=checkbox]:checked + input + label {
	background: url("../images/icons/checkNOn.png") no-repeat left center;
	background-size: 24px 24px;
}
.reportHeader > ul > li .dropdown {
	display:inline-block;
}  
.reportHeader > ul > li .dropdown > .dropdown-menu {
	width: 100%;
	padding: 0px 0px; 
	border: 0px;
	border-radius: 0px;
	text-transform: none;
	font-size: 14px;
	z-index: 9999;
} 
.reportHeader > ul > li .dropdown > .dropdown-toggle { 
	font-size: 14px;
	text-transform: none;
	width: auto; 
	height: auto;  
	border: 0px;
	margin-right: 10px;
	min-width: 65px;
	background:none; 
	color: #0D3968;
	font-size:14px;
}
.reportHeader > ul > li .dropdown.datepickerBtnGroup > .dropdown-toggle {
	margin-right: 0px;
}
.reportHeader > ul > li .dropdown button:focus,
.reportHeader > ul > li .dropdown button:active,
.reportHeader > ul > li .dropdown button:hover {
	box-shadow: none;
	border: 0px;
	box-shadow: none;
} 
.reportHeader > ul > li .dropdown > .dropdown-menu .dropdown-item {
	padding: 8px 15px;
	color: #6c86a1;
	text-align: right;
}
.reportHeader > ul > li .dropdown > .dropdown-menu .dropdown-item:hover,
.reportHeader > ul > li .dropdown > .dropdown-menu .dropdown-item:focus,
.reportHeader > ul > li .dropdown > .dropdown-menu .dropdown-item:active,
.reportHeader > ul > li .dropdown > .dropdown-menu .dropdown-item.active {
	color: #18426f !important;
	background: transparent;
}
.reportHeader > ul > li .dropdown .dropdown-divider {
	border-top: 1px solid #68839f;
	margin: 1px 0;
}
.reportHeader > ul > li .showHideDate {	
	color: #0D3968;
	margin-top: 17px;
}
.reportHeader > ul > li .datePickerSpan {	
	color: #0D3968;
	background: #E9EAEB;
}
.reportHeader > ul > li .dropdown.datepickerBtnGroup {	
	margin-left: 25px;
}
/*** **************  ************** ****
		*** Org Management Header ***
**** **************  ************** ***/

.orgHeader {
	width: 100%;
	padding: 0px;
	display: table;
	border-bottom: 1px solid #7e8690;
}
.orgHeader > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.orgHeader > ul > li {
	padding: 18px 15px 19px;
	display: table-cell;
	color: #0D3968;
	vertical-align: middle;
	font-size: 18px;
	font-family: 'open_sanssemibold';
}
.orgHeader > ul > li > a {
	color: #96a8bb;
	margin-right: 10px;
	padding: 0px 15px 0px;
	font-size: 18px;
	display: inline-block;
	font-family: 'open_sanssemibold';
}
.orgHeader > ul > li > a.active,
.orgHeader > ul > li > a:hover {
	color: #0D3968;
}
.orgHeader > ul > li:nth-child(1) { 
	width:50px; 
	padding-left:0px;
	padding-right:0px;
}
.orgHeader > ul > li:nth-child(2) {
	text-align: right;
	width:120px;	
	padding-left:0px;
	padding-right:0px;
}  
.orgHeader > ul > li .btn.disabled {
	color: #bcc3cf;
} 
.orgHeader > ul > li .userEdit,
.orgHeader > ul > li .delete,
.orgHeader > ul > li .add {
	width: 24px;
	height: 24px;
} 
.orgHeader > ul > li .userEdit {
	background: url("../images/icons/penB.svg") no-repeat center center;
	background-size: 24px 24px;
}
.orgHeader > ul > li .userEdit.disabled {
	background: url("../images/icons/penD.svg") no-repeat center center;
	background-size: 24px 24px;
} 
.orgHeader > ul > li .delete {
	background: url("../images/icons/trashB.svg") no-repeat center center;
	background-size: 24px 24px;
}
.orgHeader > ul > li .delete.disabled {
	background: url("../images/icons/trashC.svg") no-repeat center center;
	background-size: 24px 24px;
}
.orgHeader > ul > li .add {
	background: url("../images/icons/plusA.svg") no-repeat center center;
	background-size: 24px 24px;
	margin-right:10px;
} 
.orgHeader > ul > li .add.disabled {
	background: url("../images/icons/plusD.svg") no-repeat center center;
	background-size: 24px 24px; 
}  
.orgHeader > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.orgHeader > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}
.orgHeader > ul > li .purposeTo,
.orgHeader > ul > li .purposeTo,
.orgHeader > ul > li .purposeTo {
	background: #e9eaeb;
	padding: 3px 25px 3px 3px;
	font-size: 11px;
	display: inline-block;
	margin: 2px;
}
.orgHeader > ul > li .purposeTo .close,
.orgHeader > ul > li .purposeTo .close,
.orgHeader > ul > li .purposeTo .close {
	width: 20px;
	height: 20px;
	display: inline-block;
	padding: 0px;
}
/*** **************  ************** ****
		*** Org Table ***
**** **************  ************** ***/

.orgTable {
	width: 100%;
	padding: 0px;
	display: table; 
}
.orgTable > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.orgTable > ul.tHead > li {
    color: #16406d;
    border-bottom: 1px solid #63778a;
    background: #e9eaeb;
}
.orgTable > ul > li {
	padding: 10px 10px;
	display: table-cell; 
	vertical-align: middle;
	color: #16406d;
    font-size: 16px;
    text-align: left;
	border-bottom: 1px solid #aab4be; 
} 
.orgTable > ul > li > a {
	color: #0D3968;
}
.orgTable > ul > li > a.active,
.orgTable > ul > li > a:hover {
	color: #0D3968;
}
.orgTable > ul > li:nth-child(1) {
    width: 40px;
    text-align: center;
    color: #16406d;
    border-bottom: 1px solid #F2F2F2;
	padding-left:0px;
	padding-right:0px;
} 
.orgTable > ul > li:nth-child(1) .collapseExpand{
    float:none;
} 
.orgTable > ul.tHead > li {
    color: #16406d;
    border-bottom: 1px solid #63778a;
    background: #e9eaeb;
}
.orgTable > ul > li:nth-child(2) {
	text-align: center;
	width:50px;	
	padding-left:0px;
	padding-right:0px;
}
.orgTable > ul > li:nth-child(3) {
	width: 220px;
	text-align: left;
}
.orgTable > ul > li:nth-child(5) {
	width: 180px; 
}
.orgTable > ul > li:nth-child(6) {
	width: 230px; 
}
.orgTable > ul > li:nth-child(7) {
	width: 80px; 
	text-align: center;
}
.orgTable > ul > li:nth-child(8) {
	width: 40px;
	text-align: center;
	padding-right: 0px;
	padding-left:0px;
}
.orgTable > ul > li > a:hover {
	color: #000000;
}  
.orgTable > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.orgTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
} 
.orgTable > ul > li .purposeTo,
.orgTable > ul > li .purposeTo,
.orgTable > ul > li .purposeTo {
	background: #dce3e9;
	padding: 3px 5px 3px 5px;
	font-size: 12px;
	display: inline-block;
	margin: 2px;
	color:#16406d;
} 
.orgTable > ul > li .sgInfo {
	background: url("../images/icons/informationS.png") no-repeat center center;
	background-size: 24px 24px;
}
.orgTable > ul.tBody > li .orgListSM {
	display: none;
	width: 100%;
	float: left;  
}
.orgTableOuter {
	width: 100%;
	float: left;
	padding: 0px;
}
.orgTableOuter .panel-collapse {
	background: #ffffff;
	padding-left:40px;
	padding-right:40px;
}
.orgTableOuter .panel-collapse > .btn.btn-default{
	background: #c7d7e6; 
	color:#16406d;
	margin:10px 0px;
	font-family: 'open_sanssemibold';
}
.orgTableOuter .panel-collapse > .btn.btn-disabled{
	background:none; 
	color:#16406d;
	margin:10px 0px; 
}/*** **************  ************** ****
		*** Org Management Header ***
**** **************  ************** ***/

.orgHeader {
	width: 100%;
	padding: 0px;
	display: table;
	border-bottom: 1px solid #7e8690;
}
.orgHeader > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.orgHeader > ul > li {
	padding: 18px 15px 19px;
	display: table-cell;
	color: #0D3968;
	vertical-align: middle;
	font-size: 18px;
	font-family: 'open_sanssemibold';
}
.orgHeader > ul > li > a {
	color: #96a8bb;
	margin-right: 10px;
	padding: 0px 15px 0px;
	font-size: 18px;
	display: inline-block;
	font-family: 'open_sanssemibold';
}
.orgHeader > ul > li > a.active,
.orgHeader > ul > li > a:hover {
	color: #0D3968;
}
.orgHeader > ul > li:nth-child(1) { 
	width:50px; 
	padding-left:0px;
	padding-right:0px;
}
.orgHeader > ul > li:nth-child(2) {
	text-align: right;
	width:120px;	
	padding-left:0px;
	padding-right:0px;
}  
.orgHeader > ul > li .btn.disabled {
	color: #bcc3cf;
} 
.orgHeader > ul > li .userEdit,
.orgHeader > ul > li .delete,
.orgHeader > ul > li .add {
	width: 24px;
	height: 24px;
} 
.orgHeader > ul > li .userEdit {
	background: url("../images/icons/penB.svg") no-repeat center center;
	background-size: 24px 24px;
}
.orgHeader > ul > li .userEdit.disabled {
	background: url("../images/icons/penD.svg") no-repeat center center;
	background-size: 24px 24px;
} 
.orgHeader > ul > li .delete {
	background: url("../images/icons/trashB.svg") no-repeat center center;
	background-size: 24px 24px;
}
.orgHeader > ul > li .delete.disabled {
	background: url("../images/icons/trashC.svg") no-repeat center center;
	background-size: 24px 24px;
}
.orgHeader > ul > li .add {
	background: url("../images/icons/plusA.svg") no-repeat center center;
	background-size: 24px 24px;
	margin-right:10px;
}  
.orgHeader > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.orgHeader > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}
.orgHeader > ul > li .purposeTo,
.orgHeader > ul > li .purposeTo,
.orgHeader > ul > li .purposeTo {
	background: #e9eaeb;
	padding: 3px 25px 3px 3px;
	font-size: 11px;
	display: inline-block;
	margin: 2px;
}
.orgHeader > ul > li .purposeTo .close,
.orgHeader > ul > li .purposeTo .close,
.orgHeader > ul > li .purposeTo .close {
	width: 20px;
	height: 20px;
	display: inline-block;
	padding: 0px;
}
/*** **************  ************** ****
		*** Org Purpose Table ***
**** **************  ************** ***/

.orgPurposeTable {
	width: 100%;
	padding: 0px;
	display: table; 
}
.orgPurposeTable > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.orgPurposeTable > ul.tHead > li {
    color: #16406d;
    border-bottom: 1px solid #63778a;
    background: #e9eaeb;
}
.orgPurposeTable > ul > li {
	padding: 10px 10px;
	display: table-cell; 
	vertical-align: middle;
	color: #16406d;
    font-size: 16px;
    text-align: left;
	border-bottom: 1px solid #aab4be;
} 
.orgPurposeTable > ul > li > a.active,
.orgPurposeTable > ul > li > a:hover {
	color: #0D3968;
}
.orgPurposeTable > ul.tHead > li:first-child { 
    border-bottom: 1px solid #63778a;
}
.orgPurposeTable > ul > li:nth-child(1) { 
	width: 50px;
    text-align: center;
    color: #16406d;
    border-bottom: 1px solid #F2F2F2;
}
.orgPurposeTable > ul > li:nth-child(2) { 
	padding-left:0px; 
	width: 260px;
}
.orgPurposeTable > ul > li:nth-child(3) {
	width: 200px; 
} 
.orgPurposeTable > ul > li:nth-child(5) {
	width: 50px;
	text-align: center;
	padding-right: 15px;
} 
.orgPurposeTable > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.orgPurposeTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}

/* ****** ****** ****** ****** ****** **
 * ****** OrgU nits List ****** *
** ****** ****** ****** ****** ****** */

.orgUnitsList {
	width: 100%;
	display: table;
}
.orgUnitsList > ul {
	width: 100%;
	display: table-row;
}
.orgUnitsList > ul.tHead > li {
	color: #16406d;
	border-bottom: 1px solid #63778a;
	background: #e9eaeb;
}
.orgUnitsList > ul.tHead > li:first-child {
	border-left: 0px;
}
.orgUnitsList > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
}
.orgUnitsList > ul > li h6 {
	font-size: 14px;
	font-weight: normal;
	padding: 0px 0px 0px;
}
.orgUnitsList > ul > li p {
	margin: 0px;
	font-size: 13px;
	color: #b5b6b8;
}
.orgUnitsList > ul > li:nth-child(1) {
	width: 40px;
	text-align: center;
	color: #16406d;
	border-bottom: 1px solid #F2F2F2;
}
.orgUnitsList > ul > li:nth-child(2) {
	padding-left: 0px;
}

.orgUnitsList > ul > li:nth-child(2) {
	width: 40px;
	text-align: center;
}  
.orgUnitsList > ul.tBody > li:nth-child(2) > img {
	width: 32px;
	height: 32px;
} 
.orgUnitsList > ul > li:nth-child(5),
.orgUnitsList > ul > li:nth-child(6),
.orgUnitsList > ul > li:nth-child(7),
.orgUnitsList > ul > li:nth-child(8) {
	text-align: left;
	width:160px;
} 
.orgUnitsList > ul > li:nth-child(9) {
	width: 180px;  
}
.orgUnitsList > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.orgUnitsList > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
} 
.orgUnitsList > ul.tBody > li .unitsListSM,
.orgUnitsList > ul.tBody > li .unitsListSML {
	display: none;
	width: 100%;
	float: left;
}
.orgUnitsList > ul.tBody > li .unitsListAN {
	display: none;
	width: 100%;
	float: left;
	background: url("../images/ANTransparent.png") repeat;
	padding: 5px;
	font-size: 12px;
}
.orgUnitsList > ul.tBody > li .unitsListAN > b {
	width: 100%;
	float: left;
	font-weight: normal;
}
/* ****** ****** ****** ****** ****** **
 * ****** Report List Table ****** *
** ****** ****** ****** ****** ****** */

.reportListTable {
	width: 100%;
	display: table;
}
.reportListTable > ul {
	width: 100%;
	display: table-row;
}
.reportListTable > ul.tHead > li {
	color: #16406d;
	border-bottom: 1px solid #63778a;
	background: #e9eaeb;
}
.reportListTable > ul.tHead > li:first-child {
	border-left: 0px; 
}
.reportListTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
}
.reportListTable > ul > li > a {
	color: #16406d;
}
.reportListTable > ul.tHead > li:first-child {
    border-left: 0px;
    border-bottom: 1px solid #63778a;
}
.reportListTable > ul > li:nth-child(1) {
	width: 54px;
	text-align: center;
	color: #16406d;
	border-bottom: 1px solid #F2F2F2;
	padding-left: 5px;
	padding-right: 5px;
}
.reportListTable > ul > li:nth-child(2) {
	width: 50px;
	padding-left: 0px;
	text-align: center;  
	padding-right:0px;
}  
.reportListTable > ul > li:nth-child(4) {
	width: 20%; 
}  
.reportListTable > ul > li:nth-child(5) {
	width: 10%;
	text-align:center;
} 
.reportListTable > ul > li:nth-child(6) {
	width: 12%; 
} 
.reportListTable > ul > li:nth-child(7) {
	width: 10%;
	text-align:center;
} 
.reportListTable > ul > li:nth-child(8) {
	width: 58px;
	text-align: center;
} 
.reportListTable > ul > li .reportType {
	width: 32px;
	height: 32px; 
	display: inline-block;
	position:relative;
}
.reportListTable > ul > li .reportType > img {
	width: 32px;
	height: 32px;
} 
.reportListTable > ul > li .reportType.scheduleReport:before {
	position: absolute;
	top:-8px;
	right:-8px;
	background: url("../images/icons/iconScheduler.svg") no-repeat center center;
	background-size: 20px 20px;
	width:20px;
	height:20px;
	content: " ";  
} 
.reportListTable > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.reportListTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}
.reportListTable > ul.tBody > li .reportListBSM {
	display: none;  
	background:#ffffff;
	padding:5px;
	font-size:14px; 
} 
.reportListTable > ul.tBody > li .reportListSM {
	display: none;  
	background:#ffffff;
	padding:5px;
	font-size:12px; 
} 
.reportListTable > ul > li .reportDetails {
	background: url("../images/icons/infoReport.svg") no-repeat center center;
	background-size: 24px auto;
}
.reportListTable > ul > li .scheduleDetails {
	background: url("../images/icons/iconReportInProgress.svg") no-repeat center center;
	background-size: 24px auto;
}
.reportListTable > ul > li .delayedDetails {
	background: url("../images/icons/iconReportGenerationFailed.svg") no-repeat center center;
	background-size: 24px auto;
}
/* ****** ****** ****** ****** ****** **
 * ****** HACCP Report List Table ****** *
** ****** ****** ****** ****** ****** */

.HACCPreportListTable {
	width: 100%;
	display: table;
}
.HACCPreportListTable > ul {
	width: 100%;
	display: table-row;
}
.HACCPreportListTable > ul.tHead > li {
	color: #16406d;
	border-bottom: 1px solid #63778a;
	background: #e9eaeb;
}
.HACCPreportListTable > ul.tHead > li:first-child {
	border-left: 0px; 
}
.HACCPreportListTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
}
.HACCPreportListTable > ul > li > a {
	color: #16406d;
}
.HACCPreportListTable > ul.tHead > li:first-child {
    border-left: 0px;
    border-bottom: 1px solid #63778a;
}
.HACCPreportListTable > ul > li:nth-child(1) {
	width: 54px;
	text-align: center;
	color: #16406d;
	border-bottom: 1px solid #F2F2F2;
	padding-left: 5px;
	padding-right: 5px;
}
.HACCPreportListTable > ul > li:nth-child(2) {
	width: 50px;
	padding-left: 0px;
	text-align: center;  
	padding-right:0px;
}  
.reportListTable > ul > li:nth-child(4) {
	width: 20%; 
}  
.HACCPreportListTable > ul > li:nth-child(5) {
	width: 10%;
	text-align:center;
}  
.HACCPreportListTable > ul > li:nth-child(6) {
	width: 58px;
	text-align: center;
} 
.HACCPreportListTable > ul > li .reportType {
	width: 32px;
	height: 32px; 
	display: inline-block;
	position:relative;
}
.HACCPreportListTable > ul > li .reportType > img {
	width: 32px;
	height: 32px;
} 
.HACCPreportListTable > ul > li .reportType.scheduleReport:before {
	position: absolute;
	top:-8px;
	right:-8px;
	background: url("../images/icons/iconScheduler.svg") no-repeat center center;
	background-size: 20px 20px;
	width:20px;
	height:20px;
	content: " ";  
} 
.HACCPreportListTable > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.HACCPreportListTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
} 
.HACCPreportListTable > ul.tBody > li .reportListSM {
	display: none;  
	background:#ffffff;
	padding:5px;
	font-size:12px; 
}  

/* ****** ****** ****** ****** ****** **
 * ****** Report Schedule List Table ****** *
** ****** ****** ****** ****** ****** */ 

.reportScdlListTable {
	width: 100%;
	display: table;
}
.reportScdlListTable > ul {
	width: 100%;
	display: table-row;
}
.reportScdlListTable > ul.tHead > li {
	color: #16406d;
	border-bottom: 1px solid #63778a;
	background: #e9eaeb;
}
.reportScdlListTable > ul.tHead > li:first-child {
    border-left: 0px;
    border-bottom: 1px solid #63778a;
} 
.reportScdlListTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
}
.reportScdlListTable > ul > li > a {
	color: #16406d;
}
.reportScdlListTable > ul > li:nth-child(1) {
	width: 54px;
	text-align: center;
	color: #16406d;
	border-bottom: 1px solid #F2F2F2;
	padding-left: 5px;
	padding-right: 5px;
}
.reportScdlListTable > ul > li:nth-child(2) {
	width: 50px;
	padding-left: 0px;
	text-align: center;
	padding-top:0px;
	padding-bottom:0px;
	padding-right:0px;
}  
.reportScdlListTable > ul > li:nth-child(4) {
	width: 10%; 
}  
.reportScdlListTable > ul > li:nth-child(5) {
	width: 20%; 
} 
.reportScdlListTable > ul > li:nth-child(6) {
	width: 12%; 
} 
.reportScdlListTable > ul > li:nth-child(7) {
	width: 10%;
	text-align:center;
} 
.reportScdlListTable > ul > li:nth-child(8) {
	width: 58px;
	text-align: center;
} 
.reportScdlListTable > ul > li .reportType {
	width: 50px;
	height: 50px; 
	display: block;
}
.reportScdlListTable > ul > li .reportType > img {
	width: 42px;
	height: 42px;
} 
.reportScdlListTable > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.reportScdlListTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}
.reportScdlListTable > ul.tBody > li .reportListBSM {
	display: none;  
	background:#ffffff;
	padding:5px;
	font-size:14px; 
} 
.reportScdlListTable > ul.tBody > li .reportListSM {
	display: none;  
	background:#ffffff;
	padding:5px;
	font-size:12px; 
} 
.reportScdlListTable > ul > li .reportDetails {
	background: url("../images/icons/infoReport.svg") no-repeat center center;
	background-size: 24px 24px;
}
/*** **************  ************** ****
		*** New Report Generate ***
**** **************  ************** ***/

.newReportWrapper {
	width: 100%;
	float: left;
	padding: 25px 20px;
}
.newReportWrapperL {
	width: 47%; 
	float: left; 
	max-width:500px;
}
.newReportWrapperR {
	width: 53%;
	max-width:500px;
	float: left; 
	padding: 0px 0px 0px 25px; 
}
.newReportWrapperR .maxDuration {
	width: 100%;
	float: left;
	padding: 5px 0px 10px;
	color: #0D3968;
	font-size: 14px;
}
.newReportWrapperR .react-datepicker-wrapper {
	width: auto;
}
.newReportWrapperR .newReportForm .input-group > .react-datepicker-wrapper > .react-datepicker__input-container > input {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 0px 5px 0px;
	height: auto;
	background: #f2f2f2 !important;
	color: #0D3968;
	font-size: 20px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
	font-weight: normal;
}
/* ****** ****** ****** ****** ****** **
 * ****** Assign to Brands Table ****** *
** ****** ****** ****** ****** ****** */

.reportTypeListCBox {
	width: 100%;
	display: table;
	padding-top: 25px;
}
.reportTypeListCBox > ul {
	width: 100%;
	display: table-row;
} 
.reportTypeListCBox > ul > li {
	display: table-cell;
	padding: 5px 0px;
	vertical-align: middle;
	color: #16406d;
	font-size: 16px;
	text-align: left;
} 
.reportTypeListCBox > ul > li .customCheckbox {
	margin:0px;
	float:left;
}
.reportTypeListCBox > ul > li .customCheckbox label {
	color: #0D3968;
	font-size: 16px; 
}
.reportTypeListCBox > ul > li .customCheckbox.customCheckboxWOlabel input[type=checkbox] + label {
	padding-left: 34px;
}
.reportTypeListCBox > ul > li .reportTypeInfo {
	background: url("../images/icons/informationC.png") no-repeat center center;
	background-size: 24px 24px;
	width:24px;
	height:24px;
	float:left;
	margin-left:5px;
} 
/* ****** ****** ****** ****** ****** **
 * ****** Report Options List ****** *
** ****** ****** ****** ****** ****** */

.reportOptions {
	width: 100%;
	float: left;
	padding: 0px 0px 5px 0px;
	clear: both;
}
.reportOptions > h6 {
	width: 100%;
	float: left;
	padding: 0px 0px 15px 0px;
	clear: both;
	color: #0d3968;
	font-size: 16px; 
}
.reportOptions .reportTypeInfo {
	background: url("../images/icons/infoReport.svg") no-repeat center center;
	background-size: 20px 20px;
	width:20px;
	height:20px; 
	display:inline-block;
	margin-left:5px;
}
.reportOptions .noBrandSelect { 
	width:340px;
	float:left;
	padding:35px 0px; 
	color:#9d9d9d;
	font-size:16px;
	text-align:center; 
} 
.reportOptionsList {
	width: 100%;
	float:left; 
}
.reportOptionsList > ul {
	width: 100%;
	float:left;
} 
.reportOptionsList > ul > li {
	float:left;
	width:50%;
	padding: 5px 0px;
	vertical-align: middle;
	color: #16406d;
	font-size: 16px;
	text-align: left;
} 
.reportOptionsList > ul > li:nth-child(odd) {
	padding-right:5px;
}   
.reportOptionsList > ul > li .customCheckbox {
	margin:0px;
	float:left;
}
.reportOptionsList > ul > li .customCheckbox label {
	color: #0D3968;
	font-size: 16px; 
	height:auto;
}
.reportOptionsList > ul > li .customCheckbox input[type=checkbox] + label { 
	background: url("../images/icons/checkAllOff.svg") no-repeat left top;
	background-size: 24px 24px; 
}
.reportOptionsList > ul > li .customCheckbox input[type=checkbox] + label,
.reportOptionsList > ul > li .customCheckbox input[type=checkbox] + i + label,
.reportOptionsList > ul > li .customCheckbox input[type=checkbox] + input + label { 
	background: url("../images/icons/checkAllOff.svg") no-repeat left top;
	background-size: 24px 24px; 
}
.reportOptionsList > ul > li .customCheckbox input[type=checkbox]:checked + label,
.reportOptionsList > ul > li .customCheckbox input[type=checkbox]:checked + i + label,
.reportOptionsList > ul > li .customCheckbox input[type=checkbox]:checked + input + label {
	background: url("../images/icons/checkAllOn.svg") no-repeat left center;
	background-size: 24px 24px;
}
.reportOptionsList > ul > li .customCheckbox.customCheckboxWOlabel input[type=checkbox] + label {
	padding-left: 30px; 
}
/* ****** ****** ****** ****** ****** **
 * ****** Report Type List ****** *
** ****** ****** ****** ****** ****** */

.reportTypeList {
	width: 100%;
	float: left;
	padding: 0px 0px 5px 0px;
	clear: both;
}
.reportTypeList > h6 {
	width: 100%;
	float: left;
	padding: 0px 0px 45px 0px;
	clear: both;
	color: #042546;
	font-size: 18px;
	font-weight: normal;
}
.reportTypeList > ul {
	width: 100%;
	float: left;
	clear: both;
	margin-bottom:25px;
}
.reportTypeList > ul > li {
	float: left; 
	width:100%;
	padding: 0px 0px 10px 0px;
	text-align: center;
}
.reportTypeList > ul > li:nth-child(1) { 
	padding:0px; 
}
.reportTypeList > ul > li .ErrorReportIcon {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 0px 0px;
}
.reportTypeList > ul > li label {
	width: 100%;
    float: left;
    padding: 0px 0px 5px;
	margin:0px;
    clear: both;
    color: #0d3968;
	font-size: 16px; 
	text-align:left;
}
.reportTypeList > ul > li .ErrorReportIcon > img {
	width: 60px;
}
.reportTypeList > ul > li > .customCheckbox {
	margin: 0px;
}
.reportTypeList > ul > li > .customCheckbox input[type=checkbox] + label,
.reportTypeList > ul > li > .customCheckbox input[type=checkbox] + i + label,
.reportTypeList > ul > li > .customCheckbox input[type=checkbox] + input + label {
	font-size: 14px;
	color: #0d3968;
	margin: 0px;
}
.reportTypeList > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	max-width:340px;
	padding: 5px 0px 5px 0px;
	height: auto;
	background: #f2f2f2 !important;
	color: #0D3968;
	font-size: 16px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
}
.reportTypeList > ul > li select.form-control {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: #f2f2f2 url("../images/icons/markdownA.svg") no-repeat right center !important;
	width: 100%;
}
.reportTypeList > ul > li select.form-control option {
	font-size: 16px;
}
.reportTypeList > ul > li select::-ms-expand {
	display: none;
}
/* ****** ****** ****** ****** ****** **
 * ****** New Report Now/Schedule Table ****** *
** ****** ****** ****** ****** ****** */
 
.newReportNS {
	width: 100%;
	float: left;
	padding: 0px 0px 15px 0px;
	clear: both;
}
.newReportNS.forMObileR {
	display:none;
}
.newReportNS .customRadioBtn label { 
	color: #0d3968;
	font-size: 18px;
	font-weight: normal;
}  
.newReportNS > ul {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 0px;
}
.newReportNS > ul > li {
	float: left;  
}
.newReportNS > ul > li:nth-child(1) { 
	width:220px;
	padding: 0px 10px 0px 0px;
} 
.newReportNS > ul > li .customRadioBtn input[type=radio] + label,
.newReportNS > ul > li .customRadioBtn input[type=radio] + i + label,
.newReportNS > ul > li .customRadioBtn input[type=radio] + input + label { 
	color: #0d3968;
	font-size: 18px;
	font-family: 'open_sanssemibold';
}
.newReportNS > ul > li .customRadioBtn { 
	margin-top:0px;
	margin-right:0px;
}
/* ****** ****** ****** ****** ****** **
 * ****** New Report  Table ****** *
** ****** ****** ****** ****** ****** */
.datePeriodDiv {
	width: 100%;
	display: table;  
}
.datePeriodDiv > ul {
	width: 100%;
	display: table-row;
} 
.datePeriodDiv > ul > li {
	display: table-cell;
	padding: 5px 0px;
	vertical-align: top;
	color: #16406d;
	font-size: 16px;
	text-align: left;
} 
.datePeriodDiv > ul > li:nth-child(1) {
	width:130px;
	padding-top:0px;
} 
.datePeriodDiv > ul > li:nth-child(2) {
	padding-top:0px;
} 
.datePeriodDiv > ul > li .customRadioBtn { 
    margin: 0px 10px 0px 0px;
}
.datePeriodDiv > ul > li .newReportForm {  
    clear: inherit;
	float:none;
	width:auto;
	display:inline-block;
}
.datePeriodDiv > ul > li .newReportForm > ul > li{
	width:100%;
	padding:0px 0px 10px;
} 
.datePeriodDiv > ul > li .newReportForm > ul > li:last-child{ 
	padding:0px 0px 0px;
} 
.datePeriodDiv > ul > li .newReportForm > ul > li .customRadioBtn {
    margin: 10px 10px 0px 0px;
	float:left;
}
.datePeriodDiv > ul > li .newReportForm > ul > li .currentDaysDiv { 
	width: 180px;
	float:right; 
} 
.datePeriodDiv > ul > li .newReportForm > ul > li .daysNumber { 
	width: 40px; 
	float:left;
	margin-left:10px;
}
.datePeriodDiv > ul > li .newReportForm > ul > li .daysNumber .form-control{  
	text-align:center;
	padding: 5px 0px !important;
}  
/* ****** ****** ****** ****** ****** **
 * ****** New Report  Table ****** *
** ****** ****** ****** ****** ****** */
.newReportFormT {
	width: 100%;
	display: table; 
}
.newReportFormT > ul {
	width: 100%;
	display: table-row;
} 
.newReportFormT > ul > li {
	display: table-cell;
	padding: 5px 0px 5px;
	vertical-align: top;
	color: #16406d;
	font-size: 16px;
	text-align: left; 
}  
.newReportFormT.noEndDate > ul > li { 
	padding: 0px 0px 5px; 
	margin-top:-5px;
}  
.newReportFormT > ul > li:nth-child(1) {
	width:220px;
	padding-right:10px; 
}  
.newReportFormT > ul > li .customRadioBtn {
	margin-right:0px;
	float:left;
	margin-top:7px;
}  
.newReportFormT > ul > li .customRadioBtn label {
	margin-right:5px;
} 
.newReportForm .newReportFormT > ul > li .currentDaysDiv { 
	width: 180px;
	float:right; 
} 
.reportScheduleForm {
	width: 100%;
	float: left;
	padding: 0px 0px 5px 0px;
	clear: both;
}   
.reportScheduleForm > ul {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 0px;
}
.reportScheduleForm > ul > li {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 0px;
}
.reportScheduleForm > ul > li:nth-child(2) {
	text-align:right;
}

.newReportForm#GenerateNowView {
	margin-top: 35px;
}
.newReportForm.GenerateNowEmail,
.newReportForm.selectCustomerReport {
	margin-top: 25px;
}
.newReportForm.GenerateNowEmail h6{
	padding-bottom:15px;
}
.newReportForm {
	width: 100%;
	float: left;
	padding: 0px 0px 5px 0px;
	clear: both;
} 
.datePeriodDiv h6, 
.newReportForm h6 {
	width: 100%;
	float: left;
	padding: 10px 0px 10px 0px;
	clear: both;
	color: #0d3968;
	font-size: 16px; 
}
.datePeriodDiv h6 { 
	padding: 15px 0px 10px 0px; 
}
.newReportForm h5 {
	width: 100%;
	float: left;
	padding: 10px 0px 5px 0px;
	clear: both;
	color: #0d3968;
	font-size: 18px;
	font-weight: normal;
} 
.newReportForm > ul {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 0px;
}
.newReportForm > ul > li {
	float: left;
	padding: 0px 10px 0px 0px;
}
.newReportForm > ul > li:first-child {
	padding-right: 20px;
}
.newReportForm > ul > li:last-child {
	padding-right: 0px;
}
.newReportForm label {
	float: left;
	padding: 10px 10px 0px 0px;
	color: #0D3968;
    font-size: 16px;
	font-weight: normal;
}
.newReportForm .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 0px 5px 0px;
	height: auto;
	background: #f2f2f2 !important;
	color: #0D3968;
	font-size: 16px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
}
.newReportForm textarea.form-control { 
	border: 1px solid #6e88a4; 
	padding: 5px;
}
.newReportForm select.form-control {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: #f2f2f2 url("../images/icons/markdownA.svg") no-repeat right center !important;
	width: 220px;
}
.newReportForm select.form-control option {
	font-size: 16px;
}
.newReportForm select::-ms-expand {
	display: none;
}
.newReportForm .form-control.is-invalid { 
	border-bottom: 1px solid #f93251; 
}
.newReportForm textarea.form-control.is-invalid { 
	border: 1px solid #f93251; 
}
.newReportForm .invalid-feedback { 
	margin-top:3px;
	background: url("../images/icons/AttentionRed.svg") no-repeat left top;
	background-size: 20px 20px;
	display:block;
	padding-left:22px;
	clear: both;
    float: left;
	color:#f93251;
	text-align: left;
}
.newReportForm .input-group {
	float: left;
	width: 142px;
}
.newReportForm .input-group.selectRepetitionPattern {
	float: left;
	width:200px;
}
.newReportForm .endDatePicker {
	float: right;
	width: 142px;
	display:inline-block;
}
.newReportForm .input-group.timeSelection { 
	width: 180px; 
	float:right;
}
.newReportForm .input-group.startDateSelection { 
	width: 142px; 
	float:right;
} 
.newReportForm .timeSelection select.form-control.timeHH,
.newReportForm .timeSelection select.form-control.timeMM,
.newReportForm .timeSelection select.form-control.timeAmPm { 
	width:45px; 
	font-size:16px;
}  
.newReportForm .timeSelection select.form-control.timeMM { 
	margin-left:10px;
	margin-right:10px;
}  
.newReportForm .input-group.SelectCustomerGroup {
	float: left;
	width: 220px;
}
.newReportForm .dayDatepicker {
	background:#f2f2f2 url("../images/icons/dateS.svg") no-repeat right center !important;
	background-size: 24px 24px;
	padding-right:30px; 
	font-size: 16px !important;
    font-weight: normal;
} 
.newReportForm input[type="time"]::-webkit-calendar-picker-indicator{
	background:#f2f2f2 url("../images/icons/clockS.svg") no-repeat right center !important;
	background-size: 24px 24px; 
	cursor:pointer;
}
.newReportForm .customRadioBtn label { 
	padding-top:0px;
}
.newReportForm .customRadioBtn input[type=radio] + label,
.newReportForm .customRadioBtn input[type=radio] + i + label,
.newReportForm .customRadioBtn input[type=radio] + input + label { 
	color: #0D3968;
    font-size: 16px; 
	padding-top:3px;
}
.chartHeaderTitleTable {
	width: 100%;
	display: table;
}
.chartHeaderTitleTable > ul {
	width: 100%;
	display: table-row;
}
.chartHeaderTitleTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: top;
	text-align: left;
	font-size: 14px;
	color: #b4b5b7;
}
.chartHeaderTitleTable > ul > li:nth-child(1) {
	width: 130px;
}
.chartHeaderTitleTable > ul > li:nth-child(3) {
	text-align: right;
	width: 130px;
}
.chartHeaderTitleTable > ul > li:nth-child(3) > img {
	width: 16px;
}
.chartHeaderTitleTable > ul > li .chartTitleLgnd {
	padding: 0px 0px 0px;
}
.chartTitleLgnd {
	width: 100%;
	float: left;
	padding: 15px 0px 0px;
	font-size: 14px;
	color: #b4b5b7;
	text-align: center;
}
.chartValLgnd {
	width: 100%;
	float: left;
	padding: 0px;
	font-size: 36px;
	color: #fff;
	text-align: center;
	font-family: 'open_sanssemibold';
}
.chartValStatus {
	width: 100%;
	float: left;
	padding: 0px;
	font-size: 24px;
	text-align: center;
}
.chartValStatus.valGood {
	color: #efb302;
}
.chartValStatus.valExcellent {
	color: #009c43;
}
.chartValStatus.valPoor {
	color: #dc0026;
}
.legendSectionEquip {
	text-align: left;
	margin: 20px 0px 0 75px;
}
.legendSectionEquip > li {
	display: inline-block;
	margin: 0 77px 0 0;
	color: #fff;
	font-size: 12px;
}
.legendSectionEquip > li > span {
	height: 18px;
	width: 18px;
	margin-right: 5px;
	display: inline-block;
	vertical-align: bottom;
}
.legendSectionEquip li:nth-of-type(1) span {
	background: #009c43;
}
.legendSectionEquip li:nth-of-type(2) span {
	background: #efb302;
}
.legendSectionEquip li:nth-of-type(3) span {
	background: #dc0026;
}
.scheduleUpdateWrapperR .react-datepicker-wrapper,
.scheduleUpdateWrapper .react-datepicker-wrapper {
	width: auto;
}
.scheduleUpdateWrapperR .scheduleUpdateForm .input-group > .react-datepicker-wrapper > .react-datepicker__input-container > input,
.scheduleUpdateWrapper .scheduleUpdateForm .input-group > .react-datepicker-wrapper > .react-datepicker__input-container > input,
.scheduleUpdateWrapperR .scheduleUpdateForm .input-group > #selectTime,
.scheduleUpdateWrapper .scheduleUpdateForm .input-group > #selectTime {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 30px 5px 18px;
	height: auto;
	background-color: #f2f2f2;
	color: #0D3968;
	font-size: 20px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
	font-weight: normal;
}
.react-datepicker__input-container #ScheduleDate::-webkit-input-placeholder,
#selectTime::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	color: #0D3968;
}
.react-datepicker__input-container #ScheduleDate:-moz-placeholder,
#selectTime::-webkit-input-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #0D3968;
}
.react-datepicker__input-container #ScheduleDate::-moz-placeholder,
#selectTime::-webkit-input-placeholder {
	/* Mozilla Firefox 19+ */
	color: #0D3968;
}
.react-datepicker__input-container #ScheduleDate:-ms-input-placeholder,
#selectTime::-webkit-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #0D3968;
}
.react-datepicker__input-container #ScheduleDate::-ms-input-placeholder,
#selectTime::-webkit-input-placeholder {
	/* Microsoft Edge */
	color: #0D3968;
}
.react-datepicker__input-container #ScheduleDate::placeholder,
#selectTime::-webkit-input-placeholder {
	/* Most modern browsers support this now. */
	color: #0D3968;
}
.panStates {
	width: 100%;
	height: 500px;
	float: left;
}
.legendSection {
	text-align: left;
	margin: 20px 0px 0 75px;
}
.legendSection > li {
	display: inline-block;
	margin: 0 77px 0 0;
	color: #fff;
	font-size: 12px;
}
.legendSection > li > span {
	height: 18px;
	width: 18px;
	margin-right: 5px;
	display: inline-block;
	vertical-align: bottom;
}
.legendSection li:nth-of-type(1) span {
	background: #009c43;
}
.legendSection li:nth-of-type(2) span {
	background: #dc0026;
}
.legendSection li:nth-of-type(3) span {
	background: #b4b4b4;
}
.legendSection li:nth-of-type(4) span {
	background: #2d3034;
	border: 1px solid #fff;
}
.legendSection li:nth-of-type(5) span {
	background: #fff;	
}
.legendSection li:nth-of-type(6) span {
	background: url("../images/icons/error.png") no-repeat center center;
	background-size: center;
}
/* Schedule time custom seelct option css starts */

.scheduleUpdateWrapperR .scheduleUpdateForm .input-group > #selectTime {
	cursor: pointer;
	margin-bottom: 0px;
	text-transform: uppercase;
}
.scheduleUpdateWrapperR .scheduleUpdateForm .input-group .dropdownTimeOptions {
	width: 100%;
	border: 1px solid #aeaeae;
	border-top: 0;
	overflow: auto;
	max-height: 340px;
}
.scheduleUpdateWrapperR .scheduleUpdateForm .input-group .dropdownTimeOptions > li > a {
	color: #0d3968;
	padding: 4px 0px 2px 20px;
	display: block;
}
.scheduleUpdateWrapper .scheduleUpdateForm .input-group > #selectTime {
	cursor: pointer;
	margin-bottom: 0px;
	text-transform: uppercase;
}
.scheduleUpdateWrapper .scheduleUpdateForm .input-group .dropdownTimeOptions {
	width: 100%;
	border: 1px solid #aeaeae;
	border-top: 0;
	overflow: auto;
	max-height: 340px;
}
.scheduleUpdateWrapper .scheduleUpdateForm .input-group .dropdownTimeOptions > li > a {
	color: #0d3968;
	padding: 4px 0px 2px 20px;
	display: block;
}
/* Schedule time custom seelct option css Ends */

.PanvsTimedropdown {
	float: right;
	font-size: 12px;
	text-transform: none;
	width: auto;
	padding: 0px 5px;
	height: auto;
	background: transparent;
	color: #ffffff;
	border: 0px;
	margin-right: 10px;
	cursor: pointer;
	box-shadow: none;
	position: absolute;
	right: 0px;
}
.norecordsfound {
	width: 100%;
	float: left;
	padding: 15px 0px;
	color: #fff;
	text-align: center;
}
.noRecordMsg {
	width: 100%;
	height: 200px;
	text-align: center;
	color: #afb1b2;
	font-size: 14px;
	margin-top: 20%;
}
.noRecordMsgBlack {
	width: 100%;
	height: 200px;
	text-align: center;
	color: #000;
	font-size: 16px;
	margin-top: 10%;
}
/* ****** ****** ****** ****** ****** **
 * ****** Cooking Data and Cleaning Data Table ****** *
** ****** ****** ****** ****** ****** */

.unitQualityPrncTableOuter,
.filtrationDataTableOuter,
.TPMValueTableOuter,
.productMixTableOuter,
.utilizationTableOuter,
.holdingDataTableOuter,
.TPMValueTableOuter,
.disposeInformationTableOuter,
.cleaningDataTableOuter,
.cookingDataTableOuter {
	width: 100%;
	float: left;
}
.unitQualityPrncExpTable,
.filtrationDataExpTable,
.productMixExpTable,
.garlandPrctExpTable,
.utilizationExpTable,
.utilizationTable,
.holdingDataExpTable,
.unitQualityPrncTable,
.filtrationDataTable,
.productMixTable,
.garlandPrctTable,
.holdingDataTable,
.TPMValueTable,
.disposeInformationTable,
.cleaningDataTable,
.cookingDataTable {
	width: 100%;
	display: table;
}
.unitQualityPrncExpTable > ul,
.filtrationDataExpTable > ul,
.productMixExpTable > ul,
.garlandPrctExpTable > ul,
.utilizationExpTable > ul,
.utilizationTable > ul,
.holdingDataExpTable > ul,
.unitQualityPrncTable > ul,
.filtrationDataTable > ul,
.productMixTable > ul,
.garlandPrctTable > ul,
.holdingDataTable > ul,
.TPMValueTable > ul,
.disposeInformationTable > ul,
.cleaningDataTable > ul,
.cookingDataTable > ul {
	width: 100%;
	display: table-row;
}
.unitQualityPrncExpTable > ul.tHead li,
.filtrationDataExpTable > ul.tHead li,
.productMixExpTable > ul.tHead li,
.garlandPrctExpTable > ul.tHead li,
.utilizationExpTable > ul.tHead li,
.utilizationTable > ul.tHead li,
.holdingDataExpTable > ul.tHead li,
.unitQualityPrncTable > ul.tHead li,
.filtrationDataTable > ul.tHead li,
.productMixTable > ul.tHead li,
.holdingDataTable > ul.tHead li,
.TPMValueTable > ul.tHead li,
.disposeInformationTable > ul.tHead li,
.cleaningDataTable > ul.tHead li,
.cookingDataTable > ul.tHead li {
	border-bottom: 1px solid #4a4a4a;
	color: #9fa8b5;
}
.unitQualityPrncExpTable > ul > li,
.filtrationDataExpTable > ul > li,
.productMixExpTable > ul > li,
.garlandPrctExpTable > ul > li,
.utilizationExpTable > ul > li,
.utilizationTable > ul > li,
.holdingDataExpTable > ul > li,
.unitQualityPrncTable > ul > li,
.filtrationDataTable > ul > li,
.productMixTable > ul > li,
.garlandPrctTable > ul > li,
.holdingDataTable > ul > li,
.TPMValueTable > ul > li,
.disposeInformationTable > ul > li,
.cleaningDataTable > ul > li,
.cookingDataTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #222528;
	color: #ffffff;
	font-size: 14px;
	text-align: left;
}
.utilizationExpTable > ul > li > span,
.utilizationTable > ul > li > span,
.holdingDataExpTable > ul > li > span,
.holdingDataTable > ul > li > span,
.TPMValueTable > ul > li > span,
.disposeInformationTable > ul > li > span,
.cleaningDataTable > ul > li > span,
.cookingDataTable > ul > li > span {
	color: #9fa8b5;
}
.cleaningDataTable > ul.tBody > li .cleaningTypeSM {
	display: none;
	width: 100%;
	float: left;
}
.filtrationDataExpTable > ul.tBody > li:nth-child(1),
.productMixExpTable > ul.tBody > li:nth-child(1),
.garlandPrctExpTable > ul.tBody > li:nth-child(1),
.holdingDataExpTable > ul.tBody > li:nth-child(1),
.filtrationDataTable > ul.tBody > li:nth-child(1),
.productMixTable > ul.tBody > li:nth-child(1),
.garlandPrctTable > ul.tBody > li:nth-child(1),
.holdingDataTable > ul.tBody > li:nth-child(1),
.TPMValueTable > ul.tBody > li:nth-child(1),
.disposeInformationTable > ul.tBody > li:nth-child(1),
.cleaningDataTable > ul.tBody > li:nth-child(1),
.cookingDataTable > ul.tBody > li:nth-child(1) {
	border-bottom: 1px solid #2d3034;
}
.filtrationDataExpTable > ul > li:nth-child(1),
.productMixExpTable > ul > li:nth-child(1),
.garlandPrctExpTable > ul > li:nth-child(1),
.holdingDataExpTable > ul > li:nth-child(1),
.filtrationDataTable > ul > li:nth-child(1),
.productMixTable > ul > li:nth-child(1),
.garlandPrctTable > ul > li:nth-child(1),
.holdingDataTable > ul > li:nth-child(1),
.TPMValueTable > ul > li:nth-child(1),
.disposeInformationTable > ul > li:nth-child(1),
.cleaningDataTable > ul > li:nth-child(1),
.cookingDataTable > ul > li:nth-child(1) {
	width: 54px;
	text-align: center;
	color: #9fa8b5;
	padding-left:5px;
	padding-right:5px;
}
.cookingDataTable > ul > li:nth-child(2) {
	padding-left: 0px;
}
.cookingDataTable > ul > li:nth-child(3),
.cookingDataTable > ul > li:nth-child(4),
.cookingDataTable > ul > li:nth-child(5) {
	width: 220px;
	text-align: center;
}
/* ****** ****** ****** ****** ****** **
 * ****** TPM Value Table ****** *
** ****** ****** ****** ****** ****** */

.TPMValueTable > ul > li:nth-child(2) {
	padding-left: 0px;
	width: 220px;
}
.TPMValueTable > ul > li:nth-child(2) span {
	color:#9fa8b5;
}
.TPMValueTable > ul > li:nth-child(3),
.TPMValueTable > ul > li:nth-child(4),
.TPMValueTable > ul > li:nth-child(5),
.TPMValueTable > ul > li:nth-child(6),
.TPMValueTable > ul > li:nth-child(7),
.TPMValueTable > ul > li:nth-child(8),
.TPMValueTable > ul > li:nth-child(9),
.TPMValueTable > ul > li:nth-child(10),
.TPMValueTable > ul > li:nth-child(11),
.TPMValueTable > ul > li:nth-child(12),
.TPMValueTable > ul > li:nth-child(13),
.TPMValueTable > ul > li:nth-child(14) {
	min-width: 78px;
	text-align: center;
	padding-left:5px;
	padding-right:5px;
}
.TPMValueTable > ul > li:last-child {
	width: 65px;
	padding-left:5px;
	padding-right:5px;
	text-align: center;
}
.TPMValueTable > ul > li:nth-child(2) .frypotWiseSM {
	display: none;
	background: url(../images/ANTransparent.png) repeat;
	padding: 5px;
	font-size: 12px;
}
.TPMValueTable > ul > li:nth-child(2) .frypotWiseSM > div {
	display: inline-block;
	margin: 0px 2px 2px 0px;
	background: #2d3034;
	padding: 5px;
}
.TPMValueTable > ul > li.goodStatus{
	color: #FFBE00;
}
.TPMValueTable > ul > li.poorStatus{
	color: #f93251;
}
.TPMValueTable > ul > li.greatStatus {
	color: #3cce82;
}
/* ****** ****** ****** ****** ****** **
 * ****** Dispose Information Table ****** *
** ****** ****** ****** ****** ****** */

.disposeInformationTable > ul > li:nth-child(2) {
	width: 220px;
	padding-left: 0px;
}
.disposeInformationTable > ul > li:nth-child(2) span,
.disposeInformationTable > ul > li:nth-child(3) span {
	color: #9fa8b5;
}
.disposeInformationTable > ul > li:nth-child(4),
.disposeInformationTable > ul > li:nth-child(5),
.disposeInformationTable > ul > li:nth-child(6) {
	width: 185px;
	text-align: center;
}
.disposeInformationTable > ul > li:nth-child(7) {
	width: 210px;
	text-align: center;
}
.disposeInformationTable > ul.tBody > li .disposeInformationSM {
	display: none;
	width: 100%;
	float: left;
	background: url(../images/ANTransparent.png) repeat;
	padding: 5px;
	font-size: 12px;
	margin-top: 5px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Cleaning Data Table ****** *
** ****** ****** ****** ****** ****** */

.cleaningDataTable > ul > li:nth-child(2) {
	width: 320px;
	padding-left: 0px;
}
.cleaningDataTable > ul > li:nth-child(3) {
	width: 290px;
	text-align: center;
}
.cleaningDataTable > ul > li:nth-child(4) {
	width: 220px;
	text-align: center;
}
/* ****** ****** ****** ****** ****** **
 * ****** Data Table ****** *
** ****** ****** ****** ****** ****** */

.unitQualityPrncTableDiv,
.filtrationDataTableDiv,
.TPMValueTableDiv,
.productMixTableDiv,
.garlandPrctTableDiv,
.holdingDataTableDiv {
	width: 100%;
	float: left;
	min-height: 210px;
	margin-top: 10px;
}
.productMixTableDiv {
	margin-bottom: 25px;
}
.unitQualityPrncTableOuter .panel-collapse,
.filtrationDataTableOuter .panel-collapse,
.TPMValueTableOuter .panel-collapse,
.productMixTableOuter .panel-collapse,
.garlandPrctTableOuter .panel-collapse,
.utilizationTableOuter .panel-collapse,
.holdingDataTableOuter .panel-collapse {
	background: #2b363e;
}
.filtrationDataTable > ul > li:nth-child(2),
.productMixTable > ul > li:nth-child(2),
.garlandPrctTable > ul > li:nth-child(2),
.holdingDataTable > ul > li:nth-child(2) {
	padding-left: 0px;
}
.holdingDataTable > ul > li:nth-child(3),
.holdingDataTable > ul > li:nth-child(4),
.holdingDataTable > ul > li:nth-child(5) {
	width: 180px;
	text-align: center;
}
.holdingDataTable > ul > li:nth-child(6) {
	width: 200px;
	text-align: center;
}
.holdingDataTable > ul > li:nth-child(7) {
	width: 140px;
	text-align: center;
}
.holdingDataTable > ul > li:nth-child(8) {
	width: 110px;
	text-align: center;
}
.unitQualityPrncTable > ul > li .collapseExpand.collapsed,
.unitQualityPrncTable > ul > li .collapseExpand.collapsed:hover,
.unitQualityPrncTable > ul > li .collapseExpand.collapsed:focus,
.unitQualityPrncTable > ul > li .collapseExpand.collapsed:active,
.filtrationDataTable > ul > li .collapseExpand.collapsed,
.filtrationDataTable > ul > li .collapseExpand.collapsed:hover,
.filtrationDataTable > ul > li .collapseExpand.collapsed:focus,
.filtrationDataTable > ul > li .collapseExpand.collapsed:active,
.TPMValueTable > ul > li .collapseExpand.collapsed,
.TPMValueTable > ul > li .collapseExpand.collapsed:hover,
.TPMValueTable > ul > li .collapseExpand.collapsed:focus,
.TPMValueTable > ul > li .collapseExpand.collapsed:active,
.productMixTable > ul > li .collapseExpand.collapsed,
.productMixTable > ul > li .collapseExpand.collapsed:hover,
.productMixTable > ul > li .collapseExpand.collapsed:focus,
.productMixTable > ul > li .collapseExpand.collapsed:active,
.garlandPrctTable > ul > li .collapseExpand.collapsed,
.garlandPrctTable > ul > li .collapseExpand.collapsed:hover,
.garlandPrctTable > ul > li .collapseExpand.collapsed:focus,
.garlandPrctTable > ul > li .collapseExpand.collapsed:active,
.utilizationTable > ul > li .collapseExpand.collapsed,
.utilizationTable > ul > li .collapseExpand.collapsed:hover,
.utilizationTable > ul > li .collapseExpand.collapsed:focus,
.utilizationTable > ul > li .collapseExpand.collapsed:active,
.holdingDataTable > ul > li .collapseExpand.collapsed,
.holdingDataTable > ul > li .collapseExpand.collapsed:hover,
.holdingDataTable > ul > li .collapseExpand.collapsed:focus,
.holdingDataTable > ul > li .collapseExpand.collapsed:active {
	background: url("../images/icons/arrow2r_A.svg") no-repeat center center;
	background-size: 24px 24px;
	float: inherit;
	display: inline-block;
}
.unitQualityPrncTable > ul > li .collapseExpand,
.unitQualityPrncTable > ul > li .collapseExpand:hover,
.unitQualityPrncTable > ul > li .collapseExpand:focus,
.unitQualityPrncTable > ul > li .collapseExpand:active,
.filtrationDataTable > ul > li .collapseExpand,
.filtrationDataTable > ul > li .collapseExpand:hover,
.filtrationDataTable > ul > li .collapseExpand:focus,
.filtrationDataTable > ul > li .collapseExpand:active,
.TPMValueTable > ul > li .collapseExpand,
.TPMValueTable > ul > li .collapseExpand:hover,
.TPMValueTable > ul > li .collapseExpand:focus,
.TPMValueTable > ul > li .collapseExpand:active,
.productMixTable > ul > li .collapseExpand,
.productMixTable > ul > li .collapseExpand:hover,
.productMixTable > ul > li .collapseExpand:focus,
.productMixTable > ul > li .collapseExpand:active,
.garlandPrctTable > ul > li .collapseExpand,
.garlandPrctTable > ul > li .collapseExpand:hover,
.garlandPrctTable > ul > li .collapseExpand:focus,
.garlandPrctTable > ul > li .collapseExpand:active,
.utilizationTable > ul > li .collapseExpand,
.utilizationTable > ul > li .collapseExpand:hover,
.utilizationTable > ul > li .collapseExpand:focus,
.utilizationTable > ul > li .collapseExpand:active,
.holdingDataTable > ul > li .collapseExpand,
.holdingDataTable > ul > li .collapseExpand:hover,
.holdingDataTable > ul > li .collapseExpand:focus,
.holdingDataTable > ul > li .collapseExpand:active {
	background: url("../images/icons/markoffA.svg") no-repeat center center;
	background-size: 24px 24px;
	float: inherit;
	display: inline-block;
}
/* ****** ****** ****** ****** ****** **
 * ****** Unit Quality Performance Table ****** *
** ****** ****** ****** ****** ****** */

.unitQualityPrncTable > ul > li:nth-child(1) {
	padding-left: 15px;
	min-width: 100px;
}
.unitQualityPrncExpTable > ul > li:nth-child(1) {
	padding-left: 30px;
	min-width: 100px;
}
.unitQualityPrncTable > ul > li:nth-child(2),
.unitQualityPrncExpTable > ul > li:nth-child(2),
.unitQualityPrncTable > ul > li:nth-child(3),
.unitQualityPrncExpTable > ul > li:nth-child(3),
.unitQualityPrncTable > ul > li:nth-child(4),
.unitQualityPrncExpTable > ul > li:nth-child(4),
.unitQualityPrncTable > ul > li:nth-child(5),
.unitQualityPrncExpTable > ul > li:nth-child(5) {
	width: 18%;
	text-align: center;
}
.unitQualityPrncTable > ul > li:nth-child(6),
.unitQualityPrncExpTable > ul > li:nth-child(6) {
	width: 110px;
	text-align: center;
}
.unitQualityPrncTable > ul > li:nth-child(7),
.unitQualityPrncExpTable > ul > li:nth-child(7) {
	width: 85px;
	text-align: center;
}
.unitQualityPrncTable > ul > li.goodStatus,
.unitQualityPrncExpTable > ul > li.goodStatus {
	color: #FFBE00;
}
.unitQualityPrncTable > ul > li.poorStatus,
.unitQualityPrncExpTable > ul > li.poorStatus {
	color: #f93251;
}
.unitQualityPrncTable > ul > li.greatStatus,
.unitQualityPrncExpTable > ul > li.greatStatus {
	color: #3cce82;
}
/* ****** ****** ****** ****** ****** **
 * ****** Filtration Data Table ****** *
** ****** ****** ****** ****** ****** */

.filtrationDataExpTable > ul > li:nth-child(2) {
	padding-left: 15px;
}
.filtrationDataTable > ul > li:nth-child(3),
.filtrationDataExpTable > ul > li:nth-child(3),
.filtrationDataTable > ul > li:nth-child(4),
.filtrationDataExpTable > ul > li:nth-child(4),
.filtrationDataTable > ul > li:nth-child(5),
.filtrationDataExpTable > ul > li:nth-child(5) {
	width: 22%;
	text-align: center;
}
.filtrationDataTable > ul > li:nth-child(6),
.filtrationDataExpTable > ul > li:nth-child(6) {
	width: 110px;
	text-align: center;
}
/* ****** ****** ****** ****** ****** **
 * ****** Product Mix By Hour of the Day Expanded Table ****** *
** ****** ****** ****** ****** ****** */

.productMixTable > ul > li:nth-child(2) {
	width: 290px;
}
.productMixExpTable > ul > li:nth-child(2) {
	width: 290px;
	text-indent: -9999px;
}
.productMixTable > ul > li:nth-child(3),
.productMixExpTable > ul > li:nth-child(3) {
	text-align: center;
}
.productMixTable > ul > li:nth-child(4),
.productMixExpTable > ul > li:nth-child(4) {
	width: 320px;
	text-align: center;
}
.productMixTable > ul > li:nth-child(5),
.productMixExpTable > ul > li:nth-child(5) {
	width: 110px;
	text-align: center;
}
/* ****** ****** ****** ****** ****** **
 * ****** Cooking Data Table ****** *
** ****** ****** ****** ****** ****** */ 
.garlandPrctExpTable > ul > li:nth-child(2) {
	padding-left:0px;
}
.garlandPrctTable > ul > li:nth-child(3),
.garlandPrctExpTable > ul > li:nth-child(3),
.garlandPrctTable > ul > li:nth-child(4),
.garlandPrctExpTable > ul > li:nth-child(4),
.garlandPrctTable > ul > li:nth-child(5),
.garlandPrctExpTable > ul > li:nth-child(5) {
	width: 22%;
	text-align: center;
} 
.garlandPrctTable > ul > li:nth-child(6),
.garlandPrctExpTable > ul > li:nth-child(6) {
	width: 110px;
	text-align: center;
}
/* ****** ****** ****** ****** ****** **
 * ****** Update Status Expanded Table ****** *
** ****** ****** ****** ****** ****** */

.holdingDataExpTable > ul > li:nth-child(2) {
	padding-left: 15px;
}
.holdingDataExpTable > ul > li:nth-child(3),
.holdingDataExpTable > ul > li:nth-child(4),
.holdingDataExpTable > ul > li:nth-child(5) {
	width: 180px;
	text-align: center;
}
.holdingDataExpTable > ul > li:nth-child(6) {
	width: 200px;
	text-align: center;
}
.holdingDataExpTable > ul > li:nth-child(7) {
	width: 140px;
	text-align: center;
}
.holdingDataExpTable > ul > li:nth-child(8) {
	width: 110px;
	text-align: center;
}
/* ****** ****** ****** ****** ****** **
 * ****** Utilization Table ****** *
** ****** ****** ****** ****** ****** */

.utilizationTableDiv {
	width: 100%;
	float: left;
	min-height: 212px;
}
.utilizationTable > ul > li:nth-child(2) {
	width: 200px;
	text-align: center;
}
.utilizationTable > ul > li:nth-child(3),
.utilizationTable > ul > li:nth-child(4),
.utilizationTable > ul > li:nth-child(5) {
	width: 180px;
	text-align: center;
}
.utilizationTable > ul > li:nth-child(6) {
	width: 110px;
	text-align: center;
}
/* ****** ****** ****** ****** ****** **
 * ****** Utilization Expanded Table ****** *
** ****** ****** ****** ****** ****** */

.utilizationExpTable > ul > li:nth-child(1) {
	padding-left: 25px;
}
.utilizationExpTable > ul > li:nth-child(2) {
	width: 200px;
	text-align: center;
}
.utilizationExpTable > ul > li:nth-child(3),
.utilizationExpTable > ul > li:nth-child(4),
.utilizationExpTable > ul > li:nth-child(5) {
	width: 180px;
	text-align: center;
}
.utilizationExpTable > ul > li:nth-child(6) {
	width: 110px;
	text-align: center;
}
.textOverflow {
	text-overflow: ellipsis;
	width: 100px;
	overflow: hidden;
	white-space: nowrap;
	display: block;
}
.existingLoginForm > ul > li .customCheckbox a {
	color: #0D3968;
	margin-top: 0px;
	font-size: 16px;
	line-height: 30px;
}
.existingLoginForm > ul > li .customCheckbox:last-child {
	float: right;
}
.existingLoginForm > ul > li .customCheckbox.termsConditionsCkB {
	float: left;
}
.existingLoginForm > ul > li .customCheckbox.termsConditionsCkB a{
	color:#195d99;
	text-decoration:underline;
} 
.existingLoginForm > ul > li .customCheckbox.termsConditionsCkB label{
	padding-top:0px;
}
/* Time Zone CSS Starts */

.assignUpdateForm {
	width: 100%;
	float: left;
	padding: 0px 0px 5px 35px;
	clear: both;
}
.assignUpdateForm > ul {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 0px;
}
.assignUpdateForm > ul > li {
	float: left;
	padding: 0px 10px 0px 0px;
}
.assignUpdateForm > ul > li:first-child {
	padding-right: 30px;
}
.assignUpdateForm > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 30px 5px 0px;
	height: auto;
	background: #f2f2f2;
	color: #0D3968;
	font-size: 20px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.assignUpdateForm > ul > li select.form-control,
.assignUpdateForm > ul > li select.form-control,
.assignUpdateForm > ul > li .input-group .react-datepicker-wrapper .react-datepicker__input-container input,
.assignUpdateForm > ul > li .input-group #selectTime,
.assignUpdateForm > ul > li #timeZoneSelctOption {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: #f2f2f2 url("../images/icons/markdownA.svg") no-repeat right center;
	padding-left: 18px;
}
.assignUpdateForm > ul > li select.form-control option {
	font-size: 16px;
}
.assignUpdateForm > ul > li select::-ms-expand {
	display: none;
}
.assignUpdateForm > ul > li .input-group {
	float: left;
	width: 180px;
}
.assignUpdateForm > ul > li #timeZoneSelctOption {
	background: #ffffff url("../images/icons/markdownA.svg") no-repeat right center;
	padding-left: 0;
	opacity: 1;
}
.confirmationAssignModal .modal-dialog {
	width: 100%;
	max-width: 400px;
}
.confirmationAssignModal .deleteConfirmation {
	padding: 5px 20px;
}
.confirmationAssignModal .assignUpdateForm {
	margin: 0 0 25px 0;
	padding-right: 35px;
}
.confirmationAssignModal .assignUpdateForm ul > li {
	width: 100%;
	float: left;
	position: relative;
	padding: 0;
}
.confirmationAssignModal .assignUpdateForm > ul > li select.timeZoneSelctOption {
	background-color: transparent;
	padding: 15px;
}
.confirmationAssignModal .deleteNoBtn {
	background: #c2d7e8;
	color: #013b6d;
	font-family: 'open_sanssemibold';
}
.confirmationAssignModal .deleteYesBtn button {
	background: none;
	border: none;
	padding: 0;
	color: #fff;
	font-family: 'open_sanssemibold';
	cursor: pointer;
}
.confirmationAssignModal .deleteYesBtn button:disabled {
	color: #a3bacc;
}
.confirmationAssignModal .deleteYesBtn {
	color: #fff;
}
.confirmationAssignModal .deleteYesBtn:disabled {
	background: #dae3ea;
	color: #8c9faf;
}
.dropdownTimeZoneOptions {
	position: absolute;
	z-index: 1;
	background: #fff;
	top: 42px;
	width: 100%;
	box-shadow: -1px -1px 0px 0px #f2f2f2;
	-webkit-box-shadow: -1px -1px 0px 0px #f2f2f2;
	-moz-box-shadow: -1px -1px 0px 0px #f2f2f2;
	box-shadow: -1px -1px 0px 0px #f2f2f2;
	max-height: 280px;
	overflow: auto;
}
.dropdownTimeZoneOptions > li {
	width: 100%;
	margin: 0;
}
.dropdownTimeZoneOptions > li a {
	display: block;
	padding: 10px 20px;
	color: #003b6c;
}
#timeSchedulePanel::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #0D3968;
}
#timeSchedulePanel::-moz-placeholder {
	/* Firefox 19+ */
	color: #0D3968;
}
#timeSchedulePanel:-ms-input-placeholder {
	/* IE 10+ */
	color: #0D3968;
}
#timeSchedulePanel:-moz-placeholder {
	/* Firefox 18- */
	color: #0D3968;
}
.navbarLRight > li .convothermDeviceSNText.deviceSNText {
	display: block;
	cursor: default;
}
.navbarLeft > li .cursorDefault {
	cursor: default;
}
.navbarLRight > li .convothermDeviceSNText.deviceSNText > b {
	display: inline;
}
/* Time Zone CSS Ends */

/* ****** ****** ****** ****** ****** **
 * ****** Backdrop CSS ****** *
** ****** ****** ****** ****** ****** */

.backdrop-filter,
.backdrop-sidebar {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	background: #000;
	opacity: 0.3;
	pointer-events: none;
}
.backdrop-sidebar {
	z-index: 10000;
	overflow: hidden;
}
.g-recaptcha {
	display: none;
	width: 100% !important;
	transform: scale(0.50);
	-webkit-transform: scale(0.50);
	transform-origin: 0 0;
	-webkit-transform-origin: 0 0;
}
.chartOuterModalDiv {
	width: 100%;
	float: left;
	clear: both;
	padding: 15px;
	background: #2c3034;
}
/* ****** ****** ****** ****** ****** **
 * ****** Frpot Info Table ****** *
** ****** ****** ****** ****** ****** */

.frpotInfoTable {
	width: 100%;
	display: table;
}
.frpotInfoTable > ul {
	width: 100%;
	display: table-row;
}
.frpotInfoTable > ul.tHead > li {
	color: #16406d;
	background: #e9eaeb;
	border-bottom: 1px solid #63778a;
	border-top: 1px solid #63778a;
}
.frpotInfoTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
}
.frpotInfoTable > ul > li:nth-child(1) {
	width: 50px;
	text-align: center;
	color: #16406d;
}
.frpotInfoTable > ul > li:nth-child(3) {
	width: 150px;
	text-align: center;
}
/* Frypot Status vs Time chart legend css starts */

.frypotLegendSection {
	text-align: left;
	margin: 5px 0px 10px 80px;
	float: left;
}
.frypotLegendSection > li {
	margin: 0 75px 0 0;
	color: #fff;
	font-size: 12px;
	float: left;
}
.frypotLegendSection > li > span {
	height: 18px;
	width: 18px;
	margin-right: 5px;
	display: inline-block;
	vertical-align: bottom;
}
.frypotLegendSection li:nth-of-type(1) span {
	background: #0d81b9;
}
.frypotLegendSection li:nth-of-type(2) span {
	background: #efb302;
}
.frypotLegendSection li:nth-of-type(3) span {
	background: #00ad41;
}
.frypotLegendSection li:nth-of-type(4) span {
	background: #ff6c00;
}
.frypotLegendSection li:nth-of-type(5) span {
	background: #b4b4b4;
}
.frypotLegendSection li:nth-of-type(6) span {
	background: #2d3034;
	border: 1px solid #fff;
}
.frypotStsLegendSection {
	text-align: center;
	margin: 15px 0px;
	margin: 0px auto;
}
.frypotStsLegendSection > li {
	display: inline-block;
	margin: 0 55px 0 0;
	color: #fff;
	font-size: 12px;
	text-align: left;
}
.frypotStsLegendSection > li > span {
	height: 18px;
	width: 18px;
	margin-right: 5px;
	display: inline-block;
	vertical-align: bottom;
}
.frypotStsLegendSection li:nth-of-type(1) span {
	background: #0d81b9;
}
.frypotStsLegendSection li:nth-of-type(2) span {
	background: #b4b4b4;
}
.frypotStsLegendSection li:nth-of-type(3) span {
	background: #2d3034;
	border: 1px solid #fff;
}
.frypotStsLegendSection li:nth-of-type(4) span {
	background: #cc1056;
}
.filtersLegendSection {
	text-align: left;
	margin: 35px 0px 10px 50px;
}
.filtersLegendSection > li {
	display: inline-block;
	margin: 0 75px 0 0;
	color: #fff;
	font-size: 12px;
}
.filtersLegendSection > li > span {
	height: 18px;
	width: 18px;
	margin-right: 5px;
	display: inline-block;
	vertical-align: bottom;
}
.filtersLegendSection li:nth-of-type(1) span {
	background: #00d27a;
}
.filtersLegendSection li:nth-of-type(2) span {
	background: #fab000;
}
/* ****** ****** ****** ****** ****** **
 * ****** Frpot Status Table ****** *
** ****** ****** ****** ****** ****** */

.frypotStatusOuter {
	width: 100%;
	float: left;
	clear: both;
}
.frypotStatusChart {
	width: 50%;
	float: left;
	padding-bottom:15px;
}
.frypotStatusRD {
	width: 50%;
	float: left;
}
.frypotStatusTable {
	width: 100%;
	display: table;
}
.frypotStatusTable > ul {
	width: 100%;
	display: table-row;
}
.frypotStatusTable > ul.tHead > li {
	border-bottom: 1px solid #4a4a4a;
	color: #9fa8b5;
}
.frypotStatusTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #222528;
	color: #ffffff;
	font-size: 14px;
	text-align: left;
}
.frypotStatusTable > ul > li:nth-child(2) {
	width: 135px;
	text-align: center;
}
/* ****** ****** ****** ****** ****** **
 * ****** Menu Library Table ****** *
** ****** ****** ****** ****** ****** */


.menuLibraryTable {
	width: 100%;
	display: table;
}
.menuLibraryTable > ul {
	width: 100%;
	display: table-row;
	background-color: #FFF;
}
.menuLibraryTable > ul.tHead > li {
	color: #16406d;
	border-bottom: 1px solid #63778a;
	background: #e9eaeb;
}
.menuLibraryTable > ul.tHead > li:first-child {
	border-left: 0px;
	border-bottom: 1px solid #63778a;
}
.menuLibraryTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left; 
}
.menuLibraryTable > ul > li:nth-child(1) {
	width: 54px;
	padding-left:5px;
	padding-right:5px;
	text-align: center;
	color: #16406d;
	border-bottom: 1px solid #F2F2F2;
}
.menuLibraryTable > ul > li:nth-child(2) {
	width: 60px;
	padding-left: 0px;
	padding-right:0px;
	text-align: center;
}
.menuLibraryTable > ul > li:nth-child(3) {
	width: 60px;
	padding: 0px;
}
.menuLibraryTable > ul.tBody > li:nth-child(3) {
	height: 60px;
}
.menuLibraryTable > ul > li:nth-child(4) {
	width: 140px;
} 
.menuLibraryTable > ul > li:nth-child(5) {
	width: 180px;
}
.menuLibraryTable > ul > li:nth-child(7) {
	width: 260px;
}
.menuLibraryTable > ul > li:nth-child(8) {
	width: 60px;
}
.menuLibraryTable > ul > li:nth-child(9),
.menuLibraryTable > ul > li:nth-child(10) {
	width: 100px;
	text-align: center;
	padding-left:0px;
	padding-right:0px;
}
.menuLibraryTable > ul > li:nth-child(11) {
	width: 58px;
	text-align: center;
} 
.menuLibraryTable > ul > li .badge {
	float: right;
	background: #16d485;
	color: #fff;
	font-weight: normal;
	min-width: 36px;
} 
.menuLibraryTable > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.menuLibraryTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}
.menuLibraryTable > ul > li .menuType {
	width: 24px;
	height: 24px;
	display: inline-block;
}
.menuLibraryTable > ul > li .menuType > img {
	width: 20px;
}
.menuLibraryTable > ul > li .menuPicture {
	width: 60px;
	display: inline-block;
}
.menuLibraryTable > ul > li .menuPicture > img {
	width: 100%;
}
.menuLibraryTable > ul > li .menuInfo {
	background: url("../images/icons/infoReport.svg") no-repeat center center;
	background-size: 24px 24px;
} 
.menuLibraryTable > ul.tBody > li .menuSListSMF,
.menuLibraryTable > ul.tBody > li .menuSListRS,
.menuLibraryTable > ul.tBody > li .menuSListAS {
	display: none;
	width: 100%;
	float: left;
	font-size: 12px;
}
.menuLibraryTable > ul.tBody > li .menuSListSM {
	display: none;
}

.menuLibraryTable > ul > li .progress {
	height: 8px;
	border-radius: 4px;
	background: #d8d8d8;
}
.menuLibraryTable > ul > li .progress .updatingSW {
	background: #0aaeff;
}
.menuLibraryTable > ul > li .progress .pendingSW{
	background: #d8d8d8 !important;
}
.menuLibraryTable > ul > li .progress .warningStatus {
	background: #f93251;
}
.menuLibraryTable > ul > li .progress .completedStatus {
	background: #3cce82;
}
.menuLibraryTable > ul > li .btn-danger {
	white-space: normal;
	line-height: initial;
} 
.menuLibraryTable > ul > li .scheduleDetails {
	background: url("../images/icons/iconReportInProgress.svg") no-repeat center center;
	background-size: 24px auto;
}

.menuLbryMcfTable {
	width: 100%;
	display: table;
}
.menuLbryMcfTable > ul {
	width: 100%;
	display: table-row;
	background-color: #FFF;
}
.menuLbryMcfTable > ul.tHead > li {
	color: #16406d;
	border-bottom: 1px solid #63778a;
	background: #e9eaeb;
}
.menuLbryMcfTable > ul.tHead > li:first-child {
	border-left: 0px;
	border-bottom: 1px solid #63778a;
}
.menuLbryMcfTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left; 
}
.menuLbryMcfTable > ul > li:nth-child(1) {
	width: 54px;
	padding-left:5px;
	padding-right:5px;
	text-align: center;
	color: #16406d;
	border-bottom: 1px solid #F2F2F2;
}
.menuLbryMcfTable > ul > li:nth-child(2) {
	width: 60px;
	padding-left: 0px;
	padding-right:0px;
	text-align: center;
} 
.menuLbryMcfTable > ul > li:nth-child(4) {
	width: 200px;
}
.menuLbryMcfTable > ul > li:nth-child(5) {
	width: 220px;
}
.menuLbryMcfTable > ul > li:nth-child(6) {
	width: 260px;
}
.menuLbryMcfTable > ul > li:nth-child(7) {
	width: 60px;
}
.menuLbryMcfTable > ul > li:nth-child(8),
.menuLbryMcfTable > ul > li:nth-child(9) {
	width: 100px;
	text-align: center;
	padding-left:0px;
	padding-right:0px;
}
.menuLbryMcfTable > ul > li:nth-child(10) {
	width: 58px;
	text-align: center;
} 
.menuLbryMcfTable > ul > li .badge {
	float: right;
	background: #16d485;
	color: #fff;
	font-weight: normal;
	min-width: 36px;
} 
.menuLbryMcfTable > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.menuLbryMcfTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}
.menuLbryMcfTable > ul > li .menuType {
	width: 24px;
	height: 24px;
	display: inline-block;
}
.menuLbryMcfTable > ul > li .menuType > img {
	width: 20px;
} 
.menuLbryMcfTable > ul > li .menuInfo {
	background: url("../images/icons/infoReport.svg") no-repeat center center;
	background-size: 24px 24px;
} 
.menuLbryMcfTable > ul.tBody > li .menuSListSMF,
.menuLbryMcfTable > ul.tBody > li .menuSListRS,
.menuLbryMcfTable > ul.tBody > li .menuSListAS {
	display: none;
	width: 100%;
	float: left;
	font-size: 12px;
}
.menuLbryMcfTable > ul.tBody > li .menuSListSM {
	display: none;
}

.menuLbryMcfTable > ul > li .progress {
	height: 8px;
	border-radius: 4px;
	background: #d8d8d8;
}
.menuLbryMcfTable > ul > li .progress .updatingSW {
	background: #0aaeff;
}
.menuLbryMcfTable > ul > li .progress .pendingSW{
	background: #d8d8d8 !important;
}
.menuLbryMcfTable > ul > li .progress .warningStatus {
	background: #f93251;
}
.menuLbryMcfTable > ul > li .progress .completedStatus {
	background: #3cce82;
}
.menuLbryMcfTable > ul > li .btn-danger {
	white-space: normal;
	line-height: initial;
} 
.menuLbryMcfTable > ul > li .scheduleDetails {
	background: url("../images/icons/iconReportInProgress.svg") no-repeat center center;
	background-size: 24px auto;
}

/* ****** ****** ****** ****** ****** **
 * ****** Menu Assign Status Table ****** *
** ****** ****** ****** ****** ****** */ 
.menuAStatusTableOuter {
	width: 100%;
	float: left;
	padding: 0px;
}
.menuAStatusTableOuter .panel-collapse {
	background: #ffffff;
}
.menuAStatusTable {
	width: 100%;
	display: table;
}
.menuAStatusTable > ul {
	width: 100%;
	display: table-row;
}
.menuAStatusTable > ul.tHead li {
	background: #E9EAEB;
	border-bottom: 1px solid #607488;
}
.menuAStatusTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 16px;
	text-align: left;
}
.menuAStatusTable > ul.tBody > li {  
	font-size: 14px;  
}
.menuAStatusTable > ul > li a:hover {
	color: #C8102E;
	text-decoration: none;
}
.menuAStatusTable > ul > li a {
	color: #333F48;
}
.menuAStatusTable > ul.tBody > li:nth-child(1) {
	border-bottom: 1px solid #f2f2f2;
}
.menuAStatusTable > ul > li:nth-child(1) {
	width: 34px;
	text-align: center;
	padding-left:5px;
	padding-right:5px;
}
.menuAStatusTable > ul > li:nth-child(2) {
	width: 54px;
	text-align: center;
}
.menuAStatusTable > ul > li:nth-child(3) {
	width: 50px;
	padding-left: 0px;
	text-align: center;
}
.menuAStatusTable > ul > li:nth-child(4) {
	text-align: left;
} 
.menuAStatusTable > ul > li:nth-child(5),
.menuAStatusTable > ul > li:nth-child(6) {
	width: 160px; 
	padding: 10px 5px;
}
.menuAStatusTable > ul > li:nth-child(7) {
	width: 140px;
}
.menuAStatusTable > ul > li:nth-child(8) {
	width: 180px;
	padding-left:0px;
	padding-right:0px;
}
.menuAStatusTable > ul > li:nth-child(9) {
	width: 100px;
	text-align: center;
	padding-left: 0px;
	padding-right: 0px;
}
.menuAStatusTable > ul.tBody > li:nth-child(9) {
	color: #0aaeff;
	font-family: 'open_sanssemibold';
	font-size: 14px;
}
.menuAStatusTable > ul > li:nth-child(10) {
	width: 80px;
	text-align: center;
}
.menuAStatusTable > ul > li:nth-child(10) > img {
	width: 24px;
} 
.menuAStatusTable > ul > li:nth-child(11) {
	width: 120px;
}
.menuAStatusTable > ul.tBody > li .menuAStatusSM,
.menuAStatusTable > ul.tBody > li .menuAStatusSB {
	display: none;
	width: 100%;
	float: left;
}
.menuAStatusTable > ul.tBody > li .menuAStatusD {
	width: 100%;
	float: left;
	font-size: 12px;
}
.menuAStatusTable > ul.tBody > li .menuAStatusSB {
	margin-top: 5px;
}
.menuAStatusTable > ul > li .menuType {
	width: 24px;
	height: 24px;
	display: inline-block;
}
.menuAStatusTable > ul > li .menuType > img {
	width: 20px;
}
.menuAStatusTable > ul > li .progress {
	height: 8px;
	border-radius: 4px;
	background: #d8d8d8;
}
.menuAStatusTable > ul > li .progress .updatingSW {
	background: #0aaeff;
}
.menuAStatusTable > ul > li .progress .pendingSW{
	background: #d8d8d8 !important;
}
.menuAStatusTable > ul > li .progress .warningStatus {
	background: #f93251;
}
.menuAStatusTable > ul > li .progress .completedStatus {
	background: #3cce82;
}
.menuAStatusTable > ul > li .btn-danger {
	white-space: normal;
	line-height: initial;
}
/* ****** ****** ****** ****** ****** **
 * ****** Menu Assign Status Expanded Table ****** *
** ****** ****** ****** ****** ****** */

.menuAStatusExpTable {
	width: 100%;
	display: table;
}
.menuAStatusExpTable > ul {
	width: 100%;
	display: table-row;
}
.menuAStatusExpTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 16px;
	text-align: left;
}
.menuAStatusExpTable > ul > li a:hover {
	color: #C8102E;
	text-decoration: none;
}
.menuAStatusExpTable > ul > li a {
	color: #333F48;
}
.menuAStatusExpTable > ul.tBody > li:nth-child(1) {
	border-bottom: 1px solid #f2f2f2;
}
.menuAStatusExpTable > ul > li:nth-child(1) {
	width: 54px;
	text-align: center;
	padding-left:5px;
	padding-right:5px;
}
.menuAStatusExpTable > ul > li:nth-child(2) {
	padding-left: 0px;
}
.menuAStatusExpTable > ul > li:nth-child(3) {
	width: 200px;
	text-align: center;
}
.menuAStatusExpTable > ul > li .btn {
	background: #195d99;
	color: #ffffff;
	min-width: 80px;
}
.menuAStatusExpTable > ul > li .btn-skip {
	background: #e8ecf1;
	color: #195d99;
	min-width: 80px;
	border-radius: 20px;
	border: 0px;
	font-size: 14px;
	padding: 6px 16px;
}
.menuAStatusExpTable .menuAExpTableOuter {
	width: 250px;
	margin: 5px;
	float: left;
}
.menuAStatusExpTable .menuAExpTable {
	width: 100%;
	display: table;
	background: #f2f2f2;
}
.menuAStatusExpTable .menuAExpTable > ul {
	width: 100%;
	display: table-row;
}
.menuAStatusExpTable .menuAExpTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	color: #0D3968;
	font-size: 16px;
	text-align: left;
}
.menuAStatusExpTable .menuAExpTable > ul > li > img {
	width: 24px;
}
.menuAStatusExpTable .menuAExpTable > ul > li:nth-child(1) {
	width: 54px;
	text-align: center;
	vertical-align: top;
	padding-left:5px;
	padding-right:5px;
}
.menuAStatusExpTable .menuAExpTable > ul > li:nth-child(2) {
	padding-left: 0px;
}
.menuAStatusExpTable .menuAExpTable > ul > li h4,
.menuAStatusExpTable .menuAExpTable > ul > li h5 {
	color: #0D3968;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 14px;
	width: 190px;
}
.menuAStatusExpTable .menuAExpTable > ul > li h4 {
	margin: 0px 0px 0px;
	font-family: 'open_sanssemibold';
}
.menuAStatusExpTable .menuAExpTable > ul > li h5 > span {
	color: #748da8;
	margin-left: 10px;
}
.menuAStatusExpTable > ul > li .details {
	border-radius: 20px;
	background: #0d3968;
	border: 0px;
	color: #ffffff;
	font-size: 14px;
	padding: 6px 16px;
	margin:10px 0px 0px;
} 
/* ****** ****** ****** ****** ****** **
 * ****** Menu Select Device Table ****** *
** ****** ****** ****** ****** ****** */

.menuSelectDeviceTableOuter {
	width: 100%;
	float: left;
	padding: 0px;  
}
.menuSelectDeviceTableOuter .panel-collapse {
	background: #ffffff;
}
.menuSelectDeviceTable {
	width: 100%;
	display: table;
}
.menuSelectDeviceTable > ul {
	width: 100%;
	display: table-row;
}
.menuSelectDeviceTable > ul.tHead li {
	background: #E9EAEB;
	border-bottom: 1px solid #607488;
}
.menuSelectDeviceTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	color: #0D3968;
	font-size: 16px;
	text-align: left;
}
.menuSelectDeviceTable > ul > li a:hover {
	color: #C8102E;
	text-decoration: none;
}
.menuSelectDeviceTable > ul > li a {
	color: #333F48;
}
.menuSelectDeviceTable > ul > li:nth-child(1) {
	width: 54px;
	text-align: center;
	padding-left:5px;
	padding-right:5px;
}
.menuSelectDeviceTable > ul > li:nth-child(2) {
	padding-left: 0px;
	text-align: left;
}
.menuSelectDeviceTable > ul > li:nth-child(2) > img { 
	height: 22px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Select Device Grid View ****** *
** ****** ****** ****** ****** ****** */

.selectDeviceGrid {
	width: 100%;
	padding: 0px 54px;
	float: left;
	clear: both;
}
.selectDeviceGrid > ul {
	padding: 0px;
	margin: 0px -1px;
	clear: both;
	width: 100%;
}
.selectDeviceGrid > ul > li {
	width:210px;
	margin: 0px 5px 5px;
	padding: 10px 5px;
	float: left; 
	cursor: pointer;
	background: #7e9fc3;
	border-radius:12px;  
}
.selectDeviceGrid > ul > li .selectDeviceGridW{
	width:210px;   
	height:120px;
	display:table-cell;
	vertical-align:middle;
}
.selectDeviceGrid > ul > li.selectedList {
	background: #0d3968;
}
.selectDeviceGrid > ul > li.selectedList h5 { 
	color: #fff;
}
.selectDeviceGrid > ul > li.userNotificationInfo {
	opacity: 0.5;
	cursor: pointer;
}
.selectDeviceGrid > ul > li h5 {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 0px 0px;
	font-size: 14px;
	color: #ffffff;
	text-align: center;  
} 
.selectDeviceGrid > ul > li span {
	width: 100%;
	float: left;
	clear: both; 
	text-align: center;
	min-height: 64px; 
}
.selectDeviceGrid > ul > li span img {
	width:64px;
	max-height: 100px;
	clear: both;
}
/* ****** ****** ****** ****** ****** **
 * ****** Grid Wrapper View ****** *
** ****** ****** ****** ****** ****** */

.rcDeviceWrapper {
	width: 640px;
	margin: 60px auto 0px;
	clear: both;
}
.rcDeviceWrapper .footerText {
	width: 100%;
	padding: 5px 0px;
	float: left;
	clear: both;
	text-align: right;
	font-size: 14px;
	color: #ffffff;
	margin-bottom: 10px;
}
.rcDeviceWrapper .rcSelectedBrandLogo {
	width: 100%;
	padding: 15px 15px;
	float: left;
	clear: both;
	text-align: center;
}
.rcDeviceWrapper .rcSelectedBrandLogo > img {
	height: 24px; 
}
.rcDeviceWrapper .rcGarlandLogo {
	width: 100%;
	padding: 25px 0px 10px;
	float: left;
	clear: both;
	text-align: left;
}
.rcDeviceWrapper .rcGarlandLogo > img {
	height: 24px; 
}
.rcDeviceWrapper .rcGarlandLogo > .saveExitBtn {
	width: auto;
	min-width:120px;
	float:right;
    	text-align: center;
    	font-size: 16px;
    	color: #ffffff; 
    	border-radius: 8px;
    	padding: 6px 15px;
	background: #555555; 
	border: 1px solid #696969;
}
/* ****** ****** ****** ****** ****** **
 * ****** Recipe Editor Wrapper ****** *
** ****** ****** ****** ****** ****** */

.reBodyWrapper {
	width: 100%;
	float: left; 
	height: 320px;
}
.selectLiberaryModal .reBodyWrapper {
	background: #323232;
	height: 410px;
}
.selectLiberaryModal .reBodyWrapper .reWrapperTable {
	background: #323232;
}
.reBodyWrapper.withSearchBar {
	height: 220px;
}
.withSearchBar .reWrapperTable > .reWrapperRow > .reWrapperColmn {
	height: 220px;
}
.reWrapperTable {
	width: 100%;
	display: table;
}
.reWrapperTable > .reWrapperRow {
	width: 100%;
	display: table-row;
}
.reWrapperTable > .reWrapperRow > .reWrapperColmn {
	width: 100%;
	display: table-cell;
	padding: 0px;
	vertical-align: middle;
	height: 300px;
}
.reWrapperTable > .reWrapperRow > .reWrapperColmn.alignTop {
	vertical-align: top;
}
.reWrapperTab {
	width: 100%;
	padding:10px;
	display: table;  
} 
.reWrapperTab > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
	
}
.reWrapperTab > ul > li {
	padding:0px;
	display: table-cell;
	color: #96a8bb;
	vertical-align: middle;
	border-bottom: 1px solid #000;
}
.reWrapperTab > ul > li a {
	color: #ffffff;
	padding: 10px 15px 10px;
	font-size: 16px;
	display: inline-block; 
	background:#696969;  
}  
.reWrapperTab > ul > li a.active { 
	background:#000;  
}  

.recipeEditorWrapper {
	width: 100%;
	padding: 0px;
	border-radius: 12px;
	float: left;
	clear: both;
	background: #323232;
	position: relative;
	border: 1px solid #696969;
	overflow:hidden;
	margin-bottom:10px;
}
.rEWrapper {
	width: 100%;
	padding: 0px;
	border-radius: 12px;
	float: left;
	clear: both;
	background: #323232;
	position: relative;
	border: 3px solid #d9d9d9;
	overflow:hidden;
}
.rEWrapper .rEeditorF {
	width: 100%;
	float: left;
	clear: both;
	margin:-35px 0px 0px;
	padding: 10px;
	background: #323232;
	text-align: center;
	border-radius: 0px 0px 12px 12px;
}
.rEeditorF .easyTouchLogo {
	padding: 0px;
	float: left;
}
.rEeditorF .easyTouchLogo > img {
	max-width: 90px;
}
.recipeEditorWrapper .recipeEditorH {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px;
	border-radius: 12px 12px 0px 0px;
}
.recipeEditorWrapper .recipeEditorH.selectedH {
	background: #000000;
	font-size: 16px;
	color: #ffffff;
	text-align: center;
	line-height: 45px;
}
.recipeEditorWrapper .recipeEditorF {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px;
	background: #323232;
	text-align: center;
	border-radius: 0px 0px 12px 12px;
}
.recipeEditorWrapper .recipeInstruction {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px;
	background: #323232;
	color: #f9328f;
	font-size: 14px;
}
.recipeEditorH .languageButton,
.recipeEditorH .switchButton {
	width: 45px;
	height: 45px;
	float: left;
}
.recipeEditorH .languageButton {
	background: url("../images/recipecreation/languagesButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.recipeEditorH .switchButton {
	background: url("../images/recipecreation/switchA.png") no-repeat center center;
	background-size: 45px 45px;
}
.recipeEditorH .nextButton,
.recipeEditorH .rcSettings,
.recipeEditorH .editButton,
.recipeEditorH .editbButton {
	width: 45px;
	height: 45px;
	float: right;
}
.recipeEditorH .editButton {
	background: url("../images/recipecreation/menuEditButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.recipeEditorH .editbButton {
	background: url("../images/recipecreation/menuEditBButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.recipeEditorH .rcSettings { 
	margin-right:10px;  
	background:url("../images/recipecreation/settingsButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.recipeEditorH .backButton {
	width: 45px;
	height: 45px;
	float: left;
	background: url("../images/recipecreation/backButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.recipeEditorH .nextButton { 
	display: inline-block;
	background: url("../images/recipecreation/nextButton.png") no-repeat center center;
	background-size: 45px 45px; 
}
.recipeEditorF .easyTouchLogo {
	padding: 0px;
	float: left;
}
.recipeEditorF .easyTouchLogo > img {
	max-width: 90px;
}
.recipeEditorF .languageButton,
.recipeEditorF .confirmButton,
.recipeEditorF .cancelButton,
.recipeEditorF .backButton,
.recipeEditorF .powerButton {
	width: 45px;
	height: 45px;
	float: left;
}
.recipeEditorF .addButton {
	width: 45px;
	height: 45px;
	background: url("../images/recipecreation/addRCbutton.png") no-repeat center center;
	background-size: 45px 45px;
}
.recipeEditorF .backButton {
	background: url("../images/recipecreation/backButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.recipeEditorF .languageButton {
	background: url("../images/recipecreation/languagesButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.recipeEditorF .powerButton {
	background: url("../images/recipecreation/powerButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.recipeEditorF .cancelButton {
	background: url("../images/recipecreation/cancelButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.recipeEditorF .confirmButton {
	background: url("../images/recipecreation/confirmButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.recipeEditorF .confirmCButton,
.recipeEditorF .saveButton {
	width: 45px;
	height: 45px;
	display: inline-block; 
}
.recipeEditorF .saveRecipeButton {
	width: 45px;
	height: 45px;
	display: inline-block; 
}
.recipeEditorF .confirmCButton { 
	background: url("../images/recipecreation/confirmButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.recipeEditorF .saveButton { 
	background: url("../images/recipecreation/saveButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.recipeEditorF .saveRecipeButton {
	background: url("../images/recipecreation/confirmButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.recipeEditorF .recipeCreation,
.recipeEditorF .rcSettings {
	width: 45px;
	height: 45px;
	float:right;
	margin-left:10px;
}
.recipeEditorF .recipeCreation { 
	background: url("../images/recipecreation/rcButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.recipeEditorF .rcSettings { 
	background:url("../images/recipecreation/settingsButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.recipeEditorF .rcChefHat{
	width: 45px;
	height: 45px; 
	margin-right:10px; 
	background: url("../images/recipecreation/rcButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.recipeEditorF button.btn.rightAlign {
	float: right;
	margin-left: 5px;
}
.recipeEditorF button.btn.rgtTestBtn {
	float: right;
	margin-left: 15px;
}
.recipeEditorF .reviewButton {
	border-radius: 15px;
	margin-right: 10px;
	text-align: center;
	font-size: 14px;
	color: #ffffff;
	background: #454545;
	float: right;
	margin-top: 5px;
}
.recipeEditorF .required {
	float: left;
}
.recipeEditorF .testButton {
	width: 45px;
	height: 45px;
	display: inline-block;
	background: #696969;
	color: #ffffff;
	border-radius: 50%;
	float: right;
	padding: 0px;
}
.recipeEditorF .nextButton {
	width: 45px;
	height: 45px;
	display: inline-block;
	background: url("../images/recipecreation/nextButton.png") no-repeat center center;
	background-size: 45px 45px;
	float: right;
}
.recipeEditorF .addButton,
.recipeEditorF .saveButtonB,
.recipeEditorF .saveCButton {
	width: 45px;
	height: 45px;
	display: inline-block; 
}
.recipeEditorF .saveButtonB { 
	background: url("../images/recipecreation/saveButtonB.png") no-repeat center center;
	background-size: 45px 45px;
}
.recipeEditorF .saveCButton { 
	background: url("../images/recipecreation/confirmButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.recipeEditorF .addButton { 
	background: url("../images/recipecreation/addButton.png") no-repeat center center;
	background-size: 45px 45px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Recipe Nav Grid View ****** *
** ****** ****** ****** ****** ****** */

.recipeEditorGrid {
	width: 100%;
	padding: 10px 60px;
	float: left;
	clear: both;
}
.recipeEditorGrid.garlandRC {
	width: 80%;
	margin:0px 10%;
	padding: 10px 60px 60px;
	float: left;
	clear: both;
}
.recipeEditorGrid.frymasterBrandRC {
	padding: 10px 20px; 
	background: url("../images/recipecreation/borderBg.png") no-repeat bottom center;
	background-size: 90% 2px;
}
.recipeEditorGrid > ul {
	padding: 0px;
	margin: 0px -5px;
	clear: both;
	width: 100%;
}
.recipeEditorGrid > ul > li {
	width: 33.333%;
	padding: 5px;
	float: left;
}
.recipeEditorGrid.garlandRC > ul > li {
	width:50%;
	padding: 5px;
	float: left;
}
.recipeEditorGrid.frymasterBrandRC > ul > li {
	width: 20%;
	padding: 5px;
	float: left;
}
.recipeEditorGrid.frymasterBrandRC > ul > li > .btn {
	font-size: 16px;
}
.recipeEditorGrid > ul > li > .btn {
	width: 100%;
	float: left;
	clear: both;
	padding: 90px 0px 5px;
	font-size: 18px;
	color: #ffffff;
	text-align: center;
	min-height: 48px;
	white-space: pre-wrap;
}
.recipeEditorGrid > ul > li .recipeCreationNav {
	background: url("../images/recipecreation/recipeCreationA.png") no-repeat top center;
	background-size: 80px 80px;
}
.recipeEditorGrid > ul > li .cookbookNav {
	background: url("../images/recipecreation/cookbookA.png") no-repeat top center;
	background-size: 80px 80px;
}
.recipeEditorGrid > ul > li .settingsNav {
	background: url("../images/recipecreation/settingsA.png") no-repeat top center;
	background-size: 80px 80px;
}
.recipeEditorGrid > ul > li .menusNav {
	background: url("../images/recipecreation/menuCreation.png") no-repeat top center;
	background-size: 80px 80px;
}
.recipeEditorGrid > ul > li .informationNav {
	background: url("../images/recipecreation/informationIcon.png") no-repeat top center;
	background-size: 80px 80px;
}
.recipeEditorGrid > ul > li .helpNav {
	background: url("../images/recipecreation/helpIcon.png") no-repeat top center;
	background-size: 80px 80px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Recipe Cookbook Grid View ****** *
** ****** ****** ****** ****** ****** */

.recipeCBGrid {
	width: 100%;
	display: table;
}
.recipeCBGrid > ul {
	width: 100%;
	display: table-row;
}
.recipeCBGrid > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: top;
	color: #0D3968;
	font-size: 16px;
	text-align: center;
}
.recipeCBGrid > ul > li:nth-child(1) {
	width: 120px;
	padding-right: 10px;
	vertical-align: top;
}
.recipeCBGrid > ul > li > a {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px;
}
.recipeCBGrid > ul > li > a > .navTitle {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 0px;
	font-size: 16px;
	color: #ffffff;
	text-align: center;
}
.recipeCBGrid > ul > li > a > .navIcon {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px;
	text-align: center;
	height: 80px;
	line-height: 80px;
}
.recipeCBGrid > ul > li.actionIcon > a > .navIcon > img, 
.recipeCBGrid > ul > li:nth-child(1) > a > .navIcon > img {
	max-height: 45px;
	max-width: 45px;
	display: inline-block;
}
.recipeCBGrid > ul > li.actionIcon {
	width: 160px;
	padding-right: 15px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Cookbook Add Menu Recipe ****** *
** ****** ****** ****** ****** ****** */

.cbaddMenuRecipe {
	width: 280px;
	display: table;
	margin: 0px auto;
}
.cbaddMenuRecipe > ul {
	width: 100%;
	display: table-row;
}
.cbaddMenuRecipe > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	color: #ffffff;
	font-size: 16px;
	text-align: center;
}
.cbaddMenuRecipe > ul > li > a,
.cbaddMenuRecipe > ul > li > a > .navTitle
.cbaddMenuRecipe > ul > li > a > .navIcon {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px;
}
.cbaddMenuRecipe > ul > li > a > .navTitle { 
	font-size: 16px;
	color: #ffffff;
	text-align: center;
}
.cbaddMenuRecipe > ul > li > a > .navIcon { 
	text-align: center;
	height: 80px;
	line-height: 80px;
}
.cbaddMenuRecipe > ul > li > a > .navIcon > img {
	max-height: 45px;
	max-width: 45px;
	display: inline-block;
}
/* ****** ****** ****** ****** ****** **
 * ****** Recipe Creation Form ****** *
** ****** ****** ****** ****** ****** */

.recipeCreationForm {
	width: 100%;
	display: table;
}
.recipeCreationForm > ul {
	width: 100%;
	display: table-row;
}
.recipeCreationForm > ul > li {
	display: table-cell;
	padding: 5px 10px;
	vertical-align: middle;
	color: #0D3968;
	font-size: 16px;
	text-align: left;
}
.recipeCreationForm > ul.tHead > li {
	padding: 5px 10px 0px;
}
.recipeCreationForm > ul > li:nth-child(1),
.recipeCreationForm > ul > li:nth-child(4) {
	width: 65px;
	text-align: center;
}
.recipeCreationForm > ul > li:nth-child(2) {
	width: 45px;
	text-align: center;
}
.recipeCreationForm > ul > li:nth-child(3) {
	padding-left: 0px;
	padding-right: 0px;
	background: url("../images/recipecreation/borderBg.png") no-repeat bottom center;
	background-size: 100% 2px;
}
.recipeCreationForm > ul.tHead > li:nth-child(3),
.recipeCreationForm > ul.notesField > li:nth-child(3) {
	background: none;
}
.recipeCreationForm:last-child > ul > li:nth-child(3) {
	background: none;
}
.recipeCreationForm > ul > li:nth-child(2) > .recipeRowCount {
	background: #ffffff;
	border-radius: 50%;
	color: #000;
	font-size: 12px;
	width: 24px;
	height: 24px;
	text-align: center;
	padding: 0px;
	cursor: default;
}
.recipeCreationForm > ul > li:nth-child(1) > .btn,
.recipeCreationForm > ul > li:nth-child(4) > .btn {
	width: 45px;
	height: 45px; 
}
.recipeCreationForm > ul > li .recipeNotesInfo {
	background: url("../images/recipecreation/notesInfo.png") no-repeat center center;
	background-size: 34px 34px;
	width: 24px;
	height: 24px; 
	cursor: pointer;
	display:inline-block;
}
.recipeCreationForm > ul > li .recipeAddedImg {
	background: url("../images/recipecreation/stageIcon.png") no-repeat center center;
	background-size: 24px 24px;
	width: 24px;
	height: 24px;
	cursor: default;
	display:inline-block;
	margin: 15px 5px 0px;
}
.recipeCreationForm > ul > li .msgButton {
	background: url("../images/recipecreation/msgButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.recipeCreationForm > ul > li .deleteButton {
	background: url("../images/recipecreation/deleteButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.rcSettingsTable {
	width: 100%;
	display: table;
}
.rcSettingsTable > ul {
	width: 100%;
	display: table-row;
}
.rcSettingsTable > ul > li {
	display: table-cell;
	padding: 0px 10px;
	vertical-align: middle;
	color: #ffffff;
	text-align: center;
	width: 25%;
}
.rcSettingsTable > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 5px 5px 5px;
	height: auto;
	background: #000;
	color: #fff;
	font-size: 20px;
	border: 0px;
	border-radius: 0px;
	caret-color: #0097ff;
}
.rcSettingsTable > ul > li:nth-child(3) .form-control,
.rcSettingsTable > ul > li:nth-child(4) .form-control {
	width:70px; 
	text-align:right;
}
.rcSettingsTable > ul > li .percentage {
	padding:7px 0px 5px 5px;
	display: inline-block;
}
.rcSettingsTable > ul > li .form-control.celFrhSel {
	width: auto;
    float: inherit;
    clear: inherit;
    display: inline-block; 
	font-size:14px;	
	padding-left: 0px;
}
.rcSettingsTable > ul > li > img {
	width: 42px;
}
.rcNotesFormField {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 10px;
}
.rcNotesFormField.addedImage {
	width: 90%;
	float: left;
	clear: inherit;
	padding: 5px 10px;
}
.rcNotesFormField .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 5px 5px 5px;
	height: auto;
	background: #000;
	color: #fff;
	font-size: 20px;
	border: 0px;
	border-radius: 0px;
	caret-color: #0097ff;
}
/* ****** ****** ****** ****** ****** **
 * ****** Select Recipe Table ****** *
** ****** ****** ****** ****** ****** */


.selectRecipeTable {
	width: 100%;
	display: table;
}
.selectRecipeTable > ul {
	width: 100%;
	display: table-row;
}
.selectRecipeTable > ul.tHead > li {
	color: #16406d;
	border-bottom: 1px solid #63778a;
	background: #e9eaeb;
	border-top: 1px solid #63778a;
}
.selectRecipeTable > ul.tHead > li:first-child {
	border-left: 0px;
	border-bottom: 1px solid #63778a;
}
.selectRecipeTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 14px;
	text-align: left;
}
.selectRecipeTable > ul > li .badge {
	float: right;
	background: #16d485;
	color: #fff;
	font-weight: normal;
	min-width: 36px;
}
.selectRecipeTable > ul > li:nth-child(1) {
	width: 40px;
	text-align: center;
	color: #16406d;
	border-bottom: 1px solid #F2F2F2;
}
.selectRecipeTable > ul > li:nth-child(2) {
	width: 60px;
	padding: 0px;
}
.selectRecipeTable > ul.tBody > li:nth-child(2) {
	background: #FFFFFF;
}
.selectRecipeTable > ul.tBody:hover > li,
.selectRecipeTable > ul.tBody:active > li,
.selectRecipeTable > ul.tBody.active > li {
	background: #c6d7e6;
	cursor: pointer;
}
.selectRecipeTable > ul > li:nth-child(3) {
	width: 140px;
}
.selectRecipeTable > ul > li:nth-child(4) {
	width: 160px;
}
.selectRecipeTable > ul > li:nth-child(6) {
	width: 180px;
	padding-left: 0px;
	padding-right: 0px;
}
.selectRecipeTable > ul > li:nth-child(7) {
	width: 40px;
	text-align: center;
	padding-left: 0px;
	padding-right: 0px;
}
.selectRecipeTable > ul > li:nth-child(8) {
	width: 80px;
	text-align: center;
}
.selectRecipeTable > ul > li:nth-child(9) {
	width: 80px;
	text-align: center;
}
.selectRecipeTable > ul.tBody > li:nth-child(9) {
	width: 68px;
}
.selectRecipeTable > ul > li .menuPicture {
	width: 40px;
	display: inline-block;
}
.selectRecipeTable > ul > li .menuPicture > img {
	width: 100%;
}
.selectRecipeTable > ul > li .menuInfo {
	background: url("../images/icons/informationS.png") no-repeat center center;
	background-size: 24px 24px;
}
.selectRecipeTable > ul > li .unitsRCSM,
.selectRecipeTable > ul > li .unitsRCM,
.selectRecipeTable > ul > li .unitsRCMD {
	display: none;
	width: 100%;
	float: left;
}
/* ****** ****** ****** ****** ****** **
 * ****** Test On Unit Table ****** *
** ****** ****** ****** ****** ****** */

.testOnUnitTable {
	width: 100%;
	display: table;
	border: 1px solid #4e4e4e;
	padding: 5px 0px;
	border-radius: 8px;
}
.testOnUnitTable > ul {
	width: 100%;
	display: table-row;
}
.testOnUnitTable > ul > li {
	display: table-cell;
	padding: 5px 10px;
	vertical-align: middle;
	color: #0D3968;
	font-size: 16px;
	text-align: left;
}
.testOnUnitTable > ul > li:nth-child(1),
.testOnUnitTable > ul > li:nth-child(3) {
	width: 50px;
	text-align: center;
}
.testOnUnitTable > ul > li:nth-child(4) {
	width: 120px;
	text-align: right;
}
.testOnUnitTable > ul > li:nth-child(4) .btn {
	width: 100%;
	text-align: center;
	font-size: 14px;
	color: #ffffff;
	background: #454545;
	border-radius: 4px;
	padding: 6px 10px;
}
.testOnUnitTable > ul > li:nth-child(1) > img {
	width: 24px;
	display: inline-block;
}
.testOnUnitTable > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 5px 5px 5px;
	height: auto;
	background: #000 !important;
	color: #fff;
	font-size: 16px;
	border: 0px;
	border-radius: 0px;
}
.testOnUnitTable > ul > li .deleteButton {
	background: url("../images/recipecreation/deleteButton.png") no-repeat center center;
	background-size: 24px 24px;
	width: 24px;
	height: 24px;
	display: inline-block;
}
/* ****** ****** ****** ****** ****** **
 * ****** Swagger API Table ****** *
** ****** ****** ****** ****** ****** */

.swaggerAPITable {
	width: 100%;
	display: table;
	margin-top:10px;
}
.swaggerAPITable > ul {
	width: 100%;
	display: table-row;
}
.swaggerAPITable > ul.tHead li {
	color: #16406d;
	background: #E9EAEB;
	border-bottom: 1px solid #607488;
}
.swaggerAPITable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
}
.swaggerAPITable > ul > li a {
	color: #2dbaff;
}
.swaggerAPITable > ul.tBody > li:nth-child(1) {
	border-bottom: 1px solid #f2f2f2;
}
.swaggerAPITable > ul > li:nth-child(1) {
	width: 60px;
	text-align: center;
}
.swaggerAPITable > ul > li:nth-child(2) {
	width: 320px;
}
.swaggerAPITable > ul > li:nth-child(4) {
	width: 100px;
	text-align: center;
}
.swaggerAPITable > ul > li:nth-child(4) .btn {
	background: #4f4f4f;
	color: #ffffff;
	min-width: 80px;
}
/* ****** ****** ****** ****** ****** **
 * ****** View Offerings List ****** *
** ****** ****** ****** ****** ****** */

.C2CAPITitle {
	color: #6e87a2;
	font-size: 16px;
	width: 100%;
	float: left;
	padding: 5px 10px;
	background: #E9EAEB;
}
.viewSubscriptionList {
	width: 100%;
	display: table;
	margin-bottom:10px;
}
.viewSubscriptionList > ul {
	width: 100%;
	display: table-row;
}
.viewSubscriptionList > ul.tHead > li {
	color: #16406d;
	background: #E9EAEB;
	border-top: 1px solid #63778a;
}
.viewSubscriptionList > ul.tHead > li:first-child {
	border-left: 0px;
}
.viewSubscriptionList > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
}
.viewSubscriptionList > ul > li .progress {
	height: 2rem;
}
.viewSubscriptionList > ul > li .progress-bar {
	font-size: 16px;
}
.viewSubscriptionList > ul > li:nth-child(1) {
	color: #16406d;
	width: 25%;
}
.viewSubscriptionList > ul > li:nth-child(2) {
	padding-left: 0px;
	text-align: left;
	width: 30%;
}
.viewSubscriptionList > ul > li:nth-child(3) {
	text-align: center;
	width: 45%;
}
.viewSubscriptionList > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.viewSubscriptionList > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Recipe Creation Form ****** *
** ****** ****** ****** ****** ****** */

.rcSaveForm {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 25px;
}
.rcSaveForm .rcNameDiv {
	width: 60%;
	float: left;
	padding: 5px 0px;
}
.rcSaveForm .importImageDiv {
	width: 40%;
	float: right;
	padding: 15px 10px;
}
.rcSaveForm .rcNameDiv .form-control {
	width: 220px;
	float: left;
	clear: both;
	padding: 5px 5px 5px 5px;
	height: auto;
	background: #000;
	color: #fff;
	font-size: 16px;
	border: 0px;
	border-radius: 0px;
	caret-color: #0097ff;
}
.rcSaveForm .rcNameDiv > label {
	width: 100%;
	float: left;
	clear: both;
	padding: 15px 5px 5px 0px;
	color: #fff;
	font-size: 16px;
	text-align: left;
}
.rcSaveForm .rcSaveFrm {
	width: 100%;
	display: table;
	margin-bottom:10px;
}
.rcSaveForm .rcSaveFrm > ul {
	width: 100%;
	display: table-row;
} 
.rcSaveForm .rcSaveFrm > ul > li {
	display: table-cell;
	padding: 0px 0px 10px;
	vertical-align: top; 
	color: #16406d;
	font-size: 16px;
	text-align: left;
}
.rcSaveForm .rcSaveFrm > ul > li > label {
	width:140px;
	float: left; 
	padding: 5px 0px 0px 0px;
	color: #fff;
	font-size: 15px;
	text-align: left;
}
.rcSaveForm .rcSaveFrm > ul > li .form-control {
	width: 180px;
	float: left;
	clear: inherit;
	padding: 5px 5px 5px 5px;
	height: auto;
	background: #000;
	color: #fff;
	font-size: 16px;
	border: 0px;
	border-radius: 0px;
	caret-color: #0097ff;
}
.rcSaveForm .rcSaveFrm > ul > li > select.form-control {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: #000 url("../images/icons/markoffA.svg") no-repeat right center !important;
}
.rcSaveForm .rcSaveFrm > ul > li > select::-ms-expand {
	display: none;
}

.rcSaveForm .rcSaveFrm > ul > li > .btn-group-toggle .btn-secondary.focus,
.rcSaveForm .rcSaveFrm > ul > li > .btn-group-toggle .btn-secondary:focus {
	box-shadow: inherit;
}
.rcSaveForm .rcSaveFrm > ul > li > .btn-group-toggle .btn-secondary {
	width: 48px;
	height: 24px;
	border: 0px;
	background: #ccc url("../images/icons/switchGOFF.png") no-repeat center center;
	background-size: 46px auto;
	background-color: transparent !important;
}
.rcSaveForm .rcSaveFrm > ul > li > .btn-group-toggle .btn-secondary.active {
	background: #ccc url("../images/icons/switchGON.png") no-repeat center center;
	background-size: 46px auto;
}
.rcSaveForm .importImageDiv > label {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 5px 5px 5px;
	color: #fff;
	font-size: 16px;
	text-align: center;
}
.importImageDiv .importImage {
	width: 100%;
	float: left;
	padding: 10px;
	text-align: center;
	background: #000;
	position:relative;
}
.importImageDiv .importImage .menuDrogDroporBrowse {
	width: 100%;
	float: left;
	padding: 0px 0px 0px;
	text-align: center;
	font-size: 18px;
	color: #1b4470;
}
.importImageDiv .importImage .menuBrowse {
	width: 100%;
	float: left;
	padding: 0px;
	height: 120px;
	line-height: 120px;
	text-align: center;
	font-size: 18px;
	color: #fff;
	cursor: pointer;
}
.importImageDiv .importImage .menuBrowse > img {
	max-height: 120px;
	max-width: 98%;
	display: inline-block;
}
.importImageDiv .importImage .menuUpdateFile {
	display: none;
} 
.importImageDiv.editActive .importImage .cancelButton {
	display: block;
}
.importImageDiv .importImage .cancelButton {
	width: 24px;
	height: 24px;
	border-radius:50%;
	display: none;
	position: absolute;
	top: -10px;
	right: -10px;
	background: url("../images/bgTransparent.png") repeat; 
	border:1px solid #ffffff; 
}
.importImageDiv .importImage .cancelButton:before { 
	background: url("../images/icons/trashA.svg") no-repeat center center;
	background-size: 16px 16px;
	position: absolute;
	top:3px;
	left: 3px; 
	width:18px;
	height:18px;
	content: " "; 
	z-index: 1;
}
/* ****** ****** ****** ****** ****** **
 * ****** RC Settings ****** *
** ****** ****** ****** ****** ****** */

.rcSettingsForm {
	width: 80%;
	margin: 0px 10%;
	display: table;
}
.rcSettingsForm > ul {
	width: 100%;
	display: table-row;
}
.rcSettingsForm > ul > li {
	display: table-cell;
	padding: 0px 0px 0px;
	vertical-align: middle;
	color: #0D3968;
	font-size: 16px;
	text-align: left;
}
.rcSettingsForm > ul > li:nth-child(1),
.rcSettingsForm > ul > li:nth-child(3) {
	width: 65px;
	text-align: center;
}
.rcSettingsForm > ul > li:nth-child(1) > .btn,
.rcSettingsForm > ul > li:nth-child(3) > .btn {
	width: 45px;
	height: 45px;
}
.rcSettingsForm > ul > li .confirmButton {
	background: url("../images/recipecreation/confirmButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.rcSettingsForm > ul > li .eraseButton {
	background: url("../images/recipecreation/eraseButton.png") no-repeat center center;
	background-size: 45px 45px;
}
/* ****** ****** ****** ****** ****** **
 * ****** CB SearchBar Settings ****** *
** ****** ****** ****** ****** ****** */

.cbSearchForm {
	width: 80%;
	margin: 0px 10%;
	padding: 10px 0px;
	display: table;
}
.cbSearchForm > ul {
	width: 100%;
	display: table-row;
}
.cbSearchForm > ul > li {
	display: table-cell;
	padding: 0px 0px 0px;
	vertical-align: middle;
	color: #0D3968;
	font-size: 16px;
	text-align: left;
}
.cbSearchForm > ul > li:nth-child(2) {
	width: 65px;
	text-align: center;
}
.cbSearchForm > ul > li:nth-child(2) > .cbSearchButton {
	width: 45px;
	height: 45px; 
	background: url("../images/recipecreation/cbSearchButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.cbSearchForm > ul > li .recipeConfirmBtn {
	width: auto;
	min-width:90px;
	float:right;
	text-align: center;
	font-size: 16px;
	color: #ffffff; 
	border-radius: 8px;
	padding: 6px 10px;
	background: #555555; 
	border: 1px solid #696969;  
	margin:0px 15px 0px 0px;
} 
.cbSearchForm .cbRSearchBox {
	width:290px;
	float: left; 
	padding: 5px 10px;
}
.cbSearchForm .cbRSearchBox .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 5px 5px 5px;
	height: auto;
	background: #000 !important;
	color: #fff;
	font-size: 20px;
	border: 0px;
	border-radius: 0px;
} 
.cbSearchForm > ul > li .cbRSearchButton {
	width: 42px;
	height: 42px;
	background: url("../images/recipecreation/cbSearchButton.png") no-repeat center center;
	background-size: 42px 42px;
}
.cbSearchForm .cbSearchField {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 10px;
}
.cbSearchForm .cbSearchField .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 5px 5px 5px;
	height: auto;
	background: #000 !important;
	color: #fff;
	font-size: 20px;
	border: 0px;
	border-radius: 0px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Modal SearchBar ****** *
** ****** ****** ****** ****** ****** */

.modalSearchForm {
	width: 360px;
	margin: 0px;
	display: table;
}
.modalSearchForm > ul {
	width: 100%;
	display: table-row;
}
.modalSearchForm > ul > li {
	display: table-cell;
	padding: 0px 0px 0px;
	vertical-align: middle;
	color: #0D3968;
	font-size: 16px;
	text-align: left;
}
.modalSearchForm > ul > li:nth-child(2) {
	width: 65px;
	text-align: center;
}
.modalSearchForm > ul > li:nth-child(2) > .btn {
	width: 40px;
	height: 40px;
}
.modalSearchForm > ul > li .modalSearchButton {
	background: url("../images/icons/searchA.svg") no-repeat center center;
	background-size: 36px 36px;
}
.modalSearchForm .modalSearchField {
	width: 100%;
	float: left;
	clear: both;
}
.modalSearchForm .modalSearchField .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 5px 5px;
	height: auto;
	background: #fff !important;
	color: #0D3968;
	font-size: 20px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
}
/* ****** ****** ****** ****** ****** **
 * ****** RC Settings List****** *
** ****** ****** ****** ****** ****** */

.rcSettingsList {
	width: 80%;
	display: table;
	margin: 0px 10%;
	background: url("../images/recipecreation/borderBg.png") no-repeat bottom center;
	background-size: 100% 2px;
}
.rcSettingsList.fullWidth {
	width: 100%; 
	margin: 0px 0px; 
}
.rcSettingsList.withBg:last-child {
	background: url("../images/recipecreation/borderBg.png") no-repeat bottom center;
	background-size: 100% 2px;
}
.rcSettingsList:last-child {
	background: none;
}
.rcSettingsList > ul {
	width: 100%;
	display: table-row;
	cursor: pointer;
}
.rcSettingsList > ul > li {
	display: table-cell;
	padding: 5px 10px;
	vertical-align: middle;
	color: #ffffff;
	font-size: 16px;
	text-align: left;
}
.rcSettingsList > ul.listPadding0 > li { 
	padding: 0px 10px 0px;  
}
.rcSettingsList > ul:last-child {
	background: none;
}
.rcSettingsList > ul > li:nth-child(1),
.rcSettingsList > ul > li:nth-child(3) {
	width: 55px;
	text-align: center;
}
.rcSettingsList > ul > li:nth-child(2) {
	padding-left: 0px;
}
.rcSettingsList > ul > li:nth-child(1) > img {
	width: 24px;
	height: 24px;
}
.rcSettingsList > ul > li:nth-child(3) > img {
	width: 32px;
	height: 32px;
}
.rcSettingsList ul > li .customRadioBtn {
	margin: 0px;
}
.rcSettingsList ul > li .customRadioBtn input[type=radio] + label,
.rcSettingsList ul > li .customRadioBtn input[type=radio] + i + label,
.rcSettingsList ul > li .customRadioBtn input[type=radio] + input + label {
	height: 24px;
	padding-left: 24px;
	background: url("../images/icons/radioButtonGUnchecked.png") no-repeat left center;
	background-size: 24px 24px;
}
.rcSettingsList ul > li .customRadioBtn label {
	height: 24px;
	line-height: 24px;
	padding: 3px 0px 0px 24px;
	margin: 0px;
}
.rcSettingsList ul > li .customRadioBtn input[type=radio] + label {
	background: url("../images/icons/radioButtonGUnchecked.png") no-repeat left center;
	background-size: 24px 24px;
}
.rcSettingsList ul > li .customRadioBtn input[type=radio]:checked + label,
.rcSettingsList ul > li .customRadioBtn input[type=radio]:checked + i + label,
.rcSettingsList ul > li .customRadioBtn input[type=radio]:checked + input + label {
	background: url("../images/icons/radioButtonGChecked.png") no-repeat left center;
	background-size: 24px 24px;
}
/* ****** ****** ****** ****** ****** **
 * ****** RC Scheduler List****** *
** ****** ****** ****** ****** ****** */

.rcSchedulerList {
	width: 100%;
	float: left;
	padding: 10px 0px;
	text-align: center;
}
.rcSchedulerList > span {
	width: 45px;
	height: 45px;
	border-radius: 50%;
	line-height: 45px;
	color: #fff;
	background: #696969;
	margin: 5px 5px;
	display: inline-block;
}
.rcSchedulerList > span:hover,
.rcSchedulerList > span:active,
.rcSchedulerList > span.active {
	background: #0097ff;
	cursor: pointer;
}
/* ****** ****** ****** ****** ****** **
 * ****** All Day Select Frypots ****** *
** ****** ****** ****** ****** ****** */

.rcAllDaySOuter {
	width: 100%;
	float: left;
	padding: 10px 10px; 
	background:#cccccc;
}
.rcAllDaySFrypots {
	width: 100%;
	float: left;
	padding: 10px 0px;
	text-align: center;
}
.rcAllDaySFrypots > span {
	width:80px;
	height: 45px; 
	line-height: 35px;
	color: #000;
	background:#ffffff;
	border:5px solid #000;
	margin: 5px 5px;
	display: inline-block;
	font-family: 'open_sanssemibold';
}
.rcAllDaySFrypots > span:hover,
.rcAllDaySFrypots > span:active,
.rcAllDaySFrypots > span.active {
	background: #64c832;
	cursor: pointer;
}
 
.rcAllDaySFrypotsImg {
	width: 100%;
	float: left;
	padding: 0px 0px;
	text-align: center;
}
.rcAllDaySFrypotsImg > span {
	width:80px;  
	background: #000; 
	border-radius:6px;
	margin: 5px 5px;
	display: inline-block; 
}
.rcAllDaySFrypotsImg > span > img {
	width:60px; 
}
/* ****** ****** ****** ****** ****** **
 * ****** RC Screensaver List****** *
** ****** ****** ****** ****** ****** */

.rcScreensaverList {
	width: 80%;
	display: table;
	margin: 0px 10%;
	background: url("../images/recipecreation/borderBg.png") no-repeat bottom center;
	background-size: 100% 2px;
}
.rcScreensaverList:last-child {
	background: none;
}
.rcScreensaverList > ul {
	width: 100%;
	display: table-row;
}
.rcScreensaverList > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	color: #ffffff;
	font-size: 16px;
	text-align: left;
}
.rcScreensaverList > ul > li .ecSSAnimations {
	border: 1px solid #696969;
	width: 100%;
	float: left;
	height: 45px;
	line-height: 45px;
	text-align: center;
}
.rcScreensaverList > ul > li .ecSSAnimations.anBounce > img {
	width: 24px;
	height: 24px;
	display: inline-block;
	vertical-align: top;
}
.rcScreensaverList > ul > li .ecSSAnimations.anPopBounce > img {
	width: 32px;
	height: 32px;
	display: inline-block;
	vertical-align: middle;
}
.rcScreensaverList > ul > li .ecSSAnimations.anBounceScroll > img {
	width: 20px;
	height: 20px;
	display: inline-block;
	vertical-align: middle;
}
.rcScreensaverList > ul > li:nth-child(1),
.rcScreensaverList > ul > li:nth-child(4) {
	width: 65px;
	text-align: center;
}
.rcScreensaverList > ul > li:nth-child(2) {
	padding-left: 0px;
}
.rcScreensaverList > ul > li:nth-child(3) {
	text-align: center;
	width: 120px;
}
.rcScreensaverList > ul > li:nth-child(1) > img {
	width: 24px;
	height: 24px;
}
.rcScreensaverList > ul > li:nth-child(4) > img {
	width: 32px;
	height: 32px;
}
.rcScreensaverList ul > li .customRadioBtn {
	margin: 0px;
}
.rcScreensaverList ul > li .customRadioBtn input[type=radio] + label,
.rcScreensaverList ul > li .customRadioBtn input[type=radio] + i + label,
.rcScreensaverList ul > li .customRadioBtn input[type=radio] + input + label {
	height: 24px;
	padding-left: 24px;
	background: url("../images/icons/radioButtonGUnchecked.png") no-repeat left center;
	background-size: 24px 24px;
}
.rcScreensaverList ul > li .customRadioBtn label {
	height: 24px;
	line-height: 24px;
	padding: 3px 0px 0px 24px;
	margin: 0px;
}
.rcScreensaverList ul > li .customRadioBtn input[type=radio] + label {
	background: url("../images/icons/radioButtonGUnchecked.png") no-repeat left center;
	background-size: 24px 24px;
}
.rcScreensaverList ul > li .customRadioBtn input[type=radio]:checked + label,
.rcScreensaverList ul > li .customRadioBtn input[type=radio]:checked + i + label,
.rcScreensaverList ul > li .customRadioBtn input[type=radio]:checked + input + label {
	background: url("../images/icons/radioButtonGChecked.png") no-repeat left center;
	background-size: 24px 24px;
}
.rcScreensaverList ul > li .previewButton {
	padding: 3px 15px;
	background: #808080;
	border-radius: 20px;
	color: #ffffff;
}
.rcScreensaverList ul > li .previewButton > img {
	width: 24px;
	height: 24px;
}
/* ****** ****** ****** ****** ****** **
 * ****** RC Settings List02****** *
** ****** ****** ****** ****** ****** */

.rcSettingsList02 {
	width: 80%;
	display: table;
	margin: 0px 10%;
	padding: 20px 0px 0px;
}
.rcSettingsList02.width60 {
	width: 60%; 
	margin: 0px 20%; 
}
.rcSettingsList02 > ul {
	width: 100%;
	display: table-row;
	cursor: pointer;
}
.rcSettingsList02 > ul > li {
	display: table-cell;
	padding: 5px 10px;
	vertical-align: middle;
	color: #ffffff;
	font-size: 16px;
	text-align: left; 
}
.rcSettingsList02.schedulerList > ul > li:nth-child(1) {
	text-align: right;
}
.rcSettingsList02.schedulerList > ul > li:nth-child(2) {
	text-align: left;
}
.rcSettingsList02 > ul > li:nth-child(2) {
	text-align: right;
}
.rcSettingsList02 > ul > li:nth-child(2) > img {
	width: 32px;
	height: 32px;
}
.rcSettingsList02 > ul > li:nth-child(1) {
	padding-left: 0px;
}
.rcSettingsList02.soundLable > ul > li:nth-child(1) { 
	min-width:150px;
}
.rcSettingsList02 > ul > li .form-group {
	margin-bottom: 0px;
}
.rcSettingsList02 > ul > li.width100 {
	width: 100px; 
}
.rcSettingsLi01 {
	width: 80%;
	display: table;
	margin: 0px 10%;
}
.rcSettingsLi01.fullWidth {
	width: 100%;
	margin: 0px;
}
.rcSettingsLi01 .rcDateTimeSettings,
.rcSettingsLi01 .rcSettingsList,
.rcSettingsLi01 .rcSettingsList02 {
	width: 100%;
	margin: 0px;
	padding: 0px;
	background: url("../images/recipecreation/borderBg.png") no-repeat bottom center;
	background-size: 100% 2px;
	cursor: pointer;
} 
.rcSettingsLi01 .rcSettingsList02.borderBgNone {
	width: 100%;
	margin: 0px;
	padding: 0px;
	background: none;
}
.rcSettingsLi01 .rcDateTimeSettings:last-child,
.rcSettingsLi01 .rcSettingsList:last-child,
.rcSettingsLi01 .rcSettingsList02:last-child {
	background: none;
}
.rcSettingsLi01 .rcDateTimeSettings > ul,
.rcSettingsLi01 .rcSettingsList > ul,
.rcSettingsLi01 .rcSettingsList02 > ul {
	cursor: inherit;
}
.rcSettingsLi01 .rcSettingsList > ul > li {
	cursor: pointer;
}
.rcSettingsLi01 .rcSettingsList > ul > li .form-control,
.rcSettingsLi01 .rcSettingsList02 > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 5px 5px 5px;
	height: auto;
	background: #000;
	color: #fff;
	font-size: 20px;
	border: 0px;
	border-radius: 0px;
	text-align: center;
	caret-color: #0097ff;
}
.rcSettingsLi01 .rcSettingsList02 > ul > li .form-control.saverTime {
	width: 120px;
	float: right;
}
.rcSettingsLi01 .rcSettingsList02 > ul > li .form-control.timeField {
	width: 180px;
	float: right;
	text-align: left;
}
.rcSettingsLi01 .rcSettingsList02 > ul > li .form-control.dateField {
	width: 220px;
	float: right;
	text-align: left;
}
/* ****** ****** ****** ****** ****** **
 * ****** Change Screensaver ****** *
** ****** ****** ****** ****** ****** */

.rcChangeSSV {
	width: 100%;
	display: table;
	background: url("../images/recipecreation/borderBg.png") no-repeat bottom center;
	background-size: 100% 2px;
}
.rcChangeSSV > ul {
	width: 100%;
	display: table-row;
	cursor: pointer;
}
.rcChangeSSV > ul > li {
	display: table-cell;
	padding: 5px 10px;
	vertical-align: middle;
	color: #ffffff;
	font-size: 16px;
	text-align: left;
}
.rcChangeSSV > ul:last-child > li {
	border-bottom: 0px;
}
.rcChangeSSV > ul > li:nth-child(1) {
	padding-left: 0px;
}
.rcChangeSSV > ul > li:nth-child(2) {
	width: 55px;
	text-align: center;
}
.rcChangeSSV > ul > li:nth-child(2) > img {
	width: 32px;
	height: 32px;
}
.rcSettingsLi01 ul > li .importImageDiv {
	width: 100%;
	float: left;
	padding: 20px 0px 0px;
}
.rcSettingsLi01 ul > li .rcSaveForm {
	background: url("../images/recipecreation/borderBg.png") no-repeat bottom center;
	background-size: 100% 2px;
	padding: 0px;
}
.rcSettingsLi01 ul > li .importImageDiv .importImage {
	max-width: 100px;
	max-height: 100px;
	float: left;
	position:relative;
}
.rcSettingsLi01 ul > li .importImageDiv .importImage .menuBrowse {
	height: 80px;
	text-align: center;
	line-height: 80px;
}
.rcSettingsLi01 ul > li .importImageDiv .importImage .menuBrowse > img {
	max-width: 80px;
	max-height: 80px;
	display: inline-block;
} 
.rcSettingsLi01 ul > li .rcSaveForm .importImageDiv > label {
	width: 180px;
	float: left;
	clear: inherit;
	line-height: 100px;
	padding: 0px;
}
.rcSettingsLi01 > ul {
	width: 100%;
	display: table-row;
}
.rcSettingsLi01 > ul > li {
	display: table-cell;
	padding: 0px 10px;
	vertical-align: middle;
	color: #ffffff;
	font-size: 16px;
	text-align: left;
}
.rcSettingsLi01 ul > li .customRadioBtn {
	margin: 0px;
}
.rcSettingsLi01 ul > li .customRadioBtn input[type=radio] + label,
.rcSettingsLi01 ul > li .customRadioBtn input[type=radio] + i + label,
.rcSettingsLi01 ul > li .customRadioBtn input[type=radio] + input + label {
	height: 24px;
	padding-left: 24px;
	background: url("../images/icons/radioButtonGUnchecked.png") no-repeat left center;
	background-size: 24px 24px;
}
.rcSettingsLi01 ul > li .customRadioBtn label {
	height: 24px;
	line-height: 24px;
	padding: 3px 0px 0px 24px;
	margin: 0px;
}
.rcSettingsLi01 ul > li .customRadioBtn input[type=radio] + label {
	background: url("../images/icons/radioButtonGUnchecked.png") no-repeat left center;
	background-size: 24px 24px;
}
.rcSettingsLi01 ul > li .customRadioBtn input[type=radio]:checked + label,
.rcSettingsLi01 ul > li .customRadioBtn input[type=radio]:checked + i + label,
.rcSettingsLi01 ul > li .customRadioBtn input[type=radio]:checked + input + label {
	background: url("../images/icons/radioButtonGChecked.png") no-repeat left center;
	background-size: 24px 24px;
}
.rcSettingsLi01 ul > li .btn-group-toggle .btn-secondary.focus,
.rcSettingsLi01 ul > li .btn-group-toggle .btn-secondary:focus {
	box-shadow: inherit;
}
.rcSettingsLi01 ul > li .btn-group-toggle .btn-secondary {
	width: 48px;
	height: 24px;
	border: 0px;
	background: #ccc url("../images/icons/switchGOFF.png") no-repeat center center;
	background-size: 46px auto;
	background-color: transparent !important;
}
.rcSettingsLi01 ul > li .btn-group-toggle .btn-secondary.active {
	background: #ccc url("../images/icons/switchGON.png") no-repeat center center;
	background-size: 46px auto;
}
.rcSettingsLi01 > ul > li:nth-child(2) {
	width: 65px;
	text-align: center;
}
.rcSettingsLi01 > ul > li:nth-child(2) > .confirmButton,
.rcSettingsLi01 > ul > li:nth-child(2) > .addButton {
	width: 45px;
	height: 45px; 
}
.rcSettingsLi01 > ul > li:nth-child(2) > .confirmButton { 
	background: url("../images/recipecreation/confirmButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.rcSettingsLi01 > ul > li:nth-child(2) > .addButton { 
	background: url("../images/recipecreation/addButton.png") no-repeat center center;
	background-size: 45px 45px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Preheat Form ****** *
** ****** ****** ****** ****** ****** */

.recipeCreationWrapper { 
	/**height: 220px;**/
	width: 100%;
}
.rcPreheatForm {
	width: 100%;
	display: table;
}
.rcPreheatForm > ul {
	width: 100%;
	display: table-row;
}
.rcPreheatForm > ul > li {
	display: table-cell;
	padding: 5px 10px;
	vertical-align: middle;
	color: #0D3968;
	font-size: 16px;
	text-align: left;
}
.rcPreheatForm > ul > li:nth-child(2) {
	width: 65px;
	text-align: center;
}
.rcPreheatForm > ul > li.buttonsGrp {
	width: 130px; 
}
.rcPreheatForm > ul > li .saveCButton,
.rcPreheatForm > ul > li .deleteButton,
.rcPreheatForm > ul > li .addButton {
	width: 45px;
	height: 45px; 
}
.rcPreheatForm > ul > li .saveCButton { 
	background: url("../images/recipecreation/confirmButton.png") no-repeat center center;
	background-size: 45px 45px;
	margin-left:10px;
}
.rcPreheatForm > ul > li .deleteButton { 
	background: url("../images/recipecreation/deleteButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.rcPreheatForm > ul > li .addButton { 
	background: url("../images/recipecreation/addButton.png") no-repeat center center;
	background-size: 45px 45px;
	margin-left:10px;
}
.rcPreheatTable {
	width: 100%;
	display: table;
}
.rcPreheatTable > ul {
	width: 100%;
	display: table-row;
}
.rcPreheatTable > ul > li {
	display: table-cell;
	padding: 0px 10px;
	vertical-align: middle;
	color: #0D3968;
	text-align: center;
}
.rcPreheatTable > ul > li:nth-child(1) {
	width: 60%;
}
.rcPreheatTable > ul > li:nth-child(2) {
	width: 26%;
}
.rcPreheatTable > ul > li:nth-child(3) {
    width: 14%;
    padding-left: 0px;
    color: #FFF;
}
.rcPreheatTable > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 5px 5px 5px;
	height: auto;
	color: #fff;
	font-size: 20px;
	border: 0px;
	border-radius: 0px;
	background: #000;
	caret-color: #0097ff;
}
/* ****** ****** ****** ****** ****** **
 * ****** Temp List ****** *
** ****** ****** ****** ****** ****** */

.rcTempList {
	width: 80%;
	display: table;
	margin: 0px 10%;
	background: url("../images/recipecreation/borderBg.png") no-repeat bottom center;
	background-size: 100% 2px;
}
.rcTempList:last-child {
	background: none;
}
.rcTempList > ul {
	width: 100%;
	display: table-row;
	cursor: pointer;
}
.rcTempList > ul > li {
	display: table-cell;
	padding: 5px 10px;
	vertical-align: middle;
	color: #ffffff;
	font-size: 16px;
	text-align: left;
}
.rcTempList > ul > li:nth-child(2) {
	width: 45px;
	text-align: center;
}
.rcTempList > ul > li:nth-child(2) {
	padding-left: 0px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Timer For Temp. ****** *
** ****** ****** ****** ****** ****** */

.timerForTempList {
	width: 80%;
	display: table;
	margin: 0px 10%;
	background: url("../images/recipecreation/borderBg.png") no-repeat bottom center;
	background-size: 100% 2px;
}
.timerForTempList:last-child {
	background: none;
}
.timerForTempList > ul {
	width: 100%;
	display: table-row;
}
.timerForTempList > ul.tHead > li {
	color: #ffffff;
	font-size: 16px;
}
.timerForTempList > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	color: #ffffff;
	font-size: 14px;
	text-align: left;
}
.timerForTempList > ul > li:nth-child(1) {
	width: 50%;
}
.timerForTempList > ul > li:nth-child(2) {
	text-align: center;
}
#CleanIceEnabledDiv,
#CleanProtectorEnabledDiv {
	display: none;
}
#screensaverChgeBtn,
#screensaverSaveBtn,
#screensaverTimeDiv,
#screensaverPicDiv {
	visibility: hidden;
}
/* ****** ****** ****** ****** ****** **
 * ****** Cookbook Photo Gallery ****** *
** ****** ****** ****** ****** ****** */

.cbPhotogallery {
	width: 100%;
	padding: 20px 30px;
	float: left;
	clear: both;
}
.cbPhotogallery > ul {
	padding: 0px;
	margin: 0px -5px;
	clear: both;
	width: 100%;
}
.cbPhotogallery > ul > li {
	width: 25%;
	padding: 10px 5px;
	float: left;
	text-align: center;
}
.cbPhotogallery > ul > li > a > .navTitle {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 0px 0px;
	font-size: 16px;
	color: #ffffff;
	text-align: center;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.cbPhotogallery > ul > li > a > .navIcon {
	width: 90px;
	display: inline-block;
	padding: 0px;
	text-align: center;
	height: 90px;
	line-height: 80px;
	background: #000;
	border: 3px solid #323232;
	border-radius: 6px;
	overflow: hidden;
}
.cbPhotogallery > ul > li > a.selectedList > .navIcon {
	border: 3px solid #195d99;
}
.cbPhotogallery > ul > li > a > .navIcon > img {
	max-height: 84px;
	max-width: 84px;
	display: inline-block;
}
/* ****** ****** ****** ****** ****** **
 * ****** Cookbook Rc ****** *
** ****** ****** ****** ****** ****** */

.cbcategory {
	width: 100%;
	float: left;
	clear: both;
	margin-top: 10px;
}
.cbcategory > ul {
	padding: 0px;
	margin: 0px -5px;
	clear: both;
	width: 100%;
}
.cbcategory > ul > li {
	width: 25%;
	padding: 10px 5px;
	float: left;
	text-align: center;
}
.cbcategory > ul > li.garlandCBcategory {
	width:50%; 
	text-align: left;
	padding:0px 0px 10px 25px;
}
.cbcategory > ul > li.garlandCBcategory .cbAddButton {
	background: url("../images/recipecreation/addButton.png") no-repeat left center;
	background-size: 26px 26px;   
	width: 100%;
	float: left;
	clear: both;
	padding: 10px 0px 10px 36px;
	font-size: 16px; 
	color: #ffffff;
	text-align: left;  
} 
.cbcategory > ul > li.garlandCBcategory .cbAddedButton {
	background: url("../images/recipecreation/confirmButton.png") no-repeat left center;
	background-size: 26px 26px;   
	width: 100%;
	float: left;
	clear: both;
	padding: 10px 0px 10px 36px;
	font-size: 16px; 
	color: #ffffff;
	text-align: left;  
} 
.cbcategory > ul > li.garlandCBcategory .confirmButton {
	width: auto;
	min-width:90px;
	float:right;
    text-align: center;
    font-size: 16px;
    color: #ffffff; 
    border-radius: 8px;
    padding: 3px 10px;
	background: #555555; 
	border: 1px solid #696969;  
	margin:10px 15px 0px 0px;
} 
.cbcategory > ul > li.addCategoryLi > .cbcategoryBox > .navIcon {
	background: none;
}
.cbcategory > ul > li.addCategoryLi > .cbcategoryBox > .navIcon > img {
	max-height: 45px;
	max-width: 45px;
	line-height: 80px;
}
.cbcategory > ul > li > .cbcategoryBox {
	position: relative;
	display: inline-block;
	width: 90px;
}
.cbcategory.menuEditActive > ul > li > .cbcategoryBox .cancelButton {
	display: block;
}
.cbcategory > ul > li .cbcategoryAction {
	width: 42px;
	height: 42px;
	padding:6px;
	border-radius:50%; 
	position: absolute;
	top: 22px;
	left:25px;
	background: url("../images/bgTransparent.png") repeat; 
	border:1px solid #ffffff; 
}
.cbcategory > ul > li .cbcategoryAction .cbEditButton {
	background: url("../images/icons/rcEdit.png") no-repeat center center;
	background-size: 26px 26px; 
	width:26px;
	height:26px;
	display:block;
} 
.cbcategory > ul > li > .cbcategoryBox .cancelButton {
	width: 24px;
	height: 24px;
	border-radius:50%;
	display: none;
	position: absolute;
	top: -10px;
	right: -10px;
	background: url("../images/bgTransparent.png") repeat; 
	border:1px solid #ffffff; 
}
.cbcategory > ul > li > .cbcategoryBox .cancelButton:before { 
	background: url("../images/icons/trashA.svg") no-repeat center center;
	background-size: 16px 16px;
	position: absolute;
	top:3px;
	left: 3px; 
	width:18px;
	height:18px;
	content: " "; 
	z-index: 1;
}
.cbcategory > ul > li > .cbcategoryBox > .navTitle {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 0px 0px;
	font-size: 16px;
	line-height: 16px;
	color: #ffffff;
	text-align: center;
	min-height: 54px; 
	max-height: 54px;
}
.cbcategory > ul > li > .cbcategoryBox > .navIcon {
	width: 90px;
	display: inline-block; 
	text-align: center;
	height: 90px;
	line-height: 80px; 
	overflow: hidden;
	vertical-align: bottom; 
	border:1px dashed #ffffff;
	padding:3px;
}
.cbcategory > ul > li > .cbcategoryBox > .navIcon > img {
	max-height: 90px;
	max-width: 90px;
	width: 100%;
	display: inline-block;
	vertical-align: bottom;
}
.cbcategory > ul > li > .cbcategoryBox .cbRCicon {
	width: 28px;
	height: 28px;
	position: absolute;
	top: 5px;
	right: 5px;
	border-radius: 4px;
	background: url("../images/recipecreation/recipeCreationA.png") no-repeat center center;
	background-size: 28px 28px;
}
.cbcategory > ul > li > .cbcategoryBox .cookbookicon {
	width: 28px;
	height: 28px;
	position: absolute;
	top: 5px;
	right: 5px;
	border-radius: 4px;
	background: url("../images/recipecreation/cookbookA.png") no-repeat center center;
	background-size: 28px 28px;
}
.cbcategory > ul > li > .cbcategoryBox .cbCategoryicon {
	width: 28px;
	height: 28px;
	position: absolute;
	top: 5px;
	right: 5px;
	border-radius: 4px;
	background: url("../images/recipecreation/categoryA.png") no-repeat center center;
	background-size: 28px 28px;
}
.cbcategory > ul > li.actionIcon > a > .navIcon > img, 
.cbcategory > ul > li:nth-child(1) > a > .navIcon > img { 
    max-height: 45px; 
    max-width: 45px; 
    display: inline-block; 
}  
.cbcategory > ul > li.actionIcon .navTitle {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 0px;
	font-size: 16px;
	color: #ffffff;
	text-align: center;
}
.cbcategory > ul > li.actionIcon .navIcon {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px;
	text-align: center;
	height: 80px;
	line-height: 80px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Cookbook Add Recipe****** *
** ****** ****** ****** ****** ****** */
.cbAddRecipe {
	width: 80%;
	display: table;
	margin: 0px 10%;
	background: url("../images/recipecreation/borderBg.png") no-repeat bottom center;
	background-size: 100% 2px;
}
.cbAddRecipe:last-child {
	background: none;
}
.cbAddRecipe > ul {
	width: 100%;
	display: table-row; 
}
.cbAddRecipe > ul > li {
	display: table-cell;
	padding: 5px 10px;
	vertical-align: middle;
	color: #ffffff;
	font-size: 16px;
	text-align: left;
}
.cbAddRecipe > ul > li:nth-child(1) {
	width: 55px;
	text-align: center;
} 
.cbAddRecipe > ul > li:nth-child(4) {
	width: 45px;
	text-align: center;
} 
.cbAddRecipe > ul > li:nth-child(5) {
	width: 45px;
	text-align: center;
}
.cbAddRecipe > ul > li:nth-child(2) {
	font-size: 18px;
}
.cbAddRecipe > ul > li:nth-child(3) {
	width: 120px;
}
.cbAddRecipe > ul > li:nth-child(1) > img {
	width: 36px;
	height: 36px;
}
.cbAddRecipe > ul > li .menuInfo {
	background: url("../images/icons/infoReportW.svg") no-repeat center center;
	background-size: 24px 24px;
} 
.cbAddRecipe > ul > li .menuEditV {
	background: url("../images/icons/penW.svg") no-repeat center center;
	background-size: 24px 24px;
}
.cbAddRecipe > ul > li .menuDelete {
	background: url("../images/icons/trashA.svg") no-repeat center center;
	background-size: 24px 24px;
} 
.cbAddRecipe > ul > li .customCheckbox input[type=checkbox] + label {
	background: url("../images/icons/checkGAllOff.png") no-repeat left center;
	background-size: 24px 24px;
}
.cbAddRecipe > ul > li .customCheckbox input[type=checkbox] + label,
.cbAddRecipe > ul > li .customCheckbox input[type=checkbox] + i + label,
.cbAddRecipe > ul > li .customCheckbox input[type=checkbox] + input + label {
	background: url("../images/icons/checkGAllOff.png") no-repeat left center;
	background-size: 24px 24px;
}
.cbAddRecipe > ul > li .customCheckbox input[type=checkbox]:checked + label,
.cbAddRecipe > ul > li .customCheckbox input[type=checkbox]:checked + i + label,
.cbAddRecipe > ul > li .customCheckbox input[type=checkbox]:checked + input + label {
	background: url("../images/icons/checkGAllOn.png") no-repeat left center;
	background-size: 24px 24px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Cookbook Add Menus ****** *
** ****** ****** ****** ****** ****** */ 
.cbAddMenus {
	width: 80%;
	display: table;
	margin: 10px 10%; 
}
.cbAddMenus > ul {
	width: 100%;
	display: table-row; 
	background: url("../images/recipecreation/borderBg.png") no-repeat bottom center;
	background-size: 100% 2px;
}
.cbAddMenus > ul:last-child {
	background: none;
}
.cbAddMenus > ul > li {
	display: table-cell;
	padding: 5px 10px;
	vertical-align: middle;
	color: #ffffff;
	font-size: 16px;
	text-align: left;
}
.cbAddMenus > ul > li:nth-child(1),
.cbAddMenus > ul > li:nth-child(2) {
	width: 55px;
	text-align: center;
} 
.cbAddMenus > ul > li:nth-child(3) {
	font-size: 22px;
}
.cbAddMenus > ul > li:nth-child(2) > img {
	width: 36px;
	height: 36px;
}
.cbAddMenus > ul > li .customCheckbox input[type=checkbox] + label {
	background: url("../images/icons/checkGAllOff.png") no-repeat left center;
	background-size: 24px 24px;
}
.cbAddMenus > ul > li .customCheckbox input[type=checkbox] + label,
.cbAddMenus > ul > li .customCheckbox input[type=checkbox] + i + label,
.cbAddMenus > ul > li .customCheckbox input[type=checkbox] + input + label {
	background: url("../images/icons/checkGAllOff.png") no-repeat left center;
	background-size: 24px 24px;
}
.cbAddMenus > ul > li .customCheckbox input[type=checkbox]:checked + label,
.cbAddMenus > ul > li .customCheckbox input[type=checkbox]:checked + i + label,
.cbAddMenus > ul > li .customCheckbox input[type=checkbox]:checked + input + label {
	background: url("../images/icons/checkGAllOn.png") no-repeat left center;
	background-size: 24px 24px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Cookbook Menu Category ****** *
** ****** ****** ****** ****** ****** */
 
.cbMenuCategory {
	width: 100%;
	float: left;
	clear: both;
}
.cbMenuCategory > ul {
	padding: 0px;
	margin: 0px -5px;
	clear: both;
	width: 100%;
}
.cbMenuCategory > ul > li {
	width: 20%;
	padding: 10px 5px;
	float: left;
	text-align: center;
}
.cbMenuCategory > ul > li.addCategoryLi > .cbcategoryBox > .navIcon {
	background: none;
}
.cbMenuCategory > ul > li.addCategoryLi > .cbcategoryBox > .navIcon > img {
	max-height: 45px;
	max-width: 45px;
	line-height: 80px;
}
.cbMenuCategory > ul > li > .cbcategoryBox {
	position: relative;
	display: inline-block;
	width: 90px;
}
.cbMenuCategory.menuEditActive > ul > li > .cbcategoryBox .cancelButton {
	display: block;
}
.cbMenuCategory > ul > li > .cbcategoryBox .cancelButton {
	width: 28px;
	height: 28px;
	display: none;
	position: absolute;
	top: -15px;
	right: -15px;
	background: url("../images/recipecreation/cancelButton.png") no-repeat center center;
	background-size: 28px 28px;
}
.cbMenuCategory > ul > li > .cbcategoryBox > .navTitle {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 0px 0px;
	font-size: 14px;
	line-height: 16px;
	color: #ffffff;
	text-align: center;
	min-height: 40px;
}
.cbMenuCategory > ul > li > .cbcategoryBox > .navIcon {
	width: 90px;
	display: inline-block; 
	text-align: center;
	height: 90px;
	line-height: 80px; 
	overflow: hidden;
	vertical-align: bottom; 
	border:1px dashed #ffffff;
	padding:3px;
}
.cbMenuCategory > ul > li > .cbcategoryBox > .navIcon > img {
	max-height: 90px;
	max-width: 90px;
	width: 100%;
	display: inline-block;
	vertical-align: bottom;
} 
/* ****** ****** ****** ****** ****** **
 * ****** Menu Assign ****** *
** ****** ****** ****** ****** ****** */

.menuAssignFTable {
	width: 100%;
	display: table;
	position: fixed;
	bottom: 0px;
	left: 0px;
}
.menuAssignFTable > ul {
	width: 100%;
	display: table-row;
}
.menuAssignFTable > ul > li {
	display: table-cell;
	padding: 0px;
	vertical-align: middle;
	color: #16406d;
	font-size: 14px;
	text-align: left;
}
.menuAssignFTable > ul > li:nth-child(1) {
	text-align: center;
	color: #627d9a;
	background: #e3e5e8;
	border-right: 1px solid #ffffff;
}
.menuAssignFTable > ul > li:nth-child(2) {
	width: 220px;
	position: relative;
	text-align: center;
	color: #a3b2c1;
	background: #dce3e9;
	border-left: 1px solid #ffffff;
}
.menuAssignFTable > ul > li:nth-child(2) > button {
	color: #a7b8c8;
	background: #dce3e9;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0px;
	top: 0px;
	padding: 30px 5px;
	text-align: center;
	font-size: 20px;
	border: 0px;
	cursor: pointer;
	font-family: 'open_sanssemibold';
}
.menuAssignFTable > ul > li:nth-child(2) > button .menuABtext {
	background: url("../images/icons/assignDeviceB.png") no-repeat left center;
	background-size: 24px 24px;
	display: inline-block;
	padding: 5px 5px 5px 30px;
}
.menuAssignFTable > ul > li:nth-child(2) > button .menuAFileS {
	font-size: 14px;
}
.menuAssignFTable > ul > li.active:nth-child(2) {
	background: #195d99;
}
.menuAssignFTable > ul > li.active:nth-child(2) > button {
	color: #ffffff;
	background: #195d99;
}
.menuAssignFTable > ul > li.active:nth-child(2) > button .menuABtext {
	background: url("../images/icons/assignDeviceA.svg") no-repeat left center;
	background-size: 20px 20px;
}
.menuAssignFTable .menuAssignL {
	width: 100%;
	display: table;
	min-height: 65px;
}
.menuAssignFTable .menuAssignL > ul {
	width: 100%;
	display: table-row;
}
.menuAssignFTable .menuAssignL > ul > li {
	display: table-cell;
	padding: 0px;
	vertical-align: middle;
	color: #16406d;
	text-align: center;
	width: 33.333%;
}
.menuAssignFTable > ul > li .maxFileSize {
	width: 100%;
	padding: 5px;
	float: left;
	clear: both;
	text-align: right;
	background: #1f2124;
	color: #ffffff;
	font-size: 11px;
	position: relative;
}
.menuAssignFTable > ul > li .maxFileSize .progress-bar {
	clear: both;
	padding: 5px;
	font-size: 11px;
	font-weight: normal;
	color: #ffffff;
	text-align: right;
	position: absolute;
	left: 0px;
	top: 0px;
	background: #0aaeff;
}
.menuAssignFTable .menuAssignL .DragScreensaverhere,
.menuAssignFTable .menuAssignL .DragPreparationhere {
	display: inline-block;
	padding: 11px 10px 11px 40px;
}
.menuAssignFTable .menuAssignL > ul > li.active {
	background: #0d3968;
}
.menuAssignFTable .menuAssignL > ul > li.initialActive a {
	color: #0d3968;
}
.menuAssignFTable .menuAssignL > ul > li.active a {
	color: #FFFFFF;
}
.menuAssignFTable .dragedDetailsTable {
	width: 100%;
}
.menuAssignFTable .dragedDetailsTable > span {
	color: #a7b8c8;
	font-size: 20px;
	max-width: 280px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: inline-block;
}
.menuAssignFTable .dragedDetailsTable.active > span {
	color: #0d3968;
}
.menuAssignFTable .dragedDetailsTable > .menuManualCount,
.menuAssignFTable .dragedDetailsTable > .menuRecipeCount,
.menuAssignFTable .dragedDetailsTable > .menuSettingsCount {
	padding: 0px 10px 0px 0px;
}
.menuAssignFTable .dragedDetailsTable .menuManualText,
.menuAssignFTable .dragedDetailsTable .menuRecipeText,
.menuAssignFTable .dragedDetailsTable .menuSettingsText {
	padding: 0px 0px 0px 30px;
}
.menuAssignFTable .dragedDetailsTable .menuManualText {
	background: url("../images/icons/manualD.png") no-repeat left center;
	background-size: 24px 24px;
}
.menuAssignFTable .dragedDetailsTable.active .menuManualText {
	background: url("../images/icons/manualA.png") no-repeat left center;
	background-size: 24px 24px;
}
.menuAssignFTable .dragedDetailsTable .menuRecipeText {
	background: url("../images/icons/recipeD.png") no-repeat left center;
	background-size: 24px 24px;
}
.menuAssignFTable .dragedDetailsTable.active .menuRecipeText {
	background: url("../images/icons/recipeA.png") no-repeat left center;
	background-size: 24px 24px;
}
.menuAssignFTable .dragedDetailsTable .menuSettingsText {
	background: url("../images/icons/settingD.png") no-repeat left center;
	background-size: 24px 24px;
}
.menuAssignFTable .dragedDetailsTable.active .menuSettingsText {
	background: url("../images/icons/settingsA.png") no-repeat left center;
	background-size: 24px 24px;
}
.menuAssignFTable > ul > li .maxFileSize .progress-bar.errorUpload {
	background: #f93251;
}
/* ****** ****** ****** ****** ****** **
 * ****** country Table ****** *
** ****** ****** ****** ****** ****** */


.countryListTable {
	width: 100%;
	display: table;
}
.countryListTable > ul {
	width: 100%;
	display: table-row;
}
.countryListTable > ul.tHead > li {
	color: #16406d;
	border-bottom: 1px solid #63778a;
	background: #e9eaeb;
}
.countryListTable > ul.tHead > li:first-child {
	border-left: 0px;
}
.countryListTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
}
.countryListTable > ul > li:nth-child(1) {
	width: 40px;
	text-align: center;
	color: #16406d;
	border-bottom: 1px solid #F2F2F2;
}
.countryListTable > ul > li:nth-child(2) {
	padding-left: 0px;
}
.countryListTable > ul > li:nth-child(3) {
	width: 180px;
}
.countryListTable > ul > li:nth-child(4) {
	width: 240px;
}
.countryListTable > ul.tBody > li:nth-child(4) {
	width: 228px;
}
.timeZoneListTable {
	width: 100%;
	display: table;
}
.timeZoneListTable > ul {
	width: 100%;
	display: table-row;
}
.timeZoneListTable > ul.tHead > li {
	color: #16406d;
	border-bottom: 1px solid #63778a;
	background: #e9eaeb;
}
.timeZoneListTable > ul.tHead > li:first-child {
	border-left: 0px;
}
.timeZoneListTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
}
.timeZoneListTable > ul > li:nth-child(1) {
	width: 120px;
	text-align: center;
	color: #16406d;
	border-bottom: 1px solid #F2F2F2;
}
.timeZoneListTable > ul > li:nth-child(2) {
	padding-left: 0px;
}
.timeZoneListTable > ul > li:nth-child(3) {
	width: 140px; 
}
.timeZoneListTable > ul > li:nth-child(4) {
	width: 330px;
}
.timeZoneListTable > ul.tBody > li:nth-child(4) {
	font-size: 14px;
	width: 318px;
} 
.responseCListTable {
	width: 100%;
	display: table;
}
.responseCListTable > ul {
	width: 100%;
	display: table-row;
}
.responseCListTable > ul.tHead > li {
	color: #16406d;
	padding: 7px 5px;
	border-bottom: 1px solid #63778a;
	background: #e9eaeb;
}
.responseCListTable > ul > li {
	display: table-cell;
	padding: 5px 5px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 14px;
	text-align: left;
}
.responseCListTable > ul > li:nth-child(2),
.responseCListTable > ul > li:nth-child(3),
.responseCListTable > ul > li:nth-child(4),
.responseCListTable > ul > li:nth-child(5),
.responseCListTable > ul > li:nth-child(6),
.responseCListTable > ul > li:nth-child(7),
.responseCListTable > ul > li:nth-child(8),
.responseCListTable > ul > li:nth-child(9),
.responseCListTable > ul > li:nth-child(10),
.responseCListTable > ul > li:nth-child(11),
.responseCListTable > ul > li:nth-child(12),
.responseCListTable > ul > li:nth-child(13),
.responseCListTable > ul > li:nth-child(14),
.responseCListTable > ul > li:nth-child(15),
.responseCListTable > ul > li:nth-child(16),
.responseCListTable > ul > li:nth-child(17),
.responseCListTable > ul > li:nth-child(18),
.responseCListTable > ul > li:nth-child(19),
.responseCListTable > ul > li:nth-child(20),
.responseCListTable > ul > li:nth-child(21),
.responseCListTable > ul > li:nth-child(22),
.responseCListTable > ul > li:nth-child(23),
.responseCListTable > ul > li:nth-child(24) {
	width: 100px;
	text-align: center;
}
/*** **************  ************** ****
		*** Service Notes ***
**** **************  ************** ***/

.serviceNotesTable {
	width: 100%;
	display: table;
	margin: 10px 0px 0px;
}
.serviceNotesTable > ul {
	width: 100%;
	display: table-row;
}
.serviceNotesTable > ul.tHead > li {
	color: #68829e;
	border-bottom: 1px solid #63778a;
}
.serviceNotesTable > ul.tHead > li:nth-child(1) {
	border-bottom: 1px solid #63778a;
}
.serviceNotesTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #acbac8;
	color: #0D3968;
	font-size: 16px;
	text-align: left;
}
.serviceNotesTable > ul > li h6 {
	text-align: left;
	margin-bottom: 5px;
	color: #0D3968;
	font-size: 16px;
}
.serviceNotesTable > ul > li p {
	text-align: left;
	color: #0D3968;
	font-size: 16px;
	margin-bottom: 0px;
}
.serviceNotesTable > ul > li:nth-child(1) {
	width: 50px;
	text-align: center;
	vertical-align: top;
	color: #68829e;
	border-bottom: 1px solid #F2F2F2;
}
.serviceNotesTable > ul > li:nth-child(2) {
	padding-right: 60px;
}
.serviceNotesTable > ul > li:nth-child(3) {
	width: 180px;
}
.serviceNotesTable > ul > li:nth-child(4) {
	width: 80px;
}
/*** **************  ************** ****
		*** Scheduler Form ***
**** **************  ************** ***/

.rcSchedulerForm {
	width: 100%;
	display: table;
}
.rcSchedulerForm > ul {
	width: 100%;
	display: table-row;
	background: url("../images/recipecreation/borderBg.png") no-repeat bottom center;
	background-size: 100% 2px;
}
.rcSchedulerForm > ul:last-child {
	background: none;
}
.rcSchedulerForm > ul > li {
	display: table-cell;
	padding: 5px 10px;
	vertical-align: middle;
	color: #0D3968;
	font-size: 16px;
	text-align: left;
}
.rcSchedulerForm > ul > li:nth-child(2) {
	width: 65px;
	text-align: center;
}
.rcSchedulerForm > ul > li .deleteButton {
	width: 45px;
	height: 45px;
	background: url("../images/recipecreation/deleteButton.png") no-repeat center center;
	background-size: 45px 45px;
}
/*** **************  ************** ****
		*** Add Scheduler Form ***
**** **************  ************** ***/

.rcAddSchedulerForm {
	width: 100%;
	display: table;
}
.rcAddSchedulerForm > ul {
	width: 100%;
	display: table-row;
	background: url("../images/recipecreation/borderBg.png") no-repeat bottom center;
	background-size: 100% 2px;
}
.rcAddSchedulerForm > ul.notesField,
.rcAddSchedulerForm > ul:last-child {
	background: none;
}
.rcAddSchedulerForm > ul > li {
	display: table-cell;
	padding: 5px 10px;
	vertical-align: middle;
	color: #0D3968;
	font-size: 16px;
	text-align: left;
}
.rcAddSchedulerForm > ul > li:nth-child(1) {
	width: 25px;
	padding-left: 0px;
	padding-right: 0px;
	text-align: center;
}
.rcAddSchedulerForm > ul > li:nth-child(3) {
	width: 65px;
	text-align: center;
}
.rcAddSchedulerForm > ul > li:nth-child(1) > .recipeRowCount {
	background: #ffffff;
	border-radius: 50%;
	color: #000;
	font-size: 12px;
	width: 24px;
	height: 24px;
	text-align: center;
	padding: 0px;
	cursor: default;
}
.rcAddSchedulerForm > ul > li .deleteButton {
	width: 45px;
	height: 45px;
	background: url("../images/recipecreation/deleteButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.rcSchedulerTable {
	width: 100%;
	display: table;
}
.rcSchedulerTable > ul {
	width: 100%;
	display: table-row;
}
.rcSchedulerTable > ul > li {
	display: table-cell;
	padding: 0px 10px;
	vertical-align: middle;
	color: #ffffff;
	text-align: center;
}
.rcSchedulerTable > ul > li:nth-child(1) {
	width: 80px;
	padding-left: 0px;
	padding-right: 0px;
}
.rcSchedulerTable > ul > li:nth-child(2) {
	padding-left: 5px;
	padding-right: 5px;
}
.rcSchedulerTable > ul > li:nth-child(3) {
	width: 70px;
	padding-left: 0px;
	padding-right: 0px;
}
.rcSchedulerTable > ul > li:nth-child(4) {
	width: 40px;
	padding-left: 0px;
	padding-right: 0px;
}
.rcSchedulerTable.scheduler3Colm > ul > li:nth-child(1) {
	width: 80px;
	padding-left: 0px;
	padding-right: 0px;
}
.rcSchedulerTable.scheduler3Colm > ul > li:nth-child(2) {
	width: auto;
	padding-right: 0px;
}
.rcSchedulerTable.scheduler3Colm > ul > li:nth-child(3) {
	width: 40px;
}
.rcSchedulerTable > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 5px 5px 5px;
	height: auto;
	color: #fff;
	font-size: 20px;
	border: 0px;
	border-radius: 0px;
	background: #000;
	caret-color: #0097ff;
}
.uploadOption {
	width: 100%;
	float: left;
}
.uploadOption > .customRadioBtn {
	width: 50%;
	float: left;
	margin: 10px 0px 10px;
}
.uploadOption > .customRadioBtn input[type=radio] + label,
.uploadOption > .customRadioBtn input[type=radio] + i + label,
.uploadOption > .customRadioBtn input[type=radio] + input + label {
	font-size: 18px;
	color: #0d3968;
}
.screensaverConfrmatn {
	width: 100%;
	float: left;
	padding: 15px 15%;
	text-align: center;
}
.screensaverConfrmatn > p {
	width: 100%;
	float: left;
	padding: 25px 0px;
	margin: 0px;
	font-size: 22px;
	color: #ffffff;
	text-align: center;
}
.screensaverConfrmatn .noBtn,
.screensaverConfrmatn .yesBtn {
	border: 0px;
	border-radius: 0px;
	padding: 15px 20px;
	font-size: 22px;
	color: #ffffff;
	background: none;
	cursor: pointer;
}
.screensaverConfrmatn .noBtn > img,
.screensaverConfrmatn .yesBtn > img {
	width: 36px;
}

.rcTypeConfrmatn {
	width: 100%;
	float: left;
	padding: 15px 15%;
	text-align: center;
}
.rcTypeConfrmatn > p {
	width: 100%;
	float: left;
	padding: 25px 0px;
	margin: 0px;
	font-size: 22px;
	color: #ffffff;
	text-align: center;
}
.rcTypeConfrmatn .noBtn,
.rcTypeConfrmatn .yesBtn {
	border: 0px;
	border-radius: 0px;
	padding: 15px 20px;
	font-size: 22px;
	color: #ffffff;
	background: none;
	cursor: pointer;
}
.rcTypeConfrmatn .noBtn > img,
.rcTypeConfrmatn .yesBtn > img {
	width: 45px;
}
.rcClrConfrmatn {
	width: 100%;
	float: left;
	padding: 15px 15%;
	text-align: center;
}
.rcClrConfrmatn > p {
	width: 100%;
	float: left;
	padding: 25px 0px;
	margin: 0px;
	font-size: 22px;
	color: #ffffff;
	text-align: center;
}
.rcClrConfrmatn .menuNameBtn01,
.rcClrConfrmatn .menuNameBtn02,
.rcClrConfrmatn .menuNameBtn03 { 
	padding:8px 20px;
	margin:5px 0px;
	font-size:16px;
	border-radius:6px;
	color: #ffffff; 
	min-width:80%; 
	clear:both;
	cursor:pointer;
}
.rcClrConfrmatn .menuNameBtn01 { 
	background:#000000; 
	border:5px solid #000000; 
} 
.rcClrConfrmatn .menuNameBtn02 { 
	background:#0995ed; 
	border:5px solid #0995ed; 
} 
.rcClrConfrmatn .menuNameBtn03 { 
	background:#fc9a01; 
	border:5px solid #fc9a01; 
}  
.rcClrConfrmatn button.rcClrBtn.active {
	border:5px solid #64c832;
}
/* ****** ****** ****** ****** ****** **
 * ****** ConvolLink Table ****** *
** ****** ****** ****** ****** ****** */

.convolLinkTableDiv {
	width: 100%;
	float: left;
	padding: 0px 0px;
}
.convolLinkTable {
	width: 100%;
	display: table;
}
.convolLinkTable > ul {
	width: 100%;
	display: table-row;
}
.convolLinkTable > ul > li {
	display: table-cell;
	padding: 15px;
	vertical-align: top;
	color: #000;
	font-size: 16px;
	text-align: left;
}
.convolLinkTable > ul > li:nth-child(1) {
	width: 350px;
	text-align: center;
}
.convolLinkTable > ul > li:nth-child(1) > img {
	width: 280px;
}
.convolLinkTable > ul > li:nth-child(2) {
	padding-left: 30px;
}
.convolLinkTable > ul > li > h4 {
	width: 100%;
	float: left;
	padding: 0px 0px 5px;
	margin: 0px;
	font-size: 20px;
	color: #000;
	font-family: 'open_sanssemibold';
}
.convolLinkTable > ul > li > h5 {
	width: 100%;
	float: left;
	padding: 15px 0px 10px;
	margin: 0px;
	font-size: 22px;
	color: #000;
	font-family: 'open_sanssemibold';
}
.convolLinkTable > ul > li > p {
	font-size: 16px;
	padding: 10px 0px 15px;
	margin: 0px;
}
.convolLinkTable > ul > li > p > b {
	font-family: 'open_sanssemibold';
	font-weight: normal;
}
/* ****** ****** ****** ****** ****** **
 * ****** Frymaster Recipe Creation Form ****** *
** ****** ****** ****** ****** ****** */

.frymasterRCForm {
	width: 100%;
	display: table;
	padding: 0px 10%;
}
.frymasterRCForm > ul {
	width: 100%;
	display: table-row;
	background: url("../images/recipecreation/borderBg.png") no-repeat bottom center;
	background-size: 100% 2px;
}
.frymasterRCForm > ul.borderNone {
	background: none;
}
.frymasterRCForm > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	color: #ffffff;
	font-size: 16px;
	text-align: left;
}
.frymasterRCForm > ul.padding0px > li { 
	padding: 10px 10px 0px; 
}
.frymasterRCForm > ul > li.verticalAlignT {
	vertical-align: top;
}
.frymasterRCForm > ul > li:nth-child(1) {
	width: 150px;
	padding-left: 0px;
}
.frymasterRCForm.autoMenuChanges > ul > li {
	width:50%;
}
.frymasterRCForm.autoMenuChanges > ul > li:nth-child(1) {
	width:50%;
}
.frymasterRCForm > ul > li.width190px {
	width: 190px;
}
.frymasterRCForm > ul > li.width245px {
	width: 245px;
}
.frymasterRCForm > ul > li.width90px {
	width: 90px;
}
.frymasterRCForm > ul > li.width110px {
	width: 110px;
}
.frymasterRCForm > ul > li.width10px {
	width:10px;
} 
.frymasterRCForm > ul > li:nth-child(2) {
	text-align: right;
	padding-right: 0px;
	min-width:100px;
}
.frymasterRCForm > ul > li.arrowImg {
	text-align: right;
	cursor:pointer;
}
.frymasterRCForm > ul > li.rightAlign {
	text-align: right;
}
.frymasterRCForm > ul > li.leftAlign {
	text-align: left;
}
.frymasterRCForm > ul > li.arrowBtn{
	padding:0px 0px 0px 10px;
}
.frymasterRCForm > ul > li.smallFormFields > input {
	width: 90px;
	display: inline-block;
	text-align:center;
}
.frymasterRCForm > ul > li .floatRight {
	float: right;
}
.frymasterRCForm > ul > li .floatLeft {
	float: left;
}
.frymasterRCForm > ul > li .deleteActionBtn,
.frymasterRCForm > ul > li .editActionBtn,
.frymasterRCForm > ul > li .addActionBtn {
	width: 45px;
	height: 45px;
}
.frymasterRCForm > ul > li .deleteActionBtn {
	background: url("../images/recipecreation/cancelButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.frymasterRCForm > ul > li .editActionBtn {
	background: url("../images/recipecreation/menuEditButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.frymasterRCForm > ul > li .addActionBtn {
	background: url("../images/recipecreation/addButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.frymasterRCForm > ul > li .infoActionBtn {
	width: 45px;
	height: 45px;
	float: right;
	background: url("../images/recipecreation/infoActionBtn.png") no-repeat center center;
	background-size: 45px 45px;
}
.frymasterRCForm > ul > li .deleteButton {
	width: 45px;
	height: 45px;
	background: url("../images/recipecreation/deleteButton.png") no-repeat center center;
	background-size: 45px 45px;
}
.frymasterRCForm > ul > li .nextButton {
	width: 45px;
	height: 45px;
	background: url("../images/recipecreation/rightArrow.png") no-repeat center center;
	background-size: 45px 45px;
}
.frymasterRCForm ul > li .btn-group-toggle .btn-secondary.focus,
.frymasterRCForm ul > li .btn-group-toggle .btn-secondary:focus {
	box-shadow: inherit;
}
.frymasterRCForm ul > li .btn-group-toggle .btn-secondary {
	width: 48px;
	height: 24px;
	border: 0px;
	background: #ccc url("../images/icons/switchGOFF.png") no-repeat center center;
	background-size: 46px auto;
	background-color: transparent !important;
}
.frymasterRCForm ul > li .btn-group-toggle .btn-secondary.active {
	background: #ccc url("../images/icons/switchGON.png") no-repeat center center;
	background-size: 46px auto;
}
.frymasterRCForm ul > li .btn-group-toggle {
	display: inline-block;
	margin: 5px 0px 0px;
}
.frymasterRCForm ul > li.arrowBtn .btn-group-toggle {
	margin: 15px 0px 0px;
}
.frymasterRCForm ul > li .customRadioBtn {
	margin: 0px 0px 5px 0px;
	width:24%;
} 
.frymasterRCForm ul > li.sundayList .customRadioBtn {
	margin: 0px 0px 5px 0px;
	width:19%;
} 
.frymasterRCForm ul > li.widthAuto .customRadioBtn {
	margin: 0px 0px 0px 15px;
	width:auto; 
}
.frymasterRCForm ul > li .customRadioBtn input[type=radio] + label,
.frymasterRCForm ul > li .customRadioBtn input[type=radio] + i + label,
.frymasterRCForm ul > li .customRadioBtn input[type=radio] + input + label {
	height: 24px;
	padding-left: 30px;
	background: url("../images/icons/radioButtonWUnchecked.png") no-repeat left center;
	background-size: 24px 24px;
}
.frymasterRCForm ul > li .customRadioBtn label {
	height: 24px;
	line-height: 24px;
	padding: 0px 0px 0px 30px;
	margin: 0px;
}
.frymasterRCForm ul > li .customRadioBtn input[type=radio] + label {
	background: url("../images/icons/radioButtonWUnchecked.png") no-repeat left center;
	background-size: 24px 24px;
}
.frymasterRCForm ul > li .customRadioBtn input[type=radio]:checked + label,
.frymasterRCForm ul > li .customRadioBtn input[type=radio]:checked + i + label,
.frymasterRCForm ul > li .customRadioBtn input[type=radio]:checked + input + label {
	background: url("../images/icons/radioButtonWChecked.png") no-repeat left center;
	background-size: 24px 24px;
}
.frymasterRCForm > ul > li .customCheckbox input[type=checkbox] + label {
	background: url("../images/icons/checkGAllOff.png") no-repeat left center;
	background-size: 24px 24px;
}
.frymasterRCForm > ul > li .customCheckbox input[type=checkbox] + label,
.frymasterRCForm > ul > li .customCheckbox input[type=checkbox] + i + label,
.frymasterRCForm > ul > li .customCheckbox input[type=checkbox] + input + label {
	background: url("../images/icons/checkGAllOff.png") no-repeat left center;
	background-size: 24px 24px;
}
.frymasterRCForm > ul > li .customCheckbox input[type=checkbox]:checked + label,
.frymasterRCForm > ul > li .customCheckbox input[type=checkbox]:checked + i + label,
.frymasterRCForm > ul > li .customCheckbox input[type=checkbox]:checked + input + label {
	background: url("../images/icons/checkGAllOn.png") no-repeat left center;
	background-size: 24px 24px;
}
.frymasterRCForm.ecoModeDiv > ul > li .customCheckbox {
    margin: 0px; 
}
.frymasterRCForm > ul > li .form-control {
	padding: 5px 5px 5px 5px;
	height: auto;
	background: #000;
	color: #0095fb;
	font-size: 20px;
	border: 0px;
	border-radius: 0px;
	caret-color: #0097ff;
}
.frymasterRCForm > ul > li .monthList {
	width: 100%;
	float: left; 
}
.frymasterRCForm > ul > li .monthList > .btn {
	width:30%; 
	margin:0px 5px 5px 0px;
	color:#ffffff;
	border:2px solid #323232;
	background:none;
}
.frymasterRCForm > ul > li .monthList > .btn.active {
	border:2px solid #64c832;
}
.frymasterRCForm > ul > li .scheduledDaysList {
	width: 100%;
	float: left; 
	text-align:left;
}
.frymasterRCForm > ul > li .scheduledDaysList > .customCheckbox {
	width:100px; 
	margin: 0px 10px 5px 0px;	
} 

.frymasterRCForm > ul > li .laneConfigList {
	width: 100%;
	float: left; 
}
.frymasterRCForm > ul > li .laneConfigList > .btn {
	width:30%;
	height:80px;
	margin:0px 10px 10px 0px;
	color:#ffffff;
	border:5px solid #000; 
}
.frymasterRCForm > ul > li .laneConfigList > .btn.active {
	border:5px solid #64c832;
}
.frymasterRCForm > ul > li .laneConfigList .btn.lane1 {
	background:#fff url("../images/recipecreation/lane1.png") no-repeat center center;
	background-size: 95px 62px;
}
.frymasterRCForm > ul > li .laneConfigList .btn.lane2 {
	background:#fff url("../images/recipecreation/lane2.png") no-repeat center center;
	background-size: 95px 62px;
}
.frymasterRCForm > ul > li .laneConfigList .btn.lane3 {
	background:#fff url("../images/recipecreation/lane3.png") no-repeat center center;
	background-size: 95px 62px;
}
.frymasterRCForm > ul > li .laneConfigList .btn.lane4 {
	background:#fff url("../images/recipecreation/lane4.png") no-repeat center center;
	background-size: 95px 62px;
}
.frymasterRCForm > ul > li .laneConfigList .btn.lane5 {
	background:#fff url("../images/recipecreation/lane5.png") no-repeat center center;
	background-size: 95px 62px;
}
.frymasterRCForm > ul > li .laneConfigList .btn.lane6 {
	background:#fff url("../images/recipecreation/lane6.png") no-repeat center center;
	background-size: 95px 62px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Frymaster Recipe Creation Form ****** *
** ****** ****** ****** ****** ****** */

.frymasterRCScdlForm {
	width: 100%;
	display: table;
	padding: 0px 10%;
}
.frymasterRCScdlForm > ul {
	width: 100%;
	display: table-row; 
} 
.frymasterRCScdlForm > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	color: #ffffff;
	font-size: 16px;
	text-align: center;
	width:50%;
} 
.frymasterRCScdlForm > ul > li label { 
	width:100%;
	clear:both;
} 
.frymasterRCScdlForm > ul > li .form-control {
	padding: 5px 5px 5px 5px;
	height: auto;
	background: #000;
	color: #fff;
	font-size: 20px;
	border: 0px;
	border-radius: 0px;
	caret-color: #0097ff;
}
.frymasterRCScdlForm > ul > li .form-control.timeField {
	width:100px;
	display:inline-block;
}
/* ****** ****** ****** ****** ****** **
 * ****** User Management List Table ****** *
** ****** ****** ****** ****** ****** */

.dspdPrdtsListTable {
	width: 100%;
	display: table;
}
.dspdPrdtsListTable > ul {
	width: 100%;
	display: table-row;
}
.dspdPrdtsListTable > ul.tHead > li {
	color: #16406d;
	border-bottom: 1px solid #63778a;
	background: #e9eaeb;
} 
.dspdPrdtsListTable > ul > li .btn.btn-default {
    color: #195d99;
	background: #ffffff;
}
.dspdPrdtsListTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
}
.dspdPrdtsListTable > ul > li:nth-child(1) {
	width:120px;  
}  
.dspdPrdtsListTable > ul > li:nth-child(3) {
	width: 50px;
    text-align: center;
    padding-right: 15px;
}  
.dspdPrdtsListTable > ul > li .customCheckbox {
	margin: 0px;
	display: block;
} 
.dspdPrdtsListTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Frymaster Recipe Creation Review ****** *
** ****** ****** ****** ****** ****** */

.frymasterRCReview {
	width: 100%;
	display: table;
	padding: 0px 15px;
}
.frymasterRCReview > ul {
	width: 100%;
	display: table-row;
}
.frymasterRCReview > ul > li {
	display: table-cell;
	padding: 10px 10px 0px;
	vertical-align: top;
	color: #0D3968;
	font-size: 16px;
	text-align: left;
}
.frymasterRCReview > ul > li:nth-child(2) {
	width: 100px;
	text-align: center;
}
.frymasterRCReview > ul > li .productsImg {
	width: 100%;
	display: inline-block;
	border: 1px solid #ccc;
	box-shadow: 0px 3px 3px #666;
	-webkit-box-shadow: 0px 3px 3px #666;
	-moz-box-shadow: 0px 3px 3px #666;
	box-shadow: 0px 3px 3px #666;
}
.frymasterRCReview > ul > li .productsImg > img {
	width: 100%;
}
.frymasterRCReview > ul > li .productsTitle {
	width: 100%;
	float: left;
	clear: both;
	padding:5px 0px 0px;
	font-size:14px; 
	color: #ffffff; 
}
/* ****** ****** ****** ****** ****** **
 * ****** Frymaster RC Review ****** *
** ****** ****** ****** ****** ****** */

.frymasterRCR {
	width: 100%;
	display: table;
}
.frymasterRCR > ul {
	width: 100%;
	display: table-row;
	background: url("../images/recipecreation/borderBg.png") no-repeat bottom center;
	background-size: 100% 2px;
} 
.frymasterRCR > ul > li {
	display: table-cell;
	padding: 5px 10px;
	vertical-align: middle;
	color: #ffffff;
	font-size: 16px;
	text-align: center;
	width: 25%;
}
.frymasterRCR.threeView > ul > li { 
	width: 33.333%; 
}
.frymasterRCR.modelView > ul { 
	background:none;
} 
.frymasterRCR.modelView > ul > li { 
	width: 33.333%;
	color:#000;
}
.frymasterRCR.modelView > ul > li > label { 
	color: #969799;
}
.frymasterRCR > ul > li > label,
.frymasterRCR > ul > li > .labelVal {
	display: block;
	line-height: 20px;
	font-size: 14px;
}
.frymasterRCR > ul > li > label {
	color: #969799;
	margin-bottom: 5px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Schedule RC Review ****** *
** ****** ****** ****** ****** ****** */

.frymasterSRCR {
	width: 100%;
	display: table;
}
.frymasterSRCR > ul {
	width: 100%;
	display: table-row; 
}
.frymasterSRCR > ul:last-child {
	background: url("../images/recipecreation/borderBg.png") no-repeat bottom center;
	background-size: 100% 2px;
}
.frymasterSRCR > ul.borderNone {
	background:none;
}
.frymasterSRCR > ul > li {
	display: table-cell;
	padding: 5px 10px;
	vertical-align: top;
	color: #ffffff;
	font-size: 16px;
	text-align: center;  
}
.frymasterSRCR > ul.tHead > li { 
	padding: 5px 10px 0px;   
}
.frymasterSRCR > ul.tHead > li label{ 
	margin-bottom:0px; 
}
.frymasterSRCR > ul > li > label,
.frymasterSRCR > ul > li > .labelVal {
	display: block;
	line-height: 20px;
	font-size: 14px;
}
.frymasterSRCR > ul > li > label {
	color: #969799;
	margin-bottom: 5px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Frymaster RC Review F ****** *
** ****** ****** ****** ****** ****** */
.frymasterRCF {
	min-width: 100%;
	float:left;
}
.frymasterRCF > ul {
	min-width: 100%;
	float:left;
}
.frymasterRCF > ul > li {
	float:left;
	padding: 10px 0px 0px;
	vertical-align: top;
	color: #ffffff;
	font-size: 16px;
	text-align: center;
	min-width:60px;
}
.frymasterRCF > ul > li:nth-child(1) {
	width: 90px;
}
.frymasterRCF > ul > li.drain,
.frymasterRCF > ul > li.hold {
	width: 65px;
}
.frymasterRCF > ul > li.startCook {
	width: 120px;
}
.frymasterRCF > ul > li.endCook {
	width: 80px;
}
.frymasterRCF > ul > li > label,
.frymasterRCF > ul > li > .labelVal {
	display: block;
	line-height: 20px;
	font-size: 14px;
}
.frymasterRCF > ul > li > label {
	color: #969799;
	margin-bottom: 0px;
}
.frymasterRCF > ul > li .labelImgDiv {
	width: 100%;
	float: left;
	clear: both;
	text-align: center;
	position: relative;
}
.frymasterRCF > ul > li .labelImgDiv:before {
	position: absolute;
	top: 50%;
	right: 0px;
	background: #a8a8a8;
	height: 2px;
	content: " ";
	width: 100%;
	z-index: 1;
}
.frymasterRCF > ul > li:first-child .labelImgDiv:before {
	width: 50%;
	right: 0px;
}
.frymasterRCF > ul > li:last-child .labelImgDiv:before {
	width: 50%;
	left: 0px;
}
.frymasterRCF > ul > li .labelImg {
	width: 32px;
	display: inline-block;
	position: relative;
	z-index: 2;
}
.frymasterRCF > ul > li .labelImg > img {
	width: 32px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Frymaster RC Review Option 2 ****** *
** ****** ****** ****** ****** ****** */

.frymasterRCFT {
	min-width: 100%;
	float:left;
}
.frymasterRCFT > ul {
	min-width: 100%;
	float:left;
}
.frymasterRCFT > ul > li {
	float:left;
	padding: 10px 0px 0px;
	vertical-align: top;
	color: #ffffff;
	font-size: 16px;
	text-align: center;
	width: 50px;
}
.frymasterRCFT > ul > li:nth-child(1) {
	width: 90px;
}
.frymasterRCFT > ul > li.stir {
	width: 65px;
}
.frymasterRCFT > ul > li.startCook,
.frymasterRCFT > ul > li.endCook {
	width: 80px;
}
.frymasterRCFT > ul > li > label,
.frymasterRCFT > ul > li > .labelVal {
	display: block;
	line-height: 20px;
	font-size: 14px;
}
.frymasterRCFT > ul > li > label {
	color: #969799;
	margin-bottom: 0px;
}
.frymasterRCFT > ul > li .labelImgDiv {
	width: 100%;
	float: left;
	clear: both;
	text-align: center;
	position: relative;
}
.frymasterRCFT > ul > li .labelImgDiv:before {
	position: absolute;
	top: 50%;
	right: 0px;
	background: #a8a8a8;
	height: 2px;
	content: " ";
	width: 100%;
	z-index: 1;
}
.frymasterRCFT > ul > li:first-child .labelImgDiv:before {
	width: 50%;
	right: 0px;
}
.frymasterRCFT > ul > li:last-child .labelImgDiv:before {
	width: 50%;
	left: 0px;
}
.frymasterRCFT > ul > li .labelImg {
	width: 32px; 
	display: inline-block;
	position: relative;
	z-index: 2;
}
.frymasterRCFT > ul > li .labelImg > img {
	width: 32px;
}
.frymasterRCFT > ul > li .labelImgText {
	width: 32px;
	height:32px;
	display: inline-block;
	position: relative;
	z-index: 2;
	background:#ffcc00; 
	font-family: 'open_sanssemibold';
	color:#000;
	border-radius:50%;
	line-height:32px;
	padding: 0px;
}

/* ****** ****** ****** ****** ****** **
 * ****** All Error List ****** *
** ****** ****** ****** ****** ****** */


.allErrorListTab {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both; 
}
.allErrorListTable {
	width: 100%;
	display: table;
}
.allErrorListTable > ul {
	width: 100%;
	display: table-row;
}
.allErrorListTable > ul.tHead > li {
	color: #657f9c;
	background: #E9EAEB;
	border-bottom: 1px solid #607488;
}
.allErrorListTable > ul.tHead > li:first-child {
	border-left: 0px;
}
.allErrorListTable > ul > li {
	display: table-cell;
	padding: 10px 5px;
	vertical-align: middle; 
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 14px;
	text-align: left;
}
.allErrorListTable > ul.tBody > li:first-child { 
    border-bottom: 1px solid #F2F2F2;
}
.allErrorListTable > ul.tBody > li {
	padding: 5px 5px;
}
.allErrorListTable > ul.tBody > li.progress {
	background: #d8d8d8; 
}
.allErrorListTable > ul.tBody.descExp > li {
	background: #1b1c1f; 
	vertical-align: top;
} 
.allErrorListTable > ul.tBody > li.progress .progress-bar { 
	height: 100%; 
	padding: 0px 2px;
	text-align: left;
	color:#0D3968;
	background: #d8d8d8;
} 
.allErrorListTable > ul > li h4 {
	font-size: 14px; 
	padding: 0px 0px 0px;
	font-family: 'open_sanssemibold';
}
.allErrorListTable > ul > li h5 {
	font-size: 14px; 
	padding: 0px 0px 0px;
}
.allErrorListTable > ul > li h6 {
	font-size: 14px; 
	padding: 0px 0px 0px;
} 
.allErrorListTable > ul > li:nth-child(1) {
	width: 40px;
	text-align: center;
	padding-left: 0px;
	padding-right: 0px;
}
.allErrorListTable > ul > li:nth-child(2) {
	width: 60px;
	text-align: center;
	padding: 10px 5px;
}
.allErrorListTable > ul > li:nth-child(3) {
	width: 48px;
	padding-left: 0px;
	padding-right: 0px;
	text-align: center;
}
.allErrorListTable > ul.tBody > li:nth-child(3) {
	padding: 0px;
}
.allErrorListTable > ul > li:nth-child(4) {
	width: 90px; 
	padding-right:0px;
}
.allErrorListTable > ul > li:nth-child(6),
.allErrorListTable > ul > li:nth-child(7),
.allErrorListTable > ul > li:nth-child(8),
.allErrorListTable > ul > li:nth-child(9) {
	width: 14%; 
}
.allErrorListTable > ul > li:nth-child(7) {
	text-align:center;
}
.allErrorListTable > ul > li:nth-child(10) {
	width: 125px;
	padding-left:0px;
}  
.allErrorListTable > ul > li:nth-child(11) {
	width: 58px;
	text-align: center; 
}   
.allErrorListTable.allHErrorListTable > ul > li:nth-child(6) {
	width: 13%; 
} 
.allErrorListTable.allHErrorListTable > ul > li:nth-child(7) {
	width:100px; 
	padding-left:5px;
	padding-right:5px;
	text-align:center;
}
.allErrorListTable.allHErrorListTable > ul > li:nth-child(8) {
	width: 13%; 
}
.allErrorListTable.allHErrorListTable > ul > li:nth-child(9) {
	width: 60px;
    padding: 10px 0px; 
}
.allErrorListTable.allHErrorListTable > ul > li:nth-child(10),
.allErrorListTable.allHErrorListTable > ul > li:nth-child(11) {
	width:140px; 
	text-align: left;
}
.allErrorListTable.allHErrorListTable > ul > li:nth-child(12) {
	width: 120px;
	padding-left:0px;
}  
.allErrorListTable.allHErrorListTable > ul > li:nth-child(13) {
	width: 58px;
	text-align: center; 
}     
.allErrorListTable > ul > li.errorStausLi {
	padding: 0px;
}
.allErrorListTable > ul > li span.errorStaus {
	width: 48px;
	height: 48px;
	display: inline-block;
	border: 0px;
} 
.allErrorListTable > ul > li .errorView {
	width: 40px;
	height: 40px;
	display: inline-block;
	border: 0px;
} 
.allErrorListTable > ul > li .errorEvent {
	background:#7c8691 url("../images/icons/errorEventW.png") no-repeat center center;
	background-size: 24px auto;
}
.allErrorListTable > ul > li .errorView {
	background: url("../images/icons/navExpandIcon.png") no-repeat center center;
	background-size: 32px auto;
}
.allErrorListTable > ul > li .warnLevel {
	background: #F93251 url("../images/icons/highLevel.svg") no-repeat center center;
	background-size: 24px auto;
}
.allErrorListTable > ul > li .alertLevel {
	background: #ca9b0d url("../images/icons/mediumLevel.svg") no-repeat center center;
	background-size: 24px auto;
}
.allErrorListTable > ul > li .checkLevel {
	background: #3CCE82 url("../images/icons/mediumLevel.svg") no-repeat center center;
	background-size: 24px auto;
}
.allErrorListTable > ul > li .infoLevel {
	background: #09aefe url("../images/icons/mediumLevel.svg") no-repeat center center;
	background-size: 24px auto;
}
.allErrorListTable > ul > li .descCollapse {
	background: url("../images/icons/arrow2r_A.svg") no-repeat center center;
	background-size: 24px 24px;
}
.allErrorListTable > ul.descExp > li .descCollapse {
	background: url("../images/icons/markoffA.svg") no-repeat center center;
	background-size: 24px 24px;
}
.allErrorListTable > ul > li .customCheckbox {
	margin: 0px;
	display: inline-block;
}
.allErrorListTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
} 
.allErrorListTable > ul.tBody > li .errorListSM {
	display: none;
	width: 100%;
	float: left;
}
.allErrorListTable > ul.tBody > li .errorTypeSM {
	display: none;
}
.allErrorListTab .panel-collapse {
	background: #ffffff;
} 
.allErrorListTable > ul.tHead .active {
	color: #153f6d;
} 
.allErrorListTable > ul > li .dropdown > .btn {
	background:none;
	padding:0px;
	color: #657f9c;
	font-size:14px;
} 
.allErrorListTable > ul > li .dropdown > .dropdown-menu {
	z-index: 9999;
	border-radius:0px;
} 
.allErrorListExpTable {
	width: 100%;
	display: table;
}
.allErrorListExpTable > ul {
	width: 100%;
	display: table-row;
} 
.allErrorListExpTable > ul > li {
	display: table-cell;
	padding: 10px 5px;
	vertical-align: middle; 
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 14px;
	text-align: left;
}    
.allErrorListExpTable > ul > li p {
	margin: 0px;
	font-size: 14px;
	color: #0D3968; 
} 
.allErrorListExpTable > ul > li .helpIcon,
.allErrorListExpTable > ul > li .callServiceIcon { 
	display: inline-block; 
	padding:5px 10px 5px 32px;
	color:#16406d;
	font-family: 'open_sanssemibold';
}
.allErrorListExpTable > ul > li .helpIcon {
	background: url("../images/icons/Service_A.png") no-repeat left center;
	background-size: 24px auto;
}
.allErrorListExpTable > ul > li .callServiceIcon {
	background: url("../images/icons/ServiceAgent_A.svg") no-repeat left center;
	background-size: 24px auto;
}
.allErrorListExpTable > ul > li:nth-child(1) {
	width:148px; 
} 

.panel-body.allErrorListExpTable {
	padding: 0px;
	margin: 0px;
	border: 0px;
}
.panel-body.allErrorListExpTable > ul.tBody li {
	border-bottom: 1px solid #acbac8;
}  
/*** **************  ************** ****
		*** Modal Form ***
**** **************  ************** ***/

.modalFormWrapper {
	width: 100%;
	float: left;
	margin: 0px 0px;
}
.modalForm {
	width: 100%;
	float: left;
	clear: both;
}
.modalForm > ul {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px 0px 0px;
}
.modalForm > ul > li {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px 0px 5px;
	margin: 0px;
}
.modalForm > ul > li.marginBottom10px {
	margin-bottom: 20px;
}
.modalForm > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 10px 10px 0px;
	height: auto;
	background: #ffffff !important;
	color: #0D3968;
	font-size: 20px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
}
.modalForm > ul > li select.form-control {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: #f2f2f2 url("../images/icons/markdownA.svg") no-repeat right center !important;
}
.modalForm > ul > li select::-ms-expand {
	display: none;
}
.modalForm > ul > li > p {
	color: #0D3968;
}
.modalForm > ul > li .input-group {
	width: 100%;
	position: relative;
}
.modalForm > ul > li .input-group .requiredField {
	width: 26px;
	height: 26px;
	text-align: center;
	position: absolute;
	top: 5px;
	right: 0px;
	z-index: 3;
	color: #b8c3cf;
	font-size: 28px;
}
.modalForm > ul > li > label{ 
	padding:0px 0px 5px; 
	font-size: 14px; 
	color: #0D3968;
	width:100%;
	float:left;
	clear:both;
	margin:0px;
}
.locationMapView iframe{
	min-width:100%;
	border:0px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Text, Radio Button List ****** *
** ****** ****** ****** ****** ****** */

.textWRadioBtnList {
	width: 80%;
	display: table;
	margin: 0px 10%;
	
}
.textWRadioBtnList > ul {
	width: 100%;
	display: table-row; 
	background: url("../images/recipecreation/borderBg.png") no-repeat bottom center;
	background-size: 100% 2px;
}
.textWRadioBtnList > ul:last-child {
	background: none;
}
.textWRadioBtnList > ul > li {
	display: table-cell;
	padding: 5px 10px;
	vertical-align: middle;
	color: #ffffff;
	font-size: 16px;
	text-align: left;
}
.textWRadioBtnList > ul > li:nth-child(2) {
	width: 45px;
	text-align: center;
}
.textWRadioBtnList > ul > li:nth-child(2) {
	padding-left: 0px;
}
.textWRadioBtnList ul > li .customRadioBtn input[type=radio] + label,
.textWRadioBtnList ul > li .customRadioBtn input[type=radio] + i + label,
.textWRadioBtnList ul > li .customRadioBtn input[type=radio] + input + label {
	height: 24px;
	padding-left: 24px;
	background: url("../images/icons/radioButtonWUnchecked.png") no-repeat left center;
	background-size: 24px 24px;
}
.textWRadioBtnList ul > li .customRadioBtn label {
	height: 24px;
	width: 24px;
	line-height: 24px;
	padding: 0px 0px 0px 24px;
	margin: 0px;
}
.textWRadioBtnList ul > li .customRadioBtn input[type=radio] + label {
	background: url("../images/icons/radioButtonWUnchecked.png") no-repeat left center;
	background-size: 24px 24px;
}
.textWRadioBtnList ul > li .customRadioBtn input[type=radio]:checked + label,
.textWRadioBtnList ul > li .customRadioBtn input[type=radio]:checked + i + label,
.textWRadioBtnList ul > li .customRadioBtn input[type=radio]:checked + input + label {
	background: url("../images/icons/radioButtonWChecked.png") no-repeat left center;
	background-size: 24px 24px;
}  
/* ****** ****** ****** ****** ****** **
 * ****** OrgU nits List ****** *
** ****** ****** ****** ****** ****** */

.sgModalUnitsList {
	width: 100%;
	display: table;
	margin-bottom:15px;
}
.sgModalUnitsList > ul {
	width: 100%;
	display: table-row;
}
.sgModalUnitsList > ul.tHead > li {
	color: #16406d;
	border-bottom: 1px solid #63778a;
	background: #e9eaeb;
} 
.sgModalUnitsList > ul > li {
	display: table-cell;
	padding: 5px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 14px;
	text-align: left;
}  
.sgModalUnitsList > ul > li:nth-child(1) {
	width: 40px;
	text-align: center;
}  
.sgModalUnitsList > ul.tBody > li:nth-child(1) > img {
	width: 32px;
	height: 32px;
} 
.sgModalUnitsList > ul > li:nth-child(3){ 
	width:190px;
} 
.sgModalUnitsList > ul > li:nth-child(4) {
	width: 210px;  
	padding-right:0px;
} 
.sgModalUnitsList > ul > li span {
	width: 100%;
	float: left;
	padding: 0px 0px 0px;
	margin:0px;
	font-size:14px;
	color: #0d3968; 
} 
/* ****** ****** ****** ****** ****** **
 * ****** SW Status Expanded Table ****** *
** ****** ****** ****** ****** ****** */

.SWStatusExpTable {
	width: 100%;
	padding:0px 45px;
	float: left;
	clear: both;
	background:#ffffff;
}  
.SWsExpTable {
	width:220px;
	margin:5px 15px 5px 0px;
	display: table; 
	float: left;
	cursor:pointer;
	border:1px solid #d8dbdc;
	border-radius:6px;
	overflow:hidden;
}    
.SWsExpTable.totalUnitsDiv { 
	border:0px;  
}  
.SWsExpTable.completed > ul > li {  
	color:#ffffff;
} 
.SWsExpTable.failed > ul > li {  
	color:#ffffff;
}
.SWsExpTable > ul {
	width: 100%;
	display: table-row;
}
.SWsExpTable > ul > li {
	display: table-cell; 
	vertical-align: middle;
	color: #0D3968;
	font-size: 16px;
	text-align: left;
}
.SWsExpTable > ul > li > img {
	width: 24px;
}
.SWsExpTable > ul > li:nth-child(1) {
	width: 44px;
	text-align: center;
	vertical-align: top;
	padding: 10px 10px;
}
.SWsExpTable > ul > li.inProgress { 
	background: #d8dbdc; 
}
.SWsExpTable > ul > li.completed { 
	background: #3cce82;  
} 
.SWsExpTable > ul > li.failed { 
	background: #f93251;  
}
.SWsExpTable > ul > li.canceled { 
	background: #6e809f;  
}
.SWsExpTable > ul > li.canceled span{font-weight: bold; font-size:17px; }
.SWsExpTable > ul > li:nth-child(2) { 
	font-family: 'open_sanssemibold';
	padding: 5px 10px;
}
.SWsExpTable > ul > li h4,
.SWsExpTable > ul > li h5 { 
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 14px;
}
.SWsExpTable > ul > li h4 {
	color: #748da8;
	margin: 0px 0px 5px;
	font-family: 'open_sanssemibold';
}
.SWsExpTable > ul > li h5 {
	color: #0D3968; 
}
.SWsExpTable > ul > li h5 > span {  
	font-family: 'open_sansregular';
}

/* ****** ****** ****** ****** ****** **
 * ****** SW Units Status Table ****** *
** ****** ****** ****** ****** ****** */
 
.SWuStatusTableOuter {
	width: 100%;
	float: left;
	padding: 0px;
}
.SWuStatusTableOuter .panel-collapse {
	background: #ffffff;
}
.SWuStatusTable {
	width: 100%;
	display: table;
}
.SWuStatusTable > ul {
	width: 100%;
	display: table-row;
}
.SWuStatusTable > ul.tHead li {
	background: #E9EAEB;
	border-bottom: 1px solid #607488;
}
.SWuStatusTable > ul > li {
	display: table-cell;
	padding: 10px 5px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
}
.SWuStatusTable > ul.tBody > li {  
	font-size: 14px; 
}
.SWuStatusTable > ul > li a:hover {
	color: #C8102E;
	text-decoration: none;
}
.SWuStatusTable > ul > li a {
	color: #333F48;
}
.SWuStatusTable > ul.tBody > li:nth-child(1) {
	border-bottom: 1px solid #f2f2f2;
}
.SWuStatusTable > ul > li:nth-child(1) {
	width: 34px;
	text-align: center; 
}
.SWuStatusTable > ul > li:nth-child(2) {
	width: 54px;
	text-align: center;
}
.SWuStatusTable > ul > li:nth-child(3) { 
	padding-left: 0px; 
}
.SWuStatusTable > ul > li:nth-child(4) {
	width: 120px;
}
.SWuStatusTable > ul > li:nth-child(5) {
	width: 120px;
}
.SWuStatusTable > ul > li:nth-child(6),
.SWuStatusTable > ul > li:nth-child(7) {
	width: 110px;  
} 
.SWuStatusTable > ul > li:nth-child(8),
.SWuStatusTable > ul > li:nth-child(9) {
	width: 100px;  
}
.SWuStatusTable > ul > li:nth-child(10) {
	width: 80px;
	text-align:center;
}
.SWuStatusTable > ul > li:nth-child(11) {
	width: 120px;
	padding-left:0px;
	padding-right:0px;
}
.SWuStatusTable > ul > li:nth-child(12) {
	width: 130px;
} 
.SWuStatusTable > ul > li:nth-child(13) {
	width: 60px;
	text-align: center;
	padding-left:0px;
	padding-right:0px;
}
.SWuStatusTable > ul > li:nth-child(13) > img {
	width: 24px;
}
.SWuStatusTable > ul > li:nth-child(14) {
	width: 120px;
} 
.SWuStatusTable > ul > li .progress {
	height: 8px;
	border-radius: 4px;
	background: #d8d8d8;
}
.SWuStatusTable > ul > li .progress .updatingSW {
	background: #0aaeff;
} 
.SWuStatusTable > ul > li .progress .warningStatus {
	background: #f93251;
} 
.SWuStatusTable > ul > li .progress .completedStatus {
	background: #3cce82;
}
.SWuStatusTable > ul.tBody > li .SWuStatusSM {
	display: none;
	width: 100%;
	float: left;
}
.SWuStatusTable > ul.tBody > li .SWuStatusAN {
	display: none;
	width: 100%;
	float: left;
	background: #fff;
	padding: 5px;
	font-size: 12px;
}
.SWuStatusTable > ul.tBody > li .SWuStatusAN > b {
	width: 100%;
	float: left;
	font-weight: normal;
}
/* ****** ****** ****** ****** ****** **
 * ****** Menu Assign Status Expanded Table ****** *
** ****** ****** ****** ****** ****** */
.SWuStatusExpTable {
	width: 100%;
	padding:0px 45px;
	float: left;
	clear: both;
	background:#ffffff;
}  
.SWuExpTable {
	width:230px;
	margin:5px 15px 5px 0px;
	display: table; 
	float: left;
	cursor:pointer;
	background: #f2f2f2;
}   
.SWuExpTable > ul {
	width: 100%;
	display: table-row;
}
.SWuExpTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	color: #0D3968;
	font-size: 16px;
	text-align: left;
}
.SWuExpTable > ul > li > img {
	width: 24px;
}
.SWuExpTable > ul > li:nth-child(1) {
	width: 54px;
	text-align: center;
	vertical-align: top;
	padding-left:5px;
	padding-right:5px;
}
.SWuExpTable > ul > li:nth-child(2) {
	padding-left: 0px;
}
.SWuExpTable > ul > li h4,
.SWuExpTable > ul > li h5 {
	color: #0D3968;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 14px;
	width: 190px;
}
.SWuExpTable > ul > li h4 {
	margin: 0px 0px 0px;
	font-family: 'open_sanssemibold';
}
.SWuExpTable > ul > li h5 > span {
	color: #748da8;
	margin-left: 10px;
}

/* ****** ****** ****** ****** ****** **
 * ****** Menu Assign Status Table ****** *
** ****** ****** ****** ****** ****** */ 
.menuLbryLTableOuter {
	width: 100%;
	float: left;
	padding: 0px;
}
.menuLbryLTableOuter .panel-collapse {
	background: #ffffff;
}
.menuLbryLTable {
	width: 100%;
	display: table;
}
.menuLbryLTable > ul {
	width: 100%;
	display: table-row;
}
.menuLbryLTable > ul.tHead li {
	background: #E9EAEB;
	border-bottom: 1px solid #607488;
}
.menuLbryLTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
}
.menuLbryLTable > ul.tBody > li {  
	font-size: 14px; 
} 
.menuLbryLTable > ul.tBody > li:nth-child(1) {
	border-bottom: 1px solid #f2f2f2;
}
.menuLbryLTable > ul > li:nth-child(1) {
	width: 34px;
	text-align: center;
	padding-left:5px;
	padding-right:5px;
}
.menuLbryLTable > ul > li:nth-child(2) {
	width: 54px;
	text-align: center;
}
.menuLbryLTable > ul > li:nth-child(3) {
	padding-left: 0px; 
}
.menuLbryLTable > ul > li:nth-child(4) {
	width: 180px; 
}
.menuLbryLTable > ul > li:nth-child(5) {
	width: 80px;
	padding-right: 0px;
	padding-left:0px;
	text-align:center;
} 
.menuLbryLTable > ul > li:nth-child(6),
.menuLbryLTable > ul > li:nth-child(7) {
	width: 24%;
}  
.menuLbryLTable > ul > li:nth-child(8) {
	width: 40px;
	text-align: center;
	padding-right: 0px;
	padding-left:0px;
}
.menuLbryLTable > ul.tBody > li .menuLbryLSM,
.menuLbryLTable > ul.tBody > li .menuLbryLSB {
	display: none;
	width: 100%;
	float: left;
} 
.menuLbryLTable > ul > li .referesh{
	width: 24px;
	height: 24px; 
	background: url("../images/icons/refresh.svg") no-repeat center center;
	background-size: 22px 22px;
	display:inline-block;
}
.menuLbryLTable > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.menuLbryLTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
} 
/* ****** ****** ****** ****** ****** **
 * ****** Menu Library Expanded Table ****** *
** ****** ****** ****** ****** ****** */
 .menuLbryLExpTable {
	width: 100%;
	display: table;
}
.menuLbryLExpTable > ul {
	width: 100%;
	display: table-row;
}  
.menuLbryLExpTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: top;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 16px;
	text-align: left;
}
.menuLbryLExpTable > ul > li a {
	color: #2dbaff;
}
.menuLbryLExpTable > ul.tBody > li:nth-child(1) {
	border-bottom: 1px solid #f2f2f2;
}
.menuLbryLExpTable > ul > li:nth-child(1) {
	width: 54px;
	text-align: center;
	padding-left:5px;
	padding-right:5px;
}
.menuLbryLExpTable > ul > li:nth-child(2) {
	width: 220px;
	padding-left: 0px;
	text-align: left;
}
.menuLbryLExpTable > ul > li:nth-child(2) > img {
	height: 24px;
	max-width: 100%;
}
.menuLbryLExpTable > ul > li:nth-child(2) > b,
.menuLbryLExpTable > ul > li:nth-child(3) > b,
.menuLbryLExpTable > ul > li:nth-child(4) > b {
	clear: both;
	font-weight: normal;
	display: block;
	font-family: 'open_sanssemibold';
} 
.menuLbryLExpTable > ul > li:nth-child(4) {
	width: 240px;
} 
.menuLbryLExpTable > ul.tBody > li .SWUpdateSM {
	display: none;
	width: 100%;
	float: left;
}
/* ****** ****** ****** ****** ****** **
 * ****** Add New Menu Form ****** *
** ****** ****** ****** ****** ****** */
.addNewMenuForm {
	width: 100%;
	float: left;
	padding: 0px 0px 5px 35px;
	clear: both;
	max-width:420px;
}
.addNewMenuForm > ul {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 0px;
}
.addNewMenuForm > ul > li {
	width: 100%;
	float: left;
	clear: both;
	padding:10px 0px 10px;
} 
.addNewMenuForm > ul > li > label {
	float: left;
	padding: 10px 0px 0px 0px;
	color: #62768a;
	font-size: 14px;
	font-weight: normal;
}
.addNewMenuForm > ul > li:nth-child(1){ 
	padding: 0px 0px 10px; 
}
.addNewMenuForm > ul > li:nth-child(1) > label { 
	padding: 0px 0px 0px 0px; 
}
.addNewMenuForm > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 30px 5px 0px;
	height: auto;
	background: #f2f2f2 !important;
	color: #0D3968;
	font-size: 20px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
} 
/* ****** ****** ****** ****** ****** **
 * ****** Select Custom Purpose for Editing ****** *
** ****** ****** ****** ****** ****** */
.editPurposeList {
	width: 100%;
	float: left; 
	clear: both; 
} 
.editPurposeList .customRadioBtn {
	width:48%;
	float: left; 
	margin:5px 2% 5px 0px;
} 
/* ****** ****** ****** ****** ****** **
 * ****** Report Assign List Table ****** *
** ****** ****** ****** ****** ****** */
.reportAsgnListOuter {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both; 
}
.reportAsgnList {
	width: 100%;
	display: table;
}
.reportAsgnList > ul {
	width: 100%;
	display: table-row;
}
.reportAsgnList > ul.disabled {
	pointer-events: inherit;
}
.reportAsgnList > ul.tHead > li {
	color: #16406d;
    border-bottom: 1px solid #63778a;
    background: #e9eaeb;
}
.reportAsgnList > ul.tHead > li:first-child {
	border-left: 0px;
}
.reportAsgnList > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
    color: #16406d;
	text-align: left;
	font-size: 14px; 
}
.reportAsgnList > ul.offlineUnits > li {
	opacity: 0.5;
}
.reportAsgnList > ul.selectedStaus > li {
	background: #1d5484;
}
.reportAsgnList > ul.tBody > li:first-child {
	border-bottom: 1px solid #F2F2F2;
}  
.reportAsgnList > ul > li:nth-child(1) {
	width: 40px;
	text-align: center;
}
.reportAsgnList > ul > li:nth-child(2) {
	width: 52px;
	text-align: center;
} 
.reportAsgnList > ul > li:nth-child(3),
.reportAsgnList > ul > li:nth-child(4) {
	width: 160px;
}
.reportAsgnList > ul > li:nth-child(5),
.reportAsgnList > ul > li:nth-child(6) {
	width: 180px;
}
.reportAsgnList > ul > li:nth-child(7) {
	width: 190px;
}  
.reportAsgnList > ul > li:nth-child(9) {
	text-align: center;
	width: 70px;
}
.reportAsgnList > ul > li > span.offlineStausTable {
	opacity: 0.5;
	background: url("../images/icons/offlineT.svg") no-repeat center center;
	background-size: 24px 24px;
	padding-left: 20px;
} 
.reportAsgnList > ul > li:nth-child(10) { 
	width: 58px;
	text-align: center;
} 
.reportAsgnList > ul.tBody > li:nth-child(10) {
	padding-right: 0px;
}
.reportAsgnList > ul.tBody > li:nth-child(2) > img {
	width: 32px;
	height: 32px;
}
.reportAsgnList > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.reportAsgnList > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
} 
.reportAsgnList > ul.tBody > li .unitsListSM,
.reportAsgnList > ul.tBody > li .unitsListSML {
	display: none;
	width: 100%;
	float: left;
}
.reportAsgnList > ul.tBody > li .unitsListAN {
	display: none;
	width: 100%;
	float: left;
	background: url("../images/ANTransparent.png") repeat;
	padding: 5px;
	font-size: 12px;
}
.reportAsgnList > ul.tBody > li .unitsListAN > b {
	width: 100%;
	float: left;
	font-weight: normal;
}

/*** **************  ************** ****
		*** Nav List Header ***
**** **************  ************** ***/

.tableHNav {
	width: 100%;
	padding: 0px;
	display: table;
	border-bottom: 1px solid #7e8690;
	background: #f2f2f2;
} 
.tableHNav > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.tableHNav > ul > li {
	padding: 0px 0px;
	display: table-cell;
	color: #96a8bb;
	vertical-align: middle;
}
.tableHNav > ul > li:nth-child(1) a {
	color: #96a8bb;
	padding: 18px 15px 19px;
	font-size: 18px;
	display: inline-block;
	font-family: 'open_sanssemibold';
}
.tableHNav > ul > li:nth-child(1) > a:hover,
.tableHNav > ul > li:nth-child(1) > a.active {
	color: #0d3968;
}
.tableHNav > ul > li:nth-child(2) {
	text-align: right;
	padding-right: 15px;
}
.tableHNav > ul > li .btn {
	margin-left: 15px;
}
.tableHNav > ul > li .cancelTransfer,
.tableHNav > ul > li .listView,
.tableHNav > ul > li .gridSG,
.tableHNav > ul > li .referesh,
.tableHNav > ul > li .sgAdd,
.tableHNav > ul > li .transfertoSG,
.tableHNav > ul > li .upload,
.tableHNav > ul > li .download,
.tableHNav > ul > li .edit,
.tableHNav > ul > li .delete,
.tableHNav > ul > li .add {
	width: 24px;
	height: 24px;
}
.tableHNav > ul > li .cancelTransfer {
	background: url("../images/icons/cancelTransferA.png") no-repeat center center;
	background-size: 22px 22px;
}
.tableHNav > ul > li .cancelTransfer.disabled {
	background: url("../images/icons/cancelTransferD.png") no-repeat center center;
	background-size: 22px 22px;
}
.tableHNav > ul > li .listView {
	background: url("../images/icons/ListviewDA.svg") no-repeat center center;
	background-size: 24px 24px;
	opacity:0.3;
}
.tableHNav > ul > li .listView.active {
	background: url("../images/icons/ListviewDA.svg") no-repeat center center;
	background-size: 24px 24px;
	opacity:1;
}
.tableHNav > ul > li .gridSG {
	background: url("../images/icons/sgA.svg") no-repeat center center;
	background-size: 24px 24px;
	opacity:0.3;
}
.tableHNav > ul > li .gridSG.active {
	background: url("../images/icons/sgA.svg") no-repeat center center;
	background-size: 24px 24px;
	opacity:1;
}
.tableHNav > ul > li .referesh {
	background: url("../images/icons/refresh.svg") no-repeat center center;
	background-size: 22px 22px;
}
.tableHNav > ul > li .referesh.disabled {
	background: url("../images/icons/refreshD.svg") no-repeat center center;
	background-size: 22px 22px;
}
.tableHNav > ul > li .sgAdd {
	background: url("../images/icons/SmartGroupStatusA.svg") no-repeat center center;
	background-size: 24px 24px;
}
.tableHNav > ul > li .sgAdd.disabled {
	background: url("../images/icons/SmartGroupStatusD.svg") no-repeat center center;
	background-size: 24px 24px;
}
.tableHNav > ul > li .transfertoSG {
	background: url("../images/icons/assignDeviceT.svg") no-repeat center center;
	background-size: 22px 22px;
}
.tableHNav > ul > li .transfertoSG.disabled {
	background: url("../images/icons/assignDeviceD.svg") no-repeat center center;
	background-size: 22px 22px;
}
.tableHNav > ul > li .edit {
	background: url("../images/icons/penB.svg") no-repeat center center;
	background-size: 24px 24px;
}
.tableHNav > ul > li .edit.disabled {
	background: url("../images/icons/penD.svg") no-repeat center center;
	background-size: 24px 24px;
}
.tableHNav > ul > li .delete {
	background: url("../images/icons/trashB.svg") no-repeat center center;
	background-size: 24px 24px;
}
.tableHNav > ul > li .delete.disabled {
	background: url("../images/icons/trashC.svg") no-repeat center center;
	background-size: 24px 24px;
}
.tableHNav > ul > li .add {
	background: url("../images/icons/plusA.svg") no-repeat center center;
	background-size: 24px 24px;
}
.tableHNav > ul > li .add.disabled {
	background: url("../images/icons/plusD.svg") no-repeat center center;
	background-size: 24px 24px;
}
.tableHNav > ul > li .download {
	background: url("../images/icons/downloadA.png") no-repeat center center;
	background-size: 24px 24px;
}
.tableHNav > ul > li .download.disabled {
	background: url("../images/icons/downloadD.png") no-repeat center center;
	background-size: 24px 24px;
}
.tableHNav > ul > li .upload {
	background: url("../images/icons/uploadA.png") no-repeat center center;
	background-size: 24px 24px;
}
.tableHNav > ul > li .upload.disabled {
	background: url("../images/icons/uploadD.png") no-repeat center center;
	background-size: 24px 24px;
} 

/* ****** ****** ****** ****** ****** **
 * ****** SW Units Status Table ****** *
** ****** ****** ****** ****** ****** */

.statusTableOuter {
	width: 100%;
	float: left;
	padding: 0px;
}
.statusTableOuter .panel-collapse {
	background: #ffffff;
}
.statusTable {
	width: 100%;
	display: table;
}
.statusTable > ul {
	width: 100%;
	display: table-row;
}
.statusTable > ul.tHead li {
	background: #E9EAEB;
	border-bottom: 1px solid #607488;
}
.statusTable > ul > li {
	display: table-cell;
	padding: 10px 5px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
}
.statusTable > ul.tBody > li {  
	font-size: 14px; 
}
.statusTable > ul > li a:hover {
	color: #C8102E;
	text-decoration: none;
}
.statusTable > ul > li a {
	color: #333F48;
}
.statusTable > ul.tBody > li:nth-child(1) {
	border-bottom: 1px solid #f2f2f2;
}
.statusTable > ul > li:nth-child(1) {
	width: 40px;
	padding-left: 0px; 
	padding-right: 0px; 
	text-align: center;
}
.statusTable > ul > li:nth-child(2) {
	width: 50px;
	padding-left: 0px; 
	padding-right: 0px; 
	text-align: center;
}
.statusTable > ul > li:nth-child(3) { 
	padding-left: 0px; 
}
.statusTable > ul > li:nth-child(3),
.statusTable > ul > li:nth-child(4) {
	width: 120px;
}
.statusTable > ul > li:nth-child(5) {
	width: 90px;
} 
.statusTable > ul > li:nth-child(7) {
	width: 70px;  
	text-align:center;
} 
.statusTable > ul > li:nth-child(8) {
	width: 120px;  
} 
.statusTable > ul > li:nth-child(9),
.statusTable > ul > li:nth-child(10) {
	width: 220px; 
	padding-right:0px;
} 
.statusTable > ul > li:nth-child(11) {
	width: 55px;
	text-align: center;
	padding-left:0px;
	padding-right:0px;
}
.statusTable > ul > li:nth-child(11) > img {
	width: 24px;
}
.statusTable > ul > li:nth-child(12) {
	width: 100px;
} 
.statusTable > ul > li:nth-child(13) { 
	width: 58px;
	text-align: center;
} 
.statusTable > ul > li .progress {
	height: 8px;
	border-radius: 4px;
	background: #d8d8d8;
}
.statusTable > ul > li .progress .updatingSW {
	background: #0aaeff;
}
.statusTable > ul > li .progress .pendingSW{
	background: #d8d8d8 !important;
}
.statusTable > ul > li .progress .warningStatus {
	background: #f93251;
} 
.statusTable > ul > li .progress .completedStatus {
	background: #3cce82;
}
.statusTable > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.statusTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
} 
.statusTable > ul > li .collapseExpand,
.statusTable > ul > li .collapseExpand:hover,
.statusTable > ul > li .collapseExpand:focus,
.statusTable > ul > li .collapseExpand:active {
	display: inline-block;
	float: inherit;
}
.statusTable > ul.tBody > li .statusLAN {
	display: none;
	width: 100%;
	float: left;
	background: #fff;
	padding: 5px;
	font-size: 12px;
}
.statusTable > ul.tBody > li .statusLAN > b {
	width: 100%;
	float: left;
	font-weight: normal;
}
/* ****** ****** ****** ****** ****** **
 * ****** Status Expanded Table ****** *
** ****** ****** ****** ****** ****** */
.statusExpTableW {
	width: 100%;
	padding:0px 45px;
	float: left;
	clear: both;
	background:#ffffff;
}   
.statusExpTable {
	width: 100%; 
	display: table;   
}   
.statusExpTable > ul {
	width: 100%;
	display: table-row;
}
.statusExpTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: top;
	color: #0D3968;
	font-size: 16px;
	text-align: left;
}  
.statusExpTable > ul > li:nth-child(1),  
.statusExpTable > ul > li:nth-child(2) {  
	width:40%;
}
.statusExpTable > ul > li:nth-child(3) {
	width:120px;
	text-align:right;
} 
.statusExpTable > ul > li h5 {
	width: 100%;
	padding:0px 0px 5px;
	float: left;
	clear: both;
	font-size:16px;
	font-family: 'open_sanssemibold';
} 
.statusExpTable > ul > li .unitDetailsBtn { 
	font-size:16px;
	background: #f2f2f2;
	color: #0D3968;
	font-family: 'open_sanssemibold';
} 

/* ****** ****** ****** ****** ****** **
 * ****** Package Details Table ****** *
** ****** ****** ****** ****** ****** */ 
.packageDetails {
	width: 100%; 
	display: table;   
}   
.packageDetails > ul {
	width: 100%;
	display: table-row;
}
.packageDetails > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	color: #0D3968;
	font-size: 14px;
	text-align: left;
}
.packageDetails > ul > li:nth-child(1) {
    width: 80px;
    background: #f2f2f2;
    text-align: center;
    padding: 0px;
}
.packageDetails > ul > li label {
	color: #0D3968;
}
.packageDetails > ul > li .mediaFileSpan {
	width: 80px;
	min-height: 80px;
	display: inline-block;
}
.packageDetails > ul > li .mediaFileSpan > img,
.packageDetails > ul > li .mediaFileSpan > video {
	width: 100%;
}
.packageDetails > ul > li > h6 {
	width: 100%;
	float: left;
	padding: 5px 10px 0px;
	margin: 0px 0px;
	font-size: 14px;
	color: #0d3968; 
}
.packageDetails > ul > li > h6 > span {
	color: #748da8; 
}
/* ****** ****** ****** ****** ****** **
 * ****** Update History Table ****** *
** ****** ****** ****** ****** ****** */ 
.updateHistoryT {
	width: 100%; 
	display: table;   
}   
.updateHistoryT > ul {
	width: 100%;
	display: table-row;
}
.updateHistoryT > ul > li {
	display: table-cell;
	padding:5px 10px;
	vertical-align: middle;
	color: #0D3968;
	font-size: 14px;
	text-align: left;
	border-bottom: 1px solid #aab4be;
} 
.updateHistoryT > ul:last-child > li { 
	border-bottom:0px;
} 
.updateHistoryT > ul > li:nth-child(1) {
	width: 40px;
	padding-left: 0px; 
	padding-right: 0px; 
	text-align: center;
	border-bottom:0px;
}
.updateHistoryT > ul > li:nth-child(2) {
	width: 55px;
	text-align: center;
	padding-left:0px;
	padding-right:0px;
}
.updateHistoryT > ul > li:nth-child(2) > img {
	width: 24px;
}
.updateHistoryT > ul > li:nth-child(4) {
	width: 210px;  
} 
.modelInfoModal {
	width: 100%;
	float: left; 
} 
.modelInfoModalL {
	width: 50%;
	float: left; 
}
.modelInfoModalR {
	width: 50%;
	float: left; 
}
.modelInfoModalT {
	width: 100%;
	float: left;
	padding: 5px 0px 5px;
	margin: 0px 0px;
	font-size: 16px;
	color: #0d3968; 
}
/* ****** ****** ****** ****** ****** **
 * ****** Model Info Table ****** *
** ****** ****** ****** ****** ****** */ 
.modelInfoDetails {
	width: 100%; 
	display: table;   
}   
.modelInfoDetails > ul {
	width: 100%;
	display: table-row;
}
.modelInfoDetails > ul > li {
	display: table-cell;
	padding: 0px;
	vertical-align: middle;
	color: #0D3968;
	font-size: 14px;
	text-align: left;
}
.modelInfoDetails > ul > li:nth-child(2) { 
	padding: 0px 0px 0px 15px; 
}
.modelInfoDetails > ul > li:nth-child(2) > h6 > span { 
    min-width: 70px; 
}
.modelInfoDetails > ul > li.modelImgView {
    width: 80px; 
	background:#f2f2f2;
	text-align:center;
}  
.modelInfoDetails > ul > li.modelImgView > img {
	max-width: 100%; 
	display: inline-block;
}
.modelInfoDetails > ul > li > h6 {
	width: 100%;
	float: left;
	padding: 5px 0px;
	margin: 0px 0px;
	font-size: 14px;
	color: #0d3968; 
}
.modelInfoDetails > ul > li > h6 > span {
	color: #748da8; 
	min-width: 90px;
    display: inline-block; 
}
/* ****** ****** ****** ****** ****** **
 * ****** Package Status Table ****** *
** ****** ****** ****** ****** ****** */  
.packageStatusTable {
	width: 100%;
	display: table;
}
.packageStatusTable > ul {
	width: 100%;
	display: table-row;
}
.packageStatusTable > ul.tHead li {
	color: #16406d;
    border-bottom: 1px solid #63778a;
    background: #e9eaeb;
}
.packageStatusTable > ul > li {
	display: table-cell;
	padding: 5px 5px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
}
.packageStatusTable > ul.tBody > li {  
	font-size: 14px; 
}  
.packageStatusTable > ul.tBody > li:nth-child(1) {
	color: #16406d;
    border-bottom: 1px solid #63778a;
    background: #e9eaeb;
}
.packageStatusTable > ul > li:nth-child(1) {
	width:130px; 
} 
.packageStatusTable > ul > li:nth-child(3) { 
	width:100px;
} 
.packageStatusTable > ul > li:nth-child(4) {
	width: 210px;
}  
/* ****** ****** ****** ****** ****** **
 * ****** Email Settings List ****** *
** ****** ****** ****** ****** ****** */

.emailSettingsList {
	width: 100%;
	display: table;
	margin-bottom:15px;
}
.emailSettingsList > ul {
	width: 100%;
	display: table-row;
}
.emailSettingsList > ul.tHead > li {
	color: #16406d;
	border-bottom: 1px solid #63778a;
	background: #e9eaeb;
} 
.emailSettingsList > ul > li {
	display: table-cell;
	padding: 5px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 14px;
	text-align: left;
}   
.emailSettingsList > ul > li:nth-child(1) {
	font-size:16px;
	font-family: 'open_sanssemibold';
}  
.emailSettingsList > ul > li:nth-child(2),
.emailSettingsList > ul > li:nth-child(3),
.emailSettingsList > ul > li:nth-child(4) {
	width:120px;  
	text-align: center;
}  
.emailSettingsList > ul > li .customCheckbox {
	margin: 3px 0px 0px 0px;
	display: inline-block;
}
.emailSettingsList > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}
#mediaTop-margin{
	/* margin-top: 104px; */
}
.customPurpose_Box{
	width: 100%;
    float: left;
    clear: both;
    padding: 0px 0px 0px;
}
.customPurpose_Box li{
    padding: 0px 0px 0px 0px;
}
.customPurpose_Box li label{
	float: left;
    padding: 10px 0px 0px 0px;
    color: #62768a;
    font-size: 14px;
    font-weight: normal;
}
.noteText{
	font-size: 14px;
    color: #0d3968;
}
/*** **************  ************** ****
		*** Garland Platen Count  ***
**** **************  ************** ***/
 
#garlandPlatenchartdiv{
  width: 40%;
  height:140px;
  float: left;
}  
.platenCount {
	width: 60%;
	padding: 0px;
	float: left;
	background: #2d3034;
}
.platenCountTable {
	width: 100%;
	display: table;
	margin-top:10px;
}
.platenCountTable > div {
	width: 100%;
	display: table-row;
}
.platenCountTable > div:nth-child(1) > div {
	color: #3a92df;
}
.platenCountTable > div:nth-child(2) > div {
	color: #91c2ed;
}
.platenCountTable > div:nth-child(3) > div {
	color: #d3e7f8;
}
.platenCountTable > div > div {
	display: table-cell;
	padding: 2px 10px 2px 0px;
	vertical-align: middle;
	color: #ffffff;
	text-align: left;
	font-size: 14px;
}
.platenCountTable > div > div:nth-child(1) {
	width:70px; 
	font-size: 14px; 
} 
.platenCountTable > div > div:nth-child(2) { 
	font-size:22px;
	padding-left: 0px;
	line-height: 32px;
	font-family: 'open_sanssemibold';
}
#garlandTPCchartdiv {
	width: 100%;
	height: 360px;
	float: left;
}
/*** **************  ************** ****
		*** API Key Ciew ***
**** **************  ************** ***/
 
.apiKeyView {
	width: 100%;
	float: left;
	padding:5px 10px;
	border-bottom: 1px solid #63778a;
	background:#ffffff; 
} 
.apiKeyView .fromLable { 
    color:#16406d;
	display:inline-block;
	padding-right:10px;
}    
.apiKeyView .fromValue { 
	display:inline-block;
	min-width: 140px;
}      
.apiKeyView .apiViewBtn{ 
	display:inline-block;
	margin-left:15px;
}     
/*** **************  ************** ****
		*** Assign Org Header ***
**** **************  ************** ***/

.assignOrgHeader {
	width: 100%;
	padding: 0px;
	display: table;
	background: #f2f2f2;
	border-bottom: 1px solid #7e8690;
}
.assignOrgHeader > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.assignOrgHeader > ul > li {
	padding: 0px 0px;
	display: table-cell;
	color: #6e87a2;
	vertical-align: middle; 
}
.assignOrgHeader > ul > li a,
.assignOrgHeader > ul > li .finishBtn {
	color: #6e87a2;
	padding: 21px 0px;
	display: block;
}
.assignOrgHeader > ul > li:nth-child(1) {
	text-align: left;
	width: 64px;
}
.assignOrgHeader > ul > li:nth-child(2) {
	text-align: center;
	font-size: 20px;
	color: #0d3968;
	font-family: 'open_sanssemibold';
	padding-left: 120px;
}
.assignOrgHeader > ul > li:nth-child(3) {
	text-align: right;
	width: 120px;
	font-size: 16px;
	padding-right: 15px;
}
.assignOrgHeader > ul > li:nth-child(3) a,
.assignOrgHeader > ul > li:nth-child(3) .finishBtn {
	color: #18426f;
}
.assignOrgHeader > ul > li > a:hover,
.assignOrgHeader > ul > li > .finishBtn:hover {
	color: #000000;
}
.assignOrgHeader > ul > li > .finishBtn {
	background:none;
	border:0px;
	display: inline-block;
	text-align: right;
}
.assignOrgHeader > ul > li .backIcon {
	width: 64px;
	height: 64px;
	display: block;
	background: url("../images/icons/back.png") no-repeat center center;
	background-size: 36px 36px;
}



/* ****** ****** ****** ****** ****** **
 * ****** SW Available Updates Table ****** *
** ****** ****** ****** ****** ****** */

.SWavlUpdtsDivOuter {
	width: 100%;
	float: left;
	padding: 0px;
}
.SWavlUpdtsDivOuter .panel-collapse {
	background: #ffffff;
}
.SWavlUpdtsDiv {
	width: 100%;
	display: table;
}
.SWavlUpdtsDiv > ul {
	width: 100%;
	display: table-row;
}
.SWavlUpdtsDiv > ul.tHead li {
	color: #657f9c;
	background: #E9EAEB;
	border-bottom: 1px solid #607488;
}
.SWavlUpdtsDiv > ul.tHead .active {
	color: #153f6d;
}
.SWavlUpdtsDiv > ul.tBody li {
	padding: 15px 10px;
}
.SWavlUpdtsDiv > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
}
.SWavlUpdtsDiv > ul > li a {
	color: #2dbaff;
}
.SWavlUpdtsDiv > ul.tBody > li:nth-child(1) {
	border-bottom: 1px solid #f2f2f2;
}
.SWavlUpdtsDiv > ul > li:nth-child(1) {
	width: 54px;
	text-align: center;
	padding-left:5px;
	padding-right:5px;
}
.SWavlUpdtsDiv > ul > li:nth-child(2) {
	width: 320px;
	padding-left: 0px;
	text-align: left;
}
.SWavlUpdtsDiv > ul > li:nth-child(2) > img {
	height: 22px;
}
.SWavlUpdtsDiv > ul > li:nth-child(2) > b {
	clear: both;
	font-weight: normal;
	display: block;
} 
.SWavlUpdtsDiv > ul > li:nth-child(4) {
	width: 180px;
	text-align:center;
}
.SWavlUpdtsDiv > ul > li:nth-child(5) {
	width: 180px;
}
.SWavlUpdtsDiv > ul > li:nth-child(6) {
	width: 100px;
	text-align: center;
}
.SWavlUpdtsDiv > ul > li:nth-child(6) .btn {
	background: #195d99;
	color: #ffffff;
	min-width: 80px;
}
.SWavlUpdtsDiv > ul.tBody > li .SWUpdateSM,
.SWavlUpdtsDiv > ul.tBody > li .SWUpdateSMVD {
	display: none;
	width: 100%;
	float: left;
}
.SWavlUpdtsDiv > ul.tBody > li .SWUpdateSMVD > b {
	font-weight: normal;
}
.panel-body.SWavlUpdtsDiv {
	padding: 0px;
	margin: 0px;
	border: 0px;
}
.panel-body.SWavlUpdtsDiv > ul.tBody li {
	border-bottom: 1px solid #acbac8;
}
.panel-body.subGrpTable.SWavlUpdtsDiv > ul.tBody li {
	padding-left: 34px;
}
/* ****** ****** ****** ****** ****** **
 * ****** SW Expanded  Table ****** *
** ****** ****** ****** ****** ****** */  
.SWavlUpdtsExp {
	width: 100%;
	display: table;
}
.SWavlUpdtsExp > ul {
	width: 100%;
	display: table-row;
} 
.SWavlUpdtsExp > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 16px;
	text-align: left;
}
.SWavlUpdtsExp > ul > li a {
	color: #2dbaff;
} 
.SWavlUpdtsExp > ul > li:nth-child(1) {
	width: 44px;
	text-align: center;
	padding-left:5px;
	padding-right:5px;
}
.SWavlUpdtsExp > ul > li:nth-child(2) { 
	padding-left: 0px;
	text-align: left;
} 
.SWavlUpdtsExp > ul > li:nth-child(3) { 
	width: 40%;
} 
.SWavlUpdtsDivI {
	width: 100%;
	display: table;
}
.SWavlUpdtsDivI > ul {
	width: 100%;
	display: table-row;
} 
.SWavlUpdtsDivI > ul > li {
	display: table-cell;
	padding: 5px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
} 
.SWavlUpdtsDivI > ul.tHead li {
	color: #0D3968;
	border-bottom: 1px solid #607488;
	font-family: 'open_sanssemibold';
}
.SWavlUpdtsDivI > ul > li:nth-child(1) {
	width: 220px;
	padding-left: 0px;
	text-align: left;
}  
.SWavlUpdtsDivI > ul:last-child > li {
	border-bottom:0px;
}  
/* Global Search CSS */ 

.headerSearchBox .hSearchBox {
   border: 0px;
   border-radius: 0px; 
   font-size: 20px;
   color: #0D3968; 
   height:64px; 
   background:#e9eaeb;
   /* border-left:1px solid #ffffff; */
   /* padding-right: 40px; */
   min-width: 305px;	
}
.headerSearchBox .searchBxBtn {
   border: 0px;
   border-radius: 0px; 
   height:64px;
   width:64px;
   background: #e9eaeb url("../images/icons/searchA.svg") no-repeat center center;
   background-size: 32px auto;
   /* border-left:1px solid #ffffff; */
}
.headerSearchBox .navAdvSearch { 
   height:64px;
   width:64px;
   background: #e9eaeb url("../images/icons/markdownA.svg") no-repeat center center;
   background-size: 24px auto; 
}
.headerSearchBox .navAdvSearch.active{
   transform: rotate(180deg);
}
.navSearchToggled .headerSearchBox .navAdvSearch {  
   background: #e9eaeb url("../images/icons/markUpA.svg") no-repeat center center;
   background-size: 24px auto; 
} 
.navSearchToggled .navbarRight .navSearchDrpdn {
   display: block; 
}
.navbarRight .navSearchDrpdn .dropdown-item{
	white-space: normal;
	line-height: 20px; 
	padding: 6px 10px;
 }
.navbarRight .navSearchDrpdn {
   margin: 0px;
   border: 0px;
   width: 520px;
   padding: 10px;
   position: absolute;
   top: 100%;
   right: 0px;
   z-index: 1000;
   display: none;
   float: left;
   min-width: 10rem;
   font-size: 1rem;
   color: #212529;
   text-align: left;
   background: #fff;
}

/* Start Written CSS By Simpy for Global Search */
.nav-item.navSearchToggleBox {
   position: relative;
   width: 435px;
}
.navSearchDrpdn.active {
   display: block; 
   box-shadow: 0 3px 1px -2px rgb(0 0 0 / 5%), 0 2px 2px 0 rgb(0 0 0 / 1%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}
.hSearchText.no-background-icon {
   background-image: none;
   padding: 5px 40px 5px 15px; 
} 
.advanced-pos-relative{
   position: relative;
   width: 65%;
   float: right;
}
.advanced-pos-relative .dropdown .dropdownSub{
   background:#ffffff;
}
.headerSearchBox .cross-button{
   border: 0px;
   border-radius: 0px;
   height: 64px;
   width: 40px;
   background: #e9eaeb url(../images/icons/cancelB.svg) no-repeat center center;
   background-size: 24px auto;
   position: absolute;
   right: 65px;
   top: 0;
   z-index: 9;
}
.advanced-pos-relative .advanced-cross-button{
   border: 0px;
   border-radius: 0px;
   height: 35px;
   width: 35px;
   background: #ffffff url(../images/icons/cancelB.svg) no-repeat center center;
   background-size: 24px auto;
   position: absolute;
   right: 0;
   top: 0;
   z-index: 9;
} 
.navSearchDrpdn .input-group{
   display: inline-block;
}
.navSearchDrpdn .input-group.opacity{
   opacity: 0.6;
}
.navSearchDrpdn .input-group-prepend{
   width: 35%;
   float: left;
}
.input-group-prepend .dropdown{
   width: 100%;
}

.navSearchDrpdn.advance-search-box .input-group .input-group-prepend select.form-control{
   font-size: 16px;
}
.navSearchDrpdn.advance-search-box .input-group .form-control.hSearchText{
   font-size: 16px;
}
.navSearchDrpdn .searchBtn {
   background: #195d99;
   width:120px;
   float: right;
   border: 0px; 
   padding:8px 10px;
   font-size:14px;
   color:#ffffff;
   margin:0px 0px 0px;
   border-radius: 20px;
}
.navSearchDrpdn .searchBtn[disabled]{
   background: #6c757d;
} 
.toaster-box{
   position: absolute;
   left: 60px;
   top: 30%;
   right: 5px;
}
.toaster-box .toast{
   /* display: none; */
   display: inline-block;
   margin: 0;
   font-size: 12px;
   margin-right: 3px;
   opacity: 1;
   background-color: rgba(255,255,255,.85);
   background-clip: padding-box;
   border: 1px solid rgba(0,0,0,.1);
   box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%);
   border-radius: .25rem;
}
.toaster-box.active .toast{
   display: inline-block;
}
.toaster-box .toast-header{
   padding: 4px 8px;
   display: flex;
   align-items: center;
   color: #6c757d;
   background-color: rgba(255,255,255,.85);
   background-clip: padding-box;
   border-bottom: 1px solid rgba(0,0,0,.05);
   border-top-left-radius: calc(.25rem - 1px);
   border-top-right-radius: calc(.25rem - 1px);
}
.toaster-box .toast-header strong{
   font-weight: normal;
}
.toaster-box .toast-header .btn-close {
   box-sizing: content-box;
   width: 6px;
   height: 10px;
   padding: 3px 2px 3px 3px;
   color: #000;
   background: #ffffff url(../images/icons/cancelB.svg) no-repeat center center;
   border: 0;
   border-radius: .25rem;
   opacity: .5;
   margin-left: 5px;
}
.option-disabled{
   background-color: #eaeaea;
   margin: 2px;
}
.option-disabled-text{
   color:#b7b7b7
}
/* .mediaTop-margin{
   margin-top: 104px;
} */
.text-unit-color{
   color: #16406d;
   font-size: 15px;
   font-weight: 600;
}
/* End Written CSS By Simpy for Global Search */

/*** **************  ************** ****
		*** Unit Register Form ***
**** **************  ************** ***/

.addEditFormWrapper {
	width: 100%;
	float: left;
	margin: 0px 0px;
}
.addEditForm {
	width: 400px;
	margin: 0px auto;
	clear: both;
}
.addEditForm > ul {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px 15px 0px;
}
.addEditForm > ul > li {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px 0px 5px;
	margin: 0px;
}
.addEditForm > ul > li.marginBottom10px {
	margin-bottom: 20px;
}
.addEditForm > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 30px 10px 0px;
	height: auto;
	background: #fff !important;
	color: #0D3968;
	font-size: 20px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
}
.addEditForm > ul > li select.form-control {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: #fff url("../images/icons/markdownA.svg") no-repeat right center !important;
}
.addEditForm > ul > li select::-ms-expand {
	display: none;
}
.addEditForm > ul > li > p {
	color: #0D3968;
}
.addEditForm > ul > li .input-group {
	width: 100%;
	position: relative;
}
.addEditForm > ul > li .input-group .requiredField {
	width: 26px;
	height: 26px;
	text-align: center;
	position: absolute;
	top: 5px;
	right: 0px;
	z-index: 3;
	color: #b8c3cf;
	font-size: 28px;
} 
.openLogFilesTab {
	width: 100%;
	float: left;
	clear: both; 
	margin: 0px;
	border:0px;
}
.openLogFilesTab > li { 
	float: left; 
	padding:0px; 
	font-size: 22px;
	color: #96a8bb; 
    font-family: 'open_sanssemibold';
}
.openLogFilesTab > li a{ 
	color: #96a8bb; 
	border:0px;
	padding:5px 20px 5px 0px;
}
.openLogFilesTab.nav-tabs .nav-link{ 
	color: #96a8bb; 
	border:0px;
}
.openLogFilesTab .nav-link.active{   
    color: #0d3968; 
	border:0px;
}
.openLogFilesTab.nav-tabs .nav-link:focus, 
.openLogFilesTab.nav-tabs .nav-link:hover{
	color: #0d3968; 
	border:0px;
}

/* ****** ****** ****** ****** ****** **
 * ****** Date Picker Modification ****** *
** ****** ****** ****** ****** ****** */

.dateRangeForm { 
	float: right; 
} 
.dateRangeForm > span{ 
	float: left; 
	color: #0D3968;
	font-size: 14px;
	padding:6px 0px;
	margin:0px 5px;
} 
.dateRangeForm > .form-control{  
	width: 90px;
    float: left;
    padding: 5px 0;
	background: #f2f2f2!important;
    color: #0d3968;
    font-size: 14px;
    border: 0;
    border-bottom: 1px solid #6e88a4;
	border-radius:0px;
} 

.dateRangeForm .react-datepicker-wrapper {
	float: left; 
}   

.dateRangeForm .react-datepicker-wrapper input  {
	width:90px;
	float: left; 
	padding: 5px 0px 5px 0px;
	height: auto;
	background: #f2f2f2 !important;
	color: #0D3968;
	font-size: 14px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
} 
/* ****** ****** ****** ****** ****** **
 * ****** Crem Media Preparation List ****** *
** ****** ****** ****** ****** ****** */
.mediaPreparationList {
	width: 100%;
	display: table;
}
.mediaPreparationList > ul{
	width: 100%;
	display: table-row;
	cursor:pointer;
} 
.mediaPreparationList > ul:hover{
	background:#1f2124;
} 
.mediaPreparationList > ul.tHead > li {
	color: #9fa8b5;
    font-size: 14px;
    border-bottom: 1px solid #4a4a4a;
} 
.mediaPreparationList > ul.tHead > li:first-child {
	border-left: 0px;
	color: #9fa8b5;
	border-bottom: 1px solid #4a4a4a;
} 
.mediaPreparationList > ul > li {
	display: table-cell;
    padding: 10px 10px;
    vertical-align: middle;
    border-bottom: 1px solid #1f2124;
    color: #ffffff;
    font-size: 14px;
	text-align: left; 
}  
.mediaPreparationList > ul > li:nth-child(1) {
	width: 40px;
	text-align: center;
	color: #ffffff;
	border-bottom: 1px solid #2d3034;
} 
.mediaPreparationList > ul > li:nth-child(2) {
	width: 80px;
	padding: 0px;
}
.mediaPreparationList > ul.tBody > li:nth-child(2) {
	background: #515151;
} 
.mediaPreparationList > ul > li:nth-child(4) {
	width: 220px;
} 
.mediaPreparationList > ul > li:nth-child(5) {
	width: 125px;
} 
.mediaPreparationList > ul > li:nth-child(6) {
	width: 90px; 
} 
.mediaPreparationList > ul > li:nth-child(7) {
	width: 70px;
} 
.mediaPreparationList > ul > li:nth-child(8) {
	width: 180px;
	text-align: left;
} 
.mediaPreparationList > ul > li:nth-child(9) {
	width: 100px;  
	text-align:center; 
}   
.mediaPreparationList > ul > li .assignedTo {
	background: #1f2124;
	padding: 3px 25px 3px 3px;
	font-size: 11px;
	display: inline-block;
	margin: 2px;
} 
.mediaPreparationList > ul > li .assignedTo .close {
	width: 20px;
	height: 20px;
	display: inline-block;
	padding: 0px;
} 
.mediaPreparationList > ul > li .mediaPreview {
	background: url("../images/icons/viewA.svg") no-repeat center center;
	background-size: 24px auto;
	display: inline-block;
	width: 24px;
	height: 24px;
}  
.mediaPreparationList > ul > li .mediaTeeView {
	background: url("../images/icons/SidetreeA.svg") no-repeat center center;
	background-size: 24px auto;
	display: inline-block;
	width: 24px;
	height: 24px;
}  
.mediaPreparationList > ul > li .mediaFileSpan {
	width: 80px;
	min-height: 80px;
	display: inline-block;
} 
.mediaPreparationList > ul > li .mediaFileSpan > img,
.mediaPreparationList > ul > li .mediaFileSpan > video {
	width: 100%;
}  
.mediaPreparationList > ul.tBody > li .mediaSListSM,
.mediaPreparationList > ul.tBody > li .mediaSListRS,
.mediaPreparationList > ul.tBody > li .mediaSListAS {
	display: none;
	width: 100%;
	float: left;
	font-size: 12px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Notification Settings Table ****** *
** ****** ****** ****** ****** ****** */

.timeNumberFormatForm {
	width: 100%;
	float: left;
	padding: 0px 64px;
}
.timeNFTable {
	width: 100%;
	display: table;
	margin: 15px 0px 15px;
}
.timeNFTable > ul {
	width: 100%;
	display: table-row;
}
.timeNFTable > ul.tHead > li {
	color: #68839f;
	border-bottom: 1px solid #0d3968;
}
.timeNFTable > ul.tHead > li:nth-child(1) {
	font-family: 'open_sanssemibold';
	padding-left: 0px;
	color: #0d3968;
}
.timeNFTable > ul.tHead > li .customCheckbox {
	margin: 0px 0px;
	display: inline-block;
	width: 24px;
	height: 24px;
}
.timeNFTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
}
.timeNFTable > ul > li:nth-child(1) {
	padding-left: 0px;
	width: 850px;
}
.timeNFTable > ul > li:nth-child(2) {
	width: 150px; 
} 
.timeNFTable > ul > li .customRadioBtn {
	margin: 0px;
	display: block;
}
.timeNFTable > ul.tHead .customRadioBtn label {
	margin: 0px;
	padding-left: 30px;
}
.timeNFTable > ul.tBody .customRadioBtn label {
	margin: 0px;
	padding: 0px 0px 0px 30px;
	height: 24px; 
}
.timeNumberFormatForm .seveBtn {
	color: #0d3968;
	background: #bccddc;
	border: 0;
	border-radius: 0;
	padding: 10px 15px;
	font-family: open_sanssemibold;
	margin-top: 15px;
}
.globalFilterText {
    color: #afb1b2;
    margin-bottom: 0;
    font-size: 12px;
    padding-right: 15px;
}
/*** **************  ************** ****
		*** Notification Wrapper  ***
**** **************  ************** ***/

.notificationWrapper {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both; 
}

/*** **************  ************** ****
		*** Notification Header ***
**** **************  ************** ***/

.ntnLibraryHeader {
	width: 100%;
	padding: 0px;
	display: table;
	border-bottom: 1px solid #7e8690;
}
.ntnLibraryHeader > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.ntnLibraryHeader > ul > li {
	padding: 18px 15px;
	display: table-cell;
	color: #0D3968;
	vertical-align: middle;
}
.ntnLibraryHeader > ul > li a {
	color: #96a8bb;
}
.ntnLibraryHeader > ul > li > a.active,
.ntnLibraryHeader > ul > li > a:hover {
	color: #0D3968;
}
.ntnLibraryHeader > ul > li:nth-child(1) {
	text-align: left;
	font-size: 18px;
	color: #0D3968;
	font-family: 'open_sanssemibold';
	width: 235px;
} 
.ntnLibraryHeader > ul > li .btn {
	margin-left: 10px;
}
.ntnLibraryHeader > ul > li .btn.btn-default { 
	background:#195d99;
}
.ntnLibraryHeader > ul > li .btn.disabled {
	color: #bcc3cf;
}  
/* ****** ****** ****** ****** ****** **
 * ****** Notification Library ****** *
** ****** ****** ****** ****** ****** */ 
.ntnLibraryTable {
	width: 100%;
	display: table;
}
.ntnLibraryTable > ul {
	width: 100%;
	display: table-row;
}
.ntnLibraryTable > ul.tHead > li {
	color: #16406d;
	border-bottom: 1px solid #63778a;
	background: #e9eaeb;
}
.ntnLibraryTable > ul.tHead > li:first-child {
	border-left: 0px;
}
.ntnLibraryTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left; 
} 
.ntnLibraryTable > ul > li:nth-child(1) {
	width:80px;
	padding-left:15px;
	text-align: center;
	color: #16406d;
	/* border-bottom: 1px solid #F2F2F2; */
}
.ntnLibraryTable > ul > li:nth-child(2) {
	padding-left: 0px;
}
.ntnLibraryTable > ul > li:nth-child(2), 
.ntnLibraryTable > ul > li:nth-child(3), 
.ntnLibraryTable > ul > li:nth-child(4) {
	width:150px;
}
.ntnLibraryTable > ul > li:nth-child(5){ 
	width:110px;
	padding-left:0px;
	padding-right:0px;
} 
.ntnLibraryTable > ul > li:nth-child(6){ 
	width:180px;
}  
.ntnLibraryTable > ul > li:nth-child(8) {
	text-align: center;
	width:80px; 
	padding-left:0px;
	padding-right:0px;
}  
.ntnLibraryTable > ul > li:nth-child(9) {
	text-align: center;
	width:110px; 
	padding-left:0px;
	padding-right:0px;
}  
.ntnLibraryTable > ul > li:nth-child(10) {
	text-align: center;
	width:80px; 
	padding-right: 15px;
} 
.ntnLibraryTable > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.ntnLibraryTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}
.ntnLibraryTable > ul > li .detailsBtn {
	width: 32px;
    height: 32px;
	border-radius:50%;
	display: inline-block;
	border: 0px;
	background:#e9eaeb url("../images/icons/informationB.svg") no-repeat center center;
	background-size: 24px auto;
}  
.ntnLibraryTable > ul > li .bellBtn {
	width: 32px;
    height: 32px;
	border-radius:50%;
	display: inline-block;
	border: 0px;
	background:#e9eaeb url("../images/icons/BellA.svg") no-repeat center center;
	background-size: 24px auto;
}  
.ntnLibraryTable > ul.tBody > li .unitsListSM,
.ntnLibraryTable > ul.tBody > li .unitsListSML {
	display: none;
	width: 100%;
	float: left;
}
.ntnLibraryTable > ul.tBody > li .unitsListSML {
	display: none;
	width: 100%;
	float: left;
	background: url("../images/ANTransparent.png") repeat;
	padding: 5px;
	font-size: 12px;
}
.ntnLibraryTable > ul > li .btn-group-toggle .btn-secondary.focus,
.ntnLibraryTable > ul > li .btn-group-toggle .btn-secondary:focus {
	box-shadow: inherit;
}
.ntnLibraryTable > ul > li .btn-group-toggle .btn-secondary {
	width: 48px;
	height: 24px;
	border: 0px;
	background: url("../images/icons/switchOFF.svg") no-repeat center center;
	background-size: 48px 24px;
	background-color: transparent !important;
}
.ntnLibraryTable > ul > li .btn-group-toggle .btn-secondary.active {
	background: url("../images/icons/switchON.svg") no-repeat center center;
	background-size: 48px 24px;
} 
/* ****** ****** ****** ****** ****** **
 * ****** Rules Form ****** *
** ****** ****** ****** ****** ****** */
.rulesTable {
	width: 100%;
	display: table; 
}
.rulesTable > ul {
	width: 100%;
	display: table-row;
}
.rulesTable > ul.tHead > li {
	color: #66809c;
	font-size: 14px;
	background: #e9eaeb;
	border-bottom: 1px solid #6c7e90;
}
.rulesTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	color: #1b4471;
	text-align: left;
	font-size: 14px; 
}
.rulesTable > ul.tBody > li {
	padding: 7px 10px;
	background: #ffffff;
	border-bottom: 1px solid #b4bec8;
}
.rulesTable > ul > li:nth-child(1) {
	width:220px; 
} 
.rulesTable > ul > li:nth-child(3) {
	text-align: center;
	width: 120px;
} 
.rulesTable > ul > li .btn-group-toggle .btn-secondary.focus,
.rulesTable > ul > li .btn-group-toggle .btn-secondary:focus {
	box-shadow: inherit;
}
.rulesTable > ul > li .btn-group-toggle .btn-secondary {
	width: 48px;
	height: 24px;
	border: 0px;
	background: url("../images/icons/switchOFF.svg") no-repeat center center;
	background-size: 48px 24px;
	background-color: transparent !important;
}
.rulesTable > ul > li .btn-group-toggle .btn-secondary.active {
	background: url("../images/icons/switchON.svg") no-repeat center center;
	background-size: 48px 24px;
}

/* ****** ****** ****** ****** ****** **
 * ****** Recipients Table ****** *
** ****** ****** ****** ****** ****** */

.recipientsTable {
	width: 100%;
	display: table;
}
.recipientsTable > ul {
	width: 100%;
	display: table-row;
}  
.recipientsTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
} 
.recipientsTable > ul > li:nth-child(1) {
	width: 40px;
	text-align: center; 
}
.recipientsTable > ul > li:nth-child(2) { 
	padding-left: 0px;
	text-align: center;
} 
.recipientsTable > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.recipientsTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left:35px;
	padding-top:0px;
	height: 24px;
	width: auto;
	font-size:16px;
}

/* ****** ****** ****** ****** ****** **
 * ****** Ready Recipe Table ****** *
** ****** ****** ****** ****** ****** */
.readyRecipeTable {
	width: 100%;
	display: table;
}
.readyRecipeTable > ul {
	width: 100%;
	display: table-row;
	background-color: #FFF;
}
.readyRecipeTable > ul.tHead > li {
	color: #16406d;
	border-bottom: 1px solid #63778a;
	background: #e9eaeb;
}
.readyRecipeTable > ul.tHead > li:first-child {
	border-left: 0px;
	border-bottom: 1px solid #63778a;
}
.readyRecipeTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
}
.readyRecipeTable > ul > li:nth-child(1) {
	width: 60px;
	text-align: center;
	color: #16406d;
	border-bottom: 1px solid #F2F2F2;
}
.readyRecipeTable > ul > li:nth-child(2) {
	width: 70px;
	padding-left: 0px;
	text-align: center;
}
.readyRecipeTable > ul > li:nth-child(3) {
	width: 60px;
	padding: 0px;
}
.readyRecipeTable > ul.tBody > li:nth-child(3) {
	height: 60px;
}
.readyRecipeTable > ul > li:nth-child(5),
.readyRecipeTable > ul > li:nth-child(6) {
	width: 18%;
}   
.readyRecipeTable > ul > li:nth-child(7) {
	width: 120px;
	text-align: center;
}
.readyRecipeTable > ul > li:nth-child(8) {
	width: 70px;
	padding-left:0px;
	padding-right:0px;
	text-align: center;
}
.readyRecipeTable > ul > li:nth-child(9) {
	width: 170px;
	text-align: center;
}
.readyRecipeTable > ul.tBody > li:nth-child(9) {
	width: 158px;
} 
.readyRecipeTable > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.readyRecipeTable > ul > li .btn {
	background: #0d3968; 
    color: #fff;
}
.readyRecipeTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}
.readyRecipeTable > ul > li .menuType {
	width: 24px;
	height: 24px;
	display: inline-block;
}
.readyRecipeTable > ul > li .menuType > img {
	width: 20px;
}
.readyRecipeTable > ul > li .menuPicture {
	width: 60px;
	display: inline-block;
}
.readyRecipeTable > ul > li .menuPicture > img {
	width: 100%;
}
.readyRecipeTable > ul > li .menuInfo {
	background: url("../images/icons/infoReport.svg") no-repeat center center;
	background-size: 24px 24px;
} 
.readyRecipeTable > ul > li .forwardNext {
    background: url(../images/icons/forwardA.svg) no-repeat center center;
    background-size: 40px 40px;
    width: 40px;
    height: 40px;
    display: inline-block;
}
.readyRecipeTable > ul.tBody > li .menuSListSMF,
.readyRecipeTable > ul.tBody > li .menuSListRS,
.readyRecipeTable > ul.tBody > li .menuSListAS {
	display: none;
	width: 100%;
	float: left;
	font-size: 12px;
}
.readyRecipeTable > ul.tBody > li .menuSListSM {
	display: none;
}

/* ****** ****** ****** ****** ****** **
 * ****** Content Form Table ****** *
** ****** ****** ****** ****** ****** */
.contentFormTableOuter {
	width: 100%;
	float: left;
	padding: 0px;
}
.contentFormTableOuter .panel-collapse {
	background: #ffffff;
}
.contentFormTable {
	width: 100%;
	display: table;
}
.contentFormTable > ul {
	width: 100%;
	display: table-row;
}
.contentFormTable > ul.tHead li {
	color: #657f9c;
	background: #E9EAEB;
	border-bottom: 1px solid #607488;
}
.contentFormTable > ul.tHead .active {
	color: #153f6d;
} 
.contentFormTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #ffffff;
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
	background: #E9EAEB;
}
.contentFormTable > ul > li a {
	color: #2dbaff;
} 
.contentFormTable > ul > li:nth-child(1) {
	width: 54px;
	text-align: center;
	padding-left:5px;
	padding-right:5px;
}
.contentFormTable > ul > li:nth-child(2) {
	width: 45px;
	text-align: center; 
}
.contentFormTable > ul > li:nth-child(3) { 
	padding-left: 0px;
	text-align: left; 
}   
.contentFormTable > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px 30px 10px 10px;
	height: auto;
	background: #f2f2f2 !important;
	color: #0D3968;
	font-size: 20px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
} 
.contentFormTable > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.contentFormTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
} 
.panel-body.contentFormTable {
	padding: 0px;
	margin: 0px;
	border: 0px;
}
.panel-body.contentFormTable > ul.tBody li {
	border-bottom: 1px solid #acbac8;
} 
.contentFormExpTable {
	width: 100%;
	display: table;
}
.contentFormExpTable > ul {
	width: 100%;
	display: table-row;
} 
.contentFormExpTable > ul.tHead .active {
	color: #153f6d;
}
.contentFormExpTable > ul.tBody li {
	padding: 15px 10px;
}
.contentFormExpTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: top;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
}  
.contentFormExpTable > ul > li:nth-child(1) {
	width: 54px;
	text-align: center;
	padding-left:5px;
	padding-right:5px;
}
.contentFormExpTable > ul > li:nth-child(2) {
	width: 44px;
	padding-left: 0px;
	text-align: left;
} 
.contentFormExpTable > ul > li:nth-child(3) {
	padding-left: 0px;
	text-align: left;
}   
/* ****** ****** ****** ****** ****** **
 * ****** Details View Table ****** *
** ****** ****** ****** ****** ****** */
    
.detailsViewOuter {
	width: 100%;
	display: table;
}
.detailsViewOuter > ul {
	width: 100%;
	display: table-row;
}
.detailsViewOuter > ul.tHead li { 
	font-family: 'open_sanssemibold';
	font-size:18px;
	padding-bottom:5px;
}  
.detailsViewOuter > ul.tBody li { 
	padding-top:0px;
} 
.detailsViewOuter > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle; 
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
} 
.detailsViewOuter > ul > li:nth-child(2) {
	width:50%; 
} 
.detailsViewOuter > ul > li .helpIcon { 
	display: inline-block; 
	padding:5px 10px 5px 32px;
	color:#16406d;
	font-family: 'open_sanssemibold'; 
	background: url("../images/icons/Service_A.png") no-repeat left center;
	background-size: 24px auto;
}
.detailsViewOuter > ul > li .urlButton { 
	background: #0d3968;
    padding:6px 15px;
    color: #fff;
}
.detailsViewOuter > ul > li .urlButton > img { 
	max-height:24px;
} 
/* ****** ****** ****** ****** ****** **
 * ****** Node List ****** *
** ****** ****** ****** ****** ****** */ 
.descriptionEditV {
	width: 100%;
	display: table;
	margin-bottom:15px;
}
.descriptionEditV > ul {
	width: 100%;
	display: table-row;
} 
.descriptionEditV > ul > li {
	display: table-cell;
	padding:0px;
	vertical-align: top; 
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
}  
.reportDetailsModal .descriptionEditV > ul > li h6 {
	width: 100%;
	float: left;
	padding:0px 0px 10px;
	margin: 0px 0px;
	font-size: 16px;
	color: #0d3968; 
	font-family: 'open_sanssemibold';
} 
.descriptionEditV > ul > li.menuPictureLi {  
	padding-right:15px;
	width:130px;
} 
.descriptionEditV > ul > li .menuPicture {
	width: 100%;
	float: left;
	clear: both;
}
.descriptionEditV > ul > li .menuPicture > img {
	width: 100%; 
} 
.descriptionEditV .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding:5px;
	height: auto;
	background: #ffffff !important;
	color: #0D3968;
	font-size: 16px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
}
.descriptionEditV textarea.form-control { 
	border: 1px solid #6e88a4; 
}
.descriptionEditV .descriptionPlaceholder { 
	width:100%;
	float:left;
	padding:27px 0px; 
	color:#0D3968;
	font-size:16px;
	text-align:center; 
	border: 1px solid #6e88a4;
	pointer-events: none;
	cursor: default;
	opacity: 0.5;
} 
.nodeList {
	width: 100%; 
	float:left;
	margin-bottom:15px;
}
.nodeList > ul {
	width: 100%;
	padding:0px 0px 0px 35px;
}  
.nodeList > ul > li {
	width:100%;
	float:left;
	list-style-type:number;	
	padding:0px 0px 5px;
	color: #0D3968;
}  
/* ****** ****** ****** ****** ****** **
 * ****** Created / Edited Details O ****** *
** ****** ****** ****** ****** ****** */
    
.createdEditedDetailsO {
	width: 100%;
	display: table;
}
.createdEditedDetailsO > ul {
	width: 100%;
	display: table-row;
}
.createdEditedDetailsO > ul.tHead li { 
	font-family: 'open_sanssemibold';
	font-size:16px; 
}  
.createdEditedDetailsO > ul.tBody li { 
	padding-top:0px;
} 
.createdEditedDetailsO > ul > li {
	display: table-cell;
	padding: 5px 0px;
	vertical-align: top;
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
} 
.createdEditedDetailsO > ul > li.descriptionLi { 
	padding: 0px 0px 15px 15px; 
}  
.createdEditedDetailsO > ul > li.descriptionLi h6 { 
	padding:0px 0px 0px; 
} 
.createdEditedDetailsO > ul > li.descriptionLi p { 
    margin: 0px 0px 0px; 
	font-family: 'open_sansregular';
}
.createdEditedDetailsO > ul > li .downloadListof {   
	padding:3px 0px 3px 30px;
	color: #0d3968; 
	background: url("../images/icons/download.png") no-repeat left center;
	background-size: 20px 20px; 
	float: right;
	font-size:16px;
	margin-bottom:15px;
	line-height:22px;
}  
/* ****** ****** ****** ****** ****** **
 * ****** Created / Edited Details ****** *
** ****** ****** ****** ****** ****** */
    
.createdEditedDetails {
	width: 100%;
	display: table;
}
.createdEditedDetails > ul {
	width: 100%;
	display: table-row;
}  
.createdEditedDetails > ul > li {
	display: table-cell;
	padding: 5px 0px;
	vertical-align: top; 
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
}  
.createdEditedDetails > ul > li:nth-child(2) {
	width:235px;
	padding-right:5px;	
}  
/* ****** ****** ****** ****** ****** **
 * ****** Header ****** *
** ****** ****** ****** ****** ****** */
    
.detailsViewHeader {
	width: 100%;
	display: table;
}
.detailsViewHeader > ul {
	width: 100%;
	display: table-row;
} 
.detailsViewHeader > ul > li {
	display: table-cell;
	padding: 0px 0px;
	vertical-align: top; 
	color: #0D3968;
	font-size: 22px;
	text-align: left; 
}
.detailsViewHeader > ul > li:nth-child(2) {  
	text-align: right; 
	padding-right:10px;
	padding-top: 35px;
}
.detailsViewHeader.marginTop0 > ul > li:nth-child(2) {   
	padding-right:0px;
	padding-top: 0px;
}
.detailsViewHeader > ul > li .brandModel,
.detailsViewHeader > ul > li .eventTypeText{ 
	font-size: 16px; 
	float:left;
	width:100%; 
}
.detailsViewHeader > ul > li .eventTypeText > label{ 
	font-size:14px; 
	float:left;
	width:100%; 
	margin:0px;
	padding:0px;
	margin-top:15px;
}
.detailsViewHeader > ul > li .eventTypeText .form-control {
	width: 100%;
	max-width:300px;
	float: left;
	clear: both;
	padding: 5px 30px 10px 0px;
	height: auto;
	background: #ffffff !important;
	color: #0D3968;
	font-size: 16px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
}
.detailsViewHeader > ul > li .eventTypeText select.form-control {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: #ffffff url("../images/icons/markdownA.svg") no-repeat right center !important;
}
.detailsViewHeader > ul > li .eventTypeText select.form-control.disabled {
	opacity:0.5;
}
.detailsViewHeader > ul > li .eventTypeText select::-ms-expand {
	display: none;
}
.detailsViewHeader > ul > li .brandLogo img{ 
	max-height:20px; 
}  
.detailsViewHeader > ul > li .orgViewIcon{ 
	width: 36px;
    height: 36px;
    display: inline-block;
    background: #a6a6a6;
    padding: 2px;
    vertical-align: middle;
    text-align: center;
    border-radius: 2px;
    line-height: 30px;
} 
.detailsViewHeader > ul > li .orgViewIcon.active{
    background: #0d3968;
}   
.detailsViewHeader > ul > li .orgViewIcon.btn{ 
	border:0px;
	margin-right:15px;
}   
.detailsViewHeader > ul > li .orgViewIcon img{ 
	max-height:32px; 
	max-width:32px; 
}   
.detailsViewHeader > ul > li .brandModelIcon img{ 
	max-height:36px; 
	max-width:36px; 
}   
.detailsViewHeader > ul > li .brandModelIcon{ 
	margin-left:15px;
	width: 36px;
    height: 36px;
    display: inline-block; 
    vertical-align: middle;
} 
.detailsViewHeader > ul > li .notifnFlowDetails {
	width: 100%;
	float: left;
	margin:5px 0px 0px;
}

/* ****** ****** ****** ****** ****** **
 * ****** Notification Flow Details ****** *
** ****** ****** ****** ****** ****** */ 
.nFlowViewOuter {
	width: 100%;
	display: table;
	margin:25px 0px;
}
.nFlowViewOuter > ul {
	width: 100%;
	display: table-row;
} 
.nFlowViewOuter > ul > li {
	display: table-cell;
	padding: 0px 0px;
	vertical-align: top; 
	color: #0D3968;
	font-size: 22px;
	text-align: left; 
	text-align:center;
} 
.nFlowViewOuter > ul > li .timeFlagIcon{ 
	margin-bottom:5px; 
	line-height:32px;	
	width:100%;
	float:left;
}
.nFlowViewOuter > ul > li .timeFlagIcon > img{
	max-width:45px; 
	max-height:45px;  
}
.nFlowViewOuter > ul > li .timeFlagText{
	border:2px solid #c8102e;
	padding:2px 15px;	
	font-size:12px;
	border-radius:15px;
}
.nFlowViewOuter > ul > li:nth-child(1) {
	width:80px;
}
.nFlowViewList {
	width: 100%;
	float:left;
}
.nFlowViewList > ul {
	width: 100%;
	float:left;
} 
.nFlowViewList > ul > li {
	float:left; 
	color: #0D3968; 
	text-align: left; 
	background:#f2f2f2;
	padding:10px;  
	border:1px solid #aab4be;
	border-left:0px;
	width:160px;
}
.nFlowViewList > ul > li:nth-child(1) { 
	border-left:1px solid #aab4be; 
} 
.nFlowViewList > ul > li h5{
	font-size:16px;  
	margin-bottom:10px; 
	line-height:32px; 
	overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.nFlowViewList > ul > li h5 > img{
	max-width:32px; 
	max-height:28px;  
}
.nFlowViewList > ul > li .nFlowEvents {
	width:50%;
	float: left;
	margin:6px 0px 0px;
	text-align: center;
}  
.nFlowViewList > ul > li .nFlowEvents > h4{
	font-size:14px;
	margin-top:5px;
	text-align:center; 	
}
.nFlowViewList > ul > li .nFlowEvents .nFlowIcon{
	width:36px; 
	height:36px; 
	display:inline-block; 
	background:#a6a6a6;
	padding:2px;
	vertical-align:middle;
	text-align:center;
	border-radius:2px;
	line-height:30px;
}
.nFlowViewList > ul > li .nFlowEvents .nFlowIcon.active{
	background: #0d3968;
}
.nFlowViewList > ul > li .nFlowEvents .nFlowIcon > img{
	max-width:32px; 
	max-height:28px;  
}
/*** **************  ************** ****
		*** Source Part ***
**** **************  ************** ***/

.sourcePartTable {
	width: 100%;
	float: left;
	margin: 10px 0px 0px;
}
.sourcePartTable > ul {
	width: 100%;
	float: left;
} 
.sourcePartTable > ul > li {
	width: 100%;
	float: left;
	padding: 10px 0px; 
	border-bottom: 1px solid #acbac8;
	color: #0d3968;
	font-size: 16px; 
} 
.custom-file-label::after { 
    color: #fff;
    background: #0d3968;
}
/*** **************  ************** ****
		*** Platform Upgrade ***
**** **************  ************** ***/
.unitRegSuccess {
	width:440px; 
	float:right;
	padding-right:15px;
}
.unitRegSuccess .unitRegThanksCnt{
	width: 100%;
	float: left;
	padding: 10px 0px;  
	color: #ffffff;
	font-size: 16px; 
} 
.unitRegSuccess .unitRegThanksCnt > img{ 
	float: right; 
	max-width:100px;
} 
.unitRegSuccess .unitRegThanksCnt > b{ 
	font-family: 'open_sanssemibold';
	font-weight:normal;
} 
.unitRegSuccess .signinupDiv{
	width: 100%;
	float: left;
	padding: 10px 0px 0px;  
	color: #0d3968;
	font-size: 16px; 
} 
/*** **************  ************** ****
		*** Thank You EAAS ***
**** **************  ************** ***/
.eaasWrapper {
	width:540px; 
	float:right;   
}
.thankYouInEAAS {
	width:100%; 
	float:left;   
	padding:0px 15px;
}
.thankYouEAAS {
	width:500px; 
	float:left;  
	background:#ffffff; 
	margin:55px 15px 0px;
}
.thankYouEAAS .thankYouEAASCnt{
	width: 100%;
	float: left;
	padding: 15px 0px;  
	color: #0D3968;
	font-size: 16px;  
}  
.thankYouEAAS .thankYouEAASCnt h5{
	font-size:18px;  
	margin-bottom:10px;  
	font-family: 'open_sanssemibold';
}
.thankYouEAAS .thankYouEAASCnt p {
	width: 100%;
	float: left;
	padding: 0px;
	margin: 0px;
	font-size:16px;
	color: #0d3968;
}
.thankYouEAAS .thankYouEAASCnt img{ 
	float: right; 
	max-width:100px;
} 
.thankYouEAAS .thankYouEAASCnt > b{ 
	font-family: 'open_sanssemibold';
	font-weight:normal;
}   
.termsAndConditions{
	width: 100%;
	float: left;
	padding: 10px 0px;  
	color: #16406d;
	font-size: 16px; 
} 
.termsAndConditions .customCheckbox {
	margin:5px 5px;
	display: inline-block; 
	float:left;
}
.termsAndConditions .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
} 
.termsAndConditions .termsAndC {  
	margin:5px; 
	display: inline-block;  
	float:left;
	width:80%;
	font-size:14px;
} 
.termsAndConditions .termsAndC > a{  
	text-decoration:underline;
	color: #16406d;
} 
.termsAndConditions .termsAndC > b{ 
	font-family: 'open_sanssemibold';
	font-weight:normal;
} 
.termsAndConditions .emailSend {  
	margin:5px; 
	display: inline-block;  
	float:left; 
	font-size:14px;
} 
.termsAndConditions .emailSend > a{ 
	color: #16406d;
	text-decoration:underline;
} 
.registerYourDevice {
    width:100%;
    float: left;
    padding:0px 0px 15px; 
}   
.registerYourDevice > ul {
	width: 100%;
	float: left;
	padding:10px 25px;
} 
.registerYourDevice > ul > li {
	width: 100%;
	float: left;
	clear:both;
	padding: 5px 0px;  
	font-size: 16px; 
	list-style-type:circle; 
	color: #16406d;
} 
.registerYourDevice > ul > li > span {
	float:right;
	min-width:125px;
	max-width:50%;
	text-align:left; 
	font-size: 16px; 
	font-family: 'open_sanssemibold';
} 
.registerYourDevice p  { 
	font-size:16px; 
	color: #16406d;
	margin:20px 0px;
	min-height:60px;
}
.registerYourDevice .btn.btn-default {
    background: #195d99;
    color: #fff; 
    min-width:180px;
    border: 0px;
    font-size: 16px;
    padding:10px 16px; 
	display: inherit; 
}  
.registerUnitLtable {
    width:100%;
	display:table;  
}   
.registerUnitLtable > ul {
	width: 100%;
	display:table-row;
} 
.registerUnitLtable > ul.tHead li {  
	background:#e9eaeb;
}   
.registerUnitLtable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle; 
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
	border-bottom:1px solid #e9eaeb;
	border-left:1px solid #e9eaeb;
} 
.registerUnitLtable > ul > li:nth-child(1) {
	width:54px;	
	text-align: center; 
}  
.registerUnitLtable > ul.tHead > li:nth-child(3),
.registerUnitLtable > ul.tBody > li:nth-child(3) {
	width:130px;	
} 
.registerUnitLtable > ul.tHead > li:nth-child(4) {
	width:130px;
	text-align: left;
}
.registerUnitLtable > ul.tBody > li:nth-child(4) {
	width:118px;
}
.thankYouEAAS .signUpBtn {
    width: 100%;
    float: left;
    border: 0px;
    border-radius: 0px;
    padding: 17px 0px;
    font-size: 20px;
    margin: 0px;
    background: #195d99;
}
.signinupDiv .signInDiv,
.signinupDiv .signUpDiv  {
    width:100%;
    float: right;
	clear:both;
    padding:10px 10px 15px;
    background: #e9eaeb;
    border: 1px solid #dee0e1;
	margin-bottom:10px;
} 
.signinupDiv .signUpDiv  {  
	margin-bottom:0px;
} 
.signinupDiv .signInDiv h6,
.signinupDiv .signUpDiv h6  {
    color: #0d3968;
	font-size: 16px; 
	font-family: 'open_sanssemibold';
}
.signinupDiv .signUpDiv h5  {
    color: #0d3968;
	font-size:16px; 
	margin:10px 0px 0px;
}
.signinupDiv .signUpDiv h4  {
    color: #0d3968;
	font-size:14px; 
	margin:10px 0px 0px;
}
.signinupDiv .signInDiv p,
.signinupDiv .signUpDiv p  {
    color: #0d3968;
	font-size:16px; 
	margin:20px 0px;
	min-height:70px;
}
.signinupDiv .btn.btn-default {
    color: #ffffff;
    background: #195d99;
    min-width:180px;
    border: 0px;
    font-size: 16px;
    padding:10px 16px;
	margin:0px auto;
	display: inherit; 
} 
.signinupDiv .forgotpassword {
	color: #0D3968;
    padding-top: 10px;
    display: block;
    margin: 0px auto;
    width: 130px;
} 
.signinupDiv .resendCodeBtn {
	color: #0D3968;
	padding-top: 10px;
	display: block;
	margin: 0px auto;
	width: 130px;
	text-decoration:underline;
	background:transparent;
} 
.existingUSignInForm {
	width: 100%;
	float: left;
	margin: 0px 0px;
	padding: 5px 0px; 
}
.existingUSignInForm .required{
	width: 100%;
	float: left;
	margin: 0px 0px;
	padding: 5px 0px 5px; 
	text-align:center;
	font-size:14px;
}
.existingUSignInForm > ul {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 0px;
}
.existingUSignInForm > ul > li {
	width: 100%;
	float: left;
	clear: both;
	padding:5px 0px 10px;
	margin: 0px;
	color: #0D3968;
}   
.existingUSignInForm > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding:5px 30px 5px 0px;
	height: auto;
	background:inherit !important;
	color: #0D3968;
	font-size:16px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
}
.existingUSignInForm > ul > li .form-control:focus {
	outline: none;
}
.existingUSignInForm > ul > li .btn.input-group-addon.reveal {
	border: 0px;
	width: 26px;
	height: 26px;
	padding: 0px;
	background: transparent;
}
.existingUSignInForm > ul > li .form-control.countryCode {
	width: 20%;
	margin-right: 5%;
}
.existingUSignInForm > ul > li .form-control.phoneNumber {
	width: 75%;
}
.existingUSignInForm > ul > li select.form-control {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: url("../images/icons/markdownA.svg") no-repeat right center !important;
}
.existingUSignInForm > ul > li select::-ms-expand {
	display: none;
}
.existingUSignInForm > ul > li .input-group {
	width: 100%;
	position: relative;
}
.existingUSignInForm > ul > li .input-group .glyphicon-eye-open,
.existingUSignInForm > ul > li .input-group .glyphicon-eye-close {
	width: 24px;
	height: 24px;
	display: block;
}
.existingUSignInForm > ul > li .input-group .glyphicon-eye-open {
	background: url("../images/icons/viewD.svg") no-repeat center center;
	background-size: 24px auto;
}
.existingUSignInForm > ul > li .input-group .glyphicon-eye-close {
	background: url("../images/icons/viewB.svg") no-repeat center center;
	background-size: 24px auto;
}
.existingUSignInForm > ul > li .input-group .input-group-btn {
	width: 26px;
	height: 26px;
	position: absolute;
	top: 5px;
	right: 0px;
	z-index: 3;
}  
.existingUSignInForm > ul > li .otpForm .form-control {  
	color: #0D3968;
	font-size:22px; 
	font-family: 'open_sanssemibold';
	text-align:center;
	padding:5px 0px;
}
/*** **************  ************** ****
		*** Device Register ***
**** **************  ************** ***/
.deviceRegisterWrapper {
	width:480px; 
	float:right; 
}
.deviceRegisterWrapper .existingLoginForm > ul > li > p {
	margin:0px;
}
/*** **************  ************** ****
		*** Subscription Wrapper  ***
**** **************  ************** ***/
.subscriptionBForm {
	width:990px;
	padding:15px;
	margin:0px auto;
	clear:both;
}
.subscriptionBForm > h2 {
	width: 100%;
	float: left; 
	padding: 0px 0px 15px;
	font-size:22px;
	color: #ffffff;
	font-family: 'open_sanssemibold';	
} 
.subscriptionWrapper {
	width: 100%;
	padding:15px;
	float: left;
	clear: both; 
	position:relative;
}
.subscriptionWrapper > h2 {
	width: 100%;
	float: left; 
	padding: 0px 0px 15px;
	font-size:22px;
	color: #ffffff;
	font-family: 'open_sanssemibold';	
}   
.subscriptionWrapper .btn.planToggleBtn { 
	padding:5px 10px;  
	font-size:18px;
	border-radius:8px 8px 0px 0px; 
	background: #134d84; 
	color:#ffffff;
	margin:0px; 
	position:absolute;
	right: -40px;
	top:200px;
	-ms-transform: rotate(-90deg); /* IE 9 */
	transform: rotate(-90deg);
	z-index: 9;
	display:none;
	border:0px;
	width:130px;
}   
.subscriptionWrapper .btn.planToggleBtn.basicPlanActive {  
	background: #042548;   
}     
.subscriptionWrapper .btn.planToggleBtn > span{ 
	background: url("../images/icons/icoExpandTab.png") no-repeat right center;
	background-size: 24px auto;
	padding:5px 60px 5px 5px;
	display:block;
}    
.subscriptionInWrapper {
	width: 100%;
	padding:0px;
	float: left;
	clear: both;
	background:#ffffff;	
}
.subscriptionForm {
	width:840px;
	margin:0px auto;
	clear:both;
}
/* ****** ****** ****** ****** ****** **
 * ****** Subscription ****** *
** ****** ****** ****** ****** ****** */
.subscriptionTableSrlBar {
	width: 100%;
	padding:0px;
	float: left;
	clear: both;  
}  
.subscriptionForm .mCSB_inside > .mCSB_container{
	margin-right: 30px;
} 
.subscriptionNotesDiv {
	width: 100%;
	padding:5px 0px 0px;
	float: left;
	clear: both; 
	font-size:12px;
	color:#ffffff;	
}
.subscriptionList {
	width: 100%;
	display: table;  
}
.subscriptionList.subscriptionH,
.subscriptionList.subscriptionFooter { 
	padding-right:30px;
}  
.subscriptionList > ul {
	width: 100%;
	display: table-row;
}
.subscriptionList > ul > li {
	display: table-cell;
	padding: 2px 0px;
	vertical-align: middle;  
	color: #ffffff;
	font-size: 16px;
	text-align: left;
}  
.subscriptionList > ul > li:nth-child(1) { 
	position:relative;
}  
.subscriptionList > ul > li:nth-child(1) > span { 
	background:#0d3968; 
	padding-right:5px;
	display:inline-block;
} 
.subscriptionList.subscriptionFooter > ul > li:nth-child(1):before,
.subscriptionList > ul.tHead > li:nth-child(1):before,
.subscriptionList > ul.tTitle > li:nth-child(1):before{
	display:none;
}
.subscriptionList > ul > li:nth-child(1):before { 
	border-bottom: 1px dashed #1a5da9;
	content:" ";
	position:absolute;
	right:10px;
	top:50%;
	margin-top:0px; 
	width:100%;
	z-index:-1;
} 
.subscriptionList > ul > li:nth-child(2) { 
	width:180px; 
	border-left:0px; 
	background:#134d84;	
	text-align:center;
} 
.subscriptionList > ul > li:nth-child(3) { 
	width:75px;  
	border-bottom:1px solid #0d3968;
}       
.subscriptionList > ul > li:nth-child(4) { 
	width:180px; 
	border-left:0px; 
	background:#042548;	
	text-align:center;
}    
.subscriptionList.basicPlanSelected > ul > li:nth-child(4) { 
	display:none
}    
.subscriptionList.basicPlanSelected > ul > li:nth-child(2) { 
	display:table-cell;
}     
.subscriptionList.subscriptionFooter > ul > li:nth-child(2),
.subscriptionList.subscriptionFooter > ul > li:nth-child(4) {
	text-align:center; 
	padding-right:10px;
	padding-left:10px;		
} 
.subscriptionList.subscriptionFooter > ul > li {
	border-bottom:0px;	
}     
.subscriptionList > ul > li .imageSpan {
	width: 24px;
	height: 24px;
	display: inline-block;
	border: 0px; 
}  
.subscriptionList > ul > li .imageSpan > img{
	width: 100%; 
}   
.subscriptionList > ul > li .btn.chooseProBtn,
.subscriptionList > ul > li .btn.chooseBasicBtn { 
	padding:6px 25px;  
	border:0px; 
	border-radius:20px;
	color:#ffffff;
	font-size:14px;
	margin:6px 0px;
	font-family: 'open_sanssemibold';
}  
.subscriptionList > ul > li .btn.chooseProBtn {  
	background: #195d99; 
}  
.subscriptionList > ul > li .btn.chooseBasicBtn {  
	background: #0d3968; 
} 
.subscriptionList > ul > li .customCheckbox {
	margin: 15px 5px 15px 0px;
	float:left;
}
.subscriptionList > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}
.subscriptionList > ul > li .customCheckbox input[type=checkbox] + label {
	background: url("../images/icons/iconWhiteCheckBox.svg") no-repeat left center;
	background-size: 24px 24px;
}
.subscriptionList > ul > li .customCheckbox input[type=checkbox] + label,
.subscriptionList > ul > li .customCheckbox input[type=checkbox] + i + label,
.subscriptionList > ul > li .customCheckbox input[type=checkbox] + input + label {
	background: url("../images/icons/iconWhiteCheckBox.svg") no-repeat left center;
	background-size: 24px 24px;
}
.subscriptionList > ul > li .customCheckbox input[type=checkbox]:checked + label,
.subscriptionList > ul > li .customCheckbox input[type=checkbox]:checked + i + label,
.subscriptionList > ul > li .customCheckbox input[type=checkbox]:checked + input + label {
	background: url("../images/icons/iconWhiteCheckedBox.svg") no-repeat left center;
	background-size: 24px 24px;
}
.subscriptionList > ul > li .termsAndC { 
	color:#ffffff; 
	font-size:14px;
	padding-left:10px;
	padding-right:5px;
} 
.subscriptionList > ul > li .termsAndC > a{ 
	color:#ffffff;
	text-decoration:underline;
} 
.subscriptionList.subscriptionFooter .customCheckbox,
.subscriptionList.subscriptionFooter > ul > li .termsAndC { 
	display:table-cell;
	vertical-align:middle;
	float:inherit;
}   
.subscriptionList > ul.tTitle > li{ 
	padding: 15px 0px 5px;
	font-size:18px;
	color: #ffffff;
	font-family: 'open_sanssemibold';
	text-transform:uppercase;	 
}   
.subscriptionList > ul.tHead > li { 
	padding:0px;
	border:0px;
	border-bottom: 1px solid #aab4be;
}  
.subscriptionList > ul.tHead > li:nth-child(2):before{
	border-bottom:1px solid #030f1a;
	content:" ";
	position:absolute;
	left:0px; 
	bottom:0px; 
	width:100%; 
}
.subscriptionList > ul.tHead > li:nth-child(4):before{
	border-bottom:1px solid #030f1a;
	content:" ";
	position:absolute;
	left:0px; 
	bottom:0px; 
	width:100%; 
}
.subscriptionList > ul.tHead > li:nth-child(1){   
	border-bottom:1px solid #0d3968;  
	font-size:20px; 
	font-family: 'open_sanssemibold';
} 
.subscriptionList > ul.tHead > li:nth-child(3){   
	border-bottom:1px solid #0d3968;   
} 
.subscriptionList > ul.tHead > li:nth-child(2),
.subscriptionList > ul.tHead > li:nth-child(4){   
	border-left:0px;
	border-right:0px;	
	padding-bottom:10px; 
	padding-right:10px;
	padding-left:10px;	
	position:relative;
}   
.subscriptionList > ul.tHead > li:nth-child(2){   
	border-bottom:1px solid #4babff; 
}   
.subscriptionList > ul.tHead > li:nth-child(4){   
	border-bottom:1px solid #134d84; 
}  
/* ****** ****** ****** ****** ****** **
 * ****** Subscription Pro Plan ****** *
** ****** ****** ****** ****** ****** */
.subscriptionPlanPro,
.subscriptionPlanBasic {
	width: 100%;
	float: left; 
	text-align:center; 
	position:relative; 
} 
.subscriptionPlanPro .freeTrialIcon{  
	position:absolute;
	left:-80px;
	top:-11px;
	width:100px;
	height:100px;
	background: url("../images/icons/freetrial.svg") no-repeat center center;
	background-size: 100px auto;
	z-index:1;
}

.subscriptionPlanPro h2, 
.subscriptionPlanPro h4, 
.subscriptionPlanBasic h2, 
.subscriptionPlanBasic h4 {
	width: 100%;
	float: left; 
}
.subscriptionPlanPro h2,
.subscriptionPlanBasic h2 {
	width: 100%;
	float: left; 
	padding: 10px 0px 0px;
	font-size:16px;
	color: #ffffff;  
	font-family: 'open_sanssemibold';
}  
.subscriptionPlanPro h4 {
	padding: 5px 0px 5px;
	font-size:14px;
	color: #ffffff;  
}  
.subscriptionPlanPro h4.freeTrial {  
	text-decoration: line-through;
}   
.subscriptionPlanPro h4.freeTrialR { 
	text-decoration: line-through;
	text-decoration-color: #f93251;
}  
.subscriptionPlanBasic h4 {
	padding: 10px 0px 5px;
	font-size:14px;
	color: #f2bc01;  
} 
.subscriptionPlanPro .btn.viewPrice { 
	padding:6px 20px;  
	border:0px;
	font-size:12px;
	border-radius:16px; 
	background: #bccddc; 
	color:#042548;
	margin-top:10px; 
}   
 
.subscriptionPlanList {
    width:100%;
	display:table;
    padding:10px 0px 15px;  
}   
.subscriptionPlanList > ul {
	width: 100%;
	display:table-row;
} 
.subscriptionPlanList > ul.tHead li {  
	background:#e9eaeb;
}   
.subscriptionPlanList > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle; 
	color: #0D3968;
	font-size: 16px;
	text-align: center; 
	border-bottom:1px solid #e9eaeb;
	border-left:1px solid #e9eaeb;
} 
.subscriptionPlanList > ul > li:nth-child(2) {
	width:50%; 
	border-right:1px solid #e9eaeb;	
}  
.subscriptionPlanList > ul:first-child > li { 
	border-top:1px solid #e9eaeb;	
}  

/*** **************  ************** ****
		*** Billing Register Form ***
**** **************  ************** ***/

.billingFormWrapper {
	width: 100%;
	float: left;
	clear: both;
	padding:10px 15px;
}  
.billingFormWrapper .customCheckbox label { 
	padding: 0px 0px 6px 30px;
} 
.billingFormWrapper .customCheckbox input[type=checkbox] + label { 
	height: auto;
	line-height: normal; 
	background: url("../images/icons/checkAllOff.svg") no-repeat left top; 
}
.billingFormWrapper .customCheckbox input[type=checkbox] + label,
.billingFormWrapper .customCheckbox input[type=checkbox] + i + label,
.billingFormWrapper .customCheckbox input[type=checkbox] + input + label { 
	height: auto;
	line-height: normal; 
	background: url("../images/icons/checkAllOff.svg") no-repeat left top; 
	font-size:16px;
}
.billingFormWrapper .customCheckbox input[type=checkbox]:checked + label,
.billingFormWrapper .customCheckbox input[type=checkbox]:checked + i + label,
.billingFormWrapper .customCheckbox input[type=checkbox]:checked + input + label {
	background: url("../images/icons/checkAllOn.svg") no-repeat left top; 
}
.billingFormWrapper h2 {
	width: 100%;
	float: left; 
	padding: 10px 0px 10px;
	font-size:24px;  
    color: #0d3968; 
}  
.billingForm {
	width: 100%;
	display:table; 
}
.billingForm.marginBottom { 
	margin-bottom:25px;	
}
.billingForm > ul{
	width: 100%;
	display:table-row; 
}
.billingForm > ul > li {
	display: table-cell;
	padding:10px 10px;
	vertical-align: top; 
	color: #16406d;
	font-size: 16px;
	text-align: left; 
}
.billingForm label { 
    float: left;
    padding:5px 0px 0px;
	margin:0px 10px 0px 0px;
    clear: both;
    color: #0D3968;
    font-size: 18px;
	line-height:22px; 
} 
.billingForm > ul > li.colm50:nth-child(1) { 
	width:42%;
	padding-right:8%;
}
.billingForm > ul > li.colm50:nth-child(2) { 
	width:42%;
	padding-left:8%;
}
.billingForm > ul > li:nth-child(1) { 
	padding-left:0px;
}
.billingForm > ul > li.colm33 { 
	width:33.33%;
}
.billingForm > ul > li.marginBottom10px {
	margin-bottom: 20px;
}
.billingForm > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 30px 10px 0px;
	height: auto;
	background: transparent !important;
	color: #0D3968;
	font-size:18px;
	border: 0px;
	border-bottom: 1px solid #0D3968;
	border-radius: 0px; 
}
.billingEaasForm > ul > li .form-control {
	background-color: #eeeeee !important;
}
.billingForm > ul > li select.form-control {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background:transparent url("../images/icons/markdownA.svg") no-repeat right center !important;
}
.billingForm > ul > li select::-ms-expand {
	display: none;
} 
.billingForm > ul > li .input-group {
	width: 100%;
	position: relative;
}
.billingForm > ul > li .input-group .requiredField {
	width: 26px;
	height: 26px;
	text-align: center;
	position: absolute;
	top: 5px;
	right: 0px;
	z-index: 3;
	color: #b8c3cf;
	font-size: 28px;
}
.billingForm > ul > li  input.form-control:placeholder-shown, 
.billingForm > ul > li  input.form-control1:placeholder-shown {
    opacity: 1;
}
.billingForm > ul > li .modelNameInpt::placeholder {
	color: #0d3968 !important;
	opacity: 1; /* Firefox */
} 
.billingForm > ul > li .modelNameInpt:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #0d3968;
	opacity: 1;
} 
.billingForm > ul > li .modelNameInpt::-ms-input-placeholder { /* Microsoft Edge */
 color: #0d3968;
 opacity: 1;
}
.billingForm > ul > li .form-control.countryCode {
	width: 20%;
	margin-right: 5%;
}
.billingForm > ul > li .form-control.phoneNumber {
	width: 75%;
}
.billingInfoForm {
	width: 100%;
	float: left; 
} 
.directDebitGuarantee{
	width: 100%;
	float: left; 
	padding:0px 0px;
	margin:15px 0px 0px;
	border-top:1px solid #6e88a4;
}
.directDebitGuarantee > img{
	max-width: 100%; 
}
/* ****** ****** ****** ****** ****** **
 * ****** Plan Summary ****** *
** ****** ****** ****** ****** ****** */
.planSummary {
	width: 100%;
	float: left;  
	position:relative; 
}   
.planSummary h4 {
	width: 100%;
	float: left; 
	text-align:left;  
	padding: 5px 0px 5px;
	font-size:20px;
	color: #0d3968; 
}
.planSummary h4 > span { 
	font-size: 20px;
	float:right;
} 
.btn.billingFormRegister { 
	padding:8px 15px;  
	border:0px;
	width: 160px;  
	float:right;
	font-size:18px;
	background: #195d99;
	border-radius:20px;
	margin:15px 10px; 
}    
/*** **************  ************** ****
		*** KC How to Wrapper  ***
**** **************  ************** ***/

.kcHowToWrapper {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both; 
}
.kcHowToGridOuter {
	width: 100%;
	padding:0px 25px;
	float: left;
	clear: both; 
}
.kcHowToGridOuter > h2 {
	width: 100%;
	float: left; 
	padding: 20px 0px 20px;
	font-size:18px;  
    color: #ffffff; 
} 
.kcHowToGridOuter .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { 
    width: 6px; 
	background:#aab4be;
}
.kcHowToGridOuter .mCSB_scrollTools .mCSB_draggerRail { 
    width: 1px; 
	background:#d5dadf;
}
/*** **************  ************** ****
		*** KC How To Grids  ***
**** **************  ************** ***/

.kcHowToGrid {
	width: 100%;
	padding: 0px;
	float: left;
	clear: both; 
} 
.kcHowToTable {
	width: 100%;
	display:table; 
} 
.kcHowToTable > ul{
	width: 100%;
	display:table-row; 
}
.kcHowToTable > ul > li {
	display: table-cell;
	padding:0px 15% 20px 0px;
	vertical-align: top; 
	color: #16406d;
	font-size: 16px;
	text-align: left; 
	width:50%;
}
.kcHowToTable > ul:last-child > li { 
	padding:0px 15% 0px 0px;
}
.kcHowToTable > ul > li > h5 {
	width: 100%;
	float: left; 
	padding:10px 0px 5px;
	margin-bottom:10px;
	border-bottom:1px solid #ffffff;
	font-size:18px;  
    color: #ffffff; 
	font-family: 'open_sanssemibold';
} 
.kcHowToList {
	width: 100%;
	float: left; 
}
.kcHowToList > ul {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px 0px 0px;
}
.kcHowToList > ul > li {
	width: 100%;
	float: left;
	clear: both;
	padding:5px 0px;
	margin: 0px;
	color: #ffffff;
}
.kcHowToList > ul > li > a{ 
	color: #ffffff;
	text-decoration:underline;
}
.kcHowToList > ul > li span{ 
	width:32px;
	height:32px;
	display:inline-block;
	margin-right:5px;
}
.kcHowToList > ul > li span img{ 
	width:100% 
}
/*** **************  ************** ****
		*** Multi Language  ***
**** **************  ************** ***/

.multiLanguage{
	margin:15px 10px 15px;
}
.multiLanguage .languageSelection {
	background:transparent;
	border:1px solid #195d99;
	border-radius:20px;
	padding:3px 10px;
	width:65px;
	line-height:26px; 
	text-transform:uppercase;
}
.multiLanguage .btn-secondary:not(:disabled):not(.disabled).active, 
.multiLanguage .btn-secondary:not(:disabled):not(.disabled):active, 
.multiLanguage.show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background:transparent;
	border:1px solid #195d99;
} 
.multiLanguage .dropdown-menu {
	background:#195d99; 
	padding:0px;
	border-radius:0px;
	position:absolute;
	border-left: 0px;
    border-right: 0px;
	min-width:140px;
	left:10px;
}
.multiLanguage .dropdown-menu a{
	color:#ffffff; 
	border-top:1px solid #2768a4;
	border-bottom:1px solid #042548; 
	padding:8px 10px;
}
.multiLanguage .dropdown-menu .langImg{
	width:36px;
	display:inline-block;
	margin-right:5px;
	line-height:28px;
	font-family: 'open_sanssemibold';
	text-transform:uppercase;
} 
.multiLanguage .dropdown-menu a:first-child{ 
	border-top:0px; 
}
.multiLanguage .dropdown-menu a:last-child{  
	border-bottom:0px;
}
.multiLanguage .dropdown-menu a:active,
.multiLanguage .dropdown-menu a:focus,
.multiLanguage .dropdown-menu a:hover{
	color:#ffffff;
	background:#0d3968;
}

/* ****** ****** ****** ****** ****** **
 * ****** Terms and Conditions Table ****** *
** ****** ****** ****** ****** ****** */

.termsCondtionsTable {
	width: 100%;
	display: table;
}
.termsCondtionsTable > ul {
	width: 100%;
	display: table-row;
}
.termsCondtionsTable > ul.tHead > li {
	color: #ffffff; 
	font-family: 'open_sanssemibold';
} 
.termsCondtionsTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: top; 
	border-left: 1px solid #aab4be;
	border-top: 1px solid #aab4be;
	color: #ffffff;
	font-size: 12px;
	text-align: left;
	width:33.333%;
} 
.termsCondtionsTable > ul > li:last-child { 
	border-right: 1px solid #aab4be; 
}  
.termsCondtionsTable > ul:last-child > li { 
	border-bottom: 1px solid #aab4be; 
}  

/* ****** ****** ****** ****** ****** **
 * ****** Terms and Conditions OL Table ****** *
** ****** ****** ****** ****** ****** */

.termsCondtionsOlTable {
	width: 100%;
	float:left;
	margin:10px 0px;
}
.termsCondtionsOlTable > ol { 
	margin-left:25px;
} 
.termsCondtionsOlTable > ol > li {
	width: 100%;
	float:left;
	padding: 7px 5px;  
	font-size: 12px;
	text-align: left; 
	list-style-type: auto;
}  
.termsCondtionsDropDn {
	width: 100%;
	float:left;
	margin:0px 0px 25px;
}
.termsCondtionsDropDn label { 
    float: left;
    padding:5px 0px;
	margin:0px 10px 0px 0px;
    clear: both;
    color: #ffffff;
    font-size: 16px;
    font-weight: normal; 
} 
.termsCondtionsDropDn .form-control { 
	max-width:320px;
	width:auto;
	display:inline-block;
	padding: 5px 0px 5px 0px;
	height: auto;
	background: #0d3968 !important;
	color: #ffffff;  
	font-size: 16px;
	border: 0px;
	border-bottom: 1px solid #ffffff;
	border-radius: 0px;
}
.termsCondtionsDropDn select.form-control {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: #0d3968 url("../images/icons/markoffA.svg") no-repeat right center !important; 
	padding-right:30px;
}
.termsCondtionsDropDn select.form-control option {
	font-size: 16px;
}
.termsCondtionsDropDn select::-ms-expand {
	display: none;
}
/* ****** ****** ****** ****** ****** **
 * ****** Cookie and Terms and Conditions ****** *
** ****** ****** ****** ****** ****** */

.cookieWrapper {
	width: 100%; 
	padding:10px;
	float:left;
	font-size:12px;
	color:#ffffff;
	text-align:center;
} 
.cookieWrapper a {
	text-decoration:underline;
	color:#ffffff;
}
.footerWrapper {
	width: 100%; 
	float:left;
	padding:10px;
	font-size:12px;
	color:#ffffff;
	text-align:center;
}
.footerWrapper a {
	text-decoration:underline;
	color:#ffffff;
}
/* ****** ****** ****** ****** ****** **
 * ****** Cookie and Terms and Conditions ****** *
** ****** ****** ****** ****** ****** */

.cookieWrapperBtn {
	width: 100%;
	position:absolute;
	left:0px;
	bottom:0px;
	padding:10px;
	font-size:12px;
	color:#ffffff;
	background: url("../images/bgTransparent.png") repeat;
	text-align:center;
	display:none;
}
.cookieWrapperBtn > ul {
	width: 100%;
	display: table-row;
} 
.cookieWrapperBtn > ul > li {
	display: table-cell;
	padding:5px;
	vertical-align: middle;  
	color: #ffffff;
	font-size: 12px;
	text-align: left; 
}  
.cookieWrapperBtn > ul > li:nth-child(2) {
	width:270px;
	text-align:right;
} 
.cookieWrapperBtn > ul > li p { 
	padding:0px;  
	margin:0px;
} 
.cookieWrapperBtn > ul > li a {
	text-decoration:underline;
	color:#ffffff;
}
.cookieWrapperBtn .essentialOnlyBtn, 
.cookieWrapperBtn .acceptBtn { 
	border: 0px;
	border-radius: 20px;
	padding:6px 0px;
	font-size: 14px; 
	text-align:center;
	color:#ffffff;
	min-width:120px;
} 
.cookieWrapperBtn .essentialOnlyBtn {  
	background: #878787;  
} 
.cookieWrapperBtn .acceptBtn {  
	background: #3a9919; 
	margin-left:10px;
} 
/*** **************  ************** ****
		*** Billing Onboarding Form ***
**** **************  ************** ***/

.billingOForm {
	width: 100%;
	display:table; 
}
.billingOForm > ul{
	width: 100%;
	display:table-row; 
}
.billingOForm > ul > li {
	display: table-cell;
	padding:15px;
	vertical-align: top; 
	color: #16406d;
	font-size: 16px;
	text-align: left;
}
.billingOForm > ul > li:nth-child(1) {
	width: 65%;  
} 
.billingOForm > ul > li:nth-child(2) {
	background:#f7f7f7;
} 
.billingOForm > ul > li h2 {
	width: 100%;
	float: left; 
	padding: 10px 0px 10px;
	font-size:24px;  
    color: #0d3968; 
}  
.billingOForm > ul > li .form-control.countryCode {
	width: 20%;
	margin-right: 5%;
}
.billingOForm > ul > li .form-control.phoneNumber {
	width: 75%;
}
/*** **************  ************** ****
		*** Billing Onboarding Form ***
**** **************  ************** ***/

.billingPlanForm {
	width: 100%;
	display:table; 
}
.billingPlanForm.marginBottom { 
	margin-bottom:25px;	
}
.billingPlanForm > ul{
	width: 100%;
	display:table-row; 
}
.billingPlanForm > ul > li {
	display: table-cell;
	padding:10px 10px;
	vertical-align: top; 
	color: #16406d;
	font-size: 16px;
	text-align: left; 
}
.billingPlanForm label { 
    float: left;
    padding:5px 0px 0px;
	margin:0px 10px 0px 0px;
    clear: both;
    color: #0D3968;
    font-size: 18px; 
} 
.billingPlanForm > ul > li.colm50 { 
	width:50%;
}
.billingPlanForm > ul > li.colm33 { 
	width:33.33%;
}
.billingPlanForm > ul > li:nth-child(1) { 
	padding-left:0px;
}
.billingPlanForm > ul > li.marginBottom10px {
	margin-bottom: 20px;
} 
.billingPlanForm > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 30px 10px 0px;
	height: auto;
	background: transparent !important;
	color: #0D3968;
	font-size: 18px;
	border: 0px;
	border-bottom: 1px solid #0D3968;
	border-radius: 0px; 
}
.billingPlanForm > ul > li select.form-control {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background:transparent url("../images/icons/markdownA.svg") no-repeat right center !important;
}
.billingPlanForm > ul > li select::-ms-expand {
	display: none;
} 
.billingPlanForm > ul > li .input-group {
	width: 100%;
	position: relative;
}
.billingPlanForm > ul > li .input-group .requiredField {
	width: 26px;
	height: 26px;
	text-align: center;
	position: absolute;
	top: 5px;
	right: 0px;
	z-index: 3;
	color: #b8c3cf;
	font-size: 28px;
}
.billingPlanForm > ul > li  input.form-control:placeholder-shown, 
.billingPlanForm > ul > li  input.form-control1:placeholder-shown {
    opacity: 1;
}
.billingPlanForm > ul > li .modelNameInpt::placeholder {
	color: #0d3968 !important;
	opacity: 1; /* Firefox */
} 
.billingPlanForm > ul > li .modelNameInpt:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #0d3968;
	opacity: 1;
} 
.billingPlanForm > ul > li .modelNameInpt::-ms-input-placeholder { /* Microsoft Edge */
	color: #0d3968;
	opacity: 1;
}
/*** **************  ************** ****
		*** connectivity Wrapper  ***
**** **************  ************** ***/

.conectivityWrapper {
	width:900px;
	margin:25px auto 0px;
	padding:0px 15px; 
	clear: both; 
	border:1px solid #195d99;
	box-shadow: 8px 8px 8px 0 rgba(0, 0, 0, 0.3), 8px 8px 12px 0 rgba(0, 0, 0, 0.35);
	border-radius:20px;
}   
.conectivityWrapperL {
	width:40%;
	padding:20px 60px 15px 45px;
	float: left; 
} 
.conectivityWrapperL h2 {
	width: 100%;
	float: left; 
	padding: 5px 0px 5px;
	margin-bottom:15px;
	font-size:18px;
	color: #ffffff;
	font-family: 'open_sanssemibold';
	text-align:center;	
}  
.conectivityWrapperR {
	width:60%;
	padding:19px 15px 20px 25px;
	float: left;  
	background: url("../images/icons/divider.png") no-repeat left center;
	background-size: 1px 100%;
	min-height:300px;
	position:relative;
}  
.conectivityWrapperR .mCSB_scrollTools .mCSB_draggerRail{
	background:#000000;
	width:8px;
} 
.conectivityWrapperR .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
	background:#195d99;
	width:8px;
} 
.conectivityWrapperR h2{
	width: 100%;
	float: left; 
	padding: 5px 0px 5px 35px;
	margin-bottom:15px;
	font-size: 18px; 
	font-family: 'open_sanssemibold'; 
	text-transform:uppercase;
}
.conectivityWrapperR h2.connectionFailed{
	background: url("../images/icons/connectionF.svg") no-repeat left center;
	background-size:26px auto;
	color:#f93251;
}
.conectivityWrapperR h2.notRegisterd{
	background: url("../images/icons/notRegisterd.svg") no-repeat left center;
	background-size:26px auto;
	color:#ffbe00;
}
.conectivityWrapperR h2.connectionSuccess{
	background: url("../images/icons/connectionS.svg") no-repeat left center;
	background-size:26px auto;
	color:#3cce82;
}
/* ****** ****** ****** ****** ****** **
 * ****** connectivity ****** *
** ****** ****** ****** ****** ****** */
.connectivitySrlBar {
	width: 100%;
	padding:0px;
	float: left;
	clear: both; 
	height:360px;	
}    
.verifyOtherDeviceBtn {  
	border: 0px;
	border-radius: 24px;
	padding: 10px 25px;
	font-size: 16px;
	background: #195d99;
	color:#ffffff;
	position:absolute;
	right:15px;
	bottom:15px;
}
.connectivitySubList {
	width: 100%;
	float: left;  
}  
.connectivitySubList > ul {
	width: 100%;
	float: left;  
} 
.connectivitySubList > ul > li {
	margin-left:18px;
	list-style-type:disc;
	padding: 5px 0px 25px; 
	color: #ffffff;
	font-size: 16px;
	text-align: left;
}
.resultWillbeD { 
	color: #527193;
	font-size: 18px;
	text-align: center; 
	width:100%;
	position:absolute;
	top:50%;
	left:0px;
	margin-top:-30px;
	font-family: 'open_sanssemibold';
}   
/* ****** ****** ****** ****** ****** **
 * ****** Connectivity Form ****** *
** ****** ****** ****** ****** ****** */

.connectivityForm {
	width: 100%;
	float: left; 
	clear: both;
	padding:40px 0px 0px;
} 
.connectivityForm > ul {
	width: 100%;
	float: left;
	clear: both; 
}
.connectivityForm > ul > li {
	float: left; 
	width:100%;
	padding:10px 0px 10px 0px;
	text-align: center;
} 
.connectivityForm > ul > li:nth-child(1) { 
	padding:0px 0px 10px 0px; 
} 
.connectivityForm > ul > li label {
	width: 100%;
    float: left;
    padding: 0px;
	margin:0px;
    clear: both;
    color: #ffffff;
	font-size: 16px;
	font-family: 'open_sanssemibold';
	text-align:left;
} 
.connectivityForm > ul > li .valueLabel {
	width: 100%;
    float: left;
    padding: 0px 0px 6px;
	margin:0px;
    clear: both;
    color: #ffffff;
	font-size: 16px; 
	text-align:left;
}  
.connectivityForm > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	max-width:340px;
	padding: 5px 0px 5px 0px;
	height: auto;
	background: #0d3968 !important;
	color: #ffffff;
	font-size: 16px;
	border: 0px;
	border-bottom: 1px solid #ffffff;
	border-radius: 0px;
	margin-top:-5px;
}
.connectivityForm > ul > li select.form-control {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: #0d3968 url("../images/icons/markoffA.svg") no-repeat right center !important; 
	padding-right:30px;
}
.connectivityForm > ul > li select.form-control option {
	font-size: 16px;
}
.connectivityForm > ul > li select::-ms-expand {
	display: none;
}
.connectivityForm .submitBtn {
	width: 100%;
	border: 0px;
	border-radius: 24px;
	padding: 10px 0px;
	font-size: 18px;
	background: #195d99;
	color:#ffffff;
	max-width: 340px;
	margin-top:20px;
}
.connectivityForm .numberCount {
	width:36px;
	height:36px; 
	border: 0px;
	border-radius: 50%;
	padding:0px;
	text-align:center;
	line-height:36px;
	font-size: 14px;
	background: #527193;
	color:#000;
	margin:0px 10px;
}
/*** **************  ************** ****
		*** uUploaded Units List ***
**** **************  ************** ***/

.uploadedUnitsList {
	width: 90%;
	display:table; 
	margin:15px 5%;
} 
.uploadedUnitsList > ul{
	width: 100%;
	display:table-row; 
}
.uploadedUnitsList > ul > li {
	display: table-cell;
	padding:10px 10px;
	vertical-align: top; 
	color: #16406d;
	font-size: 16px;
	text-align: center; 
	width:25%;
}
.uploadedUnitsList.samrtTagentrys > ul > li { 
	width:20%;
}
.uploadedUnitsList .registeredDevices,
.uploadedUnitsList .existingDevices,
.uploadedUnitsList .duplicateEntry,
.uploadedUnitsList .wrongEntry,
.uploadedUnitsList .removedEntry { 
    width:120px;
	height:120px;
	border-radius:50%;
    padding:22px 10px;  
    color: #ffffff; 
	text-align:center;
	display:inline-block;
}
.uploadedUnitsList h5{ 
    font-family: 'open_sanssemibold';
	font-size:24px;
	margin-bottom:5px;
} 
.uploadedUnitsList h6{  
	font-size:14px; 
} 
.uploadedUnitsList .registeredDevices { 
    background:#70bf69;
} 
.uploadedUnitsList .existingDevices { 
    background:#52b0f9;
} 
.uploadedUnitsList .duplicateEntry { 
    background:#fd9331;
} 
.uploadedUnitsList .wrongEntry { 
    background:#f93251;
} 
.uploadedUnitsList .removedEntry {
	background:#666666;
} 
/* ****** ****** ****** ****** ****** **
 * ****** Drag file here or Browse ****** *
** ****** ****** ****** ****** ****** */

.dragAndBrowse {
	width:440px;
	margin-top:20px;
	display:table;
	background: #ffffff;
	border: 1px solid #607488;
	padding:10px;
} 
.dragAndBrowse > ul{
	width:100%;
	display:table-row; 
}
.dragAndBrowse > ul > li {
	display:table-cell;  
	vertical-align:middle;
	font-size: 16px;
	text-align:left;
}
.dragAndBrowse > ul > li:nth-child(2) {
	width:110px;
}
.dragAndBrowse .drogAndDrop { 
	padding:3px 30px 3px 35px; 
	background: url("../images/icons/drogAndDrop.png") no-repeat left center;
	background-size:28px 28px; 
	color: #657f9c;
	display:inline-block;
	position:relative;	
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow:hidden;
	max-width:300px; 
	margin-top:3px;
} 
.dragAndBrowse .drogAndDrop .withClear{
   border: 0px; 
   height:24px;
   width: 24px;
   border-radius:12px;
   background:#0d3968 url(../images/icons/cancelA.svg) no-repeat center center;
   background-size: 20px auto;
   position: absolute;
   right:0px;
   top: 3px; 
}
.dragAndBrowse .drogAndDroporBrowse {   
	color: #657f9c; 
	margin-left:40px;
}
.dragAndBrowse .browseFile,
.dragAndBrowse .proceedFile {
	width:100%; 
	border-radius:24px;
	padding: 5px 0px;
	text-align: center; 
	color: #fff;
	background: #195d99;
	display:inline-block;
	border:0px;
}
.dragAndBrowse .browseFileS {
	display: none;
}
/* ****** ****** ****** ****** ****** **
 * ****** RC Date and Time Settings****** *
** ****** ****** ****** ****** ****** */

.rcDateTimeSettings {
	width: 80%;
	display: table;
	margin: 0px 10%;
	background: url("../images/recipecreation/borderBg.png") no-repeat bottom center;
	background-size: 100% 2px;
} 
.rcDateTimeSettings > ul {
	width: 100%;
	display: table-row;
	cursor: pointer;
}
.rcDateTimeSettings > ul > li {
	display: table-cell;
	padding: 5px 10px;
	vertical-align: middle;
	color: #ffffff;
	font-size: 16px;
	text-align: left;
}   
.rcDateTimeSettings > ul > li:nth-child(2) {
	text-align:right; 
}   
.rcDateTimeSettings > ul > li:nth-child(2) > img {
	width: 32px; 
} 

/*** **************  ************** ****
		*** Create Copy Form ***
**** **************  ************** ***/
 
.createCopyForm {
	width: 100%;
	float: left;
	clear: both; 
	padding:10px 15px;
}
.createCopyForm > ul {
	width: 100%;
	float: left;
	clear: both; 
}
.createCopyForm > ul > li {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px 0px 5px;
	margin: 0px;
} 
.createCopyForm > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 30px 10px 0px;
	height: auto;
	background: #fff !important;
	color: #0D3968;
	font-size: 18px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
}  
.createCopyForm > ul > li textarea.form-control { 
	border: 1px solid #6e88a4; 
	padding: 5px;
}
.createCopyForm > ul > li > .charCount {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 0px; 
	text-align: right;
	color: #0D3968;
	font-size:18px;
	font-family: 'open_sanssemibold';
}
.createCopyForm > ul > li > label {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 0px; 
	text-align: left;
	color: #0D3968;
	font-size:18px;
}
/*** **************  ************** ****
		*** Retrival Information Form ***
**** **************  ************** ***/ 
.cbfileretrievalFormP {
	width: 100%;
	float: left;
	clear: both;
	padding: 15px 15px; 
	text-align: left;
	color: #0D3968;
	font-size:18px;
}
.cbfileretrievalForm {
	width: 100%; 
	padding:10px 25px 35px;
	display: table;
}
.cbfileretrievalForm > ul {
	width: 100%;
	display: table-row;
}
.cbfileretrievalForm > ul > li { 
	display: table-cell;
	padding:5px;
	vertical-align: middle;
	color: #ffffff;
	font-size: 16px;
	text-align: left;
} 
.cbfileretrievalForm > ul > li:nth-child(1) {  
	width:140px;
}  
.cbfileretrievalForm > ul > li:nth-child(3) { 
	width:50px; 
	text-align:center;
} 
.cbfileretrievalForm > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 0px 5px 0px;
	height: auto;
	background: #fff !important;
	color: #0D3968;
	font-size: 18px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
}  
.cbfileretrievalForm > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.cbfileretrievalForm > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 30px; 
	color: #0D3968;
	font-size: 16px;
}
.cbfileretrievalForm > ul > li .menuType {
	width: 24px;
	height: 24px;
	display: inline-block;
}
.cbfileretrievalForm > ul > li .menuType > img {
	width: 20px;
}

/*** **************  ************** ****
		*** Retrival Information Process ***
**** **************  ************** ***/  
.cbfileretrievalProcess {
	width: 100%; 
	padding:0px 25px 15px;
	display: table;
}
.cbfileretrievalProcess > ul {
	width: 100%;
	display: table-row;
}
.cbfileretrievalProcess > ul > li { 
	display: table-cell;
	padding:5px;
	vertical-align: middle;
	color: #ffffff;
	font-size: 16px;
	text-align: center;
}  
.cbfileretrievalProcess > ul > li:nth-child(1) {  
	width:30%;
}  
.cbfileretrievalProcess > ul > li:nth-child(2) > img{ 
	max-width:100%;
} 
.cbfileretrievalProcess > ul > li:nth-child(3) { 
	width:30%;
} 
.cbfileretrievalProcess > ul > li:nth-child(3) > img{ 
	width:100%;
} 


/*** **************  ************** ****
		*** Upload Form ***
**** **************  ************** ***/

.uploadFormWrapper {
	width: 100%;
	float: left;
	margin: 0px 0px;
}
.uploadForm { 
	margin: 0px auto;
	clear: both; 
	width: 470px; 
}
.uploadForm > ul {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px 15px 0px;
}
.uploadForm > ul > li {
	width: 100%;
	float: left;
	clear: both;
	padding: 10px 0px 5px;
	margin: 0px;
}  
.uploadForm > ul > li .selectedCB {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 30px 5px 0px; 
	background: #f2f2f2;
	color: #0D3968;
	font-size: 16px; 
	border-bottom: 1px solid #6e88a4;
	font-style:italic;	
}
.uploadForm > ul > li .selectedCB .legacy,
.uploadForm > ul > li .selectedCB .cookbook,
.uploadForm > ul > li .selectedCB .recipe,
.uploadForm > ul > li .selectedCB .settings,
.uploadForm > ul > li .selectedCB .package{  
	padding: 5px 0px 5px 24px;   
	display:inline-block;
}
.uploadForm > ul > li .selectedCB .cookbook{  
	background: url("../images/icons/manualS.png") no-repeat left center;
	background-size:20px 20px; 
}
.uploadForm > ul > li .selectedCB .recipe{  
	background: url("../images/icons/recipeS.png") no-repeat left center;
	background-size:20px 20px; 
}
.uploadForm > ul > li .selectedCB .settings{  
	background: url("../images/icons/settingS.png") no-repeat left center;
	background-size:20px 20px; 
}
.uploadForm > ul > li .selectedCB .package{  
	background: url("../images/icons/manualS.png") no-repeat left center;
	background-size:20px 20px; 
}
.uploadForm > ul > li .selectedCB .legacy{  
	background: url("../images/icons/legacyMenuD.png") no-repeat left center;
	background-size:20px 20px; 
}
.uploadForm > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 30px 10px 0px;
	height: auto;
	background: #f2f2f2 !important;
	color: #0D3968;
	font-size:18px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
}
.uploadForm > ul > li label {
	width: 100%;
	float: left;
	clear: both;
	padding:0px 0px 5px;
	font-size:18px;	
	color: #657f9c; 
	margin:0px;	
	font-family: 'open_sanssemibold';
}
.uploadForm > ul > li .form-control.fileType {
	color: #657f9c; 
	font-style:italic;
	font-size: 16px;
}
.uploadForm > ul > li .form-control.fileType::placeholder {
	color: #657f9c; 
	opacity: 1; /* Firefox */
} 
.uploadForm > ul > li .form-control.fileType:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #657f9c; 
} 
.uploadForm > ul > li .form-control.fileType::-ms-input-placeholder { /* Microsoft Edge */
	color: #657f9c; 
}
.uploadForm > ul > li select.form-control {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: #f2f2f2 url("../images/icons/markdownA.svg") no-repeat right center !important;
}
.uploadForm > ul > li select::-ms-expand {
	display: none;
} 
.uploadForm > ul > li .input-group {
	width: 100%;
	position: relative;
}
.uploadForm > ul > li .input-group .requiredField {
	width: 26px;
	height: 26px;
	text-align: center;
	position: absolute;
	top: 5px;
	right: 0px;
	z-index: 3;
	color: #b8c3cf;
	font-size: 28px;
}
.uploadForm > ul > li  input.form-control:placeholder-shown, 
.uploadForm > ul > li  input.form-control1:placeholder-shown {
    opacity: 1;
}
.uploadForm > ul > li .modelNameInpt::placeholder {
	color: #0d3968 !important;
	opacity: 1; /* Firefox */
} 
.uploadForm > ul > li .modelNameInpt:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #0d3968;
	opacity: 1;
} 
.uploadForm > ul > li .modelNameInpt::-ms-input-placeholder { /* Microsoft Edge */
	color: #0d3968;
	opacity: 1;
} 
.uploadForm > ul > li textarea.form-control {
    border: 1px solid #6e88a4;
    padding: 5px;
}
.uploadForm > ul > li .charCount {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 0px; 
	text-align: right;
	color: #0D3968;
	font-size:18px;
	font-family: 'open_sanssemibold';
}
.uploadForm > ul > li .dragAndBrowse {
	position: relative; 
} 
.uploadForm > ul > li .dragAndBrowse .loading {
	position: absolute;
	width: 60px;
	height: 60px;
	right:-60px;
	top:50%;
	margin: -30px 0px 0px 0px;
	z-index: 9999999;
	background:url("../images/icons/loader.gif") no-repeat center center;
	background-size: 36px 36px; 
} 
.convothermStsLegendSection {
	text-align: center;
	margin: 15px 0px;
	margin: 0px auto;
}
.convothermStsLegendSection > li {
	display: inline-block;
	margin: 10px 50px 5px 0;
	color: #fff;
	font-size: 12px;
	text-align: left;
}
.convothermStsLegendSection > li > span {
	height: 18px;
	width: 18px;
	margin-right: 5px;
	display: inline-block;
	vertical-align: bottom;
}
.convothermStsLegendSection li:nth-of-type(1) span {
	background: #ffc000;
}
.convothermStsLegendSection li:nth-of-type(2) span {
	background: #5b9bd5;
}
.convothermStsLegendSection li:nth-of-type(3) span {
	background: #70ad47;
}
.convothermStsLegendSection li:nth-of-type(4) span {
	background: #a5a5a5;
}
.convothermStsLegendSection li:nth-of-type(5) span {
	background: #ed7d31;
}
.convothermStsLegendSection li:nth-of-type(6) span {
	background: #4472c4;
}

/* ****** ****** ****** ****** ****** **
 * ****** Garland Recipe Creation Form ****** *
** ****** ****** ****** ****** ****** */ 
.rcSaveForm .rcCNameDiv {
	width: 70%;
	float: left;
	padding: 5px 0px;
}
.rcSaveForm .rcCNameDiv.productRecgnForm {
	width: 80%; 
}
.rcSaveForm .rcCimportImageDiv {
	width: 30%;
	float: right;
	padding: 15px 10px;
} 
.rcSaveForm .rcCNameDiv label,
.rcSaveForm .rcCimportImageDiv label {
	width: 100%;
	float: left;
	clear: both;
	padding: 15px 5px 5px 0px;
	color: #fff;
	font-size: 16px; 
}
.rcSaveForm .rcCimportImageDiv label { 
	text-align: center;
} 
.rcSaveForm .rcCNameDiv label { 
	text-align: left;
}
.rcSaveForm .rcCNameDiv .form-control { 
	padding: 5px 5px 5px 5px;
	height: auto;
	background: #000;
	color: #fff;
	font-size: 16px;
	border: 0px;
	border-radius: 0px;
	caret-color: #0097ff;
}
/*** **************  ************** ****
		*** Garland  RC ***
**** **************  ************** ***/
.rcSaveForm .rcCreateFrm {
	width: 100%;
	display: table;
	margin-bottom:10px; 
}
.rcSaveForm .rcCreateFrm.withBox { 
	padding:15px;
	border:1px solid #3a3a3a;
	background:#2c2c2c;
	position:relative;
	margin:25px 0px;
}
.rcSaveForm .rcCreateFrm.withBox:last-child {  
	margin-bottom:65px;
}
.rcSaveForm .rcCreateFrm.titleDiv { 
	padding:0px; 
	margin:0px;
}
.rcSaveForm .rcCreateFrm.titleDiv > ul > li { 
	padding:0px; 
	margin:0px;
}
.rcSaveForm .rcCreateFrm .rcRowCount {
	background: #ffffff;
	border-radius: 50%;
	color: #000;
	font-size: 12px;
	width: 24px;
	height: 24px;
	padding: 2px 0px;
	text-align: center; 
	position:absolute;
	top:-10px;
	left:-10px;
}
.rcSaveForm .rcCreateFrm .deleteRowButton.deleteActive { 
	display: block; 
}
.rcSaveForm .rcCreateFrm .deleteRowButton {
	width: 28px;
	height: 28px;
	border-radius:50%;
	display: none;
	position: absolute;
	top: -10px;
	right: -10px;
	background: url("../images/ANTransparentW.png") repeat; 
	border:1px solid #000; 
}
.rcSaveForm .rcCreateFrm .deleteRowButton:before { 
	background: url("../images/icons/trashA.svg") no-repeat center center;
	background-size: 18px 18px;
	position: absolute;
	top:4px;
	left: 4px; 
	width:18px;
	height:18px;
	content: " "; 
	z-index: 1;
}
.rcSaveForm .rcCreateFrm > ul {
	width: 100%;
	display: table-row;
} 
.rcSaveForm .rcCreateFrm > ul > li {
	display: table-cell;
	padding: 0px 10px 10px 0px;
	vertical-align: top; 
	color: #16406d;
	font-size: 16px;
	text-align: left;
}
.rcSaveForm .rcCreateFrm.withBox > ul > li { 
	width:25%;
}  
.rcSaveForm .rcCreateFrm.withBox > ul > li:nth-child(1) { 
	width:22%;
} 
.rcSaveForm .rcCreateFrm.withBox > ul > li:nth-child(2) { 
	width:27%;
}  
.rcSaveForm .rcCreateFrm.withBox > ul > li:nth-child(4) { 
	padding-right:0px;
	width:26%;
} 
.rcSaveForm .rcCreateFrm.withBox > ul > li > label { 
	font-size:14px;
}
.rcSaveForm .rcCreateFrm.withBox > ul > li .form-control{ 
	font-size:14px; 
}
.rcSaveForm .rcCreateFrm.withBox > ul > li input[type="number"].form-control{ 
	text-align:center;
}
.rcSaveForm .rcCreateFrm > ul > li > label {
	width:100%;
	float: left; 
	padding: 5px 0px 0px 0px;
	color: #fff;
	font-size: 15px;
	text-align: left;
}
.rcSaveForm .rcCreateFrm > ul > li .form-control {
	width: 100%;
	float: left;
	clear: inherit;
	padding: 5px 5px 5px 5px;
	height: auto;
	background: #000;
	color: #fff;
	font-size: 16px;
	border: 0px;
	border-radius: 0px;
	caret-color: #0097ff;
}
.rcSaveForm .rcCreateFrm > ul > li .setValDropdown { 
	float: left; 
}
.rcSaveForm .rcCreateFrm > ul > li .setValDropdown .dropdown-toggle{
	width: 100%;
	float: left; 
	padding: 5px 5px 5px 5px; 
	background: #000;
	color: #fff;
	font-size: 16px;
	border: 0px;
	border-radius: 0px; 
}
.rcSaveForm .rcCreateFrm > ul > li .setValDropdown .dropdown-menu{ 
	padding: 5px 5px 5px 5px; 
	background: #000;
	color: #fff;
	font-size: 14px; 
	max-width:130px;
	min-width:130px;
	left:0px !important;
}
.rcSaveForm .rcCreateFrm > ul > li .setValDropdown .dropdown-menu.topView{ 
	transform: translate3d(0px, -88px, 0px) !important;
}
.rcSaveForm .rcCreateFrm > ul > li .setValDropdown .dropdown-item.active, 
.rcSaveForm .rcCreateFrm > ul > li .setValDropdown .dropdown-item:active{  
	background: #313231; 
}
.rcSaveForm .rcCreateFrm > ul > li .setValDropdown .dropdown-item:focus, 
.rcSaveForm .rcCreateFrm > ul > li .setValDropdown .dropdown-item:hover{  
	background: #313231; 
}
.rcSaveForm .rcCreateFrm > ul > li .setValDropdown .dropdown-item{  
	color: #fff; 
	padding:6px 0px;
}
.rcSaveForm .rcCreateFrm > ul > li .setValDropdown .setValD { 
	border-bottom:1px solid #6f6f6e;
	float:left;
	width:100%;
	padding:3px 0px;
}
.rcSaveForm .rcCreateFrm > ul > li .setValDropdown .form-control { 
	background: #545454;
	color: #fff; 
}
.rcSaveForm .rcCreateFrm > ul > li .setValDropdown .customCheckbox { 
	margin:0px 0px 0px 10px;
	float:left;
	width:28px;
} 
.rcSaveForm .rcCreateFrm > ul > li .setValDropdown .customCheckbox input[type=checkbox] + label {
	background: url("../images/icons/radioButtonWChecked.png") no-repeat left center;
	background-size: 24px 24px;
}
.rcSaveForm .rcCreateFrm > ul > li .setValDropdown .customCheckbox input[type=checkbox] + label,
.rcSaveForm .rcCreateFrm > ul > li .setValDropdown .customCheckbox input[type=checkbox] + i + label,
.rcSaveForm .rcCreateFrm > ul > li .setValDropdown .customCheckbox input[type=checkbox] + input + label {
	background: url("../images/icons/radioButtonWChecked.png") no-repeat left center;
	background-size: 24px 24px;
}
.rcSaveForm .rcCreateFrm > ul > li .setValDropdown .customCheckbox input[type=checkbox]:checked + label,
.rcSaveForm .rcCreateFrm > ul > li .setValDropdown .customCheckbox input[type=checkbox]:checked + i + label,
.rcSaveForm .rcCreateFrm > ul > li .setValDropdown .customCheckbox input[type=checkbox]:checked + input + label {
	background: url("../images/icons/radioButtonGGChecked.png") no-repeat left center;
	background-size: 24px 24px;
}


.rcSaveForm .rcCreateFrm > ul > li.cookingMethodology{
	width:50%; 
}  
.rcSaveForm .rcCreateFrm > ul > li.form5colm {
	width:20%; 
}  
.rcSaveForm .rcCreateFrm > ul > li.form3colm {
	width:32%;  
}   
.rcSaveForm .rcCreateFrm > ul > li.form31colm {
	width:31%;  
}  
.rcSaveForm .rcCreateFrm > ul > li.formIncolm {
	width:38%;  
}  
.rcSaveForm .rcCreateFrm > ul > li.form3colm:last-child {
	padding-right:0px;
}
.rcSaveForm .rcCreateFrm > ul > li input[type="number"].form-control{ 
	text-align:center;
}
.rcSaveForm .rcCreateFrm > ul > li .form-control.minWidth{ 
	width:60px; 
}
.rcSaveForm .rcCreateFrm > ul > li .form-control.normalWidth{ 
	width:80px; 
}
.rcSaveForm .rcCreateFrm > ul > li .form-control.mediumWidth{ 
	width:165px; 
}
.rcSaveForm .rcCreateFrm > ul > li > select.form-control {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: #000 url("../images/icons/markoffA.svg") no-repeat right center !important;
}
.rcSaveForm .rcCreateFrm > ul > li > select::-ms-expand {
	display: none;
}

.rcSaveForm .rcCimportImageDiv .importImage {
	width: 100%;
	float: left;
	padding: 10px;
	text-align: center;
	background: #4a4747;
	border:1px dashed #ffffff;
	position:relative;
}
.rcSaveForm .rcCimportImageDiv .importImage .menuDrogDroporBrowse {
	width: 100%;
	float: left;
	padding: 0px 0px 0px;
	text-align: center;
	font-size: 18px;
	color: #1b4470;
}
.rcSaveForm .rcCimportImageDiv .importImage .menuBrowse {
	width: 100%;
	float: left;
	padding: 0px;
	height: 120px;
	line-height: 120px;
	text-align: center;
	font-size: 18px;
	color: #fff;
	cursor: pointer;
}
.rcSaveForm .rcCimportImageDiv .importImage .menuBrowse > img {
	max-height: 120px;
	max-width: 98%;
	display: inline-block;
}
.rcSaveForm .rcCimportImageDiv .importImage .menuUpdateFile {
	display: none;
} 
.rcSaveForm .rcCimportImageDiv.editActive .importImage .cancelButton {
	display: block;
}
.rcSaveForm .rcCimportImageDiv .importImage .cancelButton {
	width: 24px;
	height: 24px;
	border-radius:50%;
	display: none;
	position: absolute;
	top: -10px;
	right: -10px;
	background: url("../images/bgTransparent.png") repeat; 
	border:1px solid #ffffff; 
}
.rcSaveForm .rcCimportImageDiv .importImage .cancelButton:before { 
	background: url("../images/icons/trashA.svg") no-repeat center center;
	background-size: 16px 16px;
	position: absolute;
	top:3px;
	left: 3px; 
	width:18px;
	height:18px;
	content: " "; 
	z-index: 1;
}
/* ****** ****** ****** ****** ****** **
 * ****** Garland Recipe Creation Table ****** *
** ****** ****** ****** ****** ****** */

.productRecgnTable {
	width: 100%;
	display: table;
	padding: 0px 15px;
}
.productRecgnTable > ul {
	width: 100%;
	display: table-row; 
} 
.productRecgnTable > ul.tHead > li { 
	border-top: 1px solid #707070;
	border-bottom: 1px solid #707070;
	background: #434343;
	padding:5px 10px;
	font-size:14px;
}  
.productRecgnTable > ul.tHead > li > span { 
	font-size:12px;
	line-height:16px;
	display:block;
}  
.productRecgnTable > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #707070;
	color: #ffffff;
	font-size: 16px;
	text-align: left;
} 
.productRecgnTable > ul > li:nth-child(1) {
	width:70px; 
}  
.productRecgnTable > ul > li:nth-child(2) {
	text-align: left;
	padding-left: 0px; 
}  
.productRecgnTable > ul > li:nth-child(3),
.productRecgnTable > ul > li:nth-child(4) {
	width:140px;
}   
.productRecgnTable > ul > li:nth-child(5) {
	width:50px;
	padding-left:0px;
	padding-right:0px;
	text-align:center;
}  
.productRecgnTable > ul > li .recipeIcon {
	width: 48px;
	display: inline-block; 
	text-align: center;
	height: 48px;
	line-height: 48px; 
	overflow: hidden;
	vertical-align: bottom; 
	border:1px dashed #ffffff;
	padding:3px;
}
.productRecgnTable > ul > li .recipeIcon > img {
	max-height: 48px;
	max-width: 48px;
	width: 100%;
	display: inline-block;
	vertical-align: top;
}
.productRecgnTable > ul > li .deleteButton {
	width: 36px;
	height: 36px;
	background: url("../images/recipecreation/deleteButton.png") no-repeat center center;
	background-size: 36px 36px;
} 
.productRecgnTable > ul > li .form-control {
	padding: 5px 5px 5px 5px;
	height: auto;
	background: #000;
	color: #ffffff;
	font-size: 20px;
	border: 0px;
	border-radius: 0px;
	caret-color: #0097ff;
	text-align:center;
}   
/* ****** ****** ****** ****** ****** **
 * ****** Select Model View ****** *
** ****** ****** ****** ****** ****** */

.selectModelGrid {
	width: 100%;
	padding: 0px 0px;
	float: left;
	clear: both;
}
.selectModelGrid > ul {
	padding: 0px;
	margin: 0px -5px;
	clear: both;
	width: 100%;
}
.selectModelGrid > ul > li {
	width:210px;
	margin: 0px 5px 5px;
	padding: 10px 5px;
	float: left; 
	cursor: pointer;
	background: #7e9fc3;
	border-radius:12px;  
}
.selectModelGrid > ul > li .selectDeviceGridW{
	width:210px;   
	height:120px;
	display:table-cell;
	vertical-align:middle;
}
.selectModelGrid > ul > li.selectedList {
	background: #0d3968;
}
.selectModelGrid > ul > li.selectedList h5 { 
	color: #fff;
}
.selectModelGrid > ul > li.userNotificationInfo {
	opacity: 0.5;
	cursor: pointer;
}
.selectModelGrid > ul > li h5 {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 0px 0px;
	font-size: 14px;
	color: #ffffff;
	text-align: center;  
} 
.selectModelGrid > ul > li span {
	width: 100%;
	float: left;
	clear: both; 
	text-align: center;
	min-height: 64px; 
}
.selectModelGrid > ul > li span img {
	width:64px;
	max-height: 100px;
	clear: both;
}
/*** **************  ************** ****
		*** New Form ***
**** **************  ************** ***/

.formWrapper {
	width: 100%;
	float: left;
	padding:15px 25px 10px;
	background:#ffffff;
	border:1px solid #dcdcdc;
	border-radius:16px;
	margin-top:15px;
}
.formWrapper > h5 {
	width: 100%;
	float: left;
	padding: 0px 0px 15px 0px;
	clear: both;
	color: #042546;
	font-size: 16px;
	font-weight: normal;
}
.formWrapperL {
	width: 45%; 
	float: left; 
	max-width:500px;
}
.formWrapperR {
	width: 45%;
	max-width:500px;
	float: right;  
}   
/* ****** ****** ****** ****** ****** **
 * ****** Form List ****** *
** ****** ****** ****** ****** ****** */

.formList {
	width: 100%;
	float: left;
	padding: 0px 0px 5px 0px;
	clear: both;
} 
.formList > ul {
	width: 100%;
	float: left;
	clear: both; 
}
.formList > ul > li {
	float: left; 
	width:100%;
	padding: 0px 0px 10px 0px;
	text-align: center;
} 
.formList > ul > li label {
	width: 100%;
    float: left;
    padding: 0px 0px 5px;
	margin:0px;
    clear: both;
    color: #0d3968;
	font-size: 16px; 
	text-align:left;
	font-family: 'open_sanssemibold';
}  
.formList > ul > li label.stPermissionsD { 
	padding: 0px 25px 0px 0px;
	width:auto;
    float: inherit; 
	background: url("../images/icons/lock.svg") no-repeat right center;
	background-size: 20px auto;  
	margin-bottom:5px;
}
.formList > ul > li .form-control {
	width: 100%;
	float: left;
	clear: both;
	max-width:340px;
	padding: 5px 0px 5px 0px;
	height: auto;
	background: #f2f2f2 !important;
	color: #0D3968;
	font-size: 16px;
	border: 0px;
	border-bottom: 1px solid #6e88a4;
	border-radius: 0px;
}
.formList > ul > li select.form-control {
	-ms-appearance: none;
	/* IE */
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
	background: #f2f2f2 url("../images/icons/markdownA.svg") no-repeat right center !important;
	width: 100%;
}
.formList > ul > li select.form-control option {
	font-size: 16px;
}
.formList > ul > li select::-ms-expand {
	display: none;
}
.formList > ul > li textarea.form-control { 
	border: 1px solid #6e88a4; 
	padding: 5px;
	max-width:100%;
}
.formWrapper .formList > ul > li select.form-control { 
	background: #ffffff url("../images/icons/markdownA.svg") no-repeat right center !important; 
}
.formWrapper .formList > ul > li .form-control { 
	background: #ffffff !important; 
}
.formList > ul > li > .charCount {
	width: 100%;
	max-width:100%;
	float: left;
	clear: both;
	padding: 5px 0px; 
	text-align: right;
	color: #0D3968;
	font-size:16px;
	font-family: 'open_sanssemibold';
}

/*** **************  ************** ****
		*** Pagination With Goto ***
**** **************  ************** ***/

.paginationWgoto {
	width: 100%;
	float: left;
	clear: both;
	padding:8px 10px;
	text-align: center;
	border-top: 1px solid #7e8690;  
} 
.paginationWgoto .gotoPage{
	display:inline-block;  
	width:110px;
	margin-left:15px;
	color: #0d3968;
}    
.paginationWgoto a,
.paginationWgoto span {
	color: #0d3968;
	padding:2px 8px;
	margin:0px 3px;
} 
.paginationWgoto a:active,
.paginationWgoto a:hover,
.paginationWgoto a.active {
	color: #ffffff;
	background:#0d3968;
	padding:2px 8px;
	border-radius:4px;
} 
.paginationWgoto .form-control {
	width:50px;
	float: inherit;
	display:inline-block;	
	padding: 2px 8px;
	height: auto;
	background: #ffffff !important;
	color: #0D3968;
	font-size: 16px; 
	border-radius: 6px; 
	border: 1px solid #6e88a4; 
	text-align:center;	
}
.paginationWgoto .prevBtn,
.paginationWgoto .nextBtn {
	display: inline-block;
	width: 26px;
	line-height:26px;
	height:26px;
	border:0px;
	margin-top:-2px;
}
.paginationWgoto .prevBtn {
	background: url("../images/icons/arrowr1A.svg") no-repeat center center;
	background-size: 22px 22px;
}
.paginationWgoto .nextBtn {
	background: url("../images/icons/arrow1B.svg") no-repeat center center;
	background-size: 22px 22px;
}
/*** **************  ************** ****
		*** Pagination Footer ***
**** **************  ************** ***/
.paginationDWgoto {
	width: 100%;
	float: left;
	clear: both;
	padding:8px 10px;
	text-align: center;
	background: #1f2124; 
} 
.paginationDWgoto .gotoPage{
	display:inline-block;  
	width:110px;
	margin-left:15px;
	color: #ffffff;
}    
.paginationDWgoto a,
.paginationDWgoto span {
	color: #ffffff;
	padding:2px 8px;
	margin:0px 3px;
} 
.paginationDWgoto a:active,
.paginationDWgoto a:hover,
.paginationDWgoto a.active {
	color: #ffffff;
	background:#195d99;
	padding:2px 8px;
	border-radius:4px;
} 
.paginationDWgoto .form-control {
	width:50px;
	float: inherit;
	display:inline-block;	
	padding: 2px 8px;
	height: auto;
	background: #ffffff !important;
	color: #0D3968;
	font-size: 16px; 
	border-radius: 6px; 
	border: 1px solid #6e88a4; 
	text-align:center;	
}
.paginationDWgoto .prevBtn,
.paginationDWgoto .nextBtn {
	display: inline-block;
	width: 26px;
	line-height:26px;
	height:26px;
	border:0px;
	margin-top:-2px;
} 
.paginationDWgoto .prevBtn {
	background: url("../images/icons/arrowLA.svg") no-repeat center center;
	background-size: 24px 24px;
}
.paginationDWgoto .nextBtn {
	background: url("../images/icons/arrowRA.svg") no-repeat center center;
	background-size: 24px 24px;
}
/*** **************  ************** ****
		*** Tracking Details ***
**** **************  ************** ***/
.trackingDetails {
	width: 100%;
	display: table;  
}
.trackingDetails > ul {
	width: 100%;
	display: table-row; 
} 
.trackingDetails > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 14px;
	text-align: left;
}  
.trackingDetails > ul.tBody:nth-child(odd) {
	background: #e9eaeb;
	border-top: 1px solid #aab4be;
	border-bottom: 1px solid #ffffff;
}
.trackingDetails > ul.tBody:nth-child(even) {
	background: #f2f2f2;
	border-top: 1px solid #63778a;
	border-bottom: 1px solid #ffffff;
}  
.trackingDetails > ul > li:nth-child(1) {
	color: #16406d; 
	width:180px;
} 
.trackingDetails > ul > li span { 
	padding: 0px 10px 0px;
	color: #000;
}
/* ****** ****** ****** ****** ****** **
 * ****** Smart Tag Location Table ****** *
** ****** ****** ****** ****** ****** */

.stLocationTable {
	width: 100%;
	display: table;
}
.stLocationTable > ul {
	width: 100%;
	display: table-row;
}
.stLocationTable > ul.tHead > li {
	color: #16406d;
	border-bottom: 1px solid #63778a;
	background: #e9eaeb;
} 
.stLocationTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
} 
.stLocationTable > ul.tHead > li:first-child {
    border-left: 0px;
    border-bottom: 1px solid #63778a;
	padding-left:25px;
}  
.stLocationTable > ul > li:nth-child(1) {  
	width:290px; 
}  	
.tagTypeUnit,
.tagTypeLocation{ 
	margin-left:12px;
	padding:0px 10px 0px 10px; 
	background:#0d3968;
	color:#fff;
	text-decoration:none; 	
	border-radius:4px;
	position:relative;	
	display: inline-flex; 
} 	
.tagTypeUnit.dragDiv,
.tagTypeLocation.dragDiv{  
	opacity:0.5; 
}	
.tagTypeUnit:before,
.tagTypeLocation:before{
	content:"";
	display: block;
	height: 24px;
	width: 24px;
	background-color: inherit;
	border: inherit;
	position: absolute;
	left: -10px;
    top: calc(50% - 12px);
	clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
	transform: rotate(45deg);
	border-radius: 0.20em 0 0.20em 0.35em;
}	
.tagTypeUnit:after,
.tagTypeLocation:after{
	content:"";
	position:absolute;
	top:50%;
	left:0;
	float:left;
	width:6px;
	height:6px;
	margin-top:-3px;
	-moz-border-radius:3px;
	-webkit-border-radius:3px;
	border-radius:3px;
	background:#fff;
	-moz-box-shadow:-1px -1px 2px #004977;
	-webkit-box-shadow:-1px -1px 2px #004977;
	box-shadow:-1px -1px 2px #004977;
}  
.tagTypeUnit span,
.tagTypeLocation span{ 
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow:hidden;
	max-width:245px; 
	padding:5px 0px 5px 25px;
	display:block;
}  
.tagTypeUnit span{  
	background:#0d3968 url("../images/icons/stUnitTag.svg") no-repeat left center;
	background-size: 20px 20px; 
}    
.tagTypeLocation span{  
	background:#0d3968 url("../images/icons/stLocationTag.svg") no-repeat left center;
	background-size: 20px 20px; 
}
.stLocationTable > ul > li:nth-child(2) p { 
	width:440px; 
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin:0px;
	padding:0px;	
}   
.stLocationTable > ul > li:nth-child(3) {
	width: 160px; 
	text-align:center;
} 
.stLocationTable > ul > li:nth-child(4) {
	width: 120px;
	text-align:center;
}  
.stLocationTable > ul > li:nth-child(5) {
	width: 160px;
	text-align:center;
} 
.stLocationTable > ul > li:nth-child(6) {
	width: 58px;
	text-align: center;
} 
.stLocationTable > ul > li .customCheckbox {
	margin: 0px;
	display: block;
}
.stLocationTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
} 
.stLocationTable > ul.tBody > li .stLocationSM {
	display: none;  
	background:#ffffff;
	padding:5px;
	font-size:12px; 
} 
.stLocationTable > ul > li .stLocationDetails {
	background: url("../images/icons/infoReport.svg") no-repeat center center;
	background-size: 24px auto;
}
.stLocationTable > ul > li .stPrms,
.stLocationTable > ul > li .stEditPrms,
.stLocationTable > ul > li .stViewPrms,
.stLocationTable > ul > li .stEditDPrms { 
	width:24px;
	height:24px;
	display:inline-block;
}
.stLocationTable > ul > li .stPrms {
	background: url("../images/icons/lockedB.svg") no-repeat center center;
	background-size: 24px auto; 
}
.stLocationTable > ul > li .stEditPrms {
	background: url("../images/icons/penB.svg") no-repeat center center;
	background-size: 24px auto; 
}
.stLocationTable > ul > li .stViewPrms {
	background: url("../images/icons/SidetreeDA.svg") no-repeat center center;
	background-size: 24px auto; 
}
.stLocationTable > ul > li .stEditPrms.disabled,
.stLocationTable > ul > li .stEditDPrms {
	background: url("../images/icons/penD.svg") no-repeat center center;
	background-size: 24px auto; 
	pointer-events: none;
	cursor: default;
}
/* ****** ****** ****** ****** ****** **
 * ****** Smart Tag Uplaod Form ****** *
** ****** ****** ****** ****** ****** */

.STUplaodForm {
	width:1000px;
	margin:25px auto 0px;
	display:table; 
	text-align:center;
} 
.STUplaodForm .dragAndBrowse{
	margin:0px;
	padding:5px;
	width:100%;
} 
.STUplaodForm > ul {
	width: 100%;
	display:table-row; 
}
.STUplaodForm > ul > li {
	display:table-cell;  
	color: #0D3968;
	padding:10px 0px 5px;
	text-align: center;
	vertical-align:middle;
}
.STUplaodForm > ul > li:nth-child(1) { 
	width:45%; 
}  
.STUplaodForm > ul > li:nth-child(2) { 
	padding-right:35px;
	padding-top:20px;
}   
.STUplaodForm > ul > li:nth-child(3) { 
	width:45%;  
}  
.STUplaodForm > ul > li .selectORdrag {  
	padding: 5px 0px;  
	color: #0D3968;
	font-size:16px;
	font-family: 'open_sanssemibold';
} 
.STUplaodForm > ul > li p {  
	padding:0px 0px 5px; 
}
.STUplaodForm .selectBtn {
	width:360px; 
	border-radius:24px;
	padding: 8px 0px;
	text-align: center; 
	color: #fff;
	background: #195d99; 
	border:0px;
	margin: 0px auto 10px; 
}
.STUplaodForm > ul > li .getListUnits,
.STUplaodForm > ul > li .getOrgList {   
	display:inline-block;
	padding:10px 0px 10px 30px;
	color: #0d3968;  
} 
.STUplaodForm > ul > li .getListUnits {   
	background: url("../images/icons/download.png") no-repeat left center;
	background-size: 20px 20px; 
} 
.STUplaodForm > ul > li .getOrgList {   
	background: url("../images/icons/SidetreeDA.svg") no-repeat left center;
	background-size: 20px 20px; 
} 
.STUplaodForm > ul > li .floatRight {
	float: right;
}
.STUplaodForm > ul > li .floatLeft {
	float: left;
}

.STUplaodForm > ul > li .getListUnits:hover,
.STUplaodForm > ul > li .getOrgList:hover {  
	color: #000;  
}
.STUplaodForm > ul > li .getListUnits > span,
.STUplaodForm > ul > li .getOrgList > span {  
	font-family: 'open_sanssemibold';
	margin-right:5px;
}
/* ****** ****** ****** ****** ****** **
 * ****** Smart tag Details O ****** *
** ****** ****** ****** ****** ****** */
    
.STmodalDetail {
	width: 100%;
	display: table;
	margin-bottom:15px;
}
.STmodalDetail > ul {
	width: 100%;
	display: table-row;
}  
.STmodalDetail > ul > li {
	display: table-cell;
	padding: 5px 0px;
	vertical-align: top; 
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
}    
.STmodalDetail > ul > li:nth-child(1) {
	font-family: 'open_sanssemibold';
	width:130px;
}   
.tagsList {
	float: left; 
	width:100%;
	max-height: 160px; 
    overflow-y: auto;	
}   
.STmodalDetail > ul > li .tagTypeUnit,
.STmodalDetail > ul > li .tagTypeLocation,
.tagsList .tagTypeUnit, 
.tagsList .tagTypeLocation{
	margin-right:5px;
	margin-bottom:5px;	
}    
/* ****** ****** ****** ****** ****** **
 * ****** Unit List Table ****** *
** ****** ****** ****** ****** ****** */

.STComfmtList {
	width: 100%;
	display: table;
}
.STComfmtList > ul {
	width: 100%;
	display: table-row;
}
.STComfmtList > ul.tHead > li {
	color: #16406d;
	border-top: 1px solid #63778a;
	border-bottom: 1px solid #63778a;
	background: #e9eaeb;
} 
.STComfmtList > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
} 
.STComfmtList > ul.tHead > li:nth-child(1) {
    border-left: 0px;
    border-bottom: 1px solid #63778a;
}
.STComfmtList > ul > li:nth-child(1) {
	width: 54px;
	text-align: center; 
	color: #16406d;
	border-bottom: 1px solid #F2F2F2;
	padding-left: 5px;
	padding-right: 5px;
}
.STComfmtList > ul > li:nth-child(2) {
	padding-left: 0px; 
}
.STComfmtList > ul > li:nth-child(2),
.STComfmtList > ul > li:nth-child(3) {
	width: 220px;
}  
.STComfmtList > ul > li:nth-child(4),
.STComfmtList > ul > li:nth-child(5) {
	width: 15%;
}   
.STComfmtList > ul > li:nth-child(7) {
	width: 240px;
	text-align: left;
} 
.STComfmtList > ul.tBody > li .STstatusSM {
	display: none;
	width: 100%;
	float: left;
}
.STComfmtList > ul.tBody > li .unitsListSM,
.STComfmtList > ul.tBody > li .unitsSM {
	display: none;
	width: 100%;
	float: left;
} 
.STComfmtList > ul.tBody > li .registeredDevices {
	color:#70bf69;
}
.STComfmtList > ul.tBody > li .existingDevices {
	color:#70bf69;
}
.STComfmtList > ul.tBody > li .duplicateEntry {
	color:#950000;
}
.STComfmtList > ul.tBody > li .wrongEntry {
	color:#950000;
}
.STComfmtList > ul.tBody > li .removedEntry {
	color:#666666;
}
.STComfmtList > ul.tBody > li .notAdded {
	color:#666666;
}
/* ****** ****** ****** ****** ****** **
 * ****** Use Tags for unit selection ****** *
** ****** ****** ****** ****** ****** */ 
.tableSerarchBar {  
	width: 100%; 
	float:left;
	margin:0px 0px;
	position:relative;
} 
.ToggleSerarchBar {  
	width: 240px; 
	float:left;
	margin:10px 10px;
	position:relative;
}
.tagsSearch { 
	border-radius: 22px; 
	border: 1px solid #7f95ad;
	width: 100%;
	font-size: 16px;
	color: #0D3968; 
	background: #ffffff;
	background-size: 24px auto;
	padding:6px 6px 6px 35px;
	float:left;  
}
.tableSerarchBar:after,
.ToggleSerarchBar:after{  
	content:" ";
	width: 24px; 
	height:24px;
	position:absolute;
	top:8px;
	left:10px;
	background:url("../images/icons/searchD.svg") no-repeat left center;
	background-size: 24px auto;  
}
.smartTagsList {
	width: 520px;
	margin: 20px auto; 
	float:right; 
	border: 1px solid #63778a;
}
.smartTagsAM {
	width: 100%;
	float:left; 
	float:left; 
	border: 1px solid #000;
}
/* ****** ****** ****** ****** ****** **
 * ****** Smart Tag Units Table ****** *
** ****** ****** ****** ****** ****** */
  
.STunitsTable {
	width: 100%;
	display: table;
}
.STunitsTable > ul {
	width: 100%;
	display: table-row;
}
.STunitsTable > ul.tHead > li {
	color: #16406d;
	border-bottom:0px;
	background: #e9eaeb;
} 
.STunitsTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
	background:#ffffff;
} 
.STunitsTable.darkTheme > ul.tHead > li { 
	background: #2d3034;
	border-bottom: 1px solid #4a4a4a;
    color: #ffffff;
} 
.STunitsTable.darkTheme > ul > li { 
	border-bottom: 1px solid #000;
	color: #ffffff; 
	background:#2d3034;
	font-size:14px;
}  
.STunitsTable.darkTheme > ul > li .form-control {  
	font-size:14px;
}   
.STunitsTable > ul.activeRow > li { 
	background:#aac8e3;
	border-bottom: 1px solid #63778a;
}    
.STunitsTable.darkTheme > ul.activeRow > li { 
	background:#616366;
	border-bottom: 1px solid #000;
}    
.STunitsTable.darkTheme .tagTypeUnit, 
.STunitsTable.darkTheme .tagTypeLocation { 
	font-size:14px;
	background:#616366;
} 
.STunitsTable.darkTheme .tagTypeUnit span{  
	background:#616366 url("../images/icons/stUnitTag.svg") no-repeat left center;
	background-size: 20px 20px; 
}    
.STunitsTable.darkTheme .tagTypeLocation span{  
	background:#616366 url("../images/icons/stLocationTag.svg") no-repeat left center;
	background-size: 20px 20px; 
}  
.STunitsTable.darkTheme > ul.activeRow > li .tagTypeUnit, 
.STunitsTable.darkTheme > ul.activeRow > li .tagTypeLocation{ 
	font-size:14px;
	background:#0d3968;
} 
.STunitsTable.darkTheme > ul.activeRow > li .tagTypeUnit span{  
	background:#0d3968 url("../images/icons/stUnitTag.svg") no-repeat left center;
	background-size: 20px 20px; 
}    
.STunitsTable.darkTheme > ul.activeRow > li .tagTypeLocation span{  
	background:#0d3968 url("../images/icons/stLocationTag.svg") no-repeat left center;
	background-size: 20px 20px; 
} 
.STunitsTable > ul > li:nth-child(2) {
	width:70px;
	text-align:center;
	padding-left:5px;
	padding-right:5px;
} 
.STunitsTable > ul > li:nth-child(3) {
	width: 78px;
	text-align: center;
}
.STunitsTable > ul.tBody > li:nth-child(3) {
	width: 66px;
} 
.STunitsTable > ul > li .stUnitsDetails {
	background: url("../images/icons/infoReport.svg") no-repeat center center;
	background-size: 24px auto;
	width:24px;
	height:24px;
}
.STunitsTable.darkTheme > ul > li .stUnitsDetails {
	background: url("../images/icons/infoReportW.svg") no-repeat center center;
	background-size: 24px auto; 
}
.STunitsTable > ul > li .customCheckbox {
	margin:5px 0px 0px;
	display: inline-block;
}
.STunitsTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
} 
.STunitsTable.darkTheme > ul > li .customCheckbox input[type=checkbox] + label {
	background: url("../images/icons/checkOffA.svg") no-repeat left center;
	background-size: 24px 24px;
}
.STunitsTable.darkTheme > ul > li .customCheckbox input[type=checkbox] + label,
.STunitsTable.darkTheme > ul > li .customCheckbox input[type=checkbox] + i + label,
.STunitsTable.darkTheme > ul > li .customCheckbox input[type=checkbox] + input + label {
	background: url("../images/icons/checkOffA.svg") no-repeat left center;
	background-size: 24px 24px;
}
.STunitsTable.darkTheme > ul > li .customCheckbox input[type=checkbox]:checked + label,
.STunitsTable.darkTheme > ul > li .customCheckbox input[type=checkbox]:checked + i + label,
.STunitsTable.darkTheme > ul > li .customCheckbox input[type=checkbox]:checked + input + label {
	background: url("../images/icons/checkOnA.svg") no-repeat left center;
	background-size: 24px 24px;
}
/* ****** ****** ****** ****** ****** **
 * ****** tags Side Headers ****** *
** ****** ****** ****** ****** ****** */ 
.tagsSideHeader {
	width: 100%;
	display: table;
}
.tagsSideHeader > ul {
	width: 100%;
	display: table-row;
}  
.tagsSideHeader > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #ffffff;
	font-size: 16px;
	text-align: left; 
	background:#0d3968;
} 
.tagsSideHeader > ul > li:nth-child(1) { 
	text-align: left;
}   
.tagsSideHeader > ul > li:nth-child(2) {
	width: 54px;
	text-align: center; 
}  
.tagsSideHeader > ul > li .collapseExpand.collapsed,
.tagsSideHeader > ul > li .collapseExpand.collapsed:hover,
.tagsSideHeader > ul > li .collapseExpand.collapsed:focus,
.tagsSideHeader > ul > li .collapseExpand.collapsed:active {
	background: url("../images/icons/arrow2r_A.svg") no-repeat center center;
	background-size: 24px 24px;
	width: 24px;
	height: 24px;
	border: 0px;
	float: left;
}
.tagsSideHeader > ul > li .collapseExpand,
.tagsSideHeader > ul > li .collapseExpand:hover,
.tagsSideHeader > ul > li .collapseExpand:focus,
.tagsSideHeader > ul > li .collapseExpand:active {
	background: url("../images/icons/markoffA.svg") no-repeat center center;
	background-size: 24px 24px;
	width: 24px;
	height: 24px;
	border: 0px;
	float: left;
}
/* ****** ****** ****** ****** ****** **
 * ****** Transfer Modal ****** *
** ****** ****** ****** ****** ****** */
.orgChartUnitBoxO {
	width: 100%;
	padding:0px 15px 0px 0px;
	float: left;
	clear: both;
	overflow:hidden;	
	overflow-y: auto; 
	height:300px;
	text-align: center;
}  
.orgChartUnitBoxO .addButton {
	background:url("../images/recipecreation/addButton.png") no-repeat left center;
	background-size: 26px 26px;   
	width:32px;
	height:32px; 
	display:inline-block;	
} 
.transferDetailsModal .transferModalW {
	width: 100%;
	float: left;
	padding:15px 15px; 
	overflow-x:hidden;
	overflow-y:auto;
}
.transferModalW .transferModalL {
	width:34%;
	float: left; 
}
.transferModalW .transferModalR {
	width:66%;
	float: left;
	padding-left:15px;	
}
.orgChartUnitBox {  
	border:1px solid #afc6dc; 
	position:relative;
	margin:35px 0px;
	border-radius:12px; 
	display:table;
	width:100%; 
	position:relative;
} 
.orgChartUnitBox:before {   
	content:" ";
	width:24px;
	height:24px;
	position:absolute;
	left:50%;
	top:-30px;
	margin-left:-12px;
	background:url("../images/icons/plusA.svg") no-repeat center center;
	background-size: 24px 24px; 
} 
.orgChartUnitBox:first-child {   
	margin-top:12px; 
} 
.orgChartUnitBox:first-child:before {   
	display:none; 
} 
.orgChartUnitBox .orgChartUnitRow{  
	display:table-row;
	width:100%;
}  
.orgChartUnitBox .orgChartSTBox,
.orgChartUnitBox .unitsSTBox {
    width:50%;
	display:table-cell;
	vertical-align:top;
	padding:15px;
	height:120px;
	text-align:left;
}
.orgChartUnitBox .placeholderD{
    width:100%;
	float:left;
	text-align:center;
	color: #0D3968;	
	padding-top:25px;
} 
.orgChartUnitBox .placeholderD.removeDiv{
	display:none;
}
.orgChartUnitBox .placeholderD h5{ 
    font-family: 'open_sanssemibold';
	font-size:16px;
	margin-bottom:5px;
} 
.orgChartUnitBox .placeholderD p{  
	font-size:16px;
	padding:0px;
	margin:0px;
}  
.orgChartUnitBox .orgChartSTBox .tagTypeUnit,
.orgChartUnitBox .orgChartSTBox .tagTypeLocation,
.orgChartUnitBox .unitsSTBox .tagTypeUnit,
.orgChartUnitBox .unitsSTBox .tagTypeLocation{
    margin-right:5px;
	margin-bottom:5px;
	padding-right:32px;
} 
.orgChartUnitBox .orgChartSTBox {
    background:url("../images/icons/stLocationIcon.png") no-repeat center center;
	background-size: 60px 60px; 
}  
.orgChartUnitBox .unitsSTBox {
    background:url("../images/icons/stUnitIcon.png") no-repeat center center;
	background-size: 60px 60px; 
}  
.orgChartUnitBox .dropedItemDelete {
	width: 20px;
	height: 20px; 
	padding:2px;
	top: 7px;
	right: 7px;
	position:absolute;
	background:#c6d7e6 url("../images/icons/cancelB.svg") no-repeat center center;
	background-size: 16px 16px;
	border-radius:50%;
}  
.orgChartUnitBox .unitsSTBox { 
	border-left:1px dashed #afc6dc; 
}
.transferModalR .resetBtn,
.transferModalR .applyBtn {
	border: 0px;
    border-radius: 24px;
    padding: 8px 15px;
    font-size: 16px;
    min-width: 100px;
	float:right;
	margin:15px 0px 0px 15px;
	background: #195d99;
	color:#ffffff;
} 
.transferModalR .orgChartUnitBox .deleteButton {
	width: 28px;
	height: 28px;
	border-radius:50%;
	display: none;
	position: absolute;
	top: -10px;
	right: -10px;
	background:#0d3968 url("../images/icons/trashA.svg") no-repeat center center;
	background-size: 18px 18px;
} 
.transferModalR .orgChartUnitBox .deleteButton.deleteActive {
    display: block;
}
/* ****** ****** ****** ****** ****** **
 * ****** Transfer Table ****** *
** ****** ****** ****** ****** ****** */
.transferModalL h4 {
	padding:8px 15px 8px;
	color: #08223f; 
	display: inline; 
	background: #afc6dc;
	font-size: 16px;
	border-radius:6px 6px 0px 0px;	
	width: 100%; 
	float: left;
	clear: both; 
	text-align:left;	
} 
.transferMTable {
	width: 100%;
	display: table;
}
.transferMTable ul {
	width: 100%;
	display: table-row;
} 
.transferMTable ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
	background:#ffffff;
}  
.transferMTable ul > li:nth-child(2) {
	width: 54px;
	text-align: center;
}   
.transferMTable ul > li .stUnitsDetails {
	background: url("../images/icons/infoReport.svg") no-repeat center center;
	background-size: 24px auto;
	width:24px;
	height:24px;
} 
.pageScrollbar {
	width: 100%;
	float: left; 
	overflow-x:hidden;
	overflow-y:auto;
}
/* ****** ****** ****** ****** ****** **
 * ****** Baking Plans Table ****** *
** ****** ****** ****** ****** ****** */

.bakingPlansList {
	width: 100%;
	float: left;
	padding: 0px;
} 
.bakingPlansList {
	width: 100%;
	display: table;
}
.bakingPlansList > ul {
	width: 100%;
	display: table-row;
}
.bakingPlansList > ul.tHead li {
	color: #657f9c;
	background: #E9EAEB;
	border-bottom: 1px solid #607488;
}
.bakingPlansList > ul.tHead .active {
	color: #153f6d;
}
.bakingPlansList > ul.tBody li {
	padding: 15px 10px;
}
.bakingPlansList > ul > li {
	display: table-cell;
	padding: 10px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #0D3968;
	font-size: 16px;
	text-align: left; 
}  
.bakingPlansList > ul > li:nth-child(1) { 
} 
.bakingPlansList > ul > li:nth-child(2), 
.bakingPlansList > ul > li:nth-child(3),
.bakingPlansList > ul > li:nth-child(4) {
	width: 240px;
} 
.bakingPlansList > ul > li:nth-child(5) {
	width: 180px;
	text-align: center;
}
.bakingPlansList > ul > li:nth-child(5) .btn {
	background: #195d99;
	color: #ffffff;
	min-width: 80px;
}
.bakingPlansList > ul.tBody > li .bakingPlansSM,
.bakingPlansList > ul.tBody > li .bakingPlansSMD{
	display: none;
	width: 100%;
	float: left;
} 
/*** **************  ************** ****
		*** ST Confirmation Box List ***
**** **************  ************** ***/

.stConfirmBox {
	width: 90%;
	display:table; 
	margin:15px 5%;
} 
.stConfirmBox > ul{
	width: 100%;
	display:table-row; 
}
.stConfirmBox > ul > li {
	display: table-cell;
	padding:10px 10px;
	vertical-align: middle; 
	color: #16406d;
	font-size: 16px;
	text-align: center;
	height:120px;
	width:33.333%;	
}  
.stConfirmBox .unitsWithST,
.stConfirmBox .unitsWithoutST,
.stConfirmBox .errorsST{ 
    width: 120px;
    height: 120px;
    border-radius: 50%;
    padding:22px 10px;  
    color: #ffffff; 
	text-align:center;
	display:inline-block; 
	vertical-align: middle;
}
.stConfirmBox h5{ 
    font-family: 'open_sanssemibold';
	font-size:24px;
	margin-bottom:5px;
} 
.stConfirmBox h6{  
	font-size:14px; 
} 
.stConfirmBox .stDataD { 
    margin-left: 15px;
    font-family: 'open_sanssemibold';
    display: inline-block; 
    text-align: left;
	vertical-align: middle;
}  
.stConfirmBox .stDataD h6{   
	margin:5px 0px;	 
} 
.stConfirmBox .unitsWithST { 
    background:#70bf69;
}  
.stConfirmBox .unitsWithoutST { 
    background:#666666;
} 
.stConfirmBox .errorsST { 
    background:#950000;
}  
.stConfirmBox .stDataD.unitsWithSTH { 
    color:#70bf69;
}  
.stConfirmBox .stDataD.unitsWithoutSTH { 
    color:#666666;
} 
.stConfirmBox .stDataD.errorsSTH { 
    color:#950000;
}  
.stConfirmBox .duplicateDetails {
	background: url("../images/icons/infoReportR.svg") no-repeat center center;
	background-size: 24px auto;
	width:24px;
	height:24px;
	display:inline-block;
}
/*** **************  ************** ****
		*** Validating the File ***
**** **************  ************** ***/ 

.validatingFile { 
	display:none;
	position: fixed;  
	z-index: 999999; 
	top: 0px;
	left: 0px;
	outline: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	background: url("../images/bgTransparent.png") repeat;
}
.validatingFile > span {
	position: absolute;
	top: 50%;
	left: 50%;	
	margin-left:-140px;
	z-index: 99999; 
	width:280px;  
	background:#195d99;
	font-size:12px;
	color:#ffffff;
	padding:6px 10px;
	text-align:center;
} 
/* ****** ****** ****** ****** ****** **
 * ****** Smart Tag Units Table ****** *
** ****** ****** ****** ****** ****** */  
.totalSubElements,
.assignedUsersDiv {
	width: 100%;
	height:100%;
	padding:0px;  
	display:none;
	position:absolute;
	left:0px;
	top:0px;
	z-index:2;
	background:#ffffff;
}  
.totalSubElements.active,
.assignedUsersDiv.active { 
	display:block; 
} 
.assignedUsersDiv.customerV { 
	display:block; 
}  
.totalSubElementsClose,
.assignedUsersClose {
	width: 28px;
	height: 28px;
	background: url(../images/icons/cancelB.svg) no-repeat center center;
	background-size: 28px 28px;
	position:absolute;
	top:5px;
	right:8px;
}
.STaUsersTable {
	width: 100%;
	display: table;
}
.STaUsersTable > ul {
	width: 100%;
	display: table-row;
}
.STaUsersTable > ul.tHead > li {
	color: #16406d;
	border-bottom:0px;
	background: #e9eaeb;
} 
.STaUsersTable > ul > li {
	display: table-cell;
	padding: 7px 10px;
	vertical-align: middle;
	border-bottom: 1px solid #aab4be;
	color: #16406d;
	font-size: 16px;
	text-align: left;
	background:#ffffff;
} 
.STaUsersTable > ul:last-child > li { 
	border-bottom: 0px; 
}   
.STaUsersTable > ul.activeRow > li { 
	background:#aac8e3;
	border-bottom: 1px solid #63778a;
}  
.STaUsersTable > ul.tHead > li.tHeadTitle {
    font-family: 'open_sanssemibold';
}  
.STaUsersTable > ul > li:nth-child(2) {
	width: 190px; 
} 
.STaUsersTable > ul > li .stUnitsDetails {
	background: url("../images/icons/infoReport.svg") no-repeat center center;
	background-size: 24px auto;
	width:24px;
	height:24px;
}
.STaUsersTable > ul > li .customCheckbox {
	margin:5px 0px 0px;
	display: inline-block;
}
.STaUsersTable > ul > li .customCheckbox label {
	margin: 0px;
	padding-left: 24px;
	height: 24px;
	width: 24px;
}  
/*** **************  ************** ****
		*** Nav Header ***
**** **************  ************** ***/ 
.navHeader {
	width: 100%;
	padding: 0px;
	display: table; 
	border-bottom: 1px solid #7e8690;
}
.navHeader > ul {
	width: 100%;
	padding: 0px;
	display: table-row;
}
.navHeader > ul > li {
	padding: 18px 15px;
	display: table-cell;
	color: #0D3968;
	vertical-align: middle;
	font-size:18px;
	font-family: 'open_sanssemibold';
} 
.navHeader > ul > li:nth-child(1) {
	text-align: left;  
} 
.navHeader > ul > li:nth-child(2) {
	text-align: right;  
	max-width:320px;
}   
.navHeader > ul > li > a {
	color:#96a8bb;
	margin-right:25px;
} 
.navHeader > ul > li > a.active {
	color:#0D3968; 
}  
.navHeader > ul > li .btn{ 
	margin-left:15px;
}
.navHeader > ul > li .btn.disabled{  
	color: #bcc3cf;
}
.navHeader > ul > li .orgznInfo,  
.navHeader > ul > li .orgznAddBelow,
.navHeader > ul > li .orgznAddRight, 
.navHeader > ul > li .orgznTrash{
	width: 24px;
	height: 24px; 
}

.navHeader > ul > li .orgznInfo {
	background: url("../images/icons/informationB.svg") no-repeat center center;
	background-size: 24px 24px;
}   
.navHeader > ul > li .orgznAddBelow {
	background: url("../images/icons/addBelowD.svg") no-repeat center center;
	background-size: 24px 24px;
} 
.navHeader > ul > li .orgznAddRight {
	background: url("../images/icons/addRightD.svg") no-repeat center center;
	background-size: 24px 24px;
}  
.navHeader > ul > li .orgznTrash {
	background: url("../images/icons/trashB.svg") no-repeat center center;
	background-size: 24px 24px;
} 
.STaUsersTableSrlBar,
.tagsList,
.TSESrlBar,
.orgznSiteTreeOuter1,
.transferMTableBar,
.registerULtableSrlBar,
.modalBodyContScrl,
.openLogFilesModalDiv,
.allErrorListSrlBar,
.responseCListTableForm,
.countryListTableS,
.selectRecipeTableForm,
.menuLibraryGSrlTable,
.menuLibrarySrlTable,
.readyRecipeSrlTable,  
.unitRegListTableSrlBar, 
.unitRegCListSrlBar,
.userMgmtTableSrlBar,
.mediaTableListForm,
.mediaPackageForm,
.assignUListSrlBar,
.samrtTagTableSrlBar,
.reportListTableSrlBar,
.reportScdlTableSrlBar,
.statusTableSGSrlBar,
.reportAsgnListSrlBar,
.statusMTableSrlBar,
.statusTableSrlBar,
.notificationlibrary,
.STComfmtListSrlBar,
.STunitsTableSrlBar,
.STListAMTableSrlBar,
.tableBodyScrollbar {
	width: 100%;
	padding:0px;
	float: left; 
	overflow-y: auto; /* Fallback */
    overflow-y: overlay;
} 
 
.STunitsTableSrlBar { 
	max-height:320px; 
	min-height:320px;
}  
.STListAMTableSrlBar { 
	max-height:145px; 
}
.menuLibraryGSrlTable,
.menuLibrarySrlTable,
.readyRecipeSrlTable { 
	overflow-x: hidden;
	background-color: #FFF;
} 
.selectRecipeTableForm {  
	overflow-x: hidden;
	height: 380px;
}
.countryListTableS { 
	overflow-x: hidden;
	max-height: 420px;
}
.responseCListTableForm { 
	width: 2400px;
	height: 420px;
}
.reviewOptionsTwoS {
	width:630px; 
	height:120px; 
	padding:0px 0px 20px;  
	overflow-x:auto;
    overflow-y:hidden; 
}
.allErrorListSrlBar { 
	min-height:200px;
}   
.openLogFilesModalDiv {
	height: 380px; 
	padding: 20px 15px; 
	overflow-x: hidden;
} 
.modalBodyContScrl {
	height: 380px; 
	overflow-x: hidden;
} 
.registerULtableSrlBar { 
	height:240px;
} 
.tagsList { 
	max-height: 160px; 	
}  
.transferMTableBar { 
	max-height:160px;
	margin-bottom:15px;
	border: 1px solid #63778a;
}   
.orgznSiteTreeOuter1 { 
	padding:0px 25px 0px 0px;
}  
.TSESrlBar { 
	height:340px;
} 
.STaUsersTableSrlBar { 
	height:330px; 
}
/* ****** ****** ****** ****** ****** **
 * ****** Cookbook Rc DND ****** *
** ****** ****** ****** ****** ****** */

.cbdnd {
	width: 100%;
	float: left;
	clear: both;
	margin-top: 10px;
} 
.cbdnd .rcListD {
	width: 25%;
	padding: 10px 5px;
	float: left;
	text-align: center;
} 
.cbdnd .rcListD.addCategoryLi > .cbcategoryBox > .navIcon {
	background: none;
}
.cbdnd .rcListD.addCategoryLi > .cbcategoryBox > .navIcon > img {
	max-height: 45px;
	max-width: 45px;
	line-height: 80px;
}
.cbdnd .rcListD > .cbcategoryBox {
	position: relative;
	display: inline-block;
	width: 90px;
	cursor: move;
}
.cbdnd.menuEditActive .rcListD > .cbcategoryBox .cancelButton {
	display: block;
}
.cbdnd .rcListD .cbcategoryAction {
	width: 42px;
	height: 42px;
	padding:6px;
	border-radius:50%; 
	position: absolute;
	top: 22px;
	left:25px;
	background: url("../images/bgTransparent.png") repeat; 
	border:1px solid #ffffff; 
}
.cbdnd .rcListD .cbcategoryAction .cbEditButton {
	background: url("../images/icons/rcEdit.png") no-repeat center center;
	background-size: 26px 26px; 
	width:26px;
	height:26px;
	display:block;
} 
.cbdnd .rcListD > .cbcategoryBox .cancelButton {
	width: 24px;
	height: 24px;
	border-radius:50%;
	display: block;
	position: absolute;
	top: -10px;
	right: -10px;
	background: url("../images/bgTransparent.png") repeat; 
	border:1px solid #ffffff; 
}
.cbdnd .rcListD > .cbcategoryBox .cancelButton:before { 
	background: url("../images/icons/trashA.svg") no-repeat center center;
	background-size: 16px 16px;
	position: absolute;
	top:3px;
	left: 3px; 
	width:18px;
	height:18px;
	content: " "; 
	z-index: 1;
}
.cbdnd .rcListD > .cbcategoryBox > .navTitle {
	width: 100%;
	float: left;
	clear: both;
	padding: 5px 0px 0px;
	font-size: 16px;
	line-height: 16px;
	color: #ffffff;
	text-align: center;
	min-height: 54px; 
	max-height: 54px;
}
.cbdnd .rcListD > .cbcategoryBox > .navIcon {
	width: 90px;
	display: inline-block; 
	text-align: center;
	height: 90px;
	line-height: 80px; 
	overflow: hidden;
	vertical-align: bottom; 
	border:1px dashed #ffffff;
	padding:3px;
	cursor: move;
}
.cbdnd .rcListD > .cbcategoryBox > .navIcon > img {
	max-height: 90px;
	max-width: 90px;
	width: 100%;
	display: inline-block;
	vertical-align: bottom;
}
.cbdnd .rcListD > .cbcategoryBox .cbRCicon,
.cbdnd .rcListD > .cbcategoryBox .cookbookicon,
.cbdnd .rcListD > .cbcategoryBox .cbCategoryicon {
	width: 28px;
	height: 28px;
	position: absolute;
	top: 5px;
	right: 5px;
	border-radius: 4px; 
}
.cbdnd .rcListD > .cbcategoryBox .cbRCicon { 
	background: url("../images/recipecreation/recipeCreationA.png") no-repeat center center;
	background-size: 28px 28px;
}
.cbdnd .rcListD > .cbcategoryBox .cookbookicon { 
	background: url("../images/recipecreation/cookbookA.png") no-repeat center center;
	background-size: 28px 28px;
}
.cbdnd .rcListD > .cbcategoryBox .cbCategoryicon { 
	background: url("../images/recipecreation/categoryA.png") no-repeat center center;
	background-size: 28px 28px;
}
.cbdnd .rcListD.actionIcon > a > .navIcon > img, 
.cbdnd .rcListD:nth-child(1) > a > .navIcon > img { 
    max-height: 45px; 
    max-width: 45px; 
    display: inline-block; 
}  
.cbdnd .rcListD.actionIcon .navTitle,
.cbdnd .rcListD.actionIcon .navIcon {
	width: 100%;
	float: left;
	clear: both;
	padding: 0px; 
	text-align: center;
} 
.cbdnd .rcListD.actionIcon .navTitle { 
	font-size: 16px;
	color: #ffffff; 
}
.cbdnd .rcListD.actionIcon .navIcon { 
	height: 80px;
	line-height: 80px;
}
.global-drag-line {
	border: 0 !important;
}